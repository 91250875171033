@import (reference) "../styles/main";

.PlatformLayout {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: @color-platformBackground;
  position: relative;
  &.small {
    height: calc(100% - 40px);
  }
  &__wrapper {
    width: 100%;
    overflow: hidden;
    flex: 1;
  }
  &__content {
    overflow: auto;
    flex: 1;
    height: 100%;
    padding-bottom: 80px;
  }
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
