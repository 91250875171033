.InternalApproval {
    &__header {
        & > h3 {
            font-size: 18px;
            color: var(--gray-900);
            font-weight: 600;
            margin-bottom: 24px;
            margin-top: 24px;
        }
}
}
