@import (reference) "../../../styles/main";

.webinar_card {
  &-summary_background {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: @color-violet-900;

    @media @tablet-landscape {
      justify-content: flex-start;
      width: 100%;
      min-height: unset;
    }
  }

  &-summary_card {
    width: 100%;
    max-width: ((@layout-max-width) / 2);
    height: 100%;
    padding: 0 5% 0 1.5rem;
    color: @color-white;

    @media @tablet-landscape {
      max-width: unset;
      padding: 0 1.5rem @main-padding-bottom;
    }

    > a {
      min-height: 2.5rem;
      height: 2.5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      margin-bottom: 2rem;

      @media @tablet-landscape {
        height: 5.625rem;
        margin-top: 0;
        margin-bottom: (@hero-padding-bottom / 2);
      }
    }
  }

  &-header {
    .text-heading-2();
    color: @color-white;
    margin-bottom: 3rem;
  }

  &-summary {
    display: flex;
    flex-direction: column;
    > * {
      .text-paragraph();
      color: @color-violet-100;
      display: block;
    }
    > h1,
    > h2,
    > h3,
    > h4 {
      .text-heading-4();
      color: @color-white;
      margin-top: 0;
    }

    & > p:first-of-type {
      .text-subheading-2();
      margin-top: 0;
      margin-bottom: 1rem;
      color: @color-white;
    }

    > *:empty,
    > * *:empty {
      margin: 0;
      height: 0;
    }

    strong {
      font-weight: normal;
      color: @color-white;
    }
  }

  &-presenter {
    position: relative;
    z-index: 20;
    display: flex;
    width: 100%;
    font-family: @font-family-body;
    font-size: 1.125rem;
    line-height: 1.575rem;
    align-items: center;
    margin-bottom: 1.875rem;
    img {
      width: 5rem;
      height: 5rem;
      margin-right: 1.75rem;
      border: 0.625rem solid rgba(197, 191, 230, 0.2);
    }
  }

  &-presenter_info {
    p:first-child {
      font-weight: bold;
      margin-bottom: 0.625rem;
    }
  }

  &-table {
    border: 1px solid @color-violet-800;
    position: relative;
    margin-top: 2rem;
    background-color: @color-violet-1000;
    border-radius: 1.5rem;

    p {
      display: flex;
      align-items: center;
      .text-paragraph();
      color: @color-violet-200;
      margin: 0;
      padding: 0.75rem 1.5rem;
      border-bottom: 1px solid @color-violet-800;
    }
  }

  &-table_header {
    font-weight: bold !important;
    color: @color-white !important;
  }

  &-table_footer {
    display: none;
  }

  &-spinner {
    margin: 3.125rem auto;
    height: 3.75rem;
    width: 3.75rem;
    border-left: 0.3125rem solid @color-teal-400;
    border-right: 0.3125rem solid @color-teal-400;
    border-top: 0.3125rem solid transparent;
    border-bottom: 0.3125rem solid transparent;
    border-radius: 1.875rem;
    animation: @animation-spin;
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
