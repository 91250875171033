@import (reference) "../../../styles/main";

.ProductPage {
  &__paper {
    &__inputsTable {
      margin-top: 40px;
      &__title {
        font-size: @text-size-22;
        font-weight: 600;
        margin-bottom: 20px;
        padding: 0 30px;
        .bp-mobile({
          padding: 0 20px;
        });
      }
      &__actions {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
    &__bottom__section {
      display: flex;
      .bp-desktop-small({
        flex-direction: column;
      });
      section:first-of-type {
        width: 60%;
        .bp-desktop-small({
          width: 100%;
        });
      }
      section:last-of-type {
        width: 40%;
        .bp-desktop-small({
          width: 100%;
        });
      }
    }
  }
}
