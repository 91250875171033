@import (reference) "./main.less";

.MotionElementPopupZIndex {
  z-index: @z-index-popups;
}
.MotionElementModalZIndex {
  z-index: @z-index-modals;
}

@keyframes skeletonBackgroundAnimation {
  0% {
    background-color: #cfcfcf;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #cfcfcf;
  }
}
