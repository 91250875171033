@import (reference) "../../styles/main";

.Modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: @z-index-modals;
  &__backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #00000066;
  }
  &__motionElement {
    width: 100%;
    height: 100%;
  }
}
