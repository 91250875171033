@import (reference) "../../../../styles/main";

.ApplicableRegulationsDescriptions {
  &__message {
    background-color: #F3F4F6;
    padding: 16px 20px;
    border-radius: 12px;
    margin-bottom: 24px;

    &__header {
      display: flex;
      align-items: center;
    }

    &__text {
      font-size: @text-size-22;
      color: #4B5563;
      font-weight: 600;
      margin-left: 16px;
    }
  
    .LockTag {
      width: 52px;
      height: 52px;
      background-color: #E5E7EB;

      > svg {
        width: 20px;
        height: 20px;
        stroke-width: 2px;
      }
    }

    > p:not(:first-child) {
      margin-top: 12px;
    }
  }
}

.ApplicableRegulationsTags {
  display: flex;
  align-items: center;
  margin-left: 12px;

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px 0 8px;
    background-color: #F3F4F6;
    font-weight: 400;
    font-size: @text-size-16;
    border-radius: 16px;
    color: #374151;
    margin-right: 10px;
    min-width: 82px;
    &:last-of-type {
      margin-right: 0;
    }
    > svg {
      margin-right: 6px;
      stroke: #9CA3AF;
      stroke-width: 3px;
    }
  }
  &--sm {
    & .ApplicableRegulationsTags__tag {
      height: 32px;
    }
  }
  &--lg {
    & .ApplicableRegulationsTags__tag {
      height: 40px;
    }
  }
}
