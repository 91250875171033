@import (reference) "../../../styles/main";

.exchange_quote {
  &-container {
    margin-bottom: @main-padding-bottom;
    @media @mobile {
      margin-bottom: @main-padding-bottom-mobile;
    }
  }

  &-main {
    padding: @card-medium-padding;
    background-color: @color-teal-900;
    border-radius: @card-border-radius;
    position: relative;
  }

  &-quote {
    position: relative;
    .text-heading-3();
    color: @color-white;
  }

  &-author {
    display: block;
    .text-heading-4();
    margin-top: 3rem;
    color: @color-white;
  }

  &-role {
    .text-paragraph();
    color: @color-white;
  }

  &-background {
    position: absolute;
    filter: grayscale(100%) invert(100%);
    top: 6rem;
    opacity: 0.2;
    right: 6rem;
  }

  &-logo {
    margin-bottom: 3.125rem;
  }
}

@media @tablet-landscape {
  .exchange_quote {
    &-container {
      padding: 0;
    }
  }
}

@media @tablet {
  .exchange_quote {
    &-main {
      padding: 3.125rem;
    }

    &-quote {
      font-size: 1.5rem;
    }

    &-author {
      font-size: 1.25rem;
    }

    &-role {
      font-size: 1.25rem;
    }

    &-background {
      top: 3.125rem;
      right: -0.9375rem;
      transform: scale(0.5);
    }
  }
}

@media @mobile {
  .exchange_quote {
    &-main {
      padding: 1.875rem;
    }

    &-background {
      top: 1.875rem;
      right: -3.75rem;
      transform: scale(0.3);
    }
  }
}
