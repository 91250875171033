@import (reference) "../../../styles/main";

.home_get_started {
  &-background {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 25rem;
    padding-bottom: 6.25rem;
  }

  &-main {
    display: flex;
    flex-direction: column;
    padding: 0 1.875rem;
  }

  &-texts {
    display: flex;
    justify-content: space-between;
  }

  &-header {
    font-family: @font-family-headings;
    font-weight: 700;
    font-size: 2.625rem;
    line-height: 120%;
    margin-bottom: 2.8125rem;
  }

  &-paragraph {
    font-family: @font-family-body;
    font-style: normal;
    font-weight: 400;

    font-size: 1.5rem;
    line-height: 140%;
    max-width: 50%;
    white-space: pre-wrap;

    a {
      font-weight: 600;
      text-decoration-line: underline;
      color: @color-primary-900;
    }
  }

  &-buttons {
    display: flex;

    a {
      margin-left: 1.25rem;
      font-family: @font-family-body;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.5rem;
      border-radius: 0;
      width: auto;
      min-width: auto;
      padding: 0 1.5rem;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

@media @tablet-landscape {
  .home_get_started {
    &-mask {
      top: -56.25rem;
    }

    &-texts {
      flex-direction: column;
    }

    &-paragraph {
      max-width: 100%;
      margin-bottom: 2.8125rem;
    }
  }
}

@media @tablet {
  .home_get_started {
    &-background {
      padding-bottom: 3.125rem;
    }

    &-main {
      padding: 0;
    }
  }
}

@media @mobile {
  .home_get_started {
    &-buttons {
      flex-direction: column;

      a {
        margin-left: 0;
        margin-bottom: 1.25rem;
      }
    }
  }
}
