@import (reference) "../../../styles/main";

.MetricContainer {
  padding: 0;
  &__header {
    padding: 30px 30px 0 30px;
    display: flex;
    .bp-mobile({
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: auto 1fr;
    });
    &__title {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      font-size: @text-size-22;
      font-weight: 600;
      align-items: flex-end;
      > p {
        margin-right: 10px;
      }
      > span {
        white-space: nowrap;
        font-size: @text-size-18;
        font-weight: 400;
        line-height: 1.2;
        color: #5b5b5b;
      }
    }
    &__button {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: @border-radius-sm;
      border: none;
      box-shadow: none;
      color: @color-coolGray-900;
      width: 30px;
      height: 30px;
      padding: 0;
      outline: none;
      cursor: pointer;
      transition-property: background-color;
      transition-duration: @transition-duration;
      transition-timing-function: @transition-timing-function;
      .bp-mobile({
        grid-column: 2;
        grid-row: 1;
      });
      & > svg {
        width: 20px;
        height: 20px;
        color: #5b5b5b;
      }
      &:hover {
        background-color: #bab9c1;
      }
      &:focus {
        background-color: #bab9c1;
      }
      &:active {
        background-color: @color-primary-900;
      }
      &--active {
        background-color: @color-primary-900;
        & > svg {
          color: @color-white;
        }
        &:hover {
          background-color: @color-primary-600;
        }
        &:focus {
          background-color: @color-primary-600;
        }
        &:active {
          background-color: @color-primary-600;
        }
      }
      //background-color: rgba(0, 0, 0, 0.1);
      //& > svg {
      //  width: 20px;
      //  height: 20px;
      //  color: #5b5b5b;
      //};
      //&--active {
      //  &:hover {
      //    background-color: @color-primary-200;
      //  }
      //  &:focus {
      //    background-color: @color-primary-900;
      //    & > svg {
      //      color: @color-white
      //    }
      //  }
      //  background-color: @color-primary-900;
      //  & > svg {
      //    color: @color-white
      //  }
      //}
    }
  }
  &__chartWrapper {
    padding: 0 30px;
  }
  &__tableWrapper {
    margin-top: 20px;
    overflow: hidden;
  }
  &__dummy {
    width: 100%;
    height: 300px;
    background-color: green;
  }
}
