@import (reference) "../styles/main";

.DevTools {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: @z-index-top;
  &__paper {
    border-radius: 6px;
    overflow: auto;
    max-height: 95%;
    min-width: 300px;

    &__table {
      display: grid;
      grid-template-columns:  1fr 1fr 1fr;
    }

    &__column {
      padding: 0 10px;

      &__title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      
      .FormComponent:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__item {
      &--true {
        background-color: @color-teal-100;
      }

      &--false {
        background-color: @color-red-100;
      }
    }

    &__actions {
      display: flex;
      gap: 15px;
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid @color-coolGray-300;
      justify-content: center;

      > button {
        width: 150px;
      }
    }
  }
}
