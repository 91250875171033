@import (reference) "../../../styles/main";

.newsletter_form {
  &-main {
    .card-medium-Layout();
    width: fit-content;

    @media @tablet-landscape {
      width: 100%;
    }

    .button-component {
      width: 100%;
      min-width: unset;
    }
    .terms_checkbox-main {
      margin-bottom: 1.875rem;
    }
  }

  &-label {
    .text-heading-3();
  }
}
