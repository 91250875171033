@import (reference) "../../../styles/main";

.DeactivateUserModal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: @z-index-modals;
  &__backdrop {
    background-color: #00000066;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__paper {
    width: 600px;
    &__header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &__closeButton {
        & > svg {
          color: #BAB9C1;
        }
      }
    }
    &__content {
      padding: 20px 50px 50px 50px;
      &__title {
        font-size: @text-size-32;
        font-weight: 600;
        margin-bottom: 20px;
      }
      &__message {
        margin-bottom: 20px;
      }
      &__deactivateButton {
        width: 100%;
      }
    }
  }
}
