@import (reference) "../../../../styles/main";

.UseCasesBatchInputsGuide {
  background-color: #F9FAFB;
  overflow-y: auto;
  padding-top: 15px;
  transition: @transition-all;
  min-height: 100%;
  &--requirementsMet {
    background-color: @color-white;
  }
  &__message {
    padding: 0 15px;
    margin-bottom: 20px;
    color: #5b5b5b;
    &--requirementsMet {
      & > p:first-of-type {
        color: #4F9E90;
      }
    }
    & > p:first-of-type {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-weight: 600;
      & > svg {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
  }
  &__providersList {
    padding: 0 15px;
    & > li {
      margin-bottom: 10px;
    }
  }
}

.UseCasesBatchProvidersListElement {
  background-color: #F1F1F7;
  border-radius: @border-radius-sm;
  border: 1px solid #E5E7EB;
  width: 100%;
  &__noInputs {
    overflow: hidden;
    color: #5b5b5b;
    padding: 8px 16px;
    font-size: @text-size-14;
    &--hidden {
      max-height: 0px;
      padding: 0;
    }
  }

  &--requirementsMet {
    background-color: #EFFFFC;
  }
  &__header {
    width: 100%;
    outline: none;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    border-bottom: 1px solid #E5E7EB;
    background-color: #F9FAFB;
    cursor: pointer;
    &--noBorderBottom {
      border-bottom: none;
    }
    &--highlight {
      background-color: #EFFFFC;
    }
    &__listIndicator {
      > svg {
        color: #9B92D2;
        stroke-width: 2.5;
        margin-left: 15px;
      }
    }
    &__providerInfo {
      display: flex;
      gap: 10px;
      align-items: center;
      .bp-phone({
        flex-direction: column;
        align-items: flex-start;
      });
      &__logo {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        & > img {
          max-width: 100%;
          height: 100%;
        }
      }
      &__details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: start;
        font-weight: 600;
        line-height: 20px;
        font-size: @text-size-14;
        color: #6B7280;
      }
    }
    &__requirementsMet {
      font-weight: 600;
      display: flex;
      align-items: center;
      font-size: @text-size-16;
      color: #bab9c1;
      &--true {
        color: #4F9E90;
      }
      & > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  &__inputsSetsList {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
    padding: 8px 16px;
    &--hidden {
      max-height: 0px;
      padding: 0;
    }
    &__element {
      position: relative;
      padding: 5px 10px 5px 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      font-weight: 600;
      line-height: 20px;
      &__input {
        display: flex;
        align-items: center;
        font-size: @text-size-14;
        color: #9CA3AF;
        margin-left: 5px;
        font-weight: 400;
        &--filled {
          color: #4F9E90;
        }
        &__fillCheck {
          position: absolute;
          left: 0;
          height: 20px;
          width: 20px;
          min-width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          & > svg {
            width: 100%;
            color: #bab9c1;
            width: 14px;
            height: 14px;
            min-width: 14px;
            color: #C7C3E4;
            stroke-width: 2;
          }
          &--filled > svg {
            color: #4F9E90;
          }
        }
        &__plus {
          height: 20px;
          width: 20px;
          min-width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 1px;
          & > svg {
            width: 100%;
            margin-right: 5px;
            color: #bab9c1;
            width: 12px;
            height: 12px;
            min-width: 12px;
            color: #C7C3E4;
            stroke-width: 3;
          }
          &--filled > svg {
            color: #4F9E90;
          }
        }
      }
    }
  }
}
