@import (reference) "../../../styles/main";

.AuthPageHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .bp-mobile({
    justify-content: flex-start;
    padding: 20px 20px;
  });
  & > a {
    display: flex;
    align-items: center;
    > svg {
      height: 40px;
      .bp-mobile({
        height: 30px;
      });
      fill: black;
      & path {
        fill: @color-primary-900;
      }
    }
  }
}
