@import (reference) "../../../styles/main";

.platform_solutions {
  .main-Layout();
  h2 {
    .text-heading-2();
    color: @color-violet-900;
    text-align: center;
    max-width: 50rem;
    padding-bottom: 4rem;
  }

  &-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    & > a > div {
      .card-small-Layout();
      .card-hoverable-Layout();
      height: 100%;
      border-color: @color-coolGray-50;
      background-color: @color-coolGray-50;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: @card-medium-padding;
      &:hover {
        background-color: @color-coolGray-100;
        .platform_solutions-card-btndummy {
          color: @color-white;
          background: @color-primary-1000;
        }
      }

      img {
        width: 3rem;
        height: 3rem;
      }

      h3 {
        .text-heading-3();
        color: @color-primary-700;
        margin-top: 1.5rem;
        flex: 1;
      }
    }

    @media @tablet-landscape {
      grid-template-columns: 1fr 1fr;
    }

    @media @mobile {
      grid-template-columns: 1fr;
    }
  }
  &-card-btndummy {
    .button-text();
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    align-items: center;
    height: 2.25rem;
    padding: 0 0.75rem;
    font-family: @font-family-headings;
    border-radius: 0.5rem;
    color: @color-primary-900;
    background: @color-white;
    cursor: pointer;
    transition: @transition-all;
    white-space: nowrap;
    width: fit-content;
    margin: 0.5rem auto 0;
  }
}
