@import (reference) "../../../../styles/main";

.DataUtilizationTable {
  display: grid;
  border-collapse: collapse;
  min-width: 1000px;
  width: 100%;
  grid-template-columns:
      repeat(var(--data-utilization-table-repeat), minmax(min-content, 1fr));

  &__old_table_that_goes_with_the_version_with_graphs_we_should_delete {
    grid-template-columns:
      minmax(20px, auto) // row number
      minmax(20px, auto) // date
      repeat(var(--data-utilization-table-repeat), minmax(min-content, 1fr))
      minmax(20px, auto) // transaction_count
      minmax(20px, auto) // cached_count
      minmax(20px, auto) // hit_count
      minmax(20px, auto); // error_count
  }

  &__container {
    overflow: auto;
  }
  thead,
  tbody,
  tr {
    display: contents;
    grid-column: 1 / -1
  }

  tr {
    &:nth-child(even) td {
      background-color: #F8F8F8;
    }
    &:hover {
      > td {
        background-color: #f0eff9;
      }
    }
    &:last-of-type td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    td {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      &:last-of-type {
        border-right: none;
      }
    }
  }

  th,
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: initial;
    text-align: end;
  }

  th {
    position: sticky;
    top: 0;
    padding: 0 15px;
    text-align: left;
    font-weight: 400;
    font-size: @text-size-14;
    color: #848484;
    background-color: @color-white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    height: 40px;
    align-items: center;
  }

  th:last-of-type {
    border-right: none;
    padding-right: 25px;
  }

  td {
    height: 100%;
    display: flex;
    min-height: 40px;
    &:last-of-type {
      .DataUtilizationTable__cell {
        padding-right: 25px;
      }
    }
  }

  &__cell {
    width: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
    font-size: @text-size-14;
    &__entryIndex {
      justify-content: flex-end;
      color: #A4A2A2;
      font-family: @font-family-mono;
      font-size: @text-size-12;
      font-weight: 400;
    }
    &__number {
      justify-content: flex-end;
    }
  }

  &__showMoreButton {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #17009A;
    font-size: @text-size-14;
    height: 52px;
    width: 100%;
    cursor: pointer;
  }
}

.TransactionsTable {
  &__transactionsPerDay {
    justify-content: flex-end;
  }
  &__totalTransactions {
    justify-content: flex-end;
  }
  &__totalAverageResponseTime {
    justify-content: flex-end;
    padding-right: 25px;
  }
}
