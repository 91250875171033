@import (reference) "../../styles/main";

.Loader {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  &--fullHeight {
    height: 100vh;
  }
  &--fullWidth {
    width: 100%;
  }
  &--withPadding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  & > svg {
    width: 48px;
    height: 48px;
    animation: loaderSpin 3s infinite linear;
    color: @color-primary-600;
    & > line {
      color: @color-primary-600;
    }
  }
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
