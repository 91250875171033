@import (reference) "../../../../styles/main";

.ErrorsGetStarted {
  position: relative;
  background: #f1f1f3;
  border: 1px solid #e5e9ed;
  border-radius: 8px;
  padding: 65px 30px 100px;
  &__mask {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    &__head {
      font-weight: 600;
      font-size: @text-size-22;
      line-height: 30px;
      margin-bottom: 20px;
    }
    &__paragraph {
      font-weight: 400;
      font-size: @text-size-18;
      line-height: 24px;
      margin-bottom: 30px;
    }
    &__button {
      border: none;
      outline: none;
      background: @color-secondary-400;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding: 10px 15px;
      font-weight: 600;
      font-size: @text-size-18;
      line-height: 130%;
      color: @color-primary-900;
      cursor: pointer;
    }
  }
}
