@import (reference) "../../../styles/main";

.UseCasePageHeader {
  padding-top: 32px;
  .bp-mobile({
    padding-top: 20px;
  });
  &__breadcrumbs {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 400;
  }
  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 20px;
    gap: 12px;
    &__title {
      display: flex;
      align-items: center;
      font-size: @text-size-30;
      line-height: 38px;
      font-weight: 600;
      color: #111827;
      flex-wrap: wrap;
      gap: 12px;
  
      &__hash {
        font-size: @text-size-30;
        line-height: 38px;
        font-weight: 400;
        color: #9CA3AF;
      }

      &__label {
        height: 32px;
        border-radius: 32px;
        font-weight: 400;
        font-size: @text-size-16;
        line-height: 24px;
        padding: 4px 12px;
        &--evaluation {
          color: #4f9e90;
          background-color: #c2f4ed;
        }
        &--production {
          color: #854d0e;
          background-color: #fef9c3;
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: flex-end;
    .bp-mobile({
      margin-top: 20px;
    });
    &__csvDownload {
      position: relative;
      &__paper {
        position: absolute;
        top: 50px;
        right: 38px;
        min-width: 300px;
      }
    }
    &__dateRangePicker {
      display: flex;
      justify-content: flex-end;
      position: relative;
      .bp-mobile({
        position: initial;
      });
      &__triggerButton {
        appearance: none;
        outline: none;
        height: 40px;
        padding: 0 15px;
        background-color: transparent;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 12px;
        color: @color-primary-900;
        font-weight: 600;
        font-size: @text-size-18;
        transition: 0.25s background-color;
        border: 2px solid #b9b2e5;
        border-radius: 4px;
        & > svg {
          transition: @transition-all;
          margin-left: 5px;
          color: #b9b2e5;
        }
        &:hover,
        &:focus {
          background-color: #b9b2e580;
          & > svg {
            color: @color-primary-900;
          }
        }
        &--active {
          & > svg {
            transform: rotate(-180deg);
          }
        }
      }
      &__popoverWrapper {
        position: absolute;
        top: 51px;
        right: -20px;
        z-index: 120;
        .bp-mobile({
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.4);
        });
      }
      &__arrow {
        .bp-mobile({
          display: none;
        });
      }
    }
  }
}
