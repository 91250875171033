@import (reference) "../../styles/main";

.ReportsMonitoringPage {
  background-color: @color-platformBackground;
  padding: 20px 40px;

  &__title {
    font-size: @text-size-30;
    color: #111827;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .ResponseTimesChartsWrapper {
    display: grid;
    grid-template-columns: minmax(500px, 1fr) minmax(500px, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    width: 100%;

    @media @desktop-small {
      grid-template-columns: 100%;
    }

    &__item {
      flex: 1 1 50%;

      .ReportsDashboardHeader {
        &--monitor {
          border-radius: 0;
        }
      }
    }
  }

  .configuration {
    display: flex;
    gap: 20px;
    position: relative;

    &__section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;


      &__header {
        color: #888888;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.025em;
        margin-top: 16px;
        margin-bottom: 8px;
      }

      .ActiveResponseTimeTypeSwitch {
        flex-direction: column;
      }
      .DateRangePickerComponent  {
        min-width: 300px;
      }
    }

  }

  .ChannelsSelectorComponent {

    .DateRangePickerComponent__button {
      border: none;
      width: 100%;

      &--active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &__popupWrapper {
      position: absolute;
      width: calc(100% + 2px);
      left: -1px;
      z-index: @z-index-popups;
      max-height: 190px;
      overflow-y: auto;
      border: 1px solid #eaeaf0;
      box-shadow: 0 10px 15px rgb(0 0 0 / 5%);

      .ChannelsList {
        &__item {
          display: flex;

          label {
            max-width: 90%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

    }
  }
  .ChartTypeSelectorComponent {
    &__popupWrapper {
      position: absolute;
    }
  }
}