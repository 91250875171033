@import (reference) "../../../styles/main";

.CreateAddConnectorModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: @color-white;
  z-index: 1;
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1);
  height: 95%;
  max-height: 570px;
  width: 95%;
  max-width: 760px;
  padding: 48px;

  .bp-phone({
    padding: 20px;
    max-height: 520px;
  });

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    > svg {
      stroke: #9B92D2;
      stroke-width: 2.5;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-size: @text-size-30;
    font-weight: 600;
  }
  
  &__list {
    margin-top: 10px;
    height: 360px;
    min-height: 57px;
    overflow: auto;
    border: 1px solid #E5E7EB;
    background-color: #F9FAFB;

    &__empty {
      margin-top: 16px;
      text-align: center;
    }

    &__loader {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      .Loader {
        width: 24px;
        height: 24px;
      }
    }

    &__item {
      display: flex;
      padding: 8px 30px 8px 12px;
      align-items: center;
      gap: 12px;
      outline: none;
      background-color: @color-white;
      border: none;
      width: 100%;
      cursor: pointer;
      min-height: 56px;
      border-bottom: 1px solid #E5E7EB;

      &__logo {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 32px;
        overflow: hidden;
        img {
          max-width: 32px;
        }
      }

      &__title {
        display: flex;
        color: #111827;
        font-size: @text-size-18;
        font-weight: 600;
        text-align: left;
      }

      &__paragraph {
        display: flex;
        color: #6B7280;
        font-size: @text-size-12;
        text-align: left;
      }

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        outline: none;
        border: 3px solid transparent;
        background: transparent;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        cursor: pointer;
  
        &__container {
          margin-left: auto;
          position: relative;
        }

        &.open {
          border: 3px solid #A6B4FC;
          background: #F0F3FF;
        }

        > svg {
          width: 20px;
          height: 20px;
          stroke: #9B92D2;
        }

        &__list {
          position: absolute;
          z-index: 1;
          top: 45px;
          right: 0;
          padding: 16px;
          background-color: @color-white;
          border-radius: 8px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1);
    
          &:after {
            content: "";
            width: 18px;
            height: 18px;
            background-color: @color-white;
            position: absolute;
            top: -10px;
            right: 11px;
            transform: rotate(45deg);
            border-top: 2px solid #F3F4F6;
            border-left: 2px solid #F3F4F6;
          }
    
          &__item {
            border: none;
            border-radius: 4px;
            outline: none;
            cursor: pointer;
            white-space: nowrap;
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 8px 12px;
            color: #4B5563;
            background-color: transparent;
    
            > svg {
              width: 16px;
              height: 16px;
              stroke: #9B92D2;
              stroke-width: 2.5;
            }
    
            &:hover {
              background-color: #F0F3FF;
            }
          }
        }
      }

      &:hover {
        background-color: #F3F4F6;
      }

      &:disabled {
        filter: grayscale(0.8);
        opacity: 0.7;
        background-color: @color-coolGray-300;
        cursor: not-allowed;
      }
    }
  }
}
