@import (reference) "../../../../styles/main";

.industry_metrics {

    &-background {
      .main-Layout();
      background-color: @color-blue-25;
    }
  }
  
  .metrics {
    background-color: #0B009A;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  
    &-item {
      padding: 4rem 1rem;
      max-width: 23rem;
  
      &-name {
        color: @color-white;
        font-family: @font-family-headings;
        font-size: 3.75rem;
        font-weight: 700;
        line-height: 4.5rem;
        padding-bottom: 0.75rem;
        letter-spacing: -0.02em;
      }
      &-description {
        color: #C5C2D2;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.75rem;
        text-align: center;

        @media @mobile {
          padding: 0 1rem;
        }
      }
  
      @media @mobile {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  
    @media @mobile {
      flex-direction: column;
      padding: 3rem 0;
    }
  }

.rounded {
    & > *:first-child {
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
    }
    & > *:last-child {
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }
}