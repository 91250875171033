@import (reference) "../../../styles/main";

.FilesTable {
  thead {
    tr {
      th {
        padding: 10px 15px;
        font-size: @text-size-14;
        color: @color-coolGray-600;
        text-align: left;
      }
    }
  }
  tbody {
    tr {
      height: 40px;
      > td {
        background-color: #ffffff;
      }
      &:nth-child(even) {
        > td {
          background-color: #f6f6f6;
        }
      }
      &:hover {
        > td {
          background-color: #f0eff9;
        }
      }
      transition: @transition-all;
      & td {
        vertical-align: middle;
        padding-left: 15px;
        padding-right: 15px;
        font-size: @text-size-16;
        line-height: 20px;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}
