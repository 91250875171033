.Results {
    padding: 40px;
}

.Category {
    display: flex;
    flex-direction: start;
}

.AttributesContainer {
    display: flex;
    flex-direction: row;
    gap: 14px;
    margin: 10px 0px;

    & > button > div > span {
        border-radius: 28px;
    }
}

.ImageContainer {
    border-radius: 28px;
}

.TabsWrapper {
    padding: 24px 40px 0px 32px;
    border-bottom: 1px solid @color-coolGray-200;
}

.results_tabs {
    color: unset;
}

.Documentation {
    &__table {
        display: grid;
        padding: 30px 0;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        @media @mobile {
            grid-template-columns: 1fr;
        }
    }
}
