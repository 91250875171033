@import (reference) "../../styles/main";

.ConnectorsPage {
  background-color: @color-platformBackground;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &__contentWrapper {
    overflow: auto;
    padding: 20px 40px;
    flex: 1;
    .bp-mobile({
      padding: 20px;
    });
  }
  &__header {
    display: flex;
    justify-content: space-between;
    &__title {
      font-size: @text-size-30;
      line-height: 40px;
      font-weight: 600;
    }
    &__button {
      > div {
        display: flex;
        align-items: center;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
  &__tabs {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #e5e7eb;
    margin-top: 25px;

    & > div {
      & > div {
        overflow: hidden;
        @media @mobile {
          overflow: auto;
        }
        & > a {
          border-bottom: none;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @text-size-16;
      line-height: 1.5;
      font-weight: 400;
      border-bottom: 2px solid transparent;
      color: #4b5563;
      padding: 16px;

      &.active {
        color: #17009a;
        font-weight: 600;
        border-bottom: 2px solid #594ab6;
      }
    }
  }
}
