@import (reference) "../../../../styles/main";

.UseCasesRunBatchFileInput {
  width: 100%;
  &__inputEl {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    &:focus-visible + .UseCasesRunBatchFileInput__clickableEl {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
  &__clickableEl {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 216px;
    background-color: #F0F3FF;
    border: 1px dashed #C8D3FE;
    border-radius: 12px;
    cursor: pointer;
    color: @color-primary-900;
    & > svg {
      width: 24px;
      height: 24px;
      margin-bottom: 16px;
      stroke: #9B92D2;
      stroke-width: 2;
    }
    & > p {
      font-weight: 400;
      font-size: @text-size-16;
      line-height: 1.5;
    }
  }
}
