@import (reference) "../../../styles/main";

.GenerateCredentialPopup {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: @z-index-modals;
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
      stroke: #9B92D2;
      stroke-width: 2px;
    }
  }
  &__title {
    color: #111827;
    font-weight: 600;
    font-size: @text-size-30;
    margin-bottom: 24px;
  }
  &__paragraph {
    color: #111827;
    font-weight: 400;
    font-size: @text-size-16;
    margin-bottom: 24px;
  }
  &__backdrop {
    background-color: #00000066;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__paper {
    position: relative;
    padding: 48px;
    width: 100%;
    max-width: 600px;
    max-height: calc(100% - 40px);
    border-radius: 12px;
    .bp-mobile({
      height: 100%;
      max-height: 100%;
      max-width: initial;
    })
  }
  &__arrow {
    right: 65px;
  }
  &__loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: @border-radius-sm;
    z-index: 1;
  }
  &__form {
    & .FormComponent {
      margin-bottom: 20px;
    }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 40px;
    }
  }
  &__select {
    width: 100%;
    margin-bottom: 24px;
    position: relative;

    &__title {
      color: #4B5563;
      font-weight: 600;
      margin-bottom: 6px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      overflow-y: auto;
      height: fit-content;
      max-height: 310px;
      z-index: 1;
      background-color: @color-white;
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1);

      &__item {
        outline: none;
        background-color: @color-white;
        border: none;
        padding: 0;
        width: 100%;
        cursor: pointer;

        &__connector {
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 12px;

          &__logo {
            width: 32px;
            height: 32px;
            border-radius: 32px;
            overflow: hidden;
            img {
              max-width: 32px;
            }
          }

          &__title {
            display: flex;
            color: #111827;
            font-size: @text-size-18;
            font-weight: 600;
            text-align: left;
          }

          &__paragraph {
            display: flex;
            color: #6B7280;
            font-size: @text-size-12;
            text-align: left;
          }
        }

        &:hover {
          background-color: #F3F4F6;
        }
      }
    }

    &__chevrons {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 12px;
      top: 33px;

      svg {
        stroke: #9B92D2;
        stroke-width: 3px;

        &:first-of-type {
          margin-bottom: -4px;
        }
        &:last-of-type {
          margin-top: -4px;
        }
      }
    }

    &__input {
      background-color: transparent;
      padding: 8px 30px 8px 12px;
      margin: 0;
      width: 100%;
      color: #111827;
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      text-align: left;
      cursor: pointer;

      &:focus {
        outline: 3px solid #A6B4FC;
        border: 1px solid #594AB6;
      }

      &__text {
        display: flex;
        gap: 5px;
        color: #111827;
        align-items: baseline;

        > p {
          &:last-of-type {
            font-size: @text-size-14;
          }
          &:first-of-type {
            font-size: @text-size-16;
          }
        }
      }
    }

    input {
      padding: 8px 12px;
      margin: 0;
      width: 100%;
      color: #111827;
      border: 1px solid #D1D5DB;
      border-radius: 4px;

      &:not(.GenerateCredentialPopup__select__default) {
        cursor: pointer;
      }

      &:focus {
        outline: 3px solid #A6B4FC;
        border: 1px solid #594AB6;
      }
    }
  }
}
