@import (reference) "../../../../styles/main";

.DataPageLayout {
  &__loading,
  &__error {
    padding-top: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > a {
      margin-top: 20px;
    }
  }

  &__container {
    display: flex;
    padding: 32px 40px 32px;
    .bp-desktop-small({
      flex-direction: column-reverse;
    });

    .bp-mobile({
      padding: 32px 20px 32px;
    });
  }

  &__info {
    width: 100%;
  }

  &__sectionTitle {
    color: #111827;
    font-size: @text-size-22;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 24px;
  }

  &__gridItems {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    margin-bottom: 40px;
    grid-template-columns: repeat(4, minmax(320px, 1fr));
    .bp-desktop-large({
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    });
  }

  &__connectorsInRecipe {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: repeat(4, minmax(320px, 1fr));
    .bp-desktop-large({
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    });
  }

  &__tableContainer {
    margin-bottom: 40px;
    position: relative;
  }

  &__inputsTableContainer {
    display: flex;
    gap: 32px;
    margin-bottom: 40px;
    width: 100%;
    .bp-desktop-big({
      flex-direction: column;
    });

    &.marginless {
      margin-bottom: 0px;
    }

    &.keepRow {
      .bp-desktop-big({
        flex-direction: row;
      });
    }
  }

  &__toggleInputsTableContainer {
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
    .bp-desktop-big({
      flex-direction: column;
    });
  }

  &__configurationContainer {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 460px;
    min-height: 460px;
    overflow: hidden;
    margin-bottom: 40px;
  }

  &__connectedRecipes {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    margin-bottom: 40px;
    grid-template-columns: repeat(4, minmax(320px, 1fr));
    .bp-desktop-large({
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    });
  }
}
