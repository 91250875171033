@import (reference) "../../styles/main";

.SingleSignOnSettings {
  &__header {
    margin-bottom: 16px;
    &__notAdminInfo {
      display: flex;
      align-items: center;
      &__icon {
        margin-right: 10px;
        padding-top: 2px;
        .bp-mobile({
          padding: 0;
          display: flex;
          align-items: center;
        });
        & > svg {
          color: #b9b2e5;
          width: 20px;
          height: 20px;
        }
      }
      &__text {
        font-size: @text-size-14;
        .bp-mobile({

          display: flex;
          align-items: center;
        })
      }
    }
  }
  &__container {
    position: relative;
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 5;
  }
  &__form {
    flex: 1;
    & > .SettingsFormRow {
      margin-bottom: 16px;
      .Switch {
        margin-bottom: 34px;
      }
    }
    &__error {
      margin: 20px 0;
      width: 100%;
      max-width: 960px;
    }
  }
  &__mt-25 {
    margin-top: 25px;
  }
  &__mt-10 {
    margin-top: 10px;
  }
  &__mb-20 {
    margin-bottom: 20px;
  }
  &__height-400 {
    height: 400px;
  }
  &__height-100 {
    height: 100px;
  }
}
