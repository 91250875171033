@import (reference) "../../../../styles/main";

.UseCasesListItem {
  position: relative;
  &-error_message {
    align-self: center;
    color: @color-coolGray-900;
    height: 100%;
  }
  > a {
    align-items: center;
  }
  > a,
  > div {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 24px 20px;
    background-color: @color-white;
    border: 1px solid #eaeaf0;
    transition: @transition-all;
    border-radius: 8px;
    height: 100%;
    .bp-mobile({
      align-items: flex-start;
    });
    &.UseCasesListItem--New {
      box-shadow: 0px 2px 10px #78f5de;
    }

    &:hover,
    &:focus {
      background-color: @color-secondary-50;
      border-color: @color-secondary-400;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &.UseCasesListItem--production {
      &:hover,
      &:focus {
        background-color: #f9ecbc;
        border-color: #feca00;
      }
    }
  }
  &__sparkline {
    overflow: hidden;
    flex: 0 0 100px;
    min-width: 100%;
    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__loading {
      & > div {
        width: 100%;
        height: 20px;
        animation: skeletonBackgroundAnimation 1.5s ease-in-out;
        border-radius: @border-radius-sm;
      }
    }
    &__noData {
      color: @color-coolGray-100;
      font-size: @text-size-12;
    }
    & .recharts-surface {
      width: 100%;
      cursor: pointer;
    }
  }
  &__info {
    color: @color-coolGray-900;
    flex: 1 1 300px;
    max-width: 100%;
    &__top {
      display: flex;
      justify-content: space-between;
    }
    &__name {
      font-size: @text-size-22;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 30px;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      & a {
        color: inherit;
      }
    }
    &__hash {
      font-size: @text-size-22;
      line-height: 30px;
      font-weight: 400;
      color: #9CA3AF;
    }
    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 10px;
      right: 10px;
      height: 24px;
      color: @color-coolGray-900;
      border-radius: 24px;
      font-weight: 400;
      font-size: @text-size-14;
      line-height: 20px;
      padding: 5px 10px;
      white-space: nowrap;

      &--evaluation {
        background-color: #c2f4ed;
        color: #4f9e90;
      }
      &--production {
        background-color: #fef9c3;
        color: #854d0e;
      }
    }
    &__logosContainer {
      display: flex;
      margin-bottom: 10px;
    }
    &__logo {
      width: 28px;
      height: 28px;
      border-radius: 28px;
      margin-right: -10px;
      border: 2px solid #ffffff;
      background-color: #ffffff;
      overflow: hidden;
      z-index: 0;
      > img, > span > img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 28px;
      }
    }
    &__more {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4b5563;
      background-color: #e5e7eb;
      font-size: @text-size-12;
      line-height: @text-size-12;
    }
  }
  &__defaultChannelQueriesData {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin-bottom: 35px;
    .bp-phone({
      margin-bottom: 0;
    });
    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .bp-phone({
        flex-direction: column;
      });
    }
    &__loading {
      & > div {
        width: 100%;
        height: 20px;
        animation: skeletonBackgroundAnimation 1.5s ease-in-out;
        border-radius: @border-radius-sm;
      }
    }
    &__noData {
      color: @color-coolGray-100;
      font-size: @text-size-12;
    }
    &__list {
      display: flex;
      justify-content: space-between;
      &__item {
        width: calc(33.3333% - 7px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: @color-coolGray-900;
        background: rgba(0, 0, 0, 0.04);
        padding: 10px;
        align-self: stretch;
        .bp-phone({
          width: 100%;
          margin-bottom: 20px;
          flex-direction: row-reverse;
          justify-content: space-between;
        });
        > p {
          &:first-child {
            font-weight: 600;
            font-size: @text-size-18;
            line-height: 20px;
          }
          &:last-child {
            font-weight: 400;
            font-size: @text-size-14;
            line-height: 140%;
            color: @color-coolGray-600;
          }
        }
      }
    }
  }
}
