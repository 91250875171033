@import (reference) "../../../styles/main";

.LandingImageBar {
  border-radius: @card-border-radius;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;

  &.no-radius {
    border-radius: 0;
  }
}
