@import (reference) "../../../../styles/main";

.UseCasesBatchHistoryTable {
  overflow-y: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  margin-bottom: 10px;

  &__removeBatch {
    background-color: #d91c5c !important;
    border: none !important;
    color: #fff !important;
    height: 24px !important;
    font-weight: 400 !important;

    &:hover, &:focus, &:active {
      background-color: #B7184E;
    }
  }

  &__loader, &__empty {
    height: 60px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    & .Loader {
      width: 40px;
      height: 40px;
    }
  }
  &__cell {
    &--status, &--transactions, &--errors {
      text-align: right;
      width: 100%;
    }
    &--download-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    &--download {
      font-size: @text-size-14;
      font-weight: 400;
      svg {
        stroke-width: 2.5;
      }
    }
    &--nameSvg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 32px;
      background-color: #C2F4ED;
      margin-right: 8px;

      & > svg {
        stroke: #64CCB9;
        stroke-width: 2.5;
      }
    }
    &--nameString {
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }
  }
  &__table {
    min-width: 600px;
    max-width: 100%;
    grid-template-columns:
      minmax(min-content, auto)
      minmax(min-content, auto)
      minmax(min-content, auto)
      minmax(100px, 1fr)
      minmax(120px, 1fr)
      minmax(min-content, 1fr)
      minmax(min-content, auto)
      minmax(min-content, auto)
      minmax(min-content, auto)
      minmax(min-content, auto)
      fit-content(150px);
    & thead th {
      min-height: 43px;
      &:first-of-type {
        padding-left: 30px;
      }

      &:last-of-type {
        padding-right: 30px;
        border-right: none;
      }
    }
    & tr > td {
      border-bottom: 1px solid #e5e5e5;
      color: #4b5563;
      font-size: @text-size-14;

      &:first-of-type {
        padding-left: 30px;
        font-weight: 600;
        color: #111827;
        font-size: @text-size-16;
        line-height: 1.5;
      }

      &:last-of-type {
        padding-right: 30px;
        border-right: none;
      }
    }
    & tr {
      &:hover {
        > td {
          background-color: #f0eff9;
        }
      }
    }
    & td {
      min-height: 48px !important;
    }
  }

  &__modal {
    position: relative;
    width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: @color-white;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    overflow: auto;
    padding: 20px;

    &__item {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #eaeaf0;
      align-items: center;
      padding: 5px 0;
    }

    &__close {
      margin-left: auto;
      margin-bottom: 10px;
    }
  }
}
