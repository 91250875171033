@import (reference) "../../styles/main.less";

.Paper {
  background-color: @color-white;
  padding: 20px;
  position: relative;
  &--withBorder {
    border: 1px solid #eaeaf0;
    & .Paper__arrow {
      &--top {
        border-top: 1px solid #eaeaf0;
        border-right: 1px solid #eaeaf0;
      }
      &--top-right {
        border-top: 1px solid #eaeaf0;
        border-right: 1px solid #eaeaf0;
      }
      &--bottom {
        border-bottom: 1px solid #eaeaf0;
        border-left: 1px solid #eaeaf0;
      }
      &--left {
        border-top: 1px solid #eaeaf0;
        border-left: 1px solid #eaeaf0;
      }
      &--right {
        border-bottom: 1px solid #eaeaf0;
        border-right: 1px solid #eaeaf0;
      }
    }
  }
  &__elevation {
    &--0 {
      box-shadow: none;
    }
    &--1 {
      box-shadow: @box-shadow-default;
    }
    &--2 {
      box-shadow: @box-shadow-darker;
    }
  }
  &__borderRadius {
    &--xs {
      border-radius: @border-radius-xs;
    }
    &--sm {
      border-radius: @border-radius-sm;
    }
    &--md {
      border-radius: @border-radius-md;
    }
    &--lg {
      border-radius: @border-radius-lg;
    }
  }
  &__arrow {
    width: 16px;
    height: 16px;
    background-color: @color-white;
    transform: translateX(50%) rotate(-45deg);
    z-index: 99;
    position: absolute;
    &--top {
      top: -9px;
      right: 50%;
    }
    &--top-right {
      top: -9px;
      right: 20%;
    }
    &--bottom {
      bottom: -8px;
      right: 20px;
    }
    &--left {
      left: -8px;
      top: 20px;
    }
    &--right {
      right: -8px;
      top: 20px;
    }
  }
}
