@import (reference) "../../../styles/main";

.LockTag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: #F3F4F6;

  > svg {
    stroke: #6B7280;
    stroke-width: 3px;
  }
}
