@import (reference) "../../../styles/main";

.DiscoveryFormTemplate {
  &.LandingHero {
    display: grid;
    grid-template-columns: 1fr 540px;
    grid-auto-flow: dense;
    column-gap: 64px;
    width: 100%;
    @media @tablet-landscape {
      gap: 0px;
      grid-template-columns: 1fr;
      > *:last-child {
        order: -1;
      }
    }
    &-background {
      padding-bottom: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .LandingHero {
    &-title {
      .text-heading-2();
      color: @color-white;
      margin-top: 4rem;
    }

    &-subtitle {
      font-size: 1.2rem;
    }
    p:not(.terms_checkbox-text, .contact_form-getStarted) > a {
      color: @color-violet-200;
      text-decoration: underline;
    }
  }

  &__feature-list {
    li {
      display: flex;
      gap: 16px;
      align-items: center;
      margin: 0 0 30px;
      h2 {
        .text-subheading-2();
        font-size: 1.2rem;
        color: @color-white;
        margin: 0;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        min-width: 16px;
        height: 16px;
        border-radius: 20px;
        background-color: rgba(@color-violet-200, 0.5);
        > div {
          width: 10px;
          min-width: 10px;
          height: 10px;
          border-radius: 10px;
          background-color: @color-violet-100;
        }
      }
    }
  }
}
