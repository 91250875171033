@import (reference) "../../styles/main";

.InternalProductPage {
    padding: 32px;

    &__AboutWrapper {
        display: flex;
        flex-direction: row;
        gap: 14px;

        @media @desktop-small{
           flex-direction: column;
          }
    }
}

