
.InternalProductHeader {

  .bp-mobile({
    padding: 20px 20px 0;
  });

  &__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .bp-mobile({
      flex-direction: column;
    });
  }

  &__ctaButton {
    > svg {
      width: 16px;
      height: 16px;
      stroke-width: 3px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 12px;

    .bp-mobile({
      flex-direction: column;
      margin-left: 0;

      > button {
        width: 100%;
      }
    });

  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    font-size: @text-size-12;
    font-weight: 400;

    > a {
      color: #9b92d2;
    }

    > p {
      color: #9ca3af;
    }

    > svg {
      stroke: #d1d5db;
      stroke-width: 3px;
      margin: 0 4px;
    }
  }

  &__product {
    display: flex;
    margin: 16px 0 32px;
    align-items: center;
    gap: 8px;

    &__logos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      
      &__connectorImage {
        width: 56px;
        height: 56px;
        border-radius: 56px;
      }

    }

    &__data {
      &__name {
        display: flex;
        color: #111827;
        font-size: @text-size-30;
        font-weight: 600;
        line-height: 1.3;
        h1 {
          font-weight: inherit;
        }
      }

      &__connector {
        color: #6b7280;
        font-size: @text-size-14;
        font-weight: 400;
        line-height: 1.4;
      }

      &__edit {
        cursor: pointer;
      }
    }
  }
}
