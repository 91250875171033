@import (reference) "../../../../styles/main";

.UseCaseCredentials {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #E5E7EB;
  position: relative;
  &__header {
    font-size: @text-size-22;
    font-weight: 600;
    margin-bottom: 24px;
    color: #111827;
    &__small {
      font-size: @text-size-18;
      font-weight: 600;
      margin-bottom: 12px;
      color: #111827;
    }
    &__actions {
      .Button {
        font-size: @text-size-16;
        font-weight: 400;
        > svg {
          width: 16px;
          height: 16px;
          stroke-width: 2.5;
        }
      }
    }
  }
  &__paragraph {
    font-size: @text-size-14;
    margin-bottom: 24px;
    color: #6B7280;
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .Loader {
      position: absolute;
    }

    &__backdrop {
      width: 100%;
      height: 100%;
      background-color: @color-white;
      opacity: 0.6;
      border-radius: 8px;
    }
  }
}
