@import (reference) "../../../../styles/main";

.DataPageAbout {
  margin-bottom: 40px;

  &__sectionTitle {
    color: #4b5563;
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__description {
    font-size: @text-size-16;
    line-height: 24px;
    margin-bottom: 32px;

    &__text {
      color: #111827;
      font-weight: 400;
      white-space: pre-wrap;
    }
  }

  &__pricing {
    min-width: 480px;
    width: 480px;
    margin-bottom: 32px;

    .bp-desktop-big({
      min-width: 430px;
      width: 430px;
    });

    .bp-desktop-small({
      width: 100%;
      max-width: 100%;
      margin-bottom: 30px;
      min-width: unset;
    });
  }

  &__awsTag {
    margin-bottom: 32px;
    display: inline-flex;
    background-color: #252f3e;
    height: 30px;
    border-radius: 2px;
    padding: 5px 10px;
    gap: 10px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    line-height: 20px;

    > img {
      max-width: 24px;
    }
  }

  &__connectors {
    margin-bottom: 32px;
    padding: 32px 0;
    border-bottom: 1px solid #e5e7eb;
    border-top: 1px solid #e5e7eb;

    &__item {
      display: flex;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      border: 1px solid transparent;
      transition: all 0.3s;

      &:hover,
      &:focus {
        cursor: pointer;
        border: 1px solid #78f5de;
        background-color: #ebfdfa;
        transition: all 0.3s;
      }
    }

    &__logoContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;

      > img,
      > span > img {
        width: fit-content;
        height: 32px;
        border-radius: 32px;
      }
    }

    &__textContainer {
      font-weight: 400;

      &__name {
        color: #111827;
        font-size: @text-size-16;
        font-weight: 600;
        line-height: 1.5;
      }

      &__connector {
        color: #6b7280;
        font-size: @text-size-12;
        line-height: 1.3;
      }
    }
  }
}
