@import (reference) "../../styles/main";

.Verification {

    &__header {
        & > h3 {
            font-size: 18px;
            color: var(--gray-900);
            font-weight: 600;
            margin-bottom: 12px;
        }
    }

    &__subheader {
        & > div {
            font-size: 14px;
            color: #4B5563;
            font-weight: 600;
            margin-bottom: 12px;
        }
    }

  
    &__actionableItems {
       
        border: 1px solid #eaeaf0;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        align-items: flex-start;
        max-width: 960px;
        margin-bottom: 12px;

        &__containerFill {
            width: 100%;
            min-height: 68px;
            display: flex;
            flex-direction: row;
            padding: 8px 16px;
            gap: 12px;
            background: var(--coolGray-50);
 
            @media @mobile {
             flex-direction: column;
           }
        }
        &__container {
           width: 100%;
           min-height: 68px;
           display: flex;
           flex-direction: row;
           padding: 8px 16px;
           gap: 12px;

           @media @mobile {
            flex-direction: column;
          }


           &__checkbox {
            display: flex;
            align-items: center;
            justify-content: center;

            &__checked {
                & > svg {
                    color: var(--indigo-600);
                    stroke-width: 3;
                }
            }
        
            &__unchecked {
                & > svg {
                    color: var(--coolGray-300);
                    stroke-width: 3;
                }
            }
           }

           &__title {
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               justify-content: center;
               width: 670px;
               overflow-wrap: break-word;
               @media @mobile {
                width: 100%;
              }

               & >:first-child {
                   font-size: 16px;
                   color: #111827;
                   font-weight: 600;
               }

               & >:last-child {
                font-size: 14px;
                color: #4B5563;
                font-weight: 400;
               }
           }

           &__link {
               display: flex;
               flex-direction: row;
               align-items: center;
               text-align: end;
               margin-left: auto;
               min-width: 200px;
               justify-content: flex-end;
               @media @mobile {
                justify-content: center;
                min-width: 100%;
              }
        
               & > a {
                font-weight: 400;
                font-size: 14px;
                color: #17009A;
               }

               & > button {
                font-weight: 400;
                font-size: 14px;
                height: auto;
               }

               &__label {
                   display: flex;
                   flex-direction: row;
                   align-items: center;
                   gap: 8px;
                   line-height: 0;

                   & > svg {
                       color: var(--blue-400);
                       stroke-width: 3;
                   }
               }
           }
        }
    }

    &__inviteTeammates {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 16px 20px;
        max-width: 960px;
        min-height: 90px;
        background: #F1F1F7;
        border-radius: 12px;  
        margin-top: 24px;
        
        &__textColumn {
            display: flex;
            flex-direction: column;
            gap: 6px;

            & >:first-child {
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                color: #374151;
            }

            & >:last-child {
                font-weight: 400;
                font-size: 14px;
                color: #4B5563;
            }
        }

        &__buttonColumn {
           margin-left: auto;
           margin-top: auto;
           & > button {
               min-height: 32px;
               & > svg {
                color: var(--blue-900);
                stroke-width: 3;
                height: 13px;
                width: 13px;
            }
           }
        &__container {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
        }
        }
    }
}
