@import (reference) "../../../../styles/main";

.BillingReportsDashboard {
  display: flex;
  padding-bottom: 40px;
  &__metricsList {
    width: 100%;
    & > div {
      margin-bottom: 10px;
    }
  }
  .yAxis .recharts-cartesian-axis-tick:first-of-type {
    display: none;
  }
}
