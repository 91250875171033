@import (reference) "../../../../styles/main";

.UseCaseCredentialsList {
  max-width: 960px;
  overflow-x: auto;
  border-radius: 8px;
  border: 1px solid #eaeaf0;
  padding: 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;

  &__name {
    font-size: @text-size-16;
    font-weight: 600;
    color: #111827;
  }

  &__created {
    font-size: @text-size-12;
    color: #6B7280;
  }

  &__item {
    display: flex;
    align-items: center;
    min-height: 56px;
    width: 100%;
    padding: 8px 16px;
    gap: 12px;

    > svg {
      stroke: #9B92D2;
      stroke-width: 3px;
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    }

    &:nth-child(2n) {
      background-color: #F9FAFB;
    }

    .Button {
      margin-left: auto;

      svg {
        width: 12px;
        height: 12px;
        stroke-width: 3px;
      }
    }
  }
  &__button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #17009A;
    font-size: @text-size-14;
    height: 52px;
    width: 100%;
    cursor: pointer;
    border-top: 1px solid #eaeaf0;
  }

  &__removeCredentialButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D91C5C;
    font-size: @text-size-14;
    border: none;
    color: @color-white;
    height: 24px;
    font-weight: 400;

    &:hover, &:focus, &:active {
      background-color: #B7184E;
    }
  }
}
