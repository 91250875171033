@import (reference) "../../../styles/main";

.recipe_article {
  background-color: white;
  padding: 1.875rem;
  border-radius: 1.25rem;
  position: relative;
  cursor: pointer;
  transition: @transition-all;
  background-color: @color-white;
  border: 0.5rem solid @color-white;
  text-align: left;
  &:hover,
  &:focus {
    box-shadow: @box-shadow-active;
    border-color: @color-indigo-100;
    box-shadow: @box-shadow-default;

    h3,
    p {
      color: @color-primary-900;
    }
  }
  &-pill {
    width: fit-content;
    height: 2.5rem;
    border-radius: 0.375rem;
    line-height: 2.5rem;
    background-color: @color-violet-50;
    font-size: @text-paragraph-font-size;
    padding: 0 0.75rem;
    color: @color-blue-600;
  }
  h3 {
    .text-heading-3();
  }
  > p {
    .text-paragraph();
    margin-bottom: 3.5rem;
  }
  a {
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;
    box-shadow: none;
    color: @color-primary-900;

    padding: 0;
    width: fit-content;
    min-width: 0;
    margin: 0;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    .button-text();
    img {
      margin-left: 0.625rem;
      width: 0.875rem;
      height: 0.875rem;
    }
  }
}
