@import (reference) "../../../styles/main";

.FileUpload {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  &__backdrop {
    background-color: #00000066;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__paper {
    padding: 0;
    width: 100%;
    max-width: 960px;
    max-height: calc(100% - 120px);
    overflow: auto;
    .bp-mobile({
      height: 100%;
      max-width: initial;
    });
  }
  &__exit {
    width: 100%;
    padding: 20px 40px;
    margin-bottom: 30px;
    .bp-mobile({
      padding: 20px;
    });
  }
  &__content {
    padding: 0 100px 100px;
    .bp-mobile({
      padding: 0 40px 40px;
    });

    &__header {
      font-size: @text-size-28;
      font-weight: 700;
      margin-bottom: 30px;
    }
    &__uploadInputWrapper {
      margin-bottom: 80px;
    }
    &__uploadSize {
      margin-bottom: 20px;
      & > p {
        font-size: @text-size-14;
      }
    }
  }
}
