@import (reference) "../../../styles/main";

.usecases_partners_section {
  padding: @main-padding;
  background-color: @color-coolGray-50;
  &-container {
    max-width: @layout-max-width;
    margin: auto;
  }
  h2 {
    .text-heading-2();
    margin-top: 0;
    text-align: center;
  }
  p {
    .text-subheading-2();
    text-align: center;
  }
  &-logos {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 10.25rem 10.25rem;
    column-gap: 3rem;
    row-gap: 3rem;
    &-card {
      .card-hoverable-Layout();
      z-index: @layer-top-1;
      border-radius: 1.25rem;
      background-color: @color-white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.875rem;
      border: 0.5rem solid @color-white;
      img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }

      h3 {
        display: none;
      }
    }
  }
  @media @tablet-landscape {
    &-logos {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(5, 10.25rem);
      column-gap: 2rem;
      row-gap: 2rem;
    }
  }
  @media @tablet {
    &-logos {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(5, 10.25rem);
    }
  }
  @media @mobile {
    &-logos {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(10, 10.25rem);
    }
  }
}
