@import (reference) "../../styles/main";

.TextField {
  position: relative;
  &__withAdornment {
    &--left {
      .TextField__input {
        padding-left: 38px;
      }
    }
    &--right {
      .TextField__input {
        padding-right: 38px;
      }
    }
  }
  &__helperText {
    font-size: @text-size-14;
    margin-top: 5px;
    color: @color-tertiary-900;
    background-color: #f0eff8;
    padding: 10px 15px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > svg {
      margin-right: 5px;
    }
  }
  &__adornment {
    position: absolute;
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    color: rgba(@color-primary-900, 50%);
    & > svg {
      width: 20px;
      height: 20px;
    }
    &--end {
      right: 10px;
      cursor: pointer;
    }
    &--start {
      left: 10px;
      pointer-events: none;
    }
  }
  &--gray {
    .TextField__input {
      border: none;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: @border-radius-md;
      color: #5b5b5b;

      &::placeholder {
        color: #5b5b5b;
      }
    }
    & .TextField__adornment {
      color: #5b5b5b;
    }
  }
  &--withShadow {
    .TextField__input {
      border: none;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: @border-radius-md;
      color: #5b5b5b;
      box-shadow: 0 2px 4px 0 #00000033;
      &:focus {
        background-color: @color-white;
        border: 1px solid #7b61ff;
      }
      &::placeholder {
        color: rgba(@color-coolGray-600, 50%);
      }
    }
    & .TextField__adornment {
      color: #5b5b5b;
    }
  }
  &--whiteWithShadow {
    .TextField__input {
      //border: none;
      background-color: @color-white;
      border-radius: @border-radius-sm;
      font-size: @text-size-14;
      color: #5b5b5b;
      box-shadow: 0 2px 4px 0 #00000033;
      border: 1px solid rgba(0, 0, 0, 0.1);
      &:focus {
        background-color: @color-white;
        border: 1px solid #7b61ff;
      }
      &::placeholder {
        color: rgba(@color-coolGray-600, 50%);
        font-size: @text-size-14;
      }
    }
  }
  &--defaultWithShadow {
    .TextField__input {
      border: none;
      border-radius: @border-radius-sm;
      color: @color-coolGray-900;
      border: 1px solid transparent;
      box-shadow: 0 2px 4px 0 #00000033;
      &::placeholder {
        color: rgba(@color-coolGray-600, 50%);
      }
      &:focus {
        background-color: @color-white;
        border: 1px solid #7b61ff;
      }
    }
    & .TextField__adornment {
      color: @color-primary-900;
      display: flex;
      align-items: center;
      justify-content: center;
      & > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  &--searchbar {
    .TextField__adornment--start {
      left: 20px;
    }

    .TextField__input {
      border-radius: @border-radius-sm;
      color: #111827;
      border: 1px solid #d1d5db;
      padding-left: 60px;
      &::placeholder {
        color: #d1d5db;
      }
      &:focus,
      &:active {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05);
        background-color: @color-white;
        border: 1px solid #594ab6;
      }
    }
    & .TextField__adornment {
      color: @color-primary-900;
      display: flex;
      align-items: center;
      justify-content: center;
      & > svg {
        stroke: #9b92d2;
        width: 20px;
        height: 20px;
      }
    }
  }
  &--searchbarSmall {
    .TextField__input {
      border-radius: @border-radius-sm;
      color: #111827;
      border: 1px solid #d1d5db;
      &::placeholder {
        color: #d1d5db;
      }
      &:focus,
      &:active {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05);
        background-color: @color-white;
        border: 1px solid #594ab6;
      }
    }
    & .TextField__adornment {
      color: @color-primary-900;
      display: flex;
      align-items: center;
      justify-content: center;
      & > svg {
        stroke: #9b92d2;
        width: 20px;
        height: 20px;
      }
    }
  }
}
