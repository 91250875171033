@import (reference) "../../../../styles/main";

.case_study {
  .card-large-Layout();
  background-color: @color-violet-900;
  border-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  border-radius: 0;
  
  p {
    .text-heading-2();
    margin: 0 0 2rem 0;
    padding: 2rem 8rem;
    color: @color-white;
  }

  @media @tablet-landscape {
    flex-direction: column;
    text-align: center;
    p {
      font-size: 1.125rem;
      padding: 2rem; 
    }
    a {
      margin: auto;
    }
  }

  @media @mobile {

    p {
      font-size: 1.125rem;
    }
    
  }
}