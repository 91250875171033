@import (reference) "../../styles/main";

.RadioButton {
  display: flex;
  justify-content: flex-start;
  &__input {
    width: 0;
    height: 20px;
    z-index: -1;
    margin: 0;
    opacity: 0;
    & + .RadioButton__label::after {
      content: none;
      opacity: 0;
    }

    &:checked + .RadioButton__label {
      &::before {
        border-color: @color-violet-900;
      }
      &::after {
        content: "";
        opacity: 1;
      }
    }

    &:focus + .RadioButton__label::before {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      outline: #5140b3 solid 1px;
    }
    &:disabled + .RadioButton__label {
      cursor: not-allowed;
      &::before {
        background-color: #dcdce0;
        border-color: #bab9c0;
      }
      &::after {
        cursor: not-allowed;
        background-color: #dcdce0;
        border-color: #bab9c0;
      }
    }
    cursor: pointer;
  }

  &__label {
    position: relative;
    display: flex;
    padding-left: 34px;
    align-items: center;
    cursor: pointer;
    width: 100%;
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: "";
      display: inline-block;
      height: 14px;
      width: 14px;
      border: 1px solid #bab9c1;
      border-radius: 50%;
      background-color: @color-white;
      transition: @transition-all;
    }
    &::after {
      position: absolute;
      top: 6px;
      left: 3px;
      content: "";
      display: inline-block;
      color: @color-white;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: @color-primary-900;
      transition: @transition-all;
    }
  }
}
