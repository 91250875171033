@import (reference) "../../../../styles/main";

.ccpa {
  .input-label span,
  .select-label span,
  .multiple_choice-label span,
  .single_choice-label {
    display: none;
  }

  &-label {
    width: 100%;
    .text-paragraph();
    background: @color-indigo-100;
    color: @color-indigo-600;
    margin-bottom: 3rem;
    padding: 0.5rem;
    text-align: center;
  }

  &-inputs_section {
    display: flex;
    flex-wrap: wrap;

    > section {
      width: 50%;

      &:nth-child(2n) {
        padding-left: 0.625rem;
      }

      &:nth-child(2n - 1) {
        padding-right: 0.625rem;
      }
    }
  }

  &-paragraph_small {
    .text-paragraph();
    color: @color-coolGray-600;
    font-weight: 600;
    &:last-of-type {
      margin-bottom: 3rem;
    }
  }
}

@media screen and (max-width: 78.75rem) {
  .ccpa {
    &-inputs_section {
      > section {
        width: 100%;

        &:nth-child(2n) {
          padding-left: 0;
        }

        &:nth-child(2n - 1) {
          padding-right: 0;
        }
      }
    }
  }
}
