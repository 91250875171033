@import (reference) "../../../styles/main";

.DataCatalogHome {
  background-color: @color-platformBackground;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  &__block {
    margin-bottom: 40px;
  }

  &__table {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    margin: 24px 0;
    .bp-desktop-large({
      grid-template-columns: 1fr 1fr 1fr;
    });
    .bp-desktop-big({
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    });
    .bp-phone({
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    });
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: @text-size-22;
    font-weight: 600;
    color: #111827;

    svg {
      width: 16px;
      stroke-width: 3px;
      stroke: #9b92d2;
      margin-right: 16px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    text-align: center;
  }

  &__loading {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__error {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > a {
      margin-top: 20px;
    }
  }

  &__noData {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 40px 40px;
    &__content {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      padding: 80px 20px;
      color: @color-primary-900;
      background-color: #f0eff9;
      border-radius: 8px;
      text-align: center;
      & > svg {
        width: 40px;
        height: 40px;
        margin-bottom: 20px;
        stroke-width: 1px;
      }
      h3 {
        font-weight: 600;
        font-size: @text-size-22;
        line-height: 30px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 15px;
        max-width: 760px;
      }
    }
    &__actions {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      gap: 15px;
    }
  }
}
