@import (reference) "../../styles/main";

.UseCasePage {
  background-color: @color-platformBackground;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &__unverified {
    font-size: @text-size-18;
    font-weight: 600;
    color: @color-white;

    span {
      padding: 2px 8px;
      background-color: #463acb;
      border-radius: 16px;
    }

    &__container {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #5048e5;
      border-radius: 12px;
      padding: 16px 32px 16px 20px;
      margin-bottom: 40px;
    }
  }
  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 12px;
    margin-left: auto;
  }
  &__loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__error {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > a {
      margin-top: 20px;
    }
  }
  &__noData {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    text-align: center;
    & > h3 {
      font-size: @text-size-24;
    }
    & > a {
      margin-top: 20px;
    }
  }
  &__tabs {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #e5e7eb;
    margin: 25px 0;

    & > div {
      & > div {
        overflow: hidden;
        @media @mobile {
          overflow: auto;
        }
        & > a {
          border-bottom: none;
          margin-bottom: -1px;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @text-size-16;
      line-height: 1.5;
      font-weight: 400;
      border-bottom: 2px solid transparent;
      color: #4b5563;
      padding: 16px;

      &.active {
        color: #17009a;
        font-weight: 600;
        border-bottom: 2px solid #594ab6;
      }
    }
  }
  &__contentWrapper {
    background-color: @color-platformBackground;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0 40px 40px;
    .bp-mobile({
      padding: 0 20px;
    });
  }
}
