@import (reference) "../../../styles/main";

.FileUploadProcess {
  position: relative;
  &__header {
    font-size: @text-size-18;
    margin-bottom: 20px;
    & > h6 {
      font-weight: 600;
    }
  }
  &__filesList {
    margin-bottom: 40px;
    &__element {
      width: 100%;
      height: 50px;
      padding: 0 20px;
      border: 1px solid #eaeaf0;
      display: flex;
      align-items: center;
      &__filename {
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
        flex: 1;
      }
      &__selects {
        display: flex;
        align-items: center;
        padding-right: 10px;
        justify-content: flex-end;
        flex: 0 0 300px;
        &__organization {
          margin-right: 10px;
          flex: 1 1 auto;
        }
        &__region {
          flex: 1 0 120px;
        }
      }
      &__removeButton {
        background-color: transparent;
        height: 30px;
        width: 30px;
        border: none;
        cursor: pointer;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        & > svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  &__errorWrapper {
    margin-bottom: 10px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &__cancel {
      margin-right: 10px;
      color: @color-coolGray-600;
      background-color: transparent;
      height: 40px;
      padding: 0 20px;
      border: none;
      cursor: pointer;
      outline: none;
    }
  }
  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
