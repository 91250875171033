@import (reference) "../../../styles/main";

.legal {
  &-background {
    .hero-backgroundLayout();
    background: @color-blue-25;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-main {
    &_paper {
      width: 100%;
      max-width: 64rem;
      padding: 6rem 4rem;
      background-color: @color-white;
      border-radius: @card-border-radius;
      z-index: @layer-top-2;
      margin: 0 auto @hero-padding-bottom;
      box-shadow: none;
    }

    h1 {
      .text-heading-2();
      display: block;
      margin-top: 0;
    }

    h2 {
      .text-heading-3();
      display: block;

      margin-top: 2rem;
    }

    h3 {
      .text-heading-4();
      margin-top: 1.5rem;
      color: @color-coolGray-900;
      display: block;
    }

    p {
      .text-paragraph();
      color: @color-coolGray-900;
      white-space: break-spaces;
      display: block;
    }

    small {
      .text-paragraph();
      margin-bottom: 3rem;
      display: block;
    }

    table {
      width: 100%;
      margin: 1.5rem 0 4rem;
      td,
      th {
        padding: 0.375rem 0.75rem;
        border: 1px solid @color-coolGray-300;
      }
      td {
        line-break: normal;
      }
      th {
        background-color: @color-blue-25;
        font-weight: bold;
        text-align: left;
      }
    }
  }
}
