@import (reference) "../../styles/main";

.Select {
  &__input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 0 top 50%, 0 0;
    background-size: 24px auto, 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 25px;
    .FormComponent--sm .FormComponent__input& {
      padding: 0 25px 0 10px;
    }
    &--outlined {
      appearance: none;
      padding: 6px 24px 6px 15px;
      background-color: transparent;
      background-position: right 10px top 50%, 0 0;
      color: #17009a;
      cursor: pointer;
      margin-right: 12px;
      font-weight: 600;
      font-size: 1rem;
      transition: 0.25s background-color;
      border: 2px solid #b9b2e5;

      &:hover {
        background-color: #b9b2e580;
      }

      &.FormComponent__input:focus,
      &.FormComponent__input:active {
        border-color: #b9b2e5;
        background-color: #b9b2e580;
      }
    }
    &--noBorder {
      appearance: none;
      padding: 6px 24px 6px 15px;
      background-color: transparent;
      background-position: right 10px top 50%, 0 0;
      color: #17009a;
      cursor: pointer;
      font-weight: 600;
      font-size: 1rem;
      transition: 0.25s background-color;
      border: none;

      &:hover {
        background-color: #b9b2e580;
      }

      &.FormComponent__input:focus,
      &.FormComponent__input:active {
        background-color: #b9b2e580;
      }
    }
    &--blueWithShadow {
      appearance: none;
      color: @color-white;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='24' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
      padding: 6px 24px 7px 15px;
      background-color: transparent;
      background-position: right 10px top 50%, 0 0;
      background-color: @color-primary-900;
      cursor: pointer;
      font-weight: 400;
      font-size: 1rem;
      transition: 0.25s background-color;
      border: none;

      svg {
        stroke: @color-white;
      }

      &:hover {
        background-color: @color-violet-900;
      }

      &.FormComponent__input:focus,
      &.FormComponent__input:active {
        background-color: @color-violet-900;
      }
    }
    &--whiteWithShadow {
      border-color: transparent;
      background-color: @color-white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      color: @color-primary-900;
      font-weight: 400;
      cursor: pointer;
      & > svg {
        width: 20px;
        height: 20px;
        color: #bab9c1;
        margin-right: 10px;
      }
      &:hover {
        background-color: #b9b2e580;
        border-color: transparent;
      }
      &:focus {
        background-color: #b9b2e580;
        border-color: transparent;
      }
      &:active {
        background-color: #b9b2e580;
        border-color: transparent;
      }
    }
    &--clear {
      appearance: none;
      padding: 6px 24px 6px 15px;
      background-color: transparent;
      background-position: right 10px top 50%, 0 0;
      color: #17009a;
      cursor: pointer;
      margin-right: 12px;
      font-weight: 600;
      font-size: 1rem;
      transition: 0.25s background-color;
      border: none;

      &:hover {
        background-color: #b9b2e580;
      }

      &.FormComponent__input:focus,
      &.FormComponent__input:active {
        border-color: initial;
        background-color: #b9b2e580;
      }
    }
  }
}
