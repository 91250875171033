@import (reference) "../../../styles/main";

.UsersTable {
  min-width: 950px;
  grid-template-columns:
    minmax(min-content, 2fr)
    minmax(170px, 1.2fr)
    minmax(min-content, 1.5fr)
    120px;

  tr:not(:last-of-type) td {
    border-bottom: 1px solid #e5e7eb;
  }

  &--short {
    min-width: 600px;
    grid-template-columns:
      minmax(min-content, 2fr)
      minmax(170px, 1.2fr)
      minmax(min-content, 1.5fr);
  }

  &__header {
    &__user {
      padding-left: 40px;
    }
  }

  &__userActionButton {
    text-align: center;
    margin: 0;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: @transition-all;
    background-color: transparent;
    border: none;
    outline: 0;
    padding: 0;
    color: @color-primary-900;

    &--deactivate {
      color: @color-tertiary-900;
    }

    &--resendInvitation {
      & > svg {
        margin-right: 10px;
      }
    }
  }

  &__cell {
    &__role {
      width: 100%;
    }

    &__user {
      display: flex;
      justify-content: flex-start;

      &__icon {
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
          width: 20px;
          height: 20px;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__name {
          font-weight: 600;
        }

        &__email {
          font-size: @text-size-14;
        }

        &__margin_left_20 {
          margin-left: 20px;
        }

        &__max_width_20 {
          height: 30px;
          min-height: 30px !important;
        }
      }
    }

    &--disabled {
      color: @color-coolGray-600;
      font-style: italic;
    }
  }
}
