@import (reference) "../../../styles/main";

.PublicConfiguration {
    position: relative;
    background-color: #111827;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 400px;
    color: @color-white;
    overflow: hidden;
    padding: 20px 30px;

    &__text {
        filter: blur(4px);
    }
}
