@import (reference) "../../../../styles/main";

.OverviewAddConnector {
  &__button {
    height: 100%;
    width: fit-content;
    &__container {
      height: 100%;
      width: fit-content;
      position: relative;
    }
  }
  &__menu {
    position: absolute;
    background-color: @color-white;
    top: 65px;
    right: 0;
    z-index: 1;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1);
    max-width: 330px;

    &.adjusted {
      right: -140px;
    }

    &__search {
      padding: 16px;
      border-bottom: 1px solid #e5e7eb;

      .TextField {
        min-width: 300px;
      }
    }
    
    &__list {
      padding: 8px 0 16px;
      max-height: 350px;
      overflow: auto;

      &__empty {
        text-align: center;
      }

      &__loader {
        display: flex;
        justify-content: center;
        .Loader {
          width: 24px;
          height: 24px;
        }
      }

      &__item {
        display: flex;
        padding: 8px;
        margin: 0 16px;
        align-items: center;
        gap: 12px;
        outline: none;
        background-color: @color-white;
        border: none;
        width: calc(100% - 32px);
        cursor: pointer;
        min-height: 56px;

        &__logo {
          width: 32px;
          min-width: 32px;
          height: 32px;
          border-radius: 32px;
          overflow: hidden;
          img {
            max-width: 32px;
          }
        }

        &__title {
          display: flex;
          color: #111827;
          font-size: @text-size-18;
          font-weight: 600;
          text-align: left;
        }

        &__paragraph {
          display: flex;
          color: #6B7280;
          font-size: @text-size-12;
          text-align: left;
        }

        &:hover {
          background-color: #F3F4F6;
        }

        &:disabled {
          filter: grayscale(0.8);
          opacity: 0.7;
          background-color: @color-coolGray-300;
          cursor: not-allowed;
        }
      }
    }
  }
}
