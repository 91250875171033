@import (reference) "../../../styles/main";

.SettingsPageHeader {
  width: 100%;
  display: flex;
  padding-top: 24px;
  margin-bottom: 24px;
  .bp-mobile({
    flex-direction: column;
    padding-top: 24px;
  });
  &__static {
    &__title {
      font-size: @text-size-22;
      color: #111827;
      font-weight: 600;
    }
  }
}
