@import (reference) "../../styles/main";

.Pagination {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 40px;

    &__pageButton {
      width: 24px;
      height: 24px;
      font-size: 16px;
      border-radius: 4px;
      border: none;
      font-weight: normal;
      outline: none;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      &:nth-last-child(3) {
        margin-right: 0;
      }

      &:hover,
      &:focus {
        cursor: pointer;
        color: @color-primary-900;
      }

      &--active {
        font-weight: 600;
        color: @color-white;
        background-color: @color-primary-900;
        &:hover,
        &:focus {
          color: @color-white;
        }
      }
    }

    &__navButton {
      width: 40px;
      height: 40px;
      outline: none;
      border: none;
      border-radius: 4px;
      background-color: @color-white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      cursor: pointer;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

      svg {
        min-width: 20px;
        min-height: 20px;
        color: @color-coolGray-600;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        svg {
          color: @color-primary-900;
        }
      }

      &--next,
      &--last {
        margin-right: 0;
        margin-left: 15px;

        img {
          transform: rotate(180deg);
        }
      }
    }
  }
}
