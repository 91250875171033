@import "./components/EnterprisePartnership.less";
@import "./components/EnterpriseDataChallenges.less";

.enterprise {
   width: 100%;
  
   .home_hero-main_image {
    margin: 3.25rem 0 5.125rem;
      & > img {
        width: 100%;
      }
    
    @media @tablet-landscape {
      margin: 2.5rem 0 3.5rem;  
    }

    @media @mobile {
        margin: 1rem 0 1.5rem;  
      }
  }
}