@import (reference) "../../../styles/main";

.GenerateApiKeyPopup {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: @z-index-modals;
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
      stroke: #9B92D2;
      stroke-width: 2px;
    }
  }
  &__title {
    color: #111827;
    font-weight: 600;
    font-size: @text-size-30;
    margin-bottom: 24px;
  }
  &__paragraph {
    color: #111827;
    font-weight: 400;
    font-size: @text-size-16;
    margin-bottom: 24px;
  }
  &__backdrop {
    background-color: #00000066;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__paper {
    position: relative;
    padding: 48px;
    width: 100%;
    max-width: 600px;
    max-height: calc(100% - 40px);
    overflow: auto;
    border-radius: 12px;
    .bp-mobile({
      height: 100%;
      max-height: 100%;
      max-width: initial;
    })
  }
  &__arrow {
    right: 65px;
  }
  &__loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: @border-radius-sm;
    z-index: 1;
  }
  &__form {
    & .FormComponent {
      margin-bottom: 20px;
    }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 40px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
