@import (reference) "../../styles/main";

.CredentialSettings {
  &__container {
    position: relative;
    max-width: 960px;
  }
  &__paragraph {
    margin-bottom: 36px;
    font-size: @text-size-14;
    color: #6B7280;
  }
  &__header {
    flex: 1;
    display: inline-flex;
    justify-content: flex-end;
    position: relative;
    .bp-mobile({
      position: initial;
    });
    &__actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    &__notAdminInfo {
      flex: 0 0 300px;
      display: flex;
      margin-left: 40px;
      .bp-mobile({
        flex: 1;
        margin-left: 0;
        margin-top: 20px;
      });
      &__icon {
        margin-right: 10px;
        padding-top: 2px;
        .bp-mobile({
          padding: 0;
          display: flex;
          align-items: center;
        });
        & > svg {
          color: #b9b2e5;
          width: 20px;
          height: 20px;
        }
      }
      &__text {
        font-size: @text-size-14;
        .bp-mobile({
          display: flex;
          align-items: center;
        })
      }
    }
  }
  &__paper {
    overflow-x: auto;
    padding: 0;
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF99;
    z-index: 5;
  }
}
