@import (reference) "../../../../styles/main";

.CatalogFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  &__container {
    position: relative;
  }

  .Button {
    height: 33px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 20%);
  }
}
