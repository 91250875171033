@import (reference) "../../styles/main";

.MessageBox {
  padding: 10px;
  width: auto;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 10px;
    min-width: 20px;
  }

  &__error {
    background-color: #faf1f5;
    color: #af184b;
  }

  &__success {
    background-color: @color-secondary-50;
    color: @color-coolGray-900;
  }

  &__info {
    background-color: @color-indigo-50;
    color: @color-coolGray-900;
  }
}
