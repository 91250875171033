@import (reference) "../styles/main";

.UnverifiedButton {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: #5048E5;
  padding: 0 16px 0 8px;
  gap: 8px;
  height: 40px;
  margin-right: 16px;
  border: none;
  cursor: pointer;
  &.desktop {
    .bp-mobile({
      display: none;
    });
  }

  &.mobile {
    margin-right: 0;
    margin-left: 16px;
    height: 30px;
  }

  &__response {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99999;
      background-color: #4F9E90;
      color: @color-white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      padding: 20px 16px;
      font-size: @text-size-14;
  }

  &__text {
    font-size: @text-size-14;
    color: @color-white;
    span {
      font-weight: 600;
    }
  }

  &__cta {
    color: #E0E8FF;
    font-size: @text-size-12;
  }

  > svg {
    stroke: #C8D3FE;
    stroke-width: 3px;
  }
}

.smallScreenButton {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: #5048E5;
  padding: 0 8px 0 8px;
  gap: 8px;
  height: 32px;
  margin-right: 16px;
  border: none;
  cursor: pointer;

  > svg {
    stroke: #C8D3FE;
    stroke-width: 3px;
  }
}
