@import (reference) "../../../styles/main";

.exchange_perks {
  &-main {
    background-color: @color-coolGray-50;
    .main-Layout();
    text-align: left;
  }

  &-perk {
    display: flex;
    gap: 6rem;
    width: 100%;

    &.inverted {
      flex-direction: row-reverse;
    }

    img {
      width: 50%;
    }
    &:not(:first-child) {
      padding-top: @main-padding-top;
      @media @mobile {
        padding-top: @main-padding-top-mobile;
      }
    }
  }

  &-text {
    width: 50%;
  }

  &-title {
    .text-heading-2();
    margin-bottom: 1rem;
  }

  &-list {
    width: 100%;

    li {
      .text-subheading-2();
      margin: 0;
      padding: 1.5rem 0;
      &:not(:last-of-type) {
        border-bottom: 1px solid @color-coolGray-300;
      }
    }
  }
}

@media @tablet-landscape {
  .exchange_perks {
    &-perk {
      flex-direction: column;
      gap: 3rem;

      &.inverted {
        flex-direction: column;
      }
      img {
        margin: auto;
      }
    }

    &-text {
      width: 100%;
    }

    &-container {
      padding: 0;
    }
  }
}

@media @tablet {
  &-perk {
    img {
      width: 100%;
      max-width: 21.875rem;
    }
  }
}
