@import (reference) "../../../styles/main";

.exchange_faq {
  &-container {
    width: 100%;
  }

  &-label {
    .text-heading-2();
    margin-bottom: 4rem;

    @media @tablet {
      text-align: left;
    }
  }

  &-main {
    position: relative;
    background-color: @color-white;
    padding-top: 1.875rem;
    width: 100%;
  }

  &-header {
    position: relative;
    z-index: 1;
    width: 100%;
    background-color: @color-white;

    &:first-of-type button {
      border-top: none;
    }

    button {
      background-color: transparent;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2.5rem 4rem 2.5rem 1.5rem;
      border: none;
      outline: none;
      width: 100%;
      border-top: 1px solid @color-coolGray-300;
      cursor: pointer;

      p {
        .text-heading-4();
        text-align: left;
        margin: 0 3rem 0 0;
      }

      img {
        width: 1rem;
        height: 1rem;
        max-width: 1rem;
        max-height: 1rem;
        transform: rotate(-180deg);
        transition: @transition-all;

        &.expanded {
          transform: rotate(0);
          transition: @transition-all;
        }
      }
    }
  }

  &-body[aria-hidden="true"] {
    max-height: 0;
    height: 0;
    margin: 0;
    overflow: hidden;
    transition: @transition-all;
  }

  &-body[aria-hidden="false"] {
    max-height: 10.75rem;
    margin-bottom: 3rem;

    transition: @transition-all;
  }

  &-body {
    .text-paragraph();
    padding: 0 4rem 0 1.5rem;
    width: 100%;
    text-align: left;
    max-width: 52.5rem;
    transition: @transition-all;
  }
}

@media @tablet-landscape {
  .exchange_faq {
    &-container {
      padding: 0;
    }
  }
}
