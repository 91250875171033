@import (reference) "../../styles/main";

.highlight-card {
  position: relative;
  width: 31%;
  max-width: 28.125rem;
  border-radius: @card-border-radius;
  overflow: hidden;
  transition: @transition-all;
  background-color: @color-white;
  border: 0.5rem solid @color-white;
  text-align: left;
  &:hover,
  &:focus {
    cursor: pointer;
    border-color: @color-indigo-100;
    box-shadow: @box-shadow-default;

    .highlight-card {
      &-title,
      &-description,
      &-date {
        color: @color-primary-900;
      }
    }
  }

  &-image {
    width: 100%;
    height: 12.5rem;
    border-radius: @card-border-radius * 0.6667;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &-textarea {
    position: relative;
    width: 100%;
    padding: 1.75rem;
    background-color: @color-white;
    z-index: 40;
  }

  &-title {
    .text-heading-3();
    margin-top: 0;
    margin-bottom: 0.75rem;
    padding-right: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-date {
    font-family: @font-family-body;
    font-weight: 400;
    color: @color-violet-600;
    margin-top: 2rem;
  }

  &-description {
    .text-paragraph();
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-more {
    .button-text();
    color: @color-primary-900;
  }
}

@media @tablet-landscape {
  .highlight-card {
    width: calc(50% - 1.875rem);
  }
}

@media @tablet {
  .highlight-card {
    width: 100%;
  }
}
