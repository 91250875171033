@import (reference) "../../styles/main";

.ResetPassword {
  width: 100%;
  min-height: 100%;
  background-color: @color-platformBackground;
  display: grid;
  grid-template-rows: 160px minmax(min-content, max-content) 1fr;
  grid-template-columns: 1fr calc(100% - 3rem) 1fr;

  grid-template-areas:
    ". header ."
    ". paper ."
    ". footer .";
  .bp-mobile({
    grid-template-rows: 80px minmax(min-content, max-content) 1fr;
    position: relative;
    background-color: @color-white;
    grid-template-columns: 1fr;
    grid-template-areas:
    "header"
    "paper"
    "footer"
  });

  &__header {
    grid-area: header;
    display: flex;
    justify-content: center;
  }
  &__paper {
    grid-area: paper;
    width: 100%;
    max-width: 520px;
    margin: auto;

    .bp-tablet-landscape({
      max-width: unset;
    });
    &__header {
      margin-bottom: 40px;
      &__title {
        font-size: @text-size-32;
        font-weight: 600;
      }
      &__message {
        margin-top: 20px;
        & #firstLine {
          margin-bottom: 20px;
        }
      }
    }
    &__backLinks {
      width: 100%;
      display: flex;
      justify-content: center;
      & > a {
        .standardLink();
      }
    }
    &__loadingOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
  &__footer {
    grid-area: footer;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
}

.ResetPasswordForm {
  width: 100%;

  & > :first-child {
    margin-bottom: 30px;
    max-width: 100%;
  }

  & > :nth-child(2) {
    margin-bottom: 30px;
    max-width: 100%;
  }

  & > .confirmPassword {
    max-width: 100%;
    margin-bottom: 30px;
  }

  &__captchaWrapper {
    margin-bottom: 30px;
  }

  &__formValidationErrors {
    list-style: inside disc;
  }

  &__tokenError {
    display: inline;
  }

  &__errorMessageWrapper {
    margin-bottom: 30px;
  }
  .Checkbox {
    margin-top: 20px;
  }
  &__submitButton {
    margin: 30px 0;
  }
}
