@import (reference) "../../styles/main";

.MainNav {
  width: 100%;
  padding: 0px 24px 48px 13px;
  flex: 1 0 auto;
  .bp-mobile({
    padding: 0 20px;
  });
  > * {
    min-width: 100% !important;
    > a {
      min-width: 100% !important;
    }
  }
  hr {
    width: 100%;
  }
}
