@import (reference) "../../styles/main";

.nav_mobile-background {
  display: none;
  overflow: hidden;
}

@media @tablet-landscape {
  .nav_mobile {
    &-close {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      margin: 0;
      padding: 0;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      z-index: @layer-top-header + 99;

      > img {
        background-color: @color-blue-25;
        width: 3.125rem;
        border-radius: 50%;
        box-shadow: @box-shadow-active;
      }
    }

    &-background {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(@color-coolGray-900, 0.5);
      z-index: @layer-top-header + 1;
    }

    &-sections {
      overflow-y: auto;
      padding: 0 0 3rem;
      border-top: 1px solid @color-coolGray-200;
      background-color: @color-coolGray-50;
    }

    &-main {
      display: flex;
      flex-direction: column;
      position: fixed;
      background: @color-white;
      top: 2rem;
      left: 2rem;
      right: 2rem;
      max-height: calc(100vh - 3.75rem);
      width: calc(100% - 4rem);
      z-index: @layer-top-header + 2;
      box-shadow: @box-shadow-active;
      border-radius: 1.25rem;
      overflow: hidden;
    }

    &-section {
      padding: 0;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid @color-coolGray-200;
      & .nav_mobile-item:not(:last-child) {
        border-bottom: 1px solid @color-coolGray-200;
      }

      &:last-child {
        border: none;
      }

      &-accordion {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 3rem;
        width: 100%;
      }
    }

    &-section_title {
      .button-text();
      min-height: 4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-button {
        min-height: 4rem;
        border: 0;
        background-color: transparent;
        padding: 0;
        width: 100%;
        text-align: left;
        display: flex;

        .arrow {
          color: @color-coolGray-400;
          &.inverted {
            transform: rotate(180deg);
          }
        }
      }
    }

    &-accordion {
      &-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 0.5rem;
        background-color: @color-blue-25;
        overflow: hidden;
      }

      &-body[aria-hidden="true"] {
        transition: @transition-all;
      }

      &-body[aria-hidden="false"] {
        transition: @transition-all;
        margin: 0.5rem 0;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      min-height: 4rem;
      padding: 0 3rem;
      img {
        margin-right: 1.25rem;
        width: 1.25rem;
      }
    }

    &-title {
      .button-text();
      color: @color-coolGray-900;
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4rem 3rem 3rem;

      > .button-component,
      > .demyst_button {
        width: 100%;
        height: 3.75rem;

        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@media @mobile {
  .nav_mobile {
    &-item {
      padding: 0 1.5rem;
    }
    &-buttons {
      padding: 3.5rem 1.5rem 1.5rem;
    }
    &-section {
      &-accordion {
        padding: 0 1.5rem;
      }
    }
  }
}
