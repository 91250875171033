@import (reference) "../../styles/main.less";

.Tooltip {
  position: relative;
  display: inline-block;
  background-color: #f0eff8;
  padding: 10px;
  border-radius: @border-radius-md;
  color: @color-primary-600;
  font-size: @text-size-14;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  &--noClick {
    pointer-events: none;
  }
  &__arrowPlacement {
    &--top {
      margin-top: 8px;
    }
    &--left {
      margin-left: 8px;
    }
    &--right {
      margin-right: 8px;
    }
    &--bottom {
      margin-bottom: 8px;
    }
  }
  &__arrow {
    position: absolute;
    background-color: #f0eff8;
    width: 14px;
    height: 14px;
    transform: rotate(-45deg);
    border-radius: @border-radius-xs;
    z-index: 1;
    &--right {
      top: calc(50% - 7px);
      right: -6px;
    }
    &--left {
      left: -6px;
      top: calc(50% - 7px);
    }
    &--top {
      top: -7px;
      left: calc(50% - 8px);
    }
    &--bottom {
      bottom: -6px;
      left: calc(50% - 8px);
    }
  }
}
