@import (reference) "../../../../styles/main";

.InputsForm {
}

.add-custom-input {
  width: calc(100% - 20px);
  .Button {
    width: 100%;
    margin: 10px;
  }
}

.InputsFormTable {
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns:
    62px
    minmax(min-content, 1fr)
    minmax(0, 1fr);
  &__columnHeader {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 1;
    &__value {
      border-right: none;
      @media @phone {
        min-width: 200px;
      }
    }
  }
  &__tableSectionHeader {
    background-color: @color-white !important;
    grid-column: 1/-1;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    color: #5b5b5bbf;
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  th,
  td {
    padding: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.InputsFormTable__nameCell {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
    &.InputsFormTable__valueCell {
      padding: 0 5px;
      @media @phone {
        min-width: 200px;
      }
    }
    &.InputsFormTable__indexCell {
      padding-right: 24px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      justify-content: flex-end;
    }
  }

  th {
    position: sticky;
    top: 0;
    text-align: left;
    font-weight: normal;
    color: #5b5b5b;
    background-color: @color-white;
    display: flex;
    align-items: center;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: @text-size-14;
  }

  td {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    //min-height: 60px;
  }

  tr:nth-child(even) td {
    background-color: rgba(25, 25, 25, 0.03);
  }

  tbody tr:last-of-type {
    & td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  tr {
    &:hover {
      > td {
        background-color: #f0eff9;
      }
    }
  }
  &__sendInput {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__index {
    font-family: monospace;
    font-variant-numeric: tabular-nums;
    color: #c5c5c5;
    font-family: Menlo, Monaco, "Courier New", monospace;
    font-size: 12px;
  }
  &__name {
    font-weight: 600;
  }
  &__value {
    width: 100%;
    &__textField {
      width: 100%;
      &__input {
        border-color: transparent;
        background-color: transparent;
        font-size: @text-size-14 !important;
        &:focus {
          background-color: @color-white;
          border-color: #7b61ff;

          box-shadow: 0 4px 4px 0 #00000040;
        }
      }
    }
    &__inputsCTATooltip {
      position: absolute;
      z-index: 2;
      text-align: center;
      transform: translateX(-120px);
    }
  }
}
