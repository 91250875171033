@import (reference) "../../../../landingPage/styles/main";

.PasswordValidator {
  width: calc(100% + 30px);
  background-color: @color-indigo-50;
  padding: 20px;
  border-radius: 8px;
  margin-left: -15px;
  font-size: 1rem;
  line-height: 24px;
  position: relative;
  color: @color-indigo-700;
  font-weight: bold;
  &:before {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    background-color: @color-indigo-50;
    border-radius: 2px;
    top: -6px;
    left: calc(50% - 8px);
    transform: rotate(45deg);
  }
  &__header {
    margin-bottom: 5px;
    text-align: left !important;
  }
  &__list {
    &__item {
      display: flex;
      align-items: center;
      padding: 5px;
      &__icon {
        height: 100%;
        margin-right: 15px;
        display: flex;
        align-items: center;
        color: @color-indigo-300;
      }
      &--passed {
        color: @color-teal-800;
        .PasswordValidator__list__item__icon {
          color: @color-teal-700;
        }
      }
    }
  }
}
