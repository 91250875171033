@import (reference) "../../../../styles/main";

.ResultsJSONView {
  width: 100%;
  height: 100%;
  position: relative;
  &__downloadButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    font-size: @text-size-16;
    height: 32px;
    & > svg {
      width: 16px;
      height: 16px;
      stroke: #9B92D2;
      stroke-width: 2.5;
    }
  }
  &__showNulls {
    position: absolute;
    top: 10px;
    right: 140px;
    z-index: 2;
    font-size: @text-size-16;
    height: 32px;
    & > svg {
      width: 16px;
      height: 16px;
      stroke: #9B92D2;
      stroke-width: 2.5;
    }
  }
}
