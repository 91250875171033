@import (reference) "../../styles/main";

.Login {
  width: 100%;
  min-height: 100%;
  background-color: @color-platformBackground;
  display: grid;
  grid-template-rows: 160px minmax(min-content, max-content) 1fr;
  grid-template-columns: 1fr calc(100% - 3rem) 1fr;
  grid-template-areas:
    ". header ."
    ". paper ."
    ". footer .";
  .bp-mobile({
    grid-template-rows: 80px minmax(min-content, max-content) 1fr;
    position: relative;
    background-color: @color-white;
    grid-template-columns: 1fr;
    grid-template-areas:
    "header"
    "paper"
    "footer"
  });

  &__header {
    grid-area: header;
    display: flex;
    justify-content: center;
  }
  &__paper {
    grid-area: paper;
    width: 100%;
    max-width: 520px;
    margin: auto;

    .bp-tablet-landscape({
      max-width: unset;
    });
    &__header {
      margin-bottom: 40px;
      &__title {
        font-size: @text-size-28;
        font-weight: 700;
      }
    }
    &__loadingOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
  &__footer {
    grid-area: footer;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    &__requestPlatformLogin {
      margin-top: 32px;
      color: @color-coolGray-600;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 20px 20px 0;
      font-size: @text-size-14;
      font-weight: 500;
      .bp-mobile({
        justify-content: flex-start;
      });
      & > a {
        color: @color-primary-900;
        transition: @transition-all;
        margin-left: 5px;
        font-weight: 500;
        &:hover {
          color: @color-primary-600;
        }
      }
    }
  }
}

.LoginForm {
  width: 100%;

  & > .FormComponent {
    margin-bottom: 30px;
  }

  & > .LoginForm__password {
    margin-bottom: 10px;
    max-width: 100%;
  }

  & > .emailInput {
    margin-bottom: 30px;
    max-width: 100%;
  }

  &__forgotPasswordLink {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 24px 0px;
    font-weight: 600;
    & > a {
      .standardLink();
      font-weight: 400 !important;
    }
  }

  &__captchaWrapper {
    margin-bottom: 30px;
  }

  &__errorMessageWrapper {
    margin-bottom: 30px;
  }

  &__toggleButton {
    display: flex;
    align-self: center;
    border: none;
    background: none;
    cursor: pointer;

    > svg {
      stroke: #9b92d2;
      stroke-width: 2.5;
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
    }
  }
}
