@import (reference) "../../styles/main";

.blog_main {
  &-main {
    height: 100%;
    width: 100%;
    background-color: @color-blue-25;
    &:before {
      content: "";
      .gradient-primary-900();
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 37.5rem;
    }
  }
}
