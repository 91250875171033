@import (reference) "../../../styles/main";

.posts_list {
  &-background {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: transparent;
    z-index: @layer-top-2;
  }

  &-main {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: @main-padding-bottom;
    .button-component {
      margin: 3.5rem auto 0;
      @media @tablet-landscape {
        margin-top: 3rem;
        width: 100%;
      }
    }
  }

  &-header {
    .text-heading-1();
    color: @color-white;
    padding: @hero-padding-bottom 0 @main-padding-bottom;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
  }

  &-post {
    position: relative;
    width: 100%;
    border-radius: @card-border-radius;
    overflow: hidden;
    transition: @transition-all;
    background-color: @color-white;
    border: 0.5rem solid @color-white;
    &:hover,
    &:focus {
      box-shadow: @box-shadow-active;
      border-color: @color-indigo-100;
      transition: @transition-all;

      .posts_list-title,
      .posts_list-date,
      .posts_list-description {
        color: @color-blue-900;
      }
    }
    > a {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
    }
  }

  &-image {
    width: 100%;
    height: 12.5rem;
    border-radius: @card-border-radius * 0.6667;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &-textarea {
    position: relative;
    width: 100%;
    padding: 1.75rem;
    background-color: @color-white;
    z-index: 40;
    display: flex;
    flex-direction: column;
  }

  &-title {
    .text-heading-3();
    margin-top: 0;
    margin-bottom: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1;
  }

  &-date {
    font-family: @font-family-body;
    font-weight: 400;
    color: @color-violet-600;
    margin-top: 2rem;
  }

  &-description {
    .text-paragraph();
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &-more {
    .button-text();
    color: @color-primary-900;
  }

  &-spinner {
    margin: 0 auto;
    height: 3.75rem;
    width: 3.75rem;
    border-left: 0.3125rem solid @color-teal-400;
    border-right: 0.3125rem solid @color-teal-400;
    border-top: 0.3125rem solid transparent;
    border-bottom: 0.3125rem solid transparent;
    border-radius: 1.875rem;
    animation: @animation-spin;
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @media @tablet-landscape {
    &-list {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
    }
  }

  @media @tablet {
    &-list {
      grid-template-columns: 1fr;
    }
  }
}
