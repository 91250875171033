@import (reference) "../../../styles/main";

.AddConnectorCard {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #DDDCED;
  border: 1px solid transparent;
  border-radius: 12px;
  transition: all 0.3s;
  padding: 24px 20px;

  &__name {
    margin-bottom: 16px;
    font-size: @text-size-22;
    line-height: 1.5;
    font-weight: 600;
    color: #4331AC;
  }

  &__description {
    margin-bottom: 16px;
    font-size: @text-size-14;
    line-height: 1.4;
    color: #6F62BE;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    em {
      font-style: italic;
    }
  }

  &__button {
    width: fit-content;
    &__container {
      width: fit-content;
      position: relative;
    }
  }
}
