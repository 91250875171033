@import (reference) "../../../styles/main";

.data-challenges {
  .main-Layout();
  h2 {
    .text-heading-2();
    color: @color-violet-900;
    padding-bottom: 4rem;
  }

  &-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media @tablet-landscape {
      grid-template-columns: 1fr 1fr;
    }
  
    @media @mobile {
      grid-template-columns: 1fr;
    }
  }

  &-card {
    .card-small-Layout();
    .card-hoverable-Layout();
    height: 100%;
    padding: 2.5rem 2rem;
    border: none;
    background-color: #DDFCF7;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
    @media @tablet-landscape {
      min-height: 215px;
    }

    &_text {
      display: flex;
      flex-direction: column;
    }

    &:hover {
      background-color: #0B009A;
      h3 {
        color: @color-white;
      }
    }

    img {
      width: 3rem;
      height: 3rem;
    }

    h3 {
      .text-heading-3();
      color: #0B009A;
      margin-top: 1.5rem;
      margin-bottom: 0;
      flex: 1;
    }
  }
};