@import (reference) "../../styles/main.less";

.slackButton {
  height: 74px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075), 0px 8px 16px rgba(0, 0, 0, 0.05);
  padding-left: 8px;
  z-index: 1000;
  background-color: #4a154b;
  border-radius: 12px;
  color: #fff;
  transition: @transition-all;

  &:hover {
    background-color: darken(#4a154b, 3%);
  }

  & > .slackIcon {
    display: flex;
    flex: 0;
    align-items: center;
    padding: 14px;

    & > img {
      transform: scale(2);
      width: 42px;
    }
  }

  & > .slackText {
    padding: 18px 18px 18px 0px;
    p {
      span {
        display: block;
      }
    }
  }
}

@media @tablet, @phone, @tablet-landscape {
  .slackButton {
    display: none;
  }
}
