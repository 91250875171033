.WebhooksTable {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    color: #6B7280;
    overflow: auto;
    
    & > table 
    
    > thead {
        padding: 14px;
        & > tr > {
            > th {
                font-weight: 400;
                font-size: 0.875rem;
                color: #848484;
                background-color: #fff;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                height: 40px;
                align-items: center;
                padding: 15px;
                min-width: 175px;
            }
        }
    }

   &__body {
    > tr > td {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        height: 100%;
        padding: 14px;
        min-height: 40px;
        text-align: center;
        vertical-align: bottom;      
    }
   }

   &__row {
    & > :first-child {
        min-width: 30px;
    }
   }
}
