@import (reference) "../../../../styles/main";

.UseCasePricing {
  margin-bottom: 20px;
  border-radius: @border-radius-md;
  padding: 30px 40px;
  &__header {
    font-size: @text-size-22;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &__content {
    display: flex;
    .bp-desktop-small({
      flex-direction: column;
    })
  }
  &__pricingTableWrapper {
    flex: 1;
    max-width: 700px;
    min-width: 320px;
    margin-right: 20px;
    .bp-desktop-small({
      margin-right: 0;
      max-width: initial;
      width: 100%;
      margin-bottom: 20px;
    })
  }
  &__estimatedTransactionsWrapper {
    max-width: 530px;
    min-width: 400px;
    .bp-desktop-small({
      max-width: initial;
      width: 100%;
    })
  }
}
