@import (reference) "../../../styles/main";

.data_template {
  &-background {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    background: @color-blue-25;
    padding-top: @hero-padding-top;
    margin-top: -@header-height;
    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 26.25rem;
      top: 0;
      background-color: @color-blue-100;
    }
  }

  &-main {
    width: 100%;
    max-width: @layout-max-width;
    padding: 6rem 4rem;
    background-color: @color-white;
    border-radius: @card-border-radius;
    z-index: @layer-top-2;
    margin-bottom: @hero-padding-bottom;
    display: flex;

    .button-component {
      z-index: @layer-top-2;
      width: 100%;
      margin-top: 2rem;
    }

    > section {
      width: 50%;

      &:first-child {
        padding-right: 4.375rem;
      }
    }
  }

  &-title {
    .text-heading-2();
  }

  &-paragraph {
    .text-subheading-2();
    white-space: break-spaces;
  }
}

@media @tablet-landscape {
  .data_template {
    &-main {
      flex-direction: column;
      padding: 3.125rem;

      > section {
        width: 100%;

        &:first-child {
          padding-right: 0;
        }
      }
    }
  }
}

@media @tablet {
  .data_template {
    &-main {
      padding: 1.875rem;
    }
  }
}
