@import (reference) "../../../styles/main";

.first_post {
  &-main {
    position: relative;
  }

  &-post {
    position: relative;
    width: 100%;
    min-width: 100%;
    border-radius: @card-border-radius;
    overflow: hidden;
    transition: @transition-all;
    margin: 0 0 3.5rem;
    background-color: @color-white;
    border: 0.5rem solid @color-white;

    @media @tablet-landscape {
      margin-bottom: 3rem;
    }
    @media @tablet {
      margin-bottom: 2rem;
    }

    &:hover,
    &:focus {
      box-shadow: @box-shadow-active;
      transition: @transition-all;
      border-color: @color-indigo-100;

      .first_post-title,
      .first_post-date,
      .first_post-description {
        color: @color-blue-900;
      }
    }

    a {
      display: flex;
      min-height: 25rem;
      display: flex;
      align-items: stretch;

      @media @tablet-landscape {
        flex-direction: column-reverse;
      }
    }
  }

  &-image {
    min-height: 100%;
    border-radius: @card-border-radius * 0.6667;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 50%;

    @media @tablet-landscape {
      width: 100%;
      height: 17.5rem;
    }

    @media @tablet {
      height: 12.5rem;
    }
  }

  &-textarea {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 50%;
    z-index: 40;
    padding: 3rem 2rem 2rem;

    @media @tablet-landscape {
      position: relative;
      width: 100%;
      padding: 1.75rem;
    }
  }

  &-title {
    .text-heading-2();
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media @tablet {
      .text-heading-3();
    }
  }

  &-date {
    font-family: @font-family-body;
    font-weight: 400;
    color: @color-violet-600;
    margin-top: 2rem;
  }

  &-description {
    .text-subheading-2();
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media @tablet {
      .text-paragraph();
    }
  }

  &-more {
    display: none;
  }
}
