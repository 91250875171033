@import (reference) "../../../styles/main";

.benefit_card {
  .card-Layout();
  padding: 1.5rem 2rem;
  text-align: center;
  background: @color-primary-100;
  border-color: @color-primary-100;
  h3 {
    .text-heading-2();
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    color: @color-primary-700;
  }
  p {
    .text-subheading-2();
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    &.big-percent {
      font-family: @font-family-headings;
      font-weight: 700;
      font-size: 5rem;
      line-height: 5rem;
      margin: 0;
      color: @color-primary-1000;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
