@import (reference) "../../styles/main";

.CreateDataAPI {
  background-color: @color-platformBackground;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__contentWrapper {
    overflow: auto;
    padding: 20px 40px;
    flex: 1;
    margin-bottom: 80px;

    .bp-mobile({
      padding: 20px;
    });
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    font-size: @text-size-12;
    font-weight: 400;
    margin-bottom: 12px;

    > a {
      color: #9b92d2;
    }

    > p {
      color: #9ca3af;
    }

    > svg {
      stroke: #d1d5db;
      stroke-width: 3px;
      margin: 0 4px;
    }
  }

  &__nameSection {
    margin-bottom: 16px;

    &__saveButton {
      margin-top: 20px;
      display: none !important;

      .bp-tablet({
        display: inline-flex !important;
      });
    }

    &__title {
      display: flex;
      align-items: center;
      position: relative;

      > svg {
        margin-left: 20px;
      }

      &__saveButton {
        margin-left: auto;

        .bp-tablet({
          display: none !important;
        });
      }

      &__text {
        font-size: @text-size-30;
        line-height: 1.4;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: calc(100vw - 400px);
  
        .bp-mobile({
          max-width: unset;
        });
      }

      &__button {
        margin-left: 12px;

        svg {
          stroke: #9B92D2;
        }
      }

      &__input {
        gap: 0 !important;

        input {
          width: calc(100vw - 340px);
          font-size: @text-size-30 !important;
          font-weight: 600;
  
          .bp-mobile({
            width: calc(100vw - 40px);
          });
        }
      }
    }
  
    &__description {
      margin-top: 8px;

      &__button {
        background: none;
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
      }

      &__text {
        width: 100%;
        color: #6B7280;
        font-size: @text-size-14;
        line-height: 1.4;
        text-align: left;
      }

      &__textarea {
        gap: 0 !important;

        textarea {
          width: calc(100vw - 340px);
          font-size: @text-size-14;
          line-height: 1.4;
  
          .bp-mobile({
            width: calc(100vw - 40px);
          });
        }
      }
    }
  }

  &__connectorSection {
    border-top: 1px solid #E5E7EB;
    padding-top: 24px;

    &__text {
      font-weight: 600;
      font-size: @text-size-22;
      margin-bottom: 24px;
    }

    &__table {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      margin: 24px 0;
      .bp-desktop-large({
        grid-template-columns: 1fr 1fr 1fr;
      });
      .bp-desktop-big({
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      });
      .bp-phone({
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
      });
    }
  }
}
