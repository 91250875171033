@import (reference) "../../styles/main";

.UseCaseTestsPage {
  &__blackfinJWTFetching {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__blackfinJWTFetchingError {
    width: 100%;
  }
}
