@import (reference) "../../../styles/main";

.newsletter {
  &-background {
    background-color: @color-violet-900;
    .main-Layout();
  }

  &-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
      position: relative;

      .button-component {
        width: 32.5rem;
        input {
          display: none !important;
        }
      }
    }
  }

  &-head {
    .text-heading-2();
    margin-bottom: 4rem;
    color: @color-white;
    @media @tablet {
      text-align: left;
    }
  }

  &-input {
    width: 100%;
    height: 3rem;
    outline: none;
    border: 1px solid @color-violet-100;
    border-radius: 0.25rem;
    .text-paragraph();
    color: @color-coolGray-900;
    margin-top: 0;
    font-weight: 300;
    padding: 0.25rem 0.75rem;
    margin-bottom: 1.5rem;
    &:focus {
      background: @color-white;
      border: 1px solid @color-primary-900;
      outline: 0.1975rem solid @color-indigo-300;
      box-shadow: @box-shadow-active;
    }
    &::placeholder {
      color: @color-blue-200;
    }
  }

  &-error {
    position: absolute;
    top: -1.25rem;
    color: @color-pink-900;
  }
}

@media @tablet {
  .newsletter {
    &-input {
      width: 100%;
    }

    &-text {
      margin: 3.125rem 0;

      form {
        width: 100%;

        .button-component {
          width: 100%;
        }
      }
    }
  }
}
