@import (reference) "../../styles/main";

.partners_benefits {
  width: 100%;
  .section-layout {
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 6rem;
    padding: @main-padding;
    align-items: flex-start;

    h2 {
      .text-heading-2();
    }
    p {
      display: flex;
      .text-subheading-2();
      margin: 0;
      padding: 1.5rem 0;
      border-bottom: 1px solid @color-coolGray-300;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  &:nth-child(2n + 1) {
    background: @color-coolGray-50;
  }
}
