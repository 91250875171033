@import (reference) "../../../../styles/main";

.UseCasesBatchApprovalTable {
  overflow-y: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  &__loader, &__empty {
    height: 60px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    & .Loader {
      width: 40px;
      height: 40px;
    }
  }
  &__cell {
    &--approve {
      margin-left: auto;
      font-size: @text-size-14;
      font-weight: 400;
      svg {
        stroke-width: 2.5;
      }
    }
    &--nameSvg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 32px;
      background-color: #C2F4ED;
      margin-right: 8px;

      & > svg {
        stroke: #64CCB9;
        stroke-width: 2.5;
      }
    }
    &--nameString {
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }
  }
  &__table {
    min-width: 600px;
    grid-template-columns:
      minmax(100px, 3fr)
      minmax(100px, 1fr)
      minmax(100px, 2fr)
      minmax(240px, 2fr);
    & thead th {
      min-height: 43px;
      &:first-of-type {
        padding-left: 30px;
      }

      &:last-of-type {
        padding-right: 30px;
        border-right: none;
      }
    }
    & tr > td {
      border-bottom: 1px solid #e5e5e5;
      color: #4b5563;
      font-size: @text-size-14;

      &:first-of-type {
        padding-left: 30px;
        font-weight: 600;
        color: #111827;
        font-size: @text-size-16;
        line-height: 1.5;
      }

      &:last-of-type {
        padding-right: 30px;
        border-right: none;
      }
    }
    & tr {
      &:hover {
        > td {
          background-color: #f0eff9;
        }
      }
    }
    & td {
      min-height: 48px !important;
    }
  }
}
