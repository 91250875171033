@import (reference) "../../styles/main";

.NewConnectorModal {
  &__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 12px;
  }
  &__description {
    color: #374151;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  &__form {
    > div {
      max-width: unset;
    }
    input {
      margin-bottom: 20px;
    }
    textarea {
      margin-bottom: 20px;
      height: 110px;
      resize: none;
    }
    .Loader {
      width: 20px;
      height: 20px;
      > svg {
        color: @color-white;
        > line {
          color: @color-white;
        }
      }
    }
  }
}
