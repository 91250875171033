@import (reference) "../../../../styles/main";

.UseCaseConfiguration {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #e5e7eb;
  &__warning {
    margin-bottom: 24px;
    p {
      padding: 8px 16px;
      background-color: #fff7ed;
      color: #ea580c;
      font-size: @text-size-14;
      margin-bottom: 24px;
    }
    a {
      background-color: @color-white;
      padding: 4px 8px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      &:hover {
        background-color: #d2cee9;
      }
    }
  }
  &__header {
    margin-bottom: 24px;
    &__title {
      margin-bottom: 24px;
      font-size: @text-size-22;
      font-weight: 600;
    }
    &__paragraph {
      font-size: @text-size-14;
      color: #6b7280;
    }
    &__actions {
      &__toggleSection {
        padding: 0;
        width: 30px;
        height: 30px;
        margin-left: 15px;
        background-color: transparent;
        box-shadow: none;
        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
        }
        &--closed {
          & > svg {
            transform: rotateZ(-180deg);
          }
        }
        & > svg {
          transition: @transition-all;
          width: 20px;
          height: 20px;
          margin: 0;
          stroke: #bab9c1;
        }
        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px;
        }
      }
    }
  }
  &__configurationFetchErrorWrapper {
    padding: 10px 15px;
  }
  &__contentWrapper {
    height: 400px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
  }
  &__actions {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
    display: flex;
    align-items: center;
    &__cancelEditingConfiguration {
      font-size: @text-size-16;
      margin-right: 10px;
      height: 30px;
    }
    &__saveConfigurationChanges {
      font-size: @text-size-14;
      height: 30px;
    }
    &__editConfiguration {
      font-size: @text-size-14;
      height: 30px;

      > svg {
        width: 16px;
        height: 16px;
      }
    }
    p {
      font-size: @text-size-14;
      color: @color-coolGray-600;
    }
  }
}
