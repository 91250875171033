@import (reference) "../../../../../styles/main";

.UsageChart {
  &__tooltip {
    min-width: 140px;
    max-height: 300px;
    overflow-y: scroll;
    white-space: nowrap;
    padding: 20px 0 10px;
    z-index: @z-index-popups;
    border: 0;
    &__date {
      font-size: @text-size-12;
      color: @color-coolGray-600;
      padding: 0 20px;
    }
    &__foot {
      border-top: 1px solid #0000001a;
      color: @color-primary-600;
      display: flex;
      align-items: center;
      font-size: @text-size-16;
      font-weight: 600;
      padding: 10px 20px 0;
      margin-top: 10px;
      > svg {
        margin-right: 5px;
      }
    }
    &__list {
      font-size: @text-size-14;
      margin-top: 10px;
      border-spacing: 20px 10px;
      border-collapse: separate;
      &__item {
        margin-bottom: 10px;
        &__key {
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &__indicator {
            min-width: 10px;
            width: 15px;
            margin-right: 15px;
            display: flex;
            align-items: flex-start;
            & svg {
              width: 100%;
            }
          }
          &__label {
            font-size: @text-size-16;
          }
        }
        &__facet {
          color: @color-coolGray-600
        }
        &__value {
          font-weight: 600;
          font-size: @text-size-14;
          text-align: right;
        }
      }
    }
  }
}
