@import (reference) "../../../styles/main";

.access {
  &-background {
    padding-top: 12.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: @color-violet-100;
    padding-top: @main-padding-top;
  }

  &-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 116.7%;
      height: auto;
    }
  }

  &-head {
    .text-heading-2();
    max-width: 64rem;
    text-align: center;
    width: 100%;
    color: @color-violet-900;
  }

  &-paragraph {
    max-width: 64rem;
    text-align: center;
    .text-subheading-2();
    margin-bottom: 3.5rem;
    color: @color-violet-600;
  }
}

@media @tablet {
  .access {
    &-background {
      padding-top: 6.25rem;
    }

    &-head {
      text-align: left;
    }

    &-paragraph {
      text-align: left;
    }
  }
}
