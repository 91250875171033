@import (reference) "../../../styles/main";

.RemoveApiKeyModal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: @z-index-modals;
  &__backdrop {
    background-color: #00000066;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__paper {
    padding: 48px;
    width: 100%;
    max-width: 600px;
    max-height: calc(100% - 40px);
    overflow: auto;
    .bp-mobile({
      height: 100%;
      max-height: 100%;
      max-width: initial;
    })
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
      stroke: #9B92D2;
      stroke-width: 2px;
    }
  }
  &__exit {
    width: 100%;
    padding: 20px 50px;
    .bp-mobile({
      padding: 20px;
    })
  }
  &__content {
    &__header {
      font-size: @text-size-30;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }
  &__paragraph {
    color: #111827;
    font-weight: 400;
    font-size: @text-size-16;
    margin-bottom: 24px;
  }
  &__confirmation {
    display: flex;
    justify-content: flex-end;
    &__button {
      font-size: @text-size-16;
      font-weight: 400;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
