@import (reference) "../../../styles/main";

.SubNav {
  width: 100%;
  padding-left: 20px;
  margin-top: 60px;
  overflow-y: auto;
  .bp-mobile({
    padding: 0 20px;
    overflow-y: unset;
  });
  &__header {
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 10px;
    margin-bottom: 20px;
    .bp-mobile({
      color: #BAB9C1;
    });
  }
  &__list {
    padding-bottom: 20px;
  }
}

.SubNavItem {
  display: flex;
  flex-direction: column;

  &__placeholder {
    width: 100%;
    height: 40px;
    padding: 10px;
    &__skeleton {
      width: 100%;
      height: 100%;
      border-radius: @border-radius-sm;
      animation: skeletonBackgroundAnimation 1.5s ease-in-out infinite;
    }
  }

  &__link {
    border-radius: @border-radius-xs;
    padding: 10px;
    display: flex;
    align-items: center;
    color: #5b5b5b;
    transition-property: color;
    transition-timing-function: @transition-timing-function;
    transition-duration: @transition-duration;
    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover {
      color: #5140b3;
    }
    .bp-mobile({
      &:hover {
        color: @color-white !important;
      }
      color: @color-white;
    });
    & > svg {
      margin-right: 10px;
    }
    &--active {
      font-weight: 600;
      color: #5140b3;
      & > svg {
        color: #7b61ff;
      }
      .bp-mobile({
        background-color: #463e9b;
        color: @color-white;
        & > svg {
          color: @color-white;
        }
      });
    }
  }
  &__subItemsList {
    font-weight: 400;
    padding-left: 10px;
    overflow: hidden;
    padding-top: 10px;
    .bp-mobile({
      padding-left: 0;
    });
    & > .SubNavItem {
      margin-bottom: 10px;
      padding-right: 10px;
      .bp-mobile({
        padding-right: 0;
      });
      & > .SubNavItem__link {
        padding: 5px 30px;
        &.SubNavItem__link--active {
          background-color: #ebe7ff;
          .bp-mobile({
            background-color: #463e9b;
          });
        }
      }
    }
  }
}
