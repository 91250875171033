@import (reference) "../../styles/main";

.header_banner {
  width: 100%;
  height: @banner-height;
  background-color: @color-violet-1000;
  align-items: center;
  padding: 0 1.2rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  color: @color-white;
  font-size: 0.875rem;
  font-family: @font-family-headings;
  font-weight: 500;
  line-height: 1rem;
  overflow: hidden;
  position: fixed;
  top: 0;
  @media @tablet {
    font-size: 0.75rem;
    line-height: 0.875rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    &-text {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      text-align: center;
      color: @color-coolGray-200;
      transition: @transition-all;
      padding: 0.75rem 0.5rem;
      &.underline:hover {
        color: @color-teal-300;
      }
    }

    @media @tablet {
      flex-direction: column;
      text-align: center;
    }

    &-button_container {
      display: flex;
      &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid @color-white;
        padding: 0.375rem 0.75rem;
        margin: 0 0.5rem;
        overflow: hidden;
        color: @color-white;
        white-space: nowrap;

        @media @tablet {
          margin: 0 0.75rem;
        }

        @media @mobile {
          margin: 0 0.5rem;
        }
      }

      @media @tablet {
        justify-content: space-between;
        gap: 0.5rem;
        margin: 0;
        margin-top: 1rem;
        max-width: 100%;
      }
    }
  }

  &-page_circle {
    flex: 0;
    &-container {
      display: flex;
      min-width: 1.5rem;
      justify-content: center;
      align-items: center;

      @media @mobile {
        flex-direction: column;
      }
    }

    &-button {
      cursor: pointer;
      background-color: @color-white;
      border: none;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 0.25rem;
      margin-right: 0.25rem;
      padding: 0;

      &.active {
        background-color: @color-teal-400;
      }

      &:hover {
        color: @color-teal-200;
      }

      @media @mobile {
        margin-right: 0;
        margin-bottom: 0.25rem;
        width: 0.375rem;
        height: 0.375rem;
      }
    }
  }

  &-close_icon {
    flex: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    min-width: 1.5rem;
    height: 1.5rem;
  }
}
