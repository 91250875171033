@import (reference) "../../../../styles/main";

.InputsGuide {
  background-color: rgba(248, 248, 248);
  overflow-y: auto;
  padding-top: 15px;
  transition: @transition-all;
  min-height: 100%;
  &--requirementsMet {
    background-color: @color-white;
  }
  &__message {
    padding: 0 15px;
    margin-bottom: 20px;
    color: #5b5b5b;
    &--requirementsMet {
      & > p:first-of-type {
        color: #5140b3;
      }
    }
    & > p:first-of-type {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-weight: 600;
      & > svg {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
  }
  &__providersList {
    padding: 0 15px;
    & > li {
      margin-bottom: 10px;
    }
  }
  &__footer {
    padding: 0 15px;
    margin-top: 20px;
    font-size: @text-size-12;
    line-height: 20px;
    color: #5b5b5b;
    display: inline-block;
    font-style: italic;
    > span {
      font-size: @text-size-12;
      line-height: 20px;
      height: 20px;
      padding: 0 10px;
      font-style: normal;
      margin-left: 5px;
      display: inline-block;
      font-weight: 600;
      color: @color-coolGray-900;
      border-radius: 10px;
      background-color: @color-secondary-50;
      &--production {
        background-color: #f9ecbc;
      }
    }
  }
}

.ProvidersListElement {
  background-color: rgba(@color-white, 0.25);
  border-radius: @border-radius-sm;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 100%;
  &__noInputs {
    margin-top: 10px;
    color: #5b5b5b;
    padding: 0 10px;
  }

  &--requirementsMet {
    border-color: rgba(123, 97, 255, 0.5);
    background-color: rgba(123, 97, 255, 0.025);
  }
  &__header {
    display: flex;
    justify-content: space-between;
    &__providerInfo {
      display: flex;
      gap: 10px;
      align-items: center;
      .bp-phone({
        flex-direction: column;
        align-items: flex-start;
      });
      &__logo {
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        & > img {
          max-width: 100%;
          height: 100%;
        }
      }
      &__details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        &__dataSourceName {
          font-size: @text-size-12;
          color: #5b5b5b;
          font-weight: 400;
        }
        &__providerName {
          font-weight: 600;
          line-height: 20px;
          display: inline-flex;
        }
        &__providerPrice {
          font-size: @text-size-12;
          line-height: 20px;
          height: 20px;
          padding: 0 10px;
          margin-left: 5px;
          border-radius: 10px;
          background-color: @color-secondary-50;
          &--production {
            background-color: #f9ecbc;
          }
        }
      }
    }
    &__requirementsMet {
      font-weight: 600;
      display: flex;
      align-items: center;
      font-size: @text-size-16;
      color: #bab9c1;
      &--true {
        color: #7b61ff;
      }
      & > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  &__inputsSetsList {
    margin-top: 10px;
    display: grid;
    grid-template-columns: minmax(min-content, auto) minmax(min-content, auto) minmax(
        min-content,
        auto
      );
    gap: 10px;
    overflow: hidden;
    &__element {
      padding: 5px 10px;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-weight: 600;
      line-height: 20px;
      &--allInputsFilled {
        background-color: #f9f8ff;
        border-color: #d0c9f1;
      }
      &__input {
        display: flex;
        align-items: center;
        font-size: @text-size-14;
        color: rgba(#5b5b5b, 75%);
        &:not(:first-of-type) {
          margin-top: 5px;
        }
        &--filled {
          color: #7b61ff;
        }
        &__fillCheck {
          height: 20px;
          width: 20px;
          min-width: 20px;
          margin-right: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 1px;
          & > svg {
            width: 100%;
            color: #bab9c1;
          }
          &--filled > svg {
            color: #7b61ff;
          }
        }
      }
    }
  }
}
