@import (reference) "../../styles/main";

.TextArea {
  &__input {
    padding: 10px;
  }
  &__no-height {
    height: unset;
  }
  &__label {
    &--optional {
      font-weight: 400;
      font-size: @text-size-16;
      line-height: 1.4;
    }
    &--flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
