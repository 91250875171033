@import (reference) "../../styles/main";

.DateRangePickerComponent {
  display: inline-block;
  position: relative;
  .bp-mobile({
    position: initial;
  });
  &__button {
    padding-right: 10px;
    & > svg {
      transition: @transition-all;
      margin-left: 5px;
      color: #5b5b5b;
      margin-right: 0;
      &:first-child {
        color: #9b92d2;
        stroke-width: 3px;
        margin-left: 0;
        margin-right: 5px;
        height: 16px;
        width: 16px;
      }
    }
    &:hover,
    &:focus {
      background-color: #b9b2e580;
      & > svg {
        color: @color-primary-900;
      }
    }
    &--active {
      & > svg {
        transform: rotate(-180deg);
        &:first-child {
          transform: rotate(0deg);
        }
      }
    }
  }
  &__popupWrapper {
    position: absolute;
    top: 51px;
    right: 0;
    z-index: @z-index-popups;
    display: flex;
    .bp-mobile({
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #00000066;
    });
  }
  &__arrow {
    right: 38%;
    .bp-mobile({
      display: none;
    });
  }
}

.TimePresets {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid @color-coolGray-200;
  min-width: 112px;

  &__title {
    color: @color-coolGray-700;
    padding: 12px 0;
  }

  &__button {
    border: none;
    cursor: pointer;
    color: @color-coolGray-500;
    background-color: @color-white;
    padding: 4px 0;
    
    &:hover {
      text-decoration: underline;
    }
    
    &:active {
      color: @color-coolGray-700;
    }
  }
}

.DatePickerContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;

  & > label {
    color:  @color-blue-900;
  }

}
