@import (reference) "../../../styles/main";

.post_body {
  &-background {
    .hero-backgroundLayout();
    background-color: @color-white;
  }

  &-main {
    text-align: initial;
    max-width: @layout-text-max-width;
  }

  &-buttons {
    top: 3.125rem;
    right: 3.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 3.125rem;

    @media @tablet {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &-button {
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: @color-white;
    border-color: transparent;
    outline: none;
    box-shadow: @box-shadow-default;

    &-wrapper {
      position: relative;
    }

    &:hover {
      cursor: pointer;
    }

    @media @tablet {
      display: none;
    }
  }

  &-header {
    .text-heading-1();
    &.aws {
      margin-bottom: 4rem;
    }
  }

  &-author {
    display: flex;
    align-items: center;
    margin-bottom: 3.125rem;

    > img {
      width: 4rem;
      height: 4rem;
      border-radius: @card-border-radius;
    }

    p {
      .text-subheading-2();
      margin: 0;
      margin-left: 2rem;
      white-space: nowrap;

      @media @tablet {
        margin-left: 1rem;
        margin-top: -1rem;
      }
    }

    @media @tablet {
      margin-bottom: 0;
    }
  }

  &-head {
    display: flex;
    @media @tablet {
      flex-direction: column;
    }
  }

  &-published {
    .text-paragraph();
    color: @color-coolGray-500;
    margin: 0.125rem 0 0 2.5rem;
    position: relative;

    @media @tablet {
      display: flex;
      margin-left: 10rem;
      margin-top: -1.75rem;
      text-align: left;
      width: 100%;
    }
  }

  &-summary {
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 3.125rem;
    max-width: 54rem;
  }

  &-image {
    max-height: 35rem;
    overflow: hidden;
    margin: 3rem 0;
    border-radius: @card-border-radius;
    img {
      border-radius: @card-border-radius;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-content {
    font-size: 1.5rem;
    line-height: 160%;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: @font-family-headings;
      margin-bottom: 1.5rem;
    }

    p,
    h3,
    figure {
      position: sticky;
      left: 0;
      margin-bottom: 3.125rem;
    }

    figcaption {
      font-size: @text-paragraph-font-size;
      line-height: 1.4rem;
    }

    ol,
    ul {
      position: sticky;
      left: 1.5rem;
      margin-left: 1.5rem;
      margin-top: 3.125rem;
      margin-bottom: 5.625rem;
    }

    li {
      position: sticky;
      left: 0;
      margin-bottom: 1.875rem;
      padding-left: 1.25rem;
    }

    ul {
      list-style: initial;
    }

    ol {
      list-style: decimal;
    }

    li::marker {
      font-weight: 700;
      color: @color-blue-600;
    }

    ul li::marker {
      //font-size: 150%;
    }

    h3 {
      font-size: 2.25rem;
      line-height: 2.7rem;
      font-weight: 600;
    }

    a {
      color: @color-primary-900;
      text-decoration: underline;

      &:visited {
        color: @color-primary-900;
      }
    }

    strong,
    b {
      font-weight: 700;
    }

    img {
      max-width: 100%;
    }

    blockquote {
      position: relative;
      margin: 6.25rem 0 7.75rem 2.5rem;
      font-style: italic;
      font-weight: 400;
      font-size: 2rem;
      line-height: 2.8rem;

      &::before {
        content: "";
        position: absolute;
        bottom: -0.9375rem;
        left: -2.5rem;
        width: calc(100% + 2.5rem);
        height: 5rem;
        background-color: #f6f5fb;
        z-index: -1;
      }
    }

    table {
      border-top: 1px solid @color-primary-200;
      border-left: 1px solid @color-primary-200;
      margin-bottom: 4.75rem;
      width: calc(100vw - 7rem);
      max-width: 85rem;
      background-color: @color-white;
      position: relative;

      tr:first-child {
        td {
          background-color: #f6f5fb;
          font-weight: 700;
        }
      }

      td {
        border-bottom: 1px solid @color-primary-200;
        border-right: 1px solid @color-primary-200;
        padding: 1.25rem;

        p {
          margin-bottom: 0;
        }
      }

      &:after {
        content: "";
        position: absolute;
        bottom: -1.625rem;
        left: -1px;
        height: 1.5rem;
        width: calc(100vw - 11rem);
        max-width: 100%;
        min-width: calc(100% - 1px);
        background-color: #f6f5fb;
        border-bottom: 1px solid @color-primary-200;
        border-right: 1px solid @color-primary-200;
        border-left: 1px solid @color-primary-200;
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
