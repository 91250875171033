@import (reference) "../../../styles/main";

.InviteUserMenu {
  position: absolute;
  top: 51px;
  right: 25px;
  width: 350px;
  z-index: @z-index-popups;
  .bp-mobile({
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: @z-index-modals;
    border: none;
    border-radius: 0;
  });
  &__loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: @border-radius-sm;
  }
  &__form {
    & .FormComponent {
      margin-bottom: 20px;
    }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 40px;
      & > button:first-of-type {
        margin-right: 10px;
      }
    }
  }
}
