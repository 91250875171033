@import (reference) "../../../styles/main";

.careers_offers {
  &-background {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: @color-blue-200;
    padding-top: @hero-padding-top;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: -@header-height;
  }

  &-main {
    width: 100%;
    max-width: 64rem;
    padding: 6rem 4rem;
    background-color: @color-white;
    border-radius: @card-border-radius;
    z-index: @layer-top-2;
    margin-bottom: @hero-padding-bottom;
    display: flex;
    flex-direction: column;

    &-text {
      ul {
        margin-bottom: 1rem;
        padding-left: 1.875rem;
        li {
          .text-paragraph();
          margin-bottom: 1rem;
          position: relative;
        }
        li:before {
          content: "-";
          position: absolute;
          left: -1.5rem;
        }
      }
    }

    .whr-container {
      margin-top: 2rem;
    }

    .whr-code {
      display: none;
    }

    .whr-item {
      background-color: @color-blue-25;
      padding: 1rem 2rem;
      margin-bottom: 0.75rem;
      position: relative;
      transition: @transition-all;
      border-radius: 0.5rem;
      &:hover {
        box-shadow: @box-shadow-active;
        background-color: @color-coolGray-100;
        .whr-info li {
          color: @color-blue-900;
        }
      }
    }

    .whr-title {
      .text-heading-3();
      margin-bottom: 0.75rem;
      a {
        color: @color-coolGray-900;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: @transition-all;

        overflow: hidden;
        z-index: 999;
        padding: 1rem 2rem;

        &:hover {
          color: @color-blue-900;
        }
      }
    }

    .whr-info {
      margin-top: 2.5rem;

      @media @tablet {
        margin-top: 6rem;
      }
      li {
        transition: @transition-all;

        .text-paragraph();
        margin: 0;
      }
    }
  }

  &-header {
    .text-heading-1();
    margin-top: 0;
  }

  &-summary {
    .text-heading-2();
    margin-top: 3rem;
  }

  &-paragraph {
    .text-paragraph();
    &:first-child {
      .text-subheading-2();
    }
  }
}

@media @tablet {
  .careers_offers {
    &-main {
      padding: 6.25rem 1.875rem 2.5rem;
    }
  }
}
