@import (reference) "../../../styles/main";

.usecases_resources_section {
  .main-Layout();
  background-color: @color-blue-25;
  &-container {
    .section-Layout();
    align-items: flex-start;
  }
  h2 {
    .text-heading-2();
  }
  &-subtitle {
    .text-subheading-2();
  }
}
