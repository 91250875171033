@import (reference) "../../../../styles/main";

.ReportsDashboardHeader {
  width: 100%;
  background-color: @color-white;
  height: 70px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: @border-radius-md;
  border-top-right-radius: @border-radius-md;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  font-size: @text-size-14;
  &__report {
    display: flex;
    &__cumulative {
      font-weight: 600;
      font-size: @text-size-22;
      margin-right: 10px;
      vertical-align: middle;
      display: inline-block;
    }
    &__infoIcon {
      position: relative;
      font-size: @text-size-22;
      cursor: pointer;
      &:hover {
        div {
          visibility: visible;
        }
      }
      svg {
        vertical-align: middle;
        display: inline-block;
      }
      div {
        width: 500px;
        position: absolute;  
        top: 50%;
        left: 35px;
        transform: translateY(-50%);
        background-color: @color-white;
        box-shadow: @box-shadow-default;
        color: #000;
        font-size: 0.6em;
        padding: 10px;
        visibility: hidden;
        &::before {
            content: "";
            position: absolute;
            left: -20px;
            top: 50%;
            border: 10px solid;
            border-color: #000 #0000 #0000 #0000;
            transform: rotate(90deg) translateX(-50%);
        }
      }
    }
    &__logo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);

      & > img {
        width: 100%;
        max-height: 100%;
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      & > span {
        &:first-of-type {
          font-weight: 600;
        }
        &:last-of-type {
          color: #5b5b5b;
        }
      }
    }
  }
  &__changeReport {
    &__button {
      & > svg {
        margin-left: 10px;
        margin-right: 0;
      }
      & > span {
        font-size: 14px;
      }
    }
    &__wrapper {
      position: relative;
    }
    &__popup {
      position: absolute;
      top: 45px;
      right: -1px;
      border-radius: @border-radius-md;
      z-index: @z-index-popups;
      min-width: 400px;
      padding: 10px 0;
      @media @mobile {
        min-width: 100%;
      }
      &__reportsList {
        display: flex;
        flex-direction: column;
        .Loader {
          margin: auto;
        }
        & > li {
          display: flex;
          padding: 0;
          align-items: center;
          font-size: @text-size-16;
          min-height: 60px;
          & > button {
            display: flex;
            width: 100%;
            padding: 10px 30px;
            border: none;
            background-color: transparent;
            cursor: pointer;
            transition: @transition-all;
            &:hover {
              background-color: #e3deff80;
            }
          }
        }
        &__header {
          color: #5b5b5b;
          min-height: 30px;
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 30px;
        }
        &__cumulativeReport {
          > button {
            font-weight: 600;
          }
        }
        &__report {
          // display: flex;
          &__logo {
            min-width: 40px;
            min-height: 40px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            border: 1px solid rgba(0, 0, 0, 0.1);

            & > img {
              width: 100%;
              max-height: 100%;
            }
          }
          &__info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            & > span {
              line-height: 20px;
              &:first-of-type {
                font-weight: 600;
                text-align: left;
              }
              &:last-of-type {
                color: #5b5b5b;
              }
            }
          }
        }
      }
      //&__filtersSetsList {
      //  display: flex;
      //  width: 100%;
      //  & > div {
      //    margin-right: 60px;
      //    &:last-of-type {
      //      margin-right: 0;
      //    }
      //  }
      //  margin-bottom: 20px;
      //}
      //&__actions {
      //  display: flex;
      //  justify-content: flex-end;
      //}
    }
  }
  &__button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
