@import (reference) "../landingPage/styles/main";

.fallback {
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  background-color: @color-coolGray-50;
  align-items: flex-start;

  img {
    max-width: 10.125rem;
    margin: 0 auto 8rem;
  }

  &__content {
    margin: 4rem auto 4rem;
    display: flex;
    padding: 0 1.5rem;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    height: 100%;

    &__button {
      button {
        .button-text();
        margin-top: 3rem;
        min-height: 3.75rem !important;
        max-height: unset;
        min-width: 10rem;
      }
    }
  }

  h1 {
    .text-heading-1();
  }

  h2 {
    .text-subheading-1();
  }

}
