@import (reference) "../../../styles/main";

.LandingCustomers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-top: 2rem;

  > img {
    max-width: 13.75rem;
    border-radius: 0.125rem;

    @media @mobile {
      max-width: 6rem;
    }
  }
}
