@import (reference) "../../../../styles/main";

.UseCasesRunBatch {
  &__paper {
    padding: 0;
    width: 100%;
    max-height: calc(100% - 120px);
    margin-top: 24px;
    .bp-mobile({
      height: 100%;
      max-width: initial;
    });
    .bp-tablet({
      overflow-x: auto;
    });
  }
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
  }
  &__inputs {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #E5E7EB;
      gap: 12px;
      padding: 27px 20px;
      .bp-mobile({
        flex-direction: column;
        > p {
          align-self: flex-start;
        }
        &__buttons {
          align-self: flex-end;
        }
      });
      > p {
        font-weight: 600;
        font-size: @text-size-18;
        line-height: 1.4;
      }
      &__buttons {
        display: flex;
        gap: 12px;
      }
    }
    &__table {
      display: grid;
      grid-template-columns:
        minmax(250px, 1.5fr)
        minmax(100px, 1fr);
      min-height: 120px;
      .bp-desktop-small({
        grid-template-columns:
          1fr;
      });
    }
  }
  &__content {
    padding: 25px 20px 20px;
    .bp-mobile({
      padding: 15px 10px 10px;
    });

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      &__title {
        font-size: @text-size-18;
        font-weight: 600;
      }
      & svg {
        stroke-width: 3;
      }
    }
    &__uploadInputWrapper {
      margin-bottom: 24px;
    }
    &__uploadSize {
      & > p {
        font-size: @text-size-16;
        line-height: 1.5;
        font-weight: 400;
      }
    }
  }
}
