@import (reference) "../../../styles/main";

.LandingTestimonials {
  min-height: 18.75rem;
  min-width: 100%;
  .section-layout {
    min-height: 18.75rem;
  }

  &-testimonial {
    min-width: 100%;
    width: 100%;
    min-height: 18.75rem;
    border-radius: @card-border-radius;
    background-color: @color-primary-900;
    box-shadow: @box-shadow-default;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: stretch;

    &-content {
      flex: 1 1 auto;
      padding: 3.125rem 3.75rem;
      color: @color-teal-300;
      display: flex;
      justify-content: center;
      flex-direction: column;
      h4,
      p {
        width: 100% !important;
        color: inherit !important;
        text-align: left !important;
        white-space: normal !important;
      }
      h4 {
        font-size: 1.25rem;
        line-height: 1.6;
        margin-bottom: 2rem !important;
        margin-top: 0 !important;
      }

      p {
        margin: 0 !important;
        &:first-of-type {
          font-family: @font-family-headings;
          font-weight: 700;
          font-size: @text-paragraph-font-size;
          line-height: 1.75rem;
        }
        &:last-of-type {
          margin: 0;
          font-size: @text-paragraph-font-size;
          line-height: 1.5rem;
        }
      }

      button {
        height: 3.5rem;
        width: 3.5rem;
        outline: 0;
        border: 0;
        border-radius: 50%;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: @color-white;
        transition: @transition-all;
        cursor: pointer;
        svg {
          height: 1.5rem;
          width: 1.5rem;
          color: @color-violet-600;
        }

        &:hover,
        &:active {
          background-color: darken(@color-coolGray-50, 2.5%);
        }

        &:nth-child(even) {
          background-color: @color-teal-300;

          &:hover,
          &:active {
            background-color: @color-teal-500;
          }
        }
      }

      @media @tablet-landscape {
        padding: 1.875rem 2.5rem;
      }
    }
    @media @tablet {
      flex-direction: column-reverse;
    }

    &-toggles {
      display: inline-flex;
      margin-top: 2rem;
      gap: 1rem;
    }
    &-image {
      flex: 0 0 auto;
      width: 30rem;
      background-size: cover;

      @media @tablet-landscape {
        max-width: 15rem;
      }

      @media @tablet {
        max-width: unset;
        width: 100%;
        height: 12.5rem;
      }
    }

    &.index-1,
    &.index-5,
    &.index-9 {
      background-color: darken(@color-pink-900, 5%);
      .LandingTestimonials-testimonial {
        &-content {
          color: @color-white !important;
        }
      }
    }

    &.index-2,
    &.index-6,
    &.index-10 {
      background-color: darken(@color-violet-900, 1.25%);
      .LandingTestimonials-testimonial {
        &-content {
          color: @color-teal-400;
        }
      }
    }

    &.index-3,
    &.index-7,
    &.index-11 {
      background-color: @color-primary-200;
    }
  }
}
