@import (reference) "../../../styles/main";

.FileUploadGPG {
  &__message {
    font-size: @text-size-14;
    margin-bottom: 20px;
    & > a {
      text-decoration: underline;
      color: @color-primary-900;
      transition: @transition-all;
      margin-left: 5px;
      &:hover {
        color: @color-primary-600;
      }
    }
  }
  &__buttons {
    &__gpgKey {
      background-color: #f1effe;
      border-radius: @border-radius-sm;
      color: @color-coolGray-600;
      font-weight: 400;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 20px;
      height: 40px;
      font-family: @font-family-mono;
      margin: 10px 10px 0 0;
    }
    &__showGPGKey {
      height: 40px;
      margin: 10px 10px 0 0;
    }
    &__downloadGPGKey {
      height: 40px;
      margin-top: 10px;
    }
  }
}
