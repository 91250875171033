@import (reference) "../../styles/main";

.GeneralPageSettings {
  &__separator {
    width: 100%;
    border-top: 1px solid #E5E7EB;
    margin-top: 24px;
    max-width: 960px;
  }
}
