@import (reference) "../styles/main";

.Toastify {
  &__toast {
    height: 40px;
    min-height: unset;
    min-width: 200px;
    padding: 0 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    box-shadow: @box-shadow-default;
    color: @color-coolGray-900;
    transition-property: background-color, color;
    transition-duration: @transition-duration;
    transition-timing-function: @transition-timing-function;
    cursor: default;
    box-shadow: 4px;
    .bp-phone({
      margin-bottom: 1rem;
    });
    &--success {
      background-color: @color-secondary-400;
    }
    &--error {
      background-color: @color-tertiary-900;
      color: @color-white;
    }
  }
  &__toast-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    &--top-center {
      left: 50%;
      .bp-mobile({
        left: 50%;
      });
      .bp-phone({
        top: 20px;
        left: unset;
      });
    }
  }
  &--animate {
    animation-duration: 0.2s;
  }
}
