@import (reference) "../../../styles/main";

.LandingConnectorCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3rem;
  margin: 3.75rem 0 1.25rem;
  @media @tablet {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  &-showMore {
    border: 0;
    height: 3.75rem;
    width: 100%;
    font-family: @font-family-body;
    font-size: 1.2rem;
    color: darken(@color-violet-400, 7.5%);
    border-radius: 1.25rem;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    transition: @transition-all;
    gap: 1rem;
    font-family: @font-family-body;

    &:hover,
    &:focus {
      cursor: pointer;
      background-color: @color-white;
    }

    &[disabled] {
      transition: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      cursor: not-allowed;
      border: none !important;
      opacity: 0.25;
    }
  }
}

.LandingConnectorCard {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 2.5rem 1.875rem;
  border-radius: 1.25rem;
  background-color: @color-white;
  border: 0.5rem solid @color-white;
  transition: @transition-all;
  gap: 0;
  font-family: @font-family-body;

  &:last-child:nth-child(2n - 1) {
    grid-column-start: 2;
    grid-column-end: 4;

    @media @tablet {
      grid-column-start: 1;
    }
  }

  @media @tablet {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .card-medium-Layout();
  .card-hoverable-Layout();

  &:hover,
  &:focus {
    .LandingConnectorCard {
      &-source,
      &-name,
      &-description {
        color: @color-primary-900;
      }
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
  }
  &-connectorImage {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 1rem;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid lighten(@color-violet-200, 10%);
  }

  &-source {
    display: inline-flex;
    .text-subheading-2();
    font-size: 1.25rem;
    color: @color-violet-600;
    margin: 0;
  }

  &-name {
    font-size: @text-heading-3-font-size !important;
    line-height: @text-heading-3-line-height !important;
    font-weight: @text-heading-3-font-weight !important;

    text-align: left !important;
    margin: 1.5rem 0 1rem 0 !important;
    @media @mobile {
      font-size: @text-heading-3-font-size-mobile !important;
    }
  }

  &-description {
    .text-paragraph();
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-tags {
    margin-top: 1.25rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.75rem;
    flex: 1 1 auto;
    align-items: flex-end;
    align-self: flex-start;
  }
  &-tag {
    font-size: @text-paragraph-font-size;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    color: @color-violet-400;
    background-color: @color-blue-25;
    height: 2.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 0.375rem;
  }
}
