@import (reference) "../../../styles/main";

.textarea {
  &-main {
    position: relative;

    &:hover {
      .textarea-proper {
        border-color: @color-violet-400;
      }
    }
  }

  &-label {
    display: flex;
    justify-content: space-between;
    .text-paragraph();
    color: @color-coolGray-600;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.25rem;

    span {
      font-weight: 400;
      color: @color-violet-500;
    }
  }

  &-proper {
    width: 100%;
    height: 7.5rem;
    outline: none;
    resize: none;
    border: 1px solid @color-violet-100;
    border-radius: 0.25rem;
    .text-paragraph();
    color: @color-coolGray-900;
    margin-top: 0;
    font-weight: 300;
    padding: 0.25rem 0.75rem;
    margin-bottom: 1.5rem;
    &.error {
      border: 1px solid @color-pink-900;
    }

    &:focus {
      background: @color-white;
      border: 1px solid @color-primary-900;
      outline: 0.1975rem solid @color-indigo-300;
      box-shadow: @box-shadow-active;
    }
  }

  &-proper-request-demo {
    width: 100%;
    outline: none;
    resize: none;
    border: 1px solid @color-violet-100;
    border-radius: 0.25rem;
    .text-paragraph();
    color: @color-coolGray-900;
    margin-top: 0;
    font-weight: 300;
    padding: 0.25rem 0.75rem;
    height: 7.5rem;

    @media (max-height: 744px){
      height: 55px
    }

    &.error {
      border: 1px solid @color-pink-900;
    }

    &:focus {
      background: @color-white;
      border: 1px solid @color-primary-900;
      outline: 0.1975rem solid @color-indigo-300;
      box-shadow: @box-shadow-active;
    }
  }

  &-error {
    position: absolute;
    bottom: 0.9375rem;
    color: @color-pink-900;
  }
}
