@import (reference) "../../styles/main";

.ProfileSettings {
  &__paper {
    display: flex;
    .bp-desktop-small({
      flex-direction: column;
    });

    &__loadingOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
  &__loading {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__message {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
  }
  &__form {
    flex: 1;
    & > .SettingsFormRow {
      margin-bottom: 20px;
    }
    &__message {
      margin: 20px 0;
      width: 100%;
    }
    &__actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  &__changePassword {
    margin-top: 24px;
    border-top: 1px solid #E5E7EB;
    & > .SettingsFormRow {
      margin-bottom: 20px;
    }
    & .MessageBox {
      max-width: 600px;
      margin-bottom: 20px;
    }
    & .MessageBox__array {
      flex-direction: column;
    }
  }
}
