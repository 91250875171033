@import (reference) "../../../styles/main";

.home_hero {
  .hero-Layout();

  &-background {
    .hero-backgroundLayout();
    .gradient-violet-900();

    &.platform {
      .gradient-violet-800();
    }
    &.discover_data {
      .gradient-violet-800();
    }
    &.governance {
      .gradient-violet-800();
    }
    &.platform_deployment {
      .gradient-violet-800();
    }
  }

  .button-component {
    height: 3.75rem;
    padding: 0 1.75rem;

    @media @tablet {
      width: 100%;
    }

    .home_hero-background.platform_page & {
      margin-bottom: 0;
    }
  }

  h1 {
    .text-heading-1();
    color: @color-white;
    margin: 0;
  }

  &-small_text {
    .text-paragraph();
    color: @color-violet-200;
    display: flex;
    &.margin {
      margin-top: 6.25rem;
    }
  }

  &-text {
    .text-subheading-2();
    color: @color-violet-200;
  }

  &-main_image {
    margin: 8rem 0 0;
    max-width: 100%;
    @media @tablet {
      margin: 2rem 0;
    }
  }

  &-providers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 6rem;
    margin-top: 1.5rem;

    @media @tablet {
      gap: 3rem;
    }

    @media @mobile {
      gap: 3rem;
    }
  }
}
