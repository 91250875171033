@import (reference) "../../styles/main";

.esg_report {
  background-color: @color-blue-600;
  width: 100%;
  &-grid {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 6.25rem;
    align-items: flex-start;
    overflow-y: auto;
    max-width: @layout-max-width;
    padding: @layout-padding;
    margin: auto;
    @media @tablet-landscape {
      grid-template-columns: 1fr;
      row-gap: @main-padding-bottom;
      margin: 0;
    }

    @media @mobile {
      row-gap: @main-padding-bottom-mobile;
    }

    img {
      min-height: 2.5rem;
      height: 2.5rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    h1 {
      .text-heading-2();
      color: @color-white;
    }
    p {
      .text-subheading-2();
      display: flex;
      align-items: center;
      color: @color-violet-50;
    }
    form {
      .card-large-Layout();
      margin-top: 6.5rem;
      margin-bottom: @main-padding-bottom;

      @media @tablet-landscape {
        margin-top: 0;
      }

      .input {
        position: relative;
      }

      .input-label {
        span {
          display: none;
        }
      }

      .input-error {
        margin: 0;
        font-weight: 400;
        font-family: @font-family-body;
        font-size: 0.875rem;
        position: absolute;
        bottom: 0.375rem;
        color: @color-pink-900;
      }

      button {
        width: 100%;
        margin-top: 2rem;
      }
    }
  }
  &-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    &-content {
      transform: translate(0, -10vh);

      .card-large-Layout();
      width: 40rem;
      max-width: calc(100vw - 2.5rem);
      h2 {
        .text-heading-2();
      }
      p {
        .text-subheading-2();
      }
      a {
        width: fit-content;
        button {
          min-width: 6.25rem;
          width: 100%;
        }
      }
    }
  }
}
