@import (reference) "../../../styles/main";

.ThirdPartyBox {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #FDFFAD;
  border: 1px solid #FFE500;
  border-radius: 4px;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 130%;
  font-weight: normal;

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 15px;
    color: #FFE500;
  }

  a {
    text-decoration: underline;
  }
}
