@import (reference) "../../../styles/main";

.home_get_started_new {
  &-background {
    .main-Layout();
    text-align: left;
    width: 100%;
    background-color: @color-white;
  }

  .section-layout {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  h2 {
    .text-heading-2();
    margin-top: 0;
    color: @color-violet-1000;
    span {
      color: @color-teal-400;
    }
  }

  &-text {
    .text-subheading-2();
    color: @color-violet-200;
    max-width: 50rem;
    @media @tablet-landscape {
      width: 100%;
    }
  }

  &-banner {
    padding: 4rem;
    .card-large-Layout();
    border-color: transparent;
    width: 100%;
    background-color: @color-blue-1000;
    display: flex;
    gap: 4rem;
    justify-content: space-between;
    align-items: center;
    h2 {
      .text-heading-2();
      color: @color-white;
    }

    img {
      height: auto;
      width: auto;
      max-width: 20rem;
    }
    @media @tablet {
      flex-direction: column-reverse;
    }
  }
}
