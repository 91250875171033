@import (reference) "../../styles/main";

.layout-header-full.banner-open {
  .layout-header-full-header.on-top {
    .nav_desktop {
      &-background {
        top: @header-height + @banner-height;
      }
    }
  }
  .layout-header-full-header {
    .nav_desktop {
      &-background {
        top: @header-height;
      }
    }
  }
}

.nav_desktop {
  &-background {
    position: fixed;
    right: 50%;
    opacity: 0;
    transition: transform 0.001s @transition-timing-function,
      opacity @transition-duration @transition-timing-function,
      top @transition-duration @transition-timing-function;
    overflow: hidden;
    height: 0;
    width: 0;
    top: @header-height;

    &.hover {
      height: unset;
      width: unset;
      opacity: 1;
      transform: translateX(50%) scale(1);
      width: 100%;
    }
  }

  &-main {
    background: linear-gradient(
      90deg,
      @color-coolGray-50 0%,
      @color-coolGray-50 50%,
      @color-white 50.001%,
      @color-white 100%
    );
    width: 100%;
    border-top: 1px solid @color-coolGray-200;
    border-bottom: 0.25rem solid @color-coolGray-200;
    box-shadow: @box-shadow-default;

    .section-layout {
      margin: auto;
    }
  }

  &-menu {
    display: flex;
    width: 100%;
  }

  &-infobox {
    flex: 1;
    padding: 1.875rem 3.125rem 1.875rem 0;

    &-title {
      .text-heading-4();
      display: inline-flex;
      margin-top: 1rem;
    }

    &-paragraph {
      .text-paragraph();
      display: inline-flex;
      margin-top: 0;
    }
  }

  &-table {
    flex: 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.875rem;
    padding: 1.875rem 0 1.875rem 3.125rem;
    background-color: @color-white;

    section:first-child {
      grid-row: 1/3;
    }

    section:last-child {
      min-height: 336px;
    }

    &-columnTitle {
      .text-heading-4();
      color: @color-violet-500;
      font-weight: 500;
      padding-left: 1rem;
      margin-top: 1rem;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    color: @color-violet-900;
    z-index: 1;
    transition-duration: @transition-duration;
    margin-bottom: 0.375rem;
    padding: 1rem;
    border-radius: 0.5rem;
    img {
      max-height: 2.5rem;
      max-width: 2.5rem;
      margin-right: 1.25rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background-color: @color-indigo-100;
      p {
        color: @color-primary-900;
      }

      .nav_desktop-arrow svg path {
        stroke: @color-primary-900;
      }
    }
  }

  &-solutionItem {
    display: flex;
    align-items: center;
    color: @color-violet-900;
    z-index: 1;
    transition-duration: @transition-duration;
    padding: 1rem;
    border-radius: 0.5rem;
    img {
      max-width: 2.5rem;
      margin-right: 1.25rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background-color: @color-indigo-100;
      p {
        color: @color-primary-900;
      }

      .nav_desktop-arrow svg path {
        stroke: @color-primary-900;
      }
    }
  }

  &-section_description {
    display: flex;
    flex-direction: column;
    margin-left: @standard-indent;
  }

  &-title {
    .text-heading-4();
    margin: 0;
  }

  &-description {
    .text-paragraph();
    margin: 0;
  }

  &-arrow {
    margin-left: auto;
  }
}