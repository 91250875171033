@import (reference) "../../../../styles/main";

.UseCasesInfoItem {
  position: relative;
  background: #f1f1f7;
  border-radius: 12px;
  &__background {
    padding: 30px 20px;
    &__title {
      width: 100%;
      height: 56px;
      background: #e8e7f1;
      margin-bottom: 24px;
    }
    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }
    &__providers {
      display: flex;
      align-items: center;
    }
    &__label {
      width: 110px;
      height: 24px;
      background: #e8e7f1;
      border-radius: 12px;
    }
    &__statistics {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 32px;
    }
    &__provider {
      width: 28px;
      height: 28px;
      border-radius: 28px;
      margin-right: -10px;
      border: 2px solid #F1F1F7;
      background-color: #E8E7F1;
      overflow: hidden;
    }
    &__statistic {
      width: calc(33.3333% - 7px);
      height: 62px;
      background: #e8e7f1;
    }
    &__graph {
      width: 100%;
      height: 160px;
      background: #e8e7f1;
    }
  }
  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    text-align: center;
    &__head {
      font-weight: 600;
      font-size: @text-size-22;
      line-height: 28px;
      margin-bottom: 32px;
    }
    &__paragraph {
      font-weight: 400;
      font-size: @text-size-16;
      line-height: 24px;
      margin-bottom: 32px;
    }
    &__button {
      margin-bottom: 16px;

      > div {
        display: flex;
        align-items: center;
      }

      svg {
        margin-right: 10px;
      }
    }
  }
}
