@import (reference) "../../styles/main";

.webinars_video {
  width: 100%;
  flex-direction: column;
  .hero-Layout();
  &-hero {
    .hero-backgroundLayout();
    background-color: @color-white;
    @media @tablet {
      .section-layout {
        padding: 0;
      }
      padding-top: @header-height;
    }
  }
}
