@import (reference) "../../../styles/main";

.ProjectHeaderSection {
  font-family: @font-family-headings;
  &__tabs {
    display: flex;
    width: 100%;
    height: 40px;
    background-color: @color-blue-25;
    margin-bottom: 32px;
    border-radius: 6px;
  }

  &__tab {
    height: 100%;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 20px;
    border-radius: 6px;
    border: none;
    outline: none;
    cursor: pointer;

    &.active {
      color: @color-blue-900;
      background-color: @color-white;
      font-weight: 600;
    }

    &.inactive {
      color: @color-coolGray-700;
      background-color: transparent;
      font-weight: 500;
    }
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    font-size: @text-size-12;
    font-weight: 400;
    margin-bottom: 16px;

    > a {
      color: #9b92d2;
    }

    > p {
      color: #9ca3af;
    }

    > svg {
      stroke: #d1d5db;
      stroke-width: 3px;
      margin: 0 4px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 26px;

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 28px;
      width: 56px;
      height: 56px;
      background-color: @color-orange-200;

      > svg {
        min-width: 20px;
        min-height: 20px;
        color: @color-orange-600;
      }
    }

    &__name {
      font-weight: 600;
      font-size: 30px;
      line-height: 1.4;
    }

    &__label {
      padding: 4px 12px;
      height: 28px;
      border-radius: 32px;
      font-weight: 500;
      font-size: 16px;

      &.sandbox {
        background-color: @color-yellow-200;
        color: @color-yellow-800;
      }

      &.deployed {
        background-color: @color-teal-900;
        color: @color-teal-50;
      }
    }
  }
}
