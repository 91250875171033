@import (reference) "../styles/main";

.ContactModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  &__background {
    width: 100%;
    height: 100%;
    background-color: @color-coolGray-900;
    opacity: 0.5;
  }
  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 960px;
    max-width: 95%;
    max-height: 95%;
    background-color: @color-white;
    padding: 80px 100px 100px;
    border: 1px solid #e5e5e5;
    box-shadow: @box-shadow-darker;
    border-radius: 8px;
    overflow: auto;
    .bp-desktop-big({
      padding: 40px 50px 50px;
    });
    .bp-tablet({
      padding: 20px 30px 30px;
    });
    &__header {
      font-weight: 700;
      font-size: @text-size-32;
      line-height: 40px;
      margin-bottom: 30px;
    }
    &__paragraph {
      font-weight: 400;
      font-size: @text-size-16;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 5;
  }
  &__form {
    padding-top: 40px;
    border-top: 1px solid #e5e5e5;
    flex: 1;
    textarea {
      margin-bottom: 40px;
      height: 110px;
      resize: none;
    }
    &__radio {
      margin-bottom: 10px;
      > p {
        font-size: @text-size-16;
        font-weight: 600;
        margin-bottom: 20px;
      }
      &__container {
        display: flex;
        flex-wrap: wrap;
        .RadioButton {
          margin-bottom: 20px;
          margin-right: 20px;
          white-space: nowrap;
        }
      }
    }
    button {
      background: @color-secondary-400;
      border: 1px solid @color-secondary-400;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding: 10px 15px;
      width: 100%;
      color: @color-primary-900;
      font-weight: 600;
      font-size: @text-size-16;
      line-height: 130%;
      &:hover,
      &:focus {
        background: @color-secondary-400;
        border: 1px solid @color-secondary-400;
      }
    }
    &__error {
      margin: 20px 0;
      width: 100%;
    }
    &__actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
