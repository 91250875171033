.industry_text_content {
    display: flex;
    justify-content: center;
    align-items: center;
  
    &-background {
      .main-Layout();
      background-color: #F2F2F5;
      padding: 6rem 0 0;

      @media @mobile {
        text-align: center;
      }
    }
  
    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-question {
      background-color: @color-white;
      border-radius: 1.5rem;
      .text-heading-3();
      color: @color-violet-900;
      padding: 1.5rem;
      margin: 0;
  
      &:nth-child(2n){
        margin: 1.5rem 0;
      }
    }
  
    &-text {
      .text-subheading-2();
      max-width: @layout-text-max-width;
      margin-top: 0;
    }
  
    &-cards_container {
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: 2rem;
  
      @media @tablet-landscape, @mobile {
        flex-direction: column;
      }
    }
  
    &-card {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      background-color: @color-white;
      padding: 2rem;
      border-radius: 1.5rem;
      max-width: 22rem;
  
      &-header {
        .text-heading-3();
        text-align: start;
        color: @color-violet-900;
        margin: 0 0 1.5rem 0;
        
        @media @mobile {
          text-align: center;
          font-size: 1.125rem;
        }
      }
      &-section {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        text-align: start;
        line-height: 1.5rem;
        gap: 1rem;
        
        &:nth-child(2n){
          margin-bottom: 1.5rem;
        }

        &-text {
          font-weight: 400;
          line-height: 1.5rem;
          color: @color-violet-900;
          font-family: @font-family-body;
        }

        @media @mobile {
          flex-direction: column;
          text-align: center;
        }
      }
    }
  }

.section-layout {
  @media @mobile {
    align-items: center;
  }
} 