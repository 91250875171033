@import (reference) "../../../styles/main";

.webinars_list {
  width: 100%;
  &-main {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-top: -12rem;
    padding-bottom: @main-padding-bottom;
  }

  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;

    @media @tablet-landscape {
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
    @media @tablet {
      grid-template-columns: 1fr;
    }
  }

  &-item {
    .card-small-Layout();
    .card-hoverable-Layout();
    &:hover {
      .webinars_list-label p {
        color: @color-blue-900;
      }
    }
  }

  &-label {
    height: 3.5rem;
    color: @color-violet-400;
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: @transition-all;
    svg {
      min-width: 3.5rem;
      min-height: 3.5rem;
      flex: 0;
    }
    p {
      .text-heading-4();
      margin: 0;
      color: @color-violet-600;
    }

    &.upcoming {
      color: @color-teal-600;
      p {
        color: @color-teal-1000;
      }
    }
  }

  &-button {
    .button-text();
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1.5rem 0 0.75rem;
    color: @color-primary-900;
  }

  &-text_container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-title {
    .text-heading-3();
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    flex: 1;
  }

  &-published {
    .text-paragraph();
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    flex: 0;
  }

  &-spinner {
    margin: 0 auto;
    height: 3.75rem;
    width: 3.75rem;
    border-left: 0.3125rem solid @color-teal-400;
    border-right: 0.3125rem solid @color-teal-400;
    border-top: 0.3125rem solid transparent;
    border-bottom: 0.3125rem solid transparent;
    border-radius: 1.875rem;
    animation: @animation-spin;
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
