@import (reference) "../styles/main";

.SearchBar {
  position: relative;
  width: 100%;
  max-width: 700px;
  .bp-mobile({
    margin-left: 16px;
  });

  & > :first-child {
    & > div {
      & > svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__formattedName {
    &--bold {
      font-weight: 600;
    }
  }
  &__clear {
    position: absolute;
    z-index: 10;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 400;
    color: #bab9c1;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    height: 20px;
    width: 20px;
    cursor: pointer;
    transition: @transition-all;
    > svg {
      height: 20px;
      width: 20px;
    }
    &:hover {
      color: #5b5b5b;
    }
  }
  &__autocomplete {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    min-width: 400px;
    background: #ffffff;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: @z-index-modals;
    .bp-phone({
      min-width: 300px;
      left: 30%;
      transform: translateX(-30%);
    });
    &__info {
      position: absolute;
      z-index: 10;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 400;
      font-size: @text-size-16;
      color: #5b5b5b;
      pointer-events: none;
      .bp-mobile({ display: none; });
    }
    &__loading {
      margin: 10px auto;
    }
    &__list {
      max-height: 80vh;
      overflow-y: auto;
      &__item {
        padding: 0 14px 0 0;

        &.separator {
          border-top: 1px solid #e5e7eb;
        }
      }

      &__images {
        width: 60px;
        min-width: 60px;

        &.region,
        &.connector,
        &.attribute,
        &.source {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 20px;
            height: 20px;
            border-radius: 20px;
          }
        }

        &.api_recipe {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: -5px;
          margin-right: 5px;
          img {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            margin-right: -10px;
            border: 2px solid @color-white;
            background-color: @color-white;
          }
        }
      }

      &__sideType {
        display: flex;
        align-items: center;
        font-size: @text-size-12;
        line-height: 1.4;
        color: #9ca3af;
        font-weight: 400;
        white-space: nowrap;
        margin-left: auto;
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      li {
        &.focused {
          background-color: rgba(227, 222, 255, 0.5);
        }

        button {
          background: transparent;
          border: none;
          border-top: 1px solid #e5e5e5;
          outline: none;
          padding-left: 60px;
          height: 40px;
          width: 100%;
          text-align: left;
          cursor: pointer;
          font-weight: 600;

          &:focus-within {
            background-color: rgba(227, 222, 255, 0.5);
          }

          &:hover {
            background-color: rgba(227, 222, 255, 0.5);
            cursor: pointer;
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        height: 40px;
        color: @color-coolGray-900;

        &:focus-within {
          background-color: rgba(227, 222, 255, 0.5);
        }

        &.focused {
          background-color: rgba(227, 222, 255, 0.5);
        }

        &:hover {
          background-color: rgba(227, 222, 255, 0.5);
          cursor: pointer;
        }

        &.attribute {
          height: 60px;
        }
      }
    }
  }
}
