.demyst-wip {
  width: 100%;
  height: 100%;
  background-color: #dddced;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 0 3rem 0;
  text-align: center;

  .title {
    font-size: 3rem;
    margin: 3rem 0 2rem 0;
    font-weight: 700;
  }

  > span {
    width: 80%;
    margin: 0.5rem auto;
    font-weight: 500;
  }
}
