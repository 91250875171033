@import (reference) "../../../../styles/main";

.DataUtilizationChart {
  &__tooltip {
    min-width: 140px;
    max-height: 300px;
    overflow-y: scroll;
    white-space: nowrap;
    padding: 20px 0 10px;
    z-index: @z-index-popups;
    border: 0;
    &__date {
      font-size: @text-size-12;
      color: @color-coolGray-600;
      padding: 0 20px;
    }
    &__foot {
      border-top: 1px solid #0000001a;
      color: @color-primary-600;
      display: flex;
      align-items: center;
      font-size: @text-size-16;
      font-weight: 600;
      padding: 10px 20px 0;
      margin-top: 10px;
      > svg {
        margin-right: 5px;
      }
    }
    &__list {
      font-size: @text-size-14;
      padding: 0 20px;
      margin-top: 10px;
      &__item {
        display: grid;
        grid-gap: 20px;
        grid-template-columns:
          minmax(40px, auto) // legend and channel_id
          repeat(var(--data-utilization-tooltip-repeat), minmax(min-content, 1fr))
          minmax(40px, auto); // transaction_count
        margin-bottom: 10px;
        &__key {
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &__indicator {
            min-width: 10px;
            width: 10px;
            height: 2px;
            margin-right: 10px;
          }
          &__label {
            font-size: @text-size-16;
          }
        }
        &__facet {
          color: @color-coolGray-600;
          text-align: left;
        }
        &__value {
          font-weight: 600;
          font-size: @text-size-14;
          text-align: right;
        }
      }
    }
  }
}

.UtilizationChartTooltip {
  border-radius: 2px;
  font-weight: 600;
  background-color: #f1f3fb99;
  padding: 5px;
  font-size: @text-size-12;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  &__item {
    display: flex;
    justify-content: space-between;
    font-weight: 400;

    &__key {
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &__indicator {
        height: 2px;
        margin-right: 10px;
        min-width: 80%;
        width: 8px;
      }

      &__label {
        font-size: @text-size-16;
      }
    }

    &__facet {
      color: @color-coolGray-600
    }
    &__value {
      font-size: @text-size-14;
      text-align: right;
    }
  }
}

.UtilizationTooltip {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: @color-white;
  line-height: 1.5;

  &__close {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &-date {
      font-size: @text-size-14;
      text-decoration: underline;
    }
  }

  &__show {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }

  &__button {
    border: none;
    outline: none;
    background-color: transparent;
    color: #17009a;
    cursor: pointer;
    font-size: @text-size-14;

    &:hover {
      color: #554999;
    }
  }

  &__row {
    display: grid;
    grid-gap: 20px;
    grid-template-columns:
      minmax(40px, auto) // legend and channel_id
      repeat(var(--data-utilization-tooltip-repeat), minmax(min-content, 1fr))
      minmax(40px, auto); // transaction_count

    &:hover {
      background-color: #f1f3fb99;
      cursor: default;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__indicator {
      min-width: 10px;
      width: 10px;
      height: 2px;
      margin-right: 10px;

      &-number {
        font-size: @text-size-14;
      }
    }

    &__facet {
      color: @color-coolGray-600;
      font-size: @text-size-14;
    }

    &__value {
      font-weight: 600;
      font-size: @text-size-14;
    }
  }
}
