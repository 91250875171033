.InternalProductAbout {
    width: 60%;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    margin-top: 24px;
    height: -webkit-fill-available;


    @media @desktop-small{
        width: 100%;
       }

    &__header {
        height: 80px;
        padding: 24px;
        border-bottom: 1px solid #E5E7EB;

        &__flex {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & > h1 {
                font-size: 22px;
                font-weight: 600;
                color: var(--coolGray-900);
            }
        &__edit {
            cursor: pointer;
        }
        }
    }

    &__description {
        padding: 24px;
        border-bottom: 1px solid #E5E7EB;

        & > h2 {
            color: var(--coolGray-900);
            font-weight: 600;
            font-size: 16px;
        }

        & > p {
            margin: 14px 0px;
            color: var(--coolGray-700);
            font-size: 14px;
            font-weight: 400;
        }

        & > div {
            & > div {
                @media @mobile {
                    grid-template-columns: 1fr;
                }
            }
        }
    }

    &__vendor {
        padding: 24px;
        border-bottom: 1px solid #E5E7EB;

        & > .ProjectCard__table__row {
            @media @mobile {
                grid-template-columns: 1fr;
            }
        }

        &__flex {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }

        &__badge {
            margin: 8px 0px;
        }

        &__website {
            color: var(--coolGray-700);
            font-size: 14px;
        }
    }

    &__attachments {
        padding: 24px;

        & > .ProjectCard__table__row {
            @media @mobile {
                grid-template-columns: 1fr;
            }
        }

        &__flex {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

    & > :last-child {
        padding: 24px;
    }

    &__input {
        max-width: 220px;
    }
}

.Input {
    max-width: 220px !important;
}
