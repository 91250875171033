@import (reference) "../../../../styles/main";

.DataPageHeader {
  background-color: @color-white;
  padding: 44px 40px 0;
  border-bottom: 1px solid #e5e7eb;

  &--extended {
    margin-bottom: 50px;
  }

  .bp-mobile({
    padding: 20px 20px 0;
  });

  &__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .bp-mobile({
      flex-direction: column;
    });
  }

  &__ctaButton {
    > svg {
      width: 16px;
      height: 16px;
      stroke-width: 3px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 12px;

    .bp-mobile({
      flex-direction: column;
      margin-left: 0;

      > button {
        width: 100%;
      }
    });

    &__withTooltip {
      position: relative;

      .bp-mobile({
          width: 100%;

          > button {
            width: 100%;
          }
      });
    }

    &__tooltip {
      z-index: -1;
      position: absolute;
      right: 0;
      opacity: 0;
      padding-top: 13px;
      overflow: hidden;
      transition: all 0.3s;

      &.visible {
        overflow: visible;
        transition: all 0.3s;
        opacity: 1;
        z-index: 1;
      }

      &__logged {
        background-color: #594ab6;
        color: @color-white;
        padding: 16px 20px 20px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        width: 330px;

        &__title {
          margin-bottom: 16px;
          font-size: @text-size-18;
          line-height: 1.4;
          font-weight: 600;
        }

        &__paragraph {
          font-size: @text-size-14;
          line-height: 1.4;
        }

        .bp-mobile({
          width: 310px;
        });
      }

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 2px;
        position: absolute;
        z-index: -1;
        top: 8px;
        right: 50px;
        transform: rotate(45deg);
        background-color: #594ab6;
      }
    }
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    font-size: @text-size-12;
    font-weight: 400;

    > a {
      color: #9b92d2;
    }

    > p {
      color: #9ca3af;
    }

    > svg {
      stroke: #d1d5db;
      stroke-width: 3px;
      margin: 0 4px;
    }
  }

  &__product {
    display: flex;
    margin: 16px 0 32px;

    &__logos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      &__connectorImage {
        width: 56px;
        height: 56px;
        border-radius: 56px;
        cursor: pointer;
      }

      &__recipeImage {
        width: fit-content;
        height: 40px;
        border-radius: 40px;
        margin-right: -20px;
        border: 2px solid @color-white;
        background-color: @color-white;
        overflow: hidden;
        z-index: 1;

        > img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 40px;
          width: 40px;
        }

        > span > img {
          border-radius: 40px;
        }

        &.counter {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #4b5563;
          background-color: #e5e7eb;
          font-size: @text-size-16;
          line-height: @text-size-16;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &__data {
      &__name {
        display: flex;
        color: #111827;
        font-size: @text-size-30;
        font-weight: 600;
        line-height: 1.3;
        h1 {
          font-weight: inherit;
        }
      }

      &__recipe {
        color: #6b7280;
        font-size: @text-size-14;
        font-weight: 400;
        line-height: 1.4;
      }

      &__sync {
        color: #6b7280;
        font-size: @text-size-14;
        font-weight: 400;
        line-height: 1.4;
        display: flex;
        .Loader {
          height: 20px;
          width: 20px;
          margin-left: 5px;
        }
      }

      &__connector {
        color: #6b7280;
        font-size: @text-size-14;
        font-weight: 400;
        line-height: 1.4;
        cursor: pointer;
      }
    }
  }
}
