@import (reference) "../../../../styles/main";

.ErrorsByDate {
  padding: 30px 40px;
  .bp-mobile({
    padding: 20px 20px;
  });
  &__header {
    font-weight: 600;
    font-size: @text-size-22;
  }
  &__errorsList {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
