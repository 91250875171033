@import (reference) "../../../../../styles/main";

.TransactionsTable {
  grid-template-columns:
    63px
    1fr
    min-content;
  min-width: 600px;
  font-size: @text-size-14;
  & th {
    font-size: @text-size-14;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    &:last-of-type {
      border-right: none;
    }
  }
  tr {
    &:hover {
      > td {
        background-color: #f0eff9;
      }
    }
  }
  & td {
    min-height: 40px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    line-height: 20px;

    &:first-of-type {
      padding-right: 24px;
      justify-content: flex-end;
    }

    &:last-of-type {
      border-right: none;
    }
  }
  &__id {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-variant-numeric: tabular-nums;
    color: #c5c5c5;
    font-family: Menlo, Monaco, "Courier New", monospace;
    font-size: @text-size-12;
  }
  &__date {
    font-weight: 600;
  }
  &__transactionCount {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.AverageResponseTable {
  grid-template-columns:
    63px
    1fr
    min-content
    min-content
    min-content;
  min-width: 600px;
  font-size: @text-size-14;
  & th {
    font-size: @text-size-14;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    &:last-of-type {
      border-right: none;
    }
  }
  tr {
    &:hover {
      > td {
        background-color: #f0eff9;
      }
    }
  }
  & td {
    min-height: 40px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    line-height: 20px;

    &:first-of-type {
      padding-right: 24px;
      justify-content: flex-end;
    }

    &:last-of-type {
      border-right: none;
    }
  }
  &__id {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-variant-numeric: tabular-nums;
    color: #c5c5c5;
    font-family: Menlo, Monaco, "Courier New", monospace;
    font-size: @text-size-12;
  }
  &__date {
    font-weight: 600;
  }
  &__averagesCount {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.ErrorsTable {
  grid-template-columns:
    63px
    1fr
    min-content;
  min-width: 600px;
  font-size: @text-size-14;
  & th {
    font-size: @text-size-14;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    &:last-of-type {
      border-right: none;
    }
  }
  tr {
    &:hover {
      > td {
        background-color: #f0eff9;
      }
    }
  }
  & td {
    min-height: 40px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    line-height: 20px;

    &:first-of-type {
      padding-right: 24px;
      justify-content: flex-end;
    }

    &:last-of-type {
      border-right: none;
    }
  }
  &__id {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-variant-numeric: tabular-nums;
    color: #c5c5c5;
    font-family: Menlo, Monaco, "Courier New", monospace;
    font-size: @text-size-12;
  }
  &__date {
    font-weight: 600;
  }
  &__errorCount {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.MatchRatesTable {
  grid-template-columns:
    63px
    1fr
    min-content;
  min-width: 600px;
  font-size: @text-size-14;
  & th {
    font-size: @text-size-14;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    &:last-of-type {
      border-right: none;
    }
  }
  tr {
    &:hover {
      > td {
        background-color: #f0eff9;
      }
    }
  }
  & td {
    min-height: 40px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    line-height: 20px;

    &:first-of-type {
      padding-right: 24px;
      justify-content: flex-end;
    }

    &:last-of-type {
      border-right: none;
    }
  }
  &__id {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-variant-numeric: tabular-nums;
    color: #c5c5c5;
    font-family: Menlo, Monaco, "Courier New", monospace;
    font-size: @text-size-12;
  }
  &__date {
    font-weight: 600;
  }
  &__matchRateCount {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
