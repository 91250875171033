@import (reference) "../styles/main";

.UserMenu {
  display: flex;
  position: relative;
  z-index: @z-index-underpopups;
  .bp-mobile({
    position: initial;
  });
  &__button {
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    padding: 0;
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
    &__logo {
      background-color: #ebebed;
      transition: @transition-all;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      & > svg {
        width: 20px;
        height: 20px;
      }
      & > img {
        max-width: 100%;
        max-height: 100%;
      }
      .bp-mobile({
        display: none;
      });
    }
    &__symbol {
      display: none;
      .bp-mobile({
        display: flex;
        svg {
          stroke: #9b92d2;
          width: 40px;
          height: 40px;
          padding: 8px;
        }
      });
    }
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      & > span {
        &:first-of-type {
          font-size: @text-size-14;
          font-weight: 400;
          color: #374151;
        }
        &:last-of-type {
          font-size: @text-size-12;
          font-weight: 400;
          color: #9ca3af;
        }
      }
      .bp-desktop-small({
        display: none;
      });
      .bp-mobile({
        display: none;
      });
    }

    &:hover {
      .UserMenu__button {
        &__logo {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

.UserMenuPopup {
  background-color: @color-white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 48px;
  right: -25px;
  min-width: 240px;
  border-radius: 8px;
  box-shadow: @box-shadow-default;
}

.UserMenuFullScreen {
  background-color: @color-white;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 101;
  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 20px 10px;
    &__closeButton {
      border-radius: 50%;
      border: none;
      outline: none;
      cursor: pointer;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      background-color: transparent;
      align-items: center;
      .bp-mobile({
        margin-top: 12px;
        background-color: #ebebed;
      });
      & > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.UserMenuContent {
  position: relative;
  transition: @transition-all;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   background-color: #fff;
  //   width: 14px;
  //   height: 14px;
  //   border: 1px solid rgba(0, 0, 0, 0.1);
  //   transform: rotate(-45deg);
  //   border-radius: @border-radius-xs;
  //   z-index: -1;
  //   top: -7px;
  //   left: calc(50% - 8px);
  // }
  // &:after {
  //   content: "";
  //   position: absolute;
  //   width: 16px;
  //   height: 1px;
  //   top: -1px;
  //   left: calc(50% - 9px);
  //   z-index: 0;
  //   background: #fff;
  // }
  .bp-mobile({
    padding: 0 20px;
  });
  &__list {
    width: 100%;
    padding: 20px 0;
    &__item {
      width: 100%;
      background: transparent;
      outline: none;
      border: none;
      padding: 0 20px;
      .bp-mobile({
        padding: 0;
      });
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      transition: @transition-all;
      font-size: @text-size-14;
      line-height: 20px;
      font-weight: 400;
      color: @color-primary-900;
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }
      .bp-desktop({
        &:hover {
          background-color: #f0eff9;
        }
      });
      &--label {
        margin-left: 10px;
        text-align: left;
      }
      svg {
        min-width: 18px;
        min-height: 18px;
        stroke: #bab9c1;
      }
    }
  }
}
