@import (reference) "../../../styles/main";

.AuthPagePaper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 40px;
  background: @color-white;
  border-radius: @border-radius-lg;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  .bp-mobile({
    box-shadow: none;
    border: none;
    padding: 0 20px 20px;
    position: initial;
    border-radius: 0;
  });
}
