.form {
    display: flex;
    flex-direction: column;
}

.close {
    position: absolute;
    right: 20px;
    top: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px;
    background-color: transparent;
    > svg {
        width: 16px;
        min-width: 16px;
        stroke: #9B92D2;
        stroke-width: 2.5;
    }
}

.modal {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 760px;
    max-width: 95%;
    max-height: 95%;
    // height: 686px;
    background-color: @color-white;
    padding: 48px;
    border: 1px solid #E5E5E5;
    box-shadow: @box-shadow-darker;
    border-radius: 8px;
    overflow: auto;
}

.inputTitle {
    font-size: @text-size-16;
    font-weight: 600;
    color: #4B5563;
    margin-bottom: 10px;
}

.title {
    font-size: @text-size-30;
    font-weight: 600;
    margin-bottom: 24px;
}

.description {
    font-size: @text-size-16;
}

.infoBox {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 6px;
    > svg {
        width: 16px;
        min-width: 16px;
        stroke: #9B92D2;
        stroke-width: 2.5;
    }
}

.infoDescription {
    font-size: @text-size-14;
    color: #9B92D2;
}

.submitButton {
    align-self: flex-end;
    width: fit-content;
}

.field {
    margin-top: 24px;
}

.textArea {
    width: 100%;
    background: white;
    border: thin solid var(--coolGray-300);
    border-radius: 4px;
    line-height: 1;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    caret-color: var(--blue-900);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    min-height: 40px;
    padding: 10px 8px;
    font-size: 16px;
    box-sizing: border-box;
}

.loading {
    position: absolute;
    background-color: white;
    width: calc(100% - 80px);
    height: calc(100% - 100px);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error {
    height: 40px;
    color: red;
    font-weight: 600;
}

.buttonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 24px;
    
    @media @mobile {
        flex-direction: column;
        margin: 24px auto;
      }
}

.content {
    padding: 14px;
    margin-top: 14px;
    color: var(--coolGray-600);
    overflow: scroll;
    height: 350px;

    @media (max-width: 300px) {
        padding: 0px;
    }
}
