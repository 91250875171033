@import (reference) "../../styles/main";

.layout-header {
  &-full {
    position: fixed;
    top: 0;
    z-index: @layer-top-header;
    width: 100%;

    &-landing {
      position: absolute;

      + .layout-main {
        padding-top: 0rem;
      }
    }

    &-header {
      display: flex;
      justify-content: center;
      background-color: @color-white;
      transition: background-color @transition-duration @transition-timing-function,
        top @transition-duration @transition-timing-function;

      position: fixed;
      top: 0;
      width: 100%;

      &:first-child {
        top: 0 !important;
      }

      &.landing {
        position: absolute;
      }

      &.transparent {
        background-color: transparent;
      }

      &.purple {
        background-color: @color-violet-900;
      }

      &.grey {
        background-color: @color-blue-25;
      }
    }

    &.banner-open {
      .layout-header-full {
        &-header {
          &.on-top {
            top: @banner-height;
          }
        }
      }
    }
  }

  &-main {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: @header-height;
    padding: 0;

    @media @tablet-landscape {
      justify-content: space-between;
    }

    & > .button-component.hidden {
      visibility: hidden;
    }
  }

  &-buttons {
    display: flex;
    gap: 1.25rem;
    flex: 0;
  }

  &-logo {
    flex: 0;
    width: 10.125rem;
    height: 2.5rem;
    &-img {
      width: auto;
      height: auto;
      max-width: 100%;
      min-width: 10.125rem;

      &.white {
        fill: @color-white;
      }
    }
  }

  &-nav {
    display: flex;
    font-size: 1rem;
    width: 100%;
    align-items: center;
    padding: 0 1.5rem;
    height: 100%;
    flex: 1;

    &-item {
      position: relative;
      text-align: left;
      height: 100%;
      display: inline-flex;
      align-items: center;

      a {
        white-space: pre-wrap;
      }

      &:hover .layout-header-link,
      &:focus .layout-header-link,
      &--active .layout-header-link {
        &:after {
          opacity: 1;
          width: 100%;
          transition: opacity @transition-duration, width @transition-duration;
        }
      }

      &--active .layout-header-link {
        color: @color-teal-900;

        &.white {
          color: @color-teal-400;
        }
        &.blue {
          color: @color-teal-900;
        }
      }
    }
  }

  &-link {
    .button-text();
    padding: 0 1rem;
    cursor: pointer;
    position: relative;
    background: transparent;
    border: none;
    text-decoration: none;
    color: @color-violet-900;
    transition: color 0.5s @transition-timing-function;
    outline: none;
    height: 100%;
    display: inline-flex;
    align-items: center;

    &.white {
      color: @color-white;
    }

    &.blue {
      color: @color-violet-900;
    }

    @media screen and (max-width: 83.75rem) {
      padding: 0 0.75rem;
    }

    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0.25rem;
      z-index: -1;
      bottom: 0;
      left: 0;
      opacity: 0;
      background: linear-gradient(270deg, @color-teal-400 0%, rgba(@color-teal-200, 50%) 100%);
      transition: opacity (@transition-duration * 4), width (@transition-duration * 4);
    }

    &:hover,
    &:focus {
      &:after {
        opacity: 1;
        width: 100%;
        transition: opacity (@transition-duration * 2), width (@transition-duration * 2);
      }
    }
  }

  &-menu {
    display: none;
  }
}

@media @desktop-small {
  .layout-header {
    &-buttons {
      gap: 0.875rem;
      .button-component {
        img {
          display: none;
        }
      }
    }

    &-nav {
      padding: 0 0.5rem;
      &-item {
        a {
          overflow: hidden;
          // white-space: nowrap;
          text-overflow: ellipsis;

          span:last-child {
            display: none;
          }
        }
      }
    }
  }
}

@media @tablet-landscape {
  .layout-header {
    &-main {
      padding: 0;

      & > .button-component {
        display: none;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    &-buttons {
      display: none;
    }

    &-nav {
      display: none;
    }

    &-menu {
      display: block;
      margin: 0;
      padding: 0;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }

    &-link {
      margin-left: 1.25rem;
    }
  }
}

@media @mobile {
  .layout-header {
    &-full {
      &.on-top {
        margin-top: 5rem;
      }
    }
  }
}
