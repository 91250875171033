.SchemaWrapper {
    padding: 5px 0px;
    .TextField {
        max-width: 700px;
    }
    &__editorWrapper{
        border: 1px solid #bab9c0;
        border-radius: 4px;
        padding: 8px 10px;
        margin-top: 5px;
    }
    &__SchemaForms {
        display: flex;
        gap: 20px;
        & > div {
            flex: 1 1 50%;
            width: 50%;
        }
        &__Headers {
            margin-top: 20px;
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: -16px;
        }
        &__EditorLabels {
            display: flex;
            justify-content: space-between;
        }
    }
}
