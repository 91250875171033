@import (reference) "../../../../styles/main";

.HomeActivityTable {
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  border-top: 1px solid var(--coolGray-200);
  margin-top: 20px;
  overflow-y: auto;
  thead,
  tbody,
  tr {
    display: contents;
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
  th,
  td {
    padding: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .total {
    background-color: var(--coolGray-100);
  }

  th {
    position: sticky;
    z-index: 1;
    top: 0;
    text-align: left;
    font-weight: normal;
    font-size: @text-size-16;
    color: #5b5b5b;
    background-color: @color-white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding-top: 8px;
    padding-bottom: 8px;
    &.DataTable__columnHeader {
      color: #4b5563;
      font-weight: 600;
      font-size: @text-size-12;
      border-bottom: 2px solid #e5e5e5;
      &--sorted {
        font-weight: 600;
        color: @color-primary-900;
      }
    }
  }

  td {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    min-height: 60px;
  }

  &__sorting {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    max-height: 20px;
    &__chevron {
      margin-left: 10px;
      stroke-width: 3px;
      color: #9b92d2;
      &:nth-child(2) {
        margin-top: -6px;
      }
      &--active {
        color: @color-primary-900;
      }
    }
  }
}
