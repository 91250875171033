@import (reference) "../../../styles/main";

.LandingMainContent {
  width: 100%;
  padding: @main-padding;
  background-color: @color-violet-900;

  @media @tablet-landscape {
    flex-direction: column;
    align-items: center;
    padding: 3.75rem 0;
  }

  .section-layout {
    text-align: center;
    @media @tablet-landscape {
      text-align: left;
    }

    h1,
    &-title {
      .text-heading-1();
      max-width: @layout-text-max-width;
      margin-left: auto;
      margin-right: auto;
      @media @tablet-landscape {
        text-align: left;
      }
    }

    &-subtitle {
      .text-subheading-2();
      max-width: @layout-text-max-width;
      margin-left: auto;
      margin-right: auto;
      @media @tablet-landscape {
        text-align: left;
      }
    }

    h2,
    h3 {
      .text-heading-2();
      max-width: @layout-text-max-width;
      margin-left: auto;
      margin-right: auto;
      @media @tablet-landscape {
        text-align: left;
      }

      + p {
        .text-subheading-2();
        max-width: @layout-text-max-width;
        margin-left: auto;
        margin-right: auto;
        @media @tablet-landscape {
          text-align: left;
        }

        &.noMarginBottom {
          margin-bottom: 0;
        }
      }
    }

    h4 {
      .text-heading-3();
      margin-left: auto;
      margin-right: auto;
      max-width: @layout-text-max-width;
      @media @tablet-landscape {
        text-align: left;
      }
    }

    p {
      .text-paragraph();

      &.section-layout-h4 {
        .text-subheading-2();
        margin-top: 0;
      }
      @media @tablet-landscape {
        text-align: left;
      }
    }
  }

  &-align-left {
    .section-layout {
      align-items: flex-start;
      h1,
      h2,
      h3,
      h4,
      p {
        text-align: left;
        @media @tablet-landscape {
          text-align: left;
        }
      }
    }
  }
  &-align-right {
    .section-layout {
      align-items: flex-end;
      h1,
      h2,
      h3,
      h4,
      p {
        text-align: right;
        @media @tablet-landscape {
          text-align: left;
        }
      }
    }
    .LandingCtas {
      justify-content: flex-end;
    }
  }
  &-align-center {
    .section-layout {
      align-items: center;
      h1,
      h2,
      h3,
      h4,
      p {
        text-align: center;
        @media @tablet-landscape {
          text-align: left;
        }
      }
      .LandingCtas {
        justify-content: center;
      }
    }
  }

  &-background {
    &-white {
      background-color: @color-white;
    }
    &-gray {
      background-color: @color-coolGray-50;
    }
    &-gray-2 {
      background-color: @color-blue-25;
    }

    &-purple {
      background-color: @color-violet-800;
      * {
        color: @color-white !important;
      }
    }
    &-blue {
      * {
        color: @color-white !important;
      }
    }
    &-purpleDark {
      background-color: @color-violet-1000;
      * {
        color: @color-white !important;
      }
    }
  }

  &-text_image {
    .section-layout {
      display: flex;
      flex-direction: row;
      gap: 6rem;
      align-items: center;

      &-column {
        flex-direction: column;
      }

      @media @tablet-landscape {
        flex-direction: column;

        gap: 3.75rem;
      }
    }
  }

  &-customers {
    p {
      text-align: center !important;
    }
  }
}
