.Agreements {

    &__sign {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        font-size: 12px;
        & > div {
            & > div {
                align-items: center;
                & > input:enabled:checked {
                    background-image: url(/checkbox-image/checkbox-checked.7e7bd052.svg);
                  }

                & > div {
                    & > label {
                        text-align: center;
                    }
                }
            }
        } 
    }

    &__header {
        & > h3 {
            font-size: 18px;
            color: var(--gray-900);
            font-weight: 600;
            margin-bottom: 24px;
            margin-top: 24px;
        }
    }

    &__notCompleted {
        border: 1px solid #eaeaf0;
        display: flex;
        flex-direction: column;
        background: var(--coolGray-100);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        align-items: flex-start;
        max-width: 960px;
        margin-bottom: 12px;

        &__container {
            width: 100%;
            min-height: 56px;
            display: flex;
            flex-direction: row;
            padding: 8px 16px;
            gap: 12px;
 
            @media @mobile {
             flex-direction: column;
           }
 
 
            &__icon {
             display: flex;
             align-items: center;
             justify-content: center;

             & > svg {
                color: var(--coolGray-300);
                stroke-width: 3;
            }

             & div {
                & > svg {
                    color: var(--coolGray-300);
                    stroke-width: 3;
                }
             }
 
            }
 
            &__title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 670px;
                overflow-wrap: break-word;
                @media @mobile {
                 width: 100%;
               }
 
                & >:first-child {
                    font-size: 16px;
                    color: var(--coolGray-600);
                    font-weight: 600;
                }
 
                & >:last-child {
                 font-size: 14px;
                 color: var(--coolGray-500);
                 font-weight: 400;
                }
            }
 
            &__link {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: end;
                margin-left: auto;
                min-width: 200px;
                justify-content: flex-end;
                @media @mobile {
                 justify-content: center;
                 min-width: 100%;
               }

               & > p {
                color: var(--coolGray-500);
               }
         
                & > a {
                 font-weight: 400;
                 font-size: 14px;
                 color: #17009A;
                }
 
 
                &__label {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                    line-height: 0;
 
                    & > svg {
                        color: var(--blue-400);
                        stroke-width: 3;
                    }
                }
            }
         }
    }

    &__actionableItems {
       
        border: 1px solid #eaeaf0;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        align-items: flex-start;
        max-width: 960px;
        margin-bottom: 12px;
        
        &__container {
           width: 100%;
           min-height: 56px;
           display: flex;
           flex-direction: row;
           padding: 8px 16px;
           gap: 12px;

           @media @mobile {
            flex-direction: column;
          }


           &__icon {
            display: flex;
            align-items: center;
            justify-content: center;

            & > svg {
                color: var(--blue-400);
                stroke-width: 3;
            }
           }

           &__title {
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               justify-content: center;
               width: 670px;
               overflow-wrap: break-word;
               @media @mobile {
                width: 100%;
              }

               & >:first-child {
                   font-size: 16px;
                   color: var(--coolGray-900);
                   font-weight: 600;
               }

               & >:last-child {
                font-size: 14px;
                color: var(--coolGray-500);
                font-weight: 400;
               }
           }

           &__link {
               display: flex;
               flex-direction: row;
               align-items: center;
               text-align: end;
               margin-left: auto;
               min-width: 200px;
               justify-content: flex-end;
               @media @mobile {
                justify-content: center;
                min-width: 100%;
              }
        
               & > a {
                font-weight: 400;
                font-size: 14px;
                color: #17009A;
               }


               &__label {
                   display: flex;
                   flex-direction: row;
                   align-items: center;
                   gap: 8px;
                   line-height: 0;

                   & > svg {
                       color: var(--blue-400);
                       stroke-width: 3;
                   }
               }
           }
        }
    }

}
