@import (reference) "../styles/main";
@keyframes slide-down {
  0% {
    margin-top: -40px;
  }
  100% {
    margin-top: 0;
  }
}

.FreeUserBar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 40px;
  font-weight: 600;
  font-size: @text-size-16;
  line-height: 130%;
  animation: slide-down ease-in-out 0.25s;
  animation-iteration-count: 1;
  margin-top: -40px;
  animation-fill-mode: forwards;
  color: @color-white;
  background: linear-gradient(270.03deg, #17009a 0%, #170a4d 49.48%, #5140b3 100%);
  .bp-mobile({
    font-size: 12px;
    line-height: 14px;
    padding: 0 15px;
    font-weight: 400;
  });
  button,
  a {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    font-weight: 600;
    font-size: @text-size-16;
    line-height: 130%;
    color: @color-secondary-400;
    .bp-mobile({ font-size: 12px; line-height: 14px; });
  }
}
