@import (reference) "../../../../../styles/main";

.InvoiceTable {
  display: grid;
  border-collapse: collapse;
  min-width: 1000px;
  width: 100%;
  grid-template-columns:
    repeat(var(--rules-table-repeat), minmax(min-content, 1fr));

  thead,
  tbody,
  tr {
    display: contents;
  }

  tr {
    &:nth-child(even) td {
      background-color: #F8F8F8;
    }
    &:hover {
      > td {
        background-color: #f0eff9;
      }
    }
    &:last-of-type td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    td {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      &:last-of-type {
        border-right: none;
      }
    }
  }

  th,
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: initial;
  }

  th {
    position: sticky;
    top: 0;
    padding: 0 15px;
    text-align: left;
    font-weight: 400;
    font-size: @text-size-14;
    color: #848484;
    background-color: @color-white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    height: 40px;
    align-items: center;
  }

  th:last-of-type {
    border-right: none;
    padding-right: 25px;
  }

  td {
    height: 100%;
    display: flex;
    min-height: 40px;
    &:last-of-type {
      .InvoiceTable__cell {
        padding-right: 25px;
      }
    }
  }

  &__cell {
    width: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
    font-size: @text-size-14;
    &__entryIndex {
      justify-content: flex-end;
      color: #A4A2A2;
      font-family: @font-family-mono;
      font-size: @text-size-12;
      font-weight: 400;
    }
    &__date {
      font-weight: 600;
    }
    &__item {
      justify-content: flex-end;
    }
  }
}
