@import (reference) "../../../styles/main";

.usecases_title_section {
  .hero-backgroundLayout();
  .gradient-primary-900();

  &-container {
    margin: auto;
  }

  &-text_container {
    max-width: 64rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      z-index: @layer-top-1;
      @media @tablet-landscape {
        width: 100%;
      }
    }
  }
  h1 {
    z-index: @layer-top-1;
    .text-heading-1();
    color: @color-white;
    text-align: center;
    @media @tablet-landscape {
      text-align: left;
    }
  }
  &-description {
    z-index: @layer-top-1;
    .text-subheading-2();
    text-align: center;
    color: @color-teal-50;
    @media @tablet-landscape {
      text-align: left;
    }
  }
}
