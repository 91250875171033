@import (reference) "../../../styles/main";

.AuthPageFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: @color-coolGray-600;
  font-size: @text-size-14;
  font-weight: 500;
  margin-top: 100px;
  text-align: center;
  .bp-mobile({
    justify-content: flex-start;
  });
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    .bp-tablet-landscape({
      justify-content: flex-start;
     
    });
    & a {
      color: inherit;
      transition: @transition-all;
      &:hover {
        color: @color-primary-600;
      }
    }
    &__spacer {
      width: 2px;
      height: 2px;
      background-color: @color-coolGray-600;
      margin: 0 0;
      border-radius: 50%;
    }
  }
}
