@import (reference) "../styles/main";

.UnverifiedDrawer {
  &__container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 980px;
    z-index: @z-index-underpopups;
    background-color: #5048E5;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 0px 0px;
    padding: 16px 32px 16px 20px;
    margin: 0 auto;
    transform: translateY(100%);
    transition: transform 1s ease;

    .bp-mobile({
      max-width: 100%;
      border-radius: 0px;
    });

    .bp-phone({
      display: none;
    });

    &.mounted {
      transform: translateY(0);
    }

    .Button {
      height: 32px;
      width: 135px;
      min-width: 135px;
      align-self: end;
      white-space: nowrap;
    }
  }

  &__title {
    font-size: @text-size-18;
    font-weight: 600;
    color: @color-white;
    margin-bottom: 8px;

    span {
      padding: 2px 8px;
      background-color: #463ACB;
      border-radius: 16px;
    }
  }

  &__bottom {
    margin-top: -10px;
    display: flex;
    gap: 24px;
    align-items: baseline;
  }

  &__paragraph {
    color: #E0E8FF;
    font-size: @text-size-14;
  }
}
