@import (reference) "../../styles/main";

.DataShare {
  overflow-y: auto;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: @color-white;
  &__table {
    min-width: 600px;
    grid-template-columns:
      minmax(min-content, 1fr)
      minmax(min-content, 1fr)
      minmax(min-content, 1fr)
      minmax(min-content, 1fr)
      minmax(min-content, 1fr)
      minmax(min-content, 1fr);
    & thead th {
      min-height: 43px;
      &:first-of-type {
        padding-left: 30px;
      }

      &:last-of-type {
        padding-right: 30px;
        border-right: none;
      }
    }
    & tr > td {
      border-bottom: 1px solid #e5e5e5;
      color: #4b5563;
      font-size: @text-size-14;

      &:first-of-type {
        padding-left: 30px;
      }

      &:last-of-type {
        padding-right: 30px;
        border-right: none;
      }
    }
    & tr {
      &:hover {
        > td {
          background-color: #f0eff9;
        }
      }
    }
    & td {
      min-height: 55px;
    }
    & code {
      font-size: @text-size-14;
    }
  }
}
