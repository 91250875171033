@import (reference) "../../styles/main";

.FileTransfers {
  background-color: @color-platformBackground;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .bp-mobile({
    position: relative;
  });
  &__contentWrapper {
    overflow: auto;
    padding: 20px 40px;
    .bp-mobile({
      padding: 20px;
    });
    flex: 1;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .bp-phone({
      flex-direction: column;
    });
    &__info {
      &__title {
        font-size: @text-size-32;
        line-height: 40px;
        font-weight: 700;
      }
    }
    &__actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .bp-phone({
        margin-top: 20px;
      });
      .bp-mobile({
        position: initial;
      });
    }
  }
  &__tableContainer {
    margin-top: 60px;
    padding: 30px 40px 40px;
    background-color: @color-white;
    border-radius: 8px;
    border: 1px solid #eaeaf0;
    width: 100%;
    &__title {
      font-size: @text-size-22;
    }
    .bp-mobile({
      margin-top: 20px;
      padding: 20px;
    });
    &__wrapper {
      margin-top: 20px;
      width: 100%;
      overflow: auto;
      position: relative;
    }
    &__loadingOverlay {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__paginationWrapper {
      padding-top: 30px;
    }
  }
  &__table {
    width: 100%;
    min-width: 800px;
    th {
      &:last-child {
        position: sticky;
        background: #fff;
        right: 0;
      }
    }
    &__cell {
      &__uploader {
        white-space: pre; /* Chrome */
      }
      &__downloadButton {
        width: 120px;
        position: sticky;
        right: 0;
      }
      &__filename {
        min-width: 300px;
        max-width: 400px;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    &__fileId {
      height: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &__box {
        font-size: 13px;
        font-family: @font-family-mono;
        line-height: 1.4;
        border: 1px solid #bab9c1;
        border-radius: @border-radius-xs;
        padding: 0 4px;
        display: inline-flex;
        color: @color-coolGray-600;
      }
    }
    &__filename {
      font-weight: 600;
      display: inline-flex;
      min-width: 0;
      max-width: 100%;
      &__first {
        white-space: nowrap;
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &__last {
        white-space: nowrap;
        flex: 0;
      }
    }
    &__uploadDate {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &__expirationInfo {
        text-transform: uppercase;
        font-weight: 600;
        white-space: pre;
        margin-left: 5px;
        font-size: @text-size-12;
        color: @color-tertiary-900;
        &--expired {
          color: @color-coolGray-600;
          font-weight: normal;
          text-transform: none;
          font-style: italic;
        }
      }
    }
    &__expiryTime {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &__expirationInfo {
        text-transform: uppercase;
        font-weight: 600;
        white-space: pre;
        margin-left: 5px;
        font-size: @text-size-12;
        color: @color-tertiary-900;
        &--expired {
          color: @color-coolGray-600;
          font-weight: normal;
          text-transform: none;
          font-style: italic;
        }
      }
    }
    &__downloadButton {
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      height: 100%;
      font-size: @text-size-16;
      font-weight: 600;
      color: @color-primary-900;
      cursor: pointer;
      & > svg {
        margin-right: 5px;
        color: @color-primary-900;
      }
    }
    &__downloadButtonDisabled {
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      height: 100%;
      font-size: @text-size-16;
      font-weight: 600;
      color: @color-primary-900;
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
      & > svg {
        margin-right: 5px;
        color: @color-primary-900;
      }
    }
    &--expired {
      font-weight: initial;
      color: @color-coolGray-600;
      font-style: italic;
    }
  }
  &__pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 10px;

    &__pageButton {
      width: 26px;
      height: 26px;
      font-size: 16px;
      border-radius: 4px;
      border: 2px solid @color-white;
      font-weight: normal;
      outline: none;
      background-color: @color-white;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 0.5;
      }

      &--active {
        font-weight: bold;
        color: @color-white;
        background-color: @color-primary-900;
        border: 2px solid @color-primary-900;
      }
    }

    &__navButton {
      width: 26px;
      height: 26px;
      outline: none;
      border: 2px solid #b9b2e5;
      border-radius: 4px;
      background-color: @color-white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }

      &--previous,
      &--next {
        img {
          width: 12px;
          height: 12px;
        }
      }

      &--next,
      &--last {
        margin-right: 0;
        margin-left: 10px;

        img {
          transform: rotate(180deg);
        }
      }
    }
  }
}
