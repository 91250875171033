@import (reference) "../../../../styles/main";

.DataPageOutputs {

  &__requiredInputSets {
    display: flex;
    flex-direction: column;

    &__wrapper {
      display: flex;
      margin-bottom: 10px;
      flex-wrap: wrap; 

      & > div {
        display: flex;
        align-items: center;
      }

      & > div > div > input:enabled:checked {
        background-image: url("/checkbox-image/checkbox-checked.7e7bd052.svg");
        background-color: @color-blue-700;
      }

      & > label{
        position: relative;
        text-overflow: ellipsis; 
        margin-right: 26px;
        &:not(:last-child)::after {
          position: absolute;
          content: "+";
          font-weight: 900;
          width: 12px;
          min-width: 12px;
          height: 12px;
          top: 0;
          right: -19px;
        }
      }
    }
  }

  &__desiredInput {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    font-size: @text-paragraph-font-size;
    font-weight: 600;
    width: 100%;
    height: 100%;
  }

  &__selectInput {
    text-align: center;
    margin: 20px 0;
    font-size: @text-paragraph-font-size;
    font-weight: 600;
  }
}
