@import (reference) "../../styles/main";

.GeneralSettings {
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 5;
  }
  &__wrapper {
    position: relative;
  }
  &__error {
    margin-top: 40px;
    width: 100%;
    max-width: 960px;
  }
  &__form {
    flex: 1;
    & > .SettingsFormRow {
      margin-bottom: 16px;
    }
  }
  &__requestChanges {
    margin-bottom: 36px;

    &__text {
      font-size: @text-size-14;
      color: #6b7280;
      & > a {
        color: @color-primary-900;
        transition: @transition-all;
        &:hover,
        &:focus {
          color: @color-primary-600;
        }
      }
    }
  }
  &__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #e5e7eb;
    padding: 4px 12px;
    border-radius: 4px;
    gap: 6px;
    min-height: 40px;
  }
  &__item {
    background-color: #d1d5db;
    padding: 6px 8px;
    border-radius: 4px;
    color: #6b7280;
    &--clear {
      background-color: transparent;
    }
  }
}
