@import (reference) "../../styles/main";

.SettingsPage {
  flex: 1;
  background-color: @color-platformBackground;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &__contentWrapper {
    overflow: auto;
    padding: 20px 40px;
    flex: 1;
    .bp-mobile({
      padding: 20px 20px;
    });
  }
  &__title {
    font-size: @text-size-30;
    color: #111827;
    font-weight: 600;
    line-height: 40px;
  }
  &__subTitle {
    margin-top: 10px;
  }
  &__tabs {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #e5e7eb;
    margin-top: 25px;

    &--mt-0 {
      margin-top: 0;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @text-size-16;
      line-height: 1.5;
      font-weight: 400;
      border-bottom: 2px solid transparent;
      color: #4b5563;
      padding: 16px;

      &__label {
        font-size: @text-size-14;
        line-height: 1.5;
        font-weight: 400;
        padding: 2px 8px;
        color: #594ab6;
        background-color: #e8e7f1;
        border-radius: 20px;
        margin-left: 8px;
        height: 25px;
        min-width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.active {
        color: #17009a;
        font-weight: 600;
        border-bottom: 2px solid #594ab6;

        .DataCatalogPage__tabs__item__label {
          background-color: #6f62be;
          color: #e8e7f1;
        }
      }
    }
  }
}
