@import (reference) "../../../../styles/main";

.ReportsDashboard {
  display: flex;
  flex-direction: column;
  &__metricsList {
    width: 100%;
    & > div {
      margin-bottom: 10px;
    }
  }
  &__loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1 / span 2;
  }
  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1 / span 2;
  }
}
