@import (reference) "./breakpoints";
@import (reference) "./transitions";
@import (reference) "./typography";
@import (reference) "./zIndex";

@box-shadow-default: 0 10px 15px rgba(0, 0, 0, 0.05);
@box-shadow-darker: 0px 10px 15px rgba(0, 0, 0, 0.15);

@border-radius-xs: 2px;
@border-radius-sm: 4px;
@border-radius-md: 8px;
@border-radius-lg: 12px;

.standardLink() {
  color: @color-primary-900;
  transition: @transition-all;
  &:hover {
    color: @color-primary-1000;
  }
}
