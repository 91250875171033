@import (reference) "../../../styles/main";

.DataUtilizationHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 20px;
  .bp-phone({
    padding-top: 20px;
    flex-direction: column;
    align-items: flex-start;
  });
  &__info {
    &__title {
      font-size: @text-size-32;
      line-height: 40px;
      font-weight: 700;

      > span {
        margin-right: 20px;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: flex-end;
    .bp-mobile({
      margin-top: 20px;
    });
    &__csvDownload {
      position: relative;
      &__paper {
        position: absolute;
        top: 50px;
        right: 38px;
        min-width: 300px;
      }
    }
    &__dateRangePicker {
      display: flex;
      justify-content: flex-end;
      position: relative;
      .bp-mobile({
        position: initial;
      });
      &__triggerButton {
        appearance: none;
        outline: none;
        height: 40px;
        padding: 0 15px;
        background-color: transparent;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 12px;
        color: @color-primary-900;
        font-weight: 600;
        font-size: @text-size-18;
        transition: 0.25s background-color;
        border: 2px solid #b9b2e5;
        border-radius: 4px;
        & > svg {
          transition: @transition-all;
          margin-left: 5px;
          color: #b9b2e5;
        }
        &:hover,
        &:focus {
          background-color: #b9b2e580;
          & > svg {
            color: @color-primary-900;
          }
        }
        &--active {
          & > svg {
            transform: rotate(-180deg);
          }
        }
      }
      &__popoverWrapper {
        position: absolute;
        top: 51px;
        right: -20px;
        z-index: 120;
        .bp-mobile({
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.4);
        });
      }
      &__arrow {
        .bp-mobile({
          display: none;
        });
      }
    }
  }
}
