@import (reference) "../../../landingPage/styles/main";

.cookie-preferences {
  width: 100%;
  min-height: 100%;
  background-color: @color-white;
  @media @tablet {
    grid-template-columns: 1fr;
  }
  @media @mobile {
    position: relative;
    background-color: @color-white;
    grid-template-columns: 1fr;
  }

  button[type="submit"] {
    margin-top: 40px;
    .button-text();
  }

  p {
    .text-paragraph();
    color: @color-coolGray-900;
    margin-bottom: 0;
  }

  &__footer {
    &__policy {
      p {
        .text-subheading-2();
      }
    }
  }

  &_option {
    display: flex;
    gap: 72px;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    background-color: @color-blue-25;

    .Switch {
      transform: scale(1.4);
    }
    &:first-child {
      margin-bottom: 16px;
      .Switch {
        opacity: 0.25;
      }
    }
    &_text {
      h2 {
        .text-heading-3();
        margin: 0;
      }
    }
  }

  &_success-message {
    z-index: 9999;
    position: fixed;
    bottom: 56px;
    left: 56px;
    & > div {
      color: @color-white;
      background-color: #4f9e90;
      width: fit-content;
      padding-left: 20px;
      padding-right: 30px;
      svg {
        color: #64ccb9;
      }
    }
  }

  &__paper {
    grid-area: paper;
    padding: @hero-padding-bottom 1.5rem;
    max-width: 1020px;
    margin: auto;
    .AuthPagePaper {
      padding: 70px;
      box-shadow: none;
      border-radius: 24px;
      background-color: @color-coolGray-50;
    }
    &__header {
      &__title {
        .text-heading-2();
        margin: 0;
      }
    }
    &__loadingOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}
