@import (reference) "../../../styles/main";

.platform_strategy {
  .main-Layout();
  background-color: #f2f2f5;

  > .section-layout {
    align-items: flex-start;
    text-align: left;
    @media @tablet-landscape {
      text-align: center;
    }

    > h2 {
      .text-heading-2();
      color: @color-violet-900;
      margin-bottom: 2rem;
      max-width: 80rem;
    }
  }

  &-grid {
    width: 100%;

    p {
      column-count: 2;
      column-gap: 4rem;
      .text-subheading-2();
      color: #514779;
      @media @tablet-landscape {
        column-count: 1;
        column-gap: 0;
      }
    }
  }
}
