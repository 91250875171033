@import (reference) "../../../../styles/main";

.MatchRatesSection {
  background-color: @color-white;
  width: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  &__header {
    padding: 0 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-height: 40px;
    &__toggleSectionButton {
      padding: 0;
      width: 30px;
      height: 30px;
      margin-left: 15px;
      background-color: transparent;
      box-shadow: none;
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
      }
      &--closed {
        & > svg {
          transform: rotateZ(-180deg);
        }
      }
      & > svg {
        transition: @transition-all;
        width: 20px;
        height: 20px;
        margin: 0;
        stroke: #bab9c1;
      }
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
  }
  &__content {
    &__loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 380px;
    }
    &__error {
    }
    &__header {
      font-size: @text-size-14;
      min-height: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;

      strong {
        font-weight: 600;
      }

      span:not(:first-of-type) {
        margin-left: 30px;
      }
      &__toggleTableButton {
        height: 30px;
        font-size: @text-size-14;
        > svg {
          width: 16px;
          height: 16px;
          stroke-width: 2.5px;
        }
      }
    }
    &__chartWrapper {
      padding: 0 15px 30px 15px;
    }
    &__tableWrapper {
      width: 100%;
      overflow: auto;
      max-height: 500px;
      &--hide {
        height: 0;
        overflow: hidden;
      }
    }
    &--hidden {
      height: 0;
      overflow: hidden;
    }
  }
}
