@import (reference) "../../../../styles/main";

.ErrorsPageDisplayTypeSwitcher {
  width: 100%;
  height: 80px;
  display: flex;
  &__button {
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    border-top: 4px solid transparent;
    transition-property: background-color, border-top-color;
    transition-timing-function: @transition-timing-function;
    transition-duration: @transition-duration;
    cursor: pointer;
    outline: 0;
    color: @color-coolGray-600;
    &:hover {
      background-color: #ebe7ff80;
    }
    &:focus {
      background-color: #ebe7ff;
    }
    &--active {
      background-color: #ebe7ff;
      color: @color-primary-900;
      border-color: #dcd8f2;
      & .ErrorsPageDisplayTypeSwitcher__button {
        &__label {
          font-weight: 600;
        }
      }
    }
    &__label {
      font-weight: 400;
    }
  }
}
