@import (reference) "../../styles/main";

.NavigationWrapper {
  width: 0;
  overflow: hidden;
  height: 100vh;
  background-color: #f1f1f7;
  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    width: 16px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.03) 100%);

    .bp-mobile({
      width: 0;
    });
  }
  .bp-mobile({
    position: absolute;
    width: 0;
    left: 0;
    top: 0;
    z-index: @z-index-top;
    overflow-y: auto;
  });
  &--showNavigation {
    width: auto;
    .bp-mobile({
      width: 100%;
    });
  }
}

.Navigation {
  width: 260px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .bp-mobile({
    width: 100%;
  });
  &__header {
    height: 104px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 44px;
    padding-left: 28px;
    .bp-mobile({
      padding: 20px;
      justify-content: flex-start;
      margin-left: 0;
    });
    & svg {
      height: 40px;
      & path {
        fill: @color-primary-900;
      }
    }
  }

  &__footer {
    padding-bottom: 40px;
    padding-left: 28px;
    padding-right: 24px;
    flex: 0;
    .bp-mobile({ display: none });
    &__content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 8px;
      row-gap: 2px;
      span,
      a {
        font-size: 12px;
        line-height: 16px;
        color: @color-coolGray-600;
      }
      span {
        display: flex;
        min-width: 100%;
      }
      a {
        display: inline-flex;
      }
      &__spacer {
        display: inline-flex;
        width: 2px;
        height: 2px;
        background-color: @color-coolGray-400;
        margin: 0 0;
        border-radius: 50%;
      }
      a:hover {
        color: @color-primary-700;
      }
    }
  }
}

.NavigationMobileToggleButton {
  position: absolute;
  bottom: 20px;
  left: 10px;
  background-color: #5140b3;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  display: none;
  outline: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  z-index: @z-index-top;
  transition-property: background-color;
  transition-timing-function: @transition-timing-function;
  transition-duration: @transition-duration;
  .bp-mobile({
    display: flex;
  });
  & > svg {
    color: #bab9c1;
    width: 20px;
    height: 20px;
  }
}
