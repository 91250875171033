@import (reference) "../../../styles/main";

.bottom_cta {
  &-background {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: @color-violet-900;
    padding: @main-padding;
  }

  &-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    width: 100%;
    color: @color-white;
  }

  &-head {
    .text-heading-2();
    max-width: 37.5rem;
    color: @color-white;
    text-align: center;
    width: 100%;
    @media @tablet {
      max-width: unset;
      text-align: left;
    }
  }

  &-buttons {
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
    justify-content: center;

    @media @tablet {
      flex-direction: column;
      align-items: stretch;
      gap: 1.5rem;
      > * {
        width: 100%;
      }
    }
  }
}
