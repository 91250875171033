@import (reference) "../../../styles/main";

.SettingsFormRow {
  &__key {
    font-size: @text-size-16;
    font-weight: 600;
    margin-bottom: 6px;
    color: #4B5563;
  }
  &__value {
    max-width: 600px;
    .bp-mobile({
      width: 100%;
    });
  }
}
