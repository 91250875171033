@import (reference) "../../../styles/main";

.ConnectorCard {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  transition: all 0.3s;
  padding: 24px 20px;

  &__header {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    outline: none;
    border: 3px solid transparent;
    background: transparent;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;

    &__container {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &.open {
      border: 3px solid #A6B4FC;
      background: #F0F3FF;
    }

    > svg {
      width: 20px;
      height: 20px;
      stroke: #9B92D2;
    }

    &__list {
      position: absolute;
      top: 45px;
      right: 0;
      padding: 16px;
      background-color: @color-white;
      border-radius: 8px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1);

      &:after {
        content: "";
        width: 18px;
        height: 18px;
        background-color: @color-white;
        position: absolute;
        top: -10px;
        right: 11px;
        transform: rotate(45deg);
        border-top: 2px solid #F3F4F6;
        border-left: 2px solid #F3F4F6;
      }

      &__item {
        border: none;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 8px 12px;
        color: #4B5563;
        background-color: transparent;

        > svg {
          width: 16px;
          height: 16px;
          stroke: #9B92D2;
          stroke-width: 2.5;
        }

        &:hover {
          background-color: #F0F3FF;
        }
      }
    }
  }

  &__logos {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__connectorImage {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }

  &__source {
    margin-left: 8px;
    font-size: @text-size-14;
    line-height: 1.4;
    color: #6B7280;
  }

  &__name {
    margin-bottom: 16px;
    font-size: @text-size-16;
    line-height: 1.5;
    font-weight: 600;
    color: #111827;
  }

  &__description {
    margin-bottom: 20px;
    font-size: @text-size-14;
    line-height: 1.4;
    color: #4B5563;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    em {
      font-style: italic;
    }
  }

  &__tags {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    border-radius: 20px;
    width: fit-content;
    background-color: #F3F4F6;
    color: #374151;
    font-size: @text-size-14;
    line-height: 1.4;

    svg {
      stroke: #9CA3AF;
      stroke-width: 3px;
      margin-right: 4px;
    }

    img {
      max-height: 18px;
    }
  }

  &__logoContainer {
    display: flex;
    justify-content: center;
    height: 18px;
    width: 18px;
    border-radius: 18px;
    margin-right: 4px;
    overflow: hidden;
  }

  &__awsTag {
    position: absolute;
    right: 0;
    top: 10px;
    background-color: #252f3e;
    transform: translateX(20px) rotate(45deg);
    display: flex;
    align-items: center;
    padding: 5px 30px;

    img {
      width: 20px;
    }
  }
}
