@import (reference) "../../styles/main";

.Button {
  font-family: inherit;
  font-size: @text-size-16;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  height: 40px;
  color: @color-white;
  background-color: @color-primary-900;
  border: 1px solid @color-primary-900;
  border-radius: 4px;
  padding: 0 10px;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: @transition-all;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  outline: 0;

  &[disabled],
  &.Button--disabled {
    background-color: #d1d5db !important;
    border-color: #d1d5db !important;
    color: #9ca3af !important;
    cursor: default !important;
    box-shadow: none !important;

    span {
      color: #9ca3af !important;
    }

    svg {
      stroke: #9ca3af !important;
    }
  }

  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  &:hover {
    background-color: darken(@color-primary-900, 10%);
    border-color: darken(@color-primary-900, 10%);
  }

  &:focus {
    background-color: @color-primary-600;
    border-color: @color-primary-600;
  }

  &:active {
    background-color: @color-primary-400;
    border-color: @color-primary-400;
  }

  &--lg {
    height: 46px;
    padding: 0 20px;
    font-size: @text-size-16;
  }

  &--md {
    height: 40px;
    padding: 15px;
    font-size: @text-size-16;
  }

  &--newsm {
    height: 32px;
    padding: 4px 8px;
    font-size: @text-size-16;
  }

  &--sm {
    height: 22px;
    padding: 2px 20px;
    font-size: @text-size-16;
  }

  &--outlined {
    background: transparent;
    color: @color-primary-900;
    border: 1px solid #b9b2e5;
    font-size: @text-size-16;
    box-shadow: none;
    &:hover {
      background-color: #b9b2e580;
      border-color: #b7b3e1;
      outline: 0;
    }
    &:focus {
      background-color: #b7b3e1;
      border-color: #b7b3e1;
      outline: 0;
    }
    &:active {
      background-color: #b9b2e580;
      border-color: #b7b3e1;
    }
  }

  &--transparent {
    background-color: transparent;
    color: @color-primary-900;
    border: 2px solid transparent;
    font-size: @text-size-16;
    box-shadow: none !important;
    font-weight: 400;
    padding: 0 12px;
    > svg {
      max-height: 16px;
      max-width: 16px;
      margin-right: 8px;
      stroke: #9b92d2;
    }
    &:hover,
    &:focus {
      background-color: #f3f4f6;
      border-color: #f3f4f6;
      outline: 0;
    }
    &:active {
      background-color: #f3f4f6;
      border-color: #a6b4fc;
      outline: 0;
    }
  }

  &--noBorder {
    background: transparent;
    color: @color-primary-900;
    font-size: @text-size-16;
    font-weight: 400;
    box-shadow: none;
    border-color: transparent;
    &:hover {
      background-color: #b8b8b87f;
      border-color: transparent;
    }
    &:focus {
      background-color: #b8b8b87f;
      border-color: transparent;
    }
    &:active {
      background-color: #b8b8b87f;
      border-color: transparent;
    }

    & > svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      stroke: #5b5b5b;
    }

    .bp-phone({
      background-color: rgba(0,0,0,0.04);
     });
  }

  &--warning {
    background: @color-tertiary-900;
    color: @color-white;
    border: none;
    &:hover {
      background-color: @color-tertiary-600;
      outline: 0;
    }
    &:focus {
      background-color: @color-tertiary-600;
      outline: 0;
    }
    &:active {
      background-color: @color-tertiary-600;
    }
  }

  &--cta {
    background: @color-tertiary-900;
    color: @color-white;
    border: none;
    &:hover {
      background-color: darken(@color-tertiary-900, 10%);
      outline: 0;
    }
    &:focus {
      background-color: darken(@color-tertiary-900, 10%);
      outline: 0;
    }
    &:active {
      background-color: darken(@color-tertiary-900, 10%);
    }
  }

  &--icon {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: @color-coolGray-900;
    width: 30px;
    height: 30px;
    padding: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      background-color: #eaeaf0;
    }
    &:focus {
      background-color: #eaeaf0;
    }
    &:active {
      background-color: #eaeaf0;
    }
  }

  &--clear {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #bab9c1;
    font-weight: 600;
    outline: none;
    & > svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    &:hover {
      background-color: #eaeaf0;
    }
    &:focus {
      background-color: #eaeaf0;
    }
    &:active {
      background-color: #eaeaf0;
    }
  }

  &--secondary {
    background: @color-secondary-400;
    border: 1px solid @color-secondary-400;
    color: #17009a;
    &:hover {
      background-color: @color-secondary-300;
      border-color: @color-secondary-300;
    }
    &:focus {
      background-color: @color-secondary-300;
      border-color: @color-secondary-300;
    }
    &:active {
      background-color: @color-secondary-300;
      border-color: @color-secondary-300;
    }
  }

  &--greyscale {
    background: @color-white;
    border: 2px solid #bab9c1;
    color: #5b5b5b;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active {
      background-color: #00000008;
      border-color: #bab9c1;
    }
  }

  &--withShadow {
    border-color: transparent;
    color: @color-primary-900;
    background-color: #eaeaf0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    font-weight: 400;
    & > svg {
      width: 20px;
      height: 20px;
      color: #5b5b5b;
      margin-right: 10px;
    }
    &:hover {
      background-color: #b8b8b87f;
      border-color: transparent;
    }
    &:focus {
      background-color: #b8b8b87f;
      border-color: transparent;
    }
    &:active {
      background-color: #b8b8b87f;
      border-color: transparent;
    }
  }

  &--withBlueShadow {
    border-color: transparent;
    color: @color-white;
    background-color: @color-primary-900;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    font-weight: 400;
    & > svg {
      width: 20px;
      height: 20px;
      color: #fff;
      margin-left: 10px;
    }
    &:hover {
      background-color: @color-primary-900;
      border-color: transparent;
    }
    &:focus {
      background-color: @color-primary-900;
      border-color: transparent;
    }
    &:active {
      background-color: @color-primary-900;
      border-color: transparent;
    }
  }

  &--whiteWithShadow {
    border-color: transparent;
    background-color: @color-white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    color: @color-primary-900;
    font-weight: 400;
    & > svg {
      width: 20px;
      height: 20px;
      color: #bab9c1;
      margin-right: 10px;
    }
    &:hover {
      background-color: #d2cee9;
      border-color: transparent;
    }
    &:focus {
      background-color: #d2cee9;
      border-color: transparent;
    }
    &:active {
      background-color: #d2cee9;
      border-color: transparent;
    }
    &.Button--disabled svg {
      color: #5b5b5b;
      opacity: 0.5;
    }
  }

  &--fullWidth {
    width: 100%;
  }

  &.TalkToSalesButton {
    min-width: 100px;
  }
}
