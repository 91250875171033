@import (reference) "../../styles/main";

.StandardModalTemplate {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  max-height: 90%;
  overflow: auto;
  width: 768px;
  max-width: 90%;
  padding: 72px 40px 40px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  &__close {
    position: absolute;
    top: 24px;
    right: 40px;
    background-color: transparent;
    border: none;

    > svg {
      stroke: #9CA3AF;
      &:hover {
        cursor: pointer;
        stroke: @color-black;
      }
    }
  }
}
