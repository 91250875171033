.ProjectsAll {
  &__loader {
    .Loader {
      margin: 20px auto;
    }
  }
  &__table {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    margin: 24px 0;
    .bp-desktop-large({
      grid-template-columns: 1fr 1fr 1fr;
    });
    .bp-desktop-big({
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    });
    .bp-phone({
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    });

    & > div > div {
      overflow-wrap: anywhere;
    }
  }
}
