@import (reference) "../../../styles/main";

.info_box {
  &-main {
    position: fixed;
    bottom: 1.25rem;
    left: 1.25rem;
    width: calc(100% - 2.5rem);
    max-width: 25rem;
    background-color: @color-white;
    box-shadow: 0 0.125rem 0.25rem rgba(31, 41, 55, 0.06), 0 0.25rem 0.375rem rgba(31, 41, 55, 0.1);
    padding: 1rem;
    z-index: 100;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.625rem;
    border-radius: 0.25rem;
    opacity: 1;
    transition: @transition-all;

    &.initiated {
      opacity: 0;
      transform: translateY(1.25rem);
      transition: @transition-all;
    }
  }

  &-icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.25rem;

    &.attention {
      background-color: #fef3c7;
    }

    &.error {
      background-color: #f1dfe7;
    }

    &.success {
      background-color: #c2f4ed;
    }
  }

  &-icon {
    width: 1.125rem;
    height: 1.125rem;
  }

  &-text {
    width: 17rem;

    h3 {
      font-family: @font-family-headings;
      font-weight: 700;
      font-size: @text-paragraph-font-size;
      line-height: 1.5;
      color: @color-coolGray-900;
    }

    p {
      font-family: @font-family-body;
      font-weight: 400;
      font-size: @text-paragraph-font-size;
      line-height: 1.25;
      color: #7c7c7c;
    }
  }

  &-action {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    margin-top: 1rem;
    border: 1px solid #e5e7eb;
    box-shadow: 0 1px 0.125rem rgba(31, 41, 55, 0.08);
    border-radius: 0.25rem;
    font-family: @font-family-body;
    font-weight: 600;
    font-size: @text-paragraph-font-size;
    line-height: 1.25;
    color: @color-coolGray-900;
    background-color: @color-white;
    cursor: pointer;
    transition: @transition-all;

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 1px 0.125rem rgba(31, 41, 55, 0.3);
    }
  }

  &-close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
      width: 1.25rem;
      height: 1.25rem;
      stroke: #9ca3af;
      transition: @transition-all;

      &:hover,
      &:focus,
      &:active {
        transform: scale(1.15);
      }
    }
  }
}
