@import (reference) "../../styles/main";

.ProjectPageTemplate {
  padding: 32px;

  &__loader {
    .Loader {
      margin: 20px auto;
    }
  }
}
