.InternalNotes {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    margin-top: 24px;
    height: -webkit-fill-available;


    &__header {
        height: 80px;
        padding: 24px;
        border-bottom: 1px solid #E5E7EB;

        &__flex {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & > h1 {
                font-size: 22px;
                font-weight: 600;
                color: var(--coolGray-900);
            }
        }
    }

    &__content {
        padding: 24px;
        border-bottom: 1px solid #E5E7EB;

        & > .ProjectCard__table__row {
            padding: 14px 0px;
            @media @mobile {
                grid-template-columns: 1fr;
            }
            & > div {
                @media @mobile {
                    margin-left: 0px;
                }
            }
        }

        & > h2 {
            color: var(--coolGray-900);
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 14px;
        }

        & > p {
            margin: 14px 0px;
            color: var(--coolGray-700);
            font-size: 14px;
            font-weight: 400;
        }

        & > div {
            & > div {
                margin-left: 200px;
                color: var(--coolGray-700);
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}
