@import (reference) "../../../../styles/main";

.PaginationOverride {
    
    .Pagination__container__navButton {
        height: 32px;
        width: 32px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);

        svg {
            stroke: #9B92D2;
        }
    }

    .Pagination__container__pageButton {
        color: #4B5563;
    }

    .Pagination__container__pageButton:focus-visible, .Pagination__container__navButton:focus-visible  {
        outline: -webkit-focus-ring-color auto 1px;
    }

    .Pagination__container__pageButton--active {
        background-color: #DDDCED;
        color: #17009A;
    }

    .Pagination__container__pageButton--disabled {
        background-color: transparent;
        box-shadow: none;
        cursor: default;

        svg {
            stroke: #D1D5DB;
        }
    }
}
