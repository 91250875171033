@import (reference) "../../../../styles/main";

.ResultsTableView {
  background: @color-white;
  font-size: @text-size-14;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &__header {
    min-height: 50px;
    height: 50px;
    padding: 0 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    &__searchField {
      flex: 1;
    }
    &__downloadButton {
      margin-left: 10px;
      height: 32px;
      & > svg {
        width: 16px;
        height: 16px;
        stroke: #9B92D2;
        stroke-width: 2.5;
      }
      & > span {
        font-size: @text-size-16;
      }
    }
    &__showNulls {
      margin-left: 20px;
      height: 32px;
      & > svg {
        width: 16px;
        height: 16px;
        stroke: #9B92D2;
        stroke-width: 2.5;
      }
      & > span {
        font-size: @text-size-16;
      }
    }
  }
  &__tableWrapper {
    flex: 1;
    height: calc(100% - 50px);
    overflow: auto;
  }
  &__table {
    min-width: 600px;
    grid-template-columns:
      62px
      min-content
      min-content
      minmax(300px, 1fr);

    & th {
      font-size: @text-size-14;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      &:last-of-type {
        border-right: none;
      }
    }
    tr {
      &:hover {
        > td {
          background-color: #f0eff9;
        }
      }
    }
    & td {
      font-weight: 600;
      min-height: 40px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      align-items: flex-start;
      line-height: 20px;

      &:first-of-type {
        font-weight: 400;
        padding-right: 24px;
        justify-content: flex-end;
      }

      &:last-of-type {
        border-right: none;
      }
    }
    &__id {
      color: #5b5b5b;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-family: monospace;
      font-variant-numeric: tabular-nums;
      color: #c5c5c5;
      font-family: Menlo, Monaco, "Courier New", monospace;
      font-size: 12px;
    }
    &__attribute,
    &__value {
      white-space: break-spaces;
    }

    &__value {
      font-weight: 400;
    }
  }
}
