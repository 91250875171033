@import (reference) "../../../styles/main";

@length: 30;
@iconWidth: 3.5rem;
@iconHeight: 3.5rem;
@iterationTime: 25s;
@positionTop: 42rem;
@sliderHeight: 6rem;
@sliderColor: @color-violet-800;

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1 * @iconWidth * @length));
  }
}

@keyframes goRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  10% {
    transform: translateX(calc(100vw + (@sliderHeight * 4))) scaleY(0.95);
    opacity: 0.15;
  }
  100% {
    transform: translateX(calc(100vw + (@sliderHeight * 4))) scaleY(0.95);
    opacity: 0.15;
  }
}

.LandingSourceCarousel {
  @media @mobile {
    display: none !important;
  }
  width: 100%;
  height: @sliderHeight;
  align-items: center;
  display: flex;
  position: relative;
  top: @positionTop;

  .DiscoveryFormTemplate & {
    top: 50rem;
  }

  &-wrapper {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &:before {
    content: "";
    height: @sliderHeight + 0.5rem;
    width: @sliderHeight * 4;
    background: linear-gradient(to right, @color-violet-200, rgba(@color-violet-400, 0.5));
    position: absolute;
    top: -0.25rem;
    left: @sliderHeight * -4;
    animation: goRight 6s ease-in 1s infinite;
    z-index: 0;
  }

  &:after {
    left: unset;
    height: @sliderHeight;
    width: 100%;
    right: 0;
    top: 0;
    content: "";
    position: absolute;
    z-index: 9;
    pointer-events: none;
    background: linear-gradient(
      to right,
      rgba(@color-violet-900, 0) 50%,
      rgba(@color-violet-900, 1) 100%
    );
  }

  &-sources-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 9;
    height: @sliderHeight;
    background: linear-gradient(
      to right,
      lighten(@sliderColor, 10%),
      lighten(@sliderColor, 5%),
      @sliderColor,
      @sliderColor
    );
  }
  &-sources {
    display: flex;
    align-items: center;
    height: @sliderHeight;
    width: calc(@iconWidth * @length * 2);
    animation: scroll @iterationTime reverse linear infinite;
    //  margin-top: (@sliderHeight + @iconHeight / 4);
  }
  &-source {
    width: @iconWidth;
    height: @iconHeight;
    display: flex;
    flex: 0 0 auto;
    margin: auto;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      background-color: @color-white;
      border: 1px solid @sliderColor;
      border-radius: 50%;
      width: @iconHeight;
      height: @iconHeight;
    }

    span {
      display: none;
    }

    // &:hover {
    //   span {
    //     display: flex;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //   }
    // }
  }
}
