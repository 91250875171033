@import (reference) "../../../styles/main";

.platform_capabilities {
  .main-Layout();
  padding-bottom: 0;
  background-color: #f2f2f5;

  @media @tablet-landscape {
    text-align: center;
  }

  > .section-layout {
    h2 {
      max-width: 85rem;
    }
  }

  h2 {
    .text-heading-2();
    padding: @layout-padding;
    color: @color-violet-900;
    margin-bottom: 4.375rem;
    span {
      display: block;
    }
  }

  &-subtitle {
    .text-subheading-2();
    padding: @layout-padding;
  }

  &-section {
    scroll-margin-top: 7.5rem;
    text-align: left;
    width: 100%;
    padding-bottom: @main-padding-bottom * 2;
    display: grid;
    grid-template-columns: 1.75fr 1fr;
    margin: 0 auto;
    column-gap: 6rem;
    align-items: center;
    grid-auto-flow: dense;

    &:last-child {
      padding-bottom: @main-padding-bottom;
    }

    &:nth-child(2n - 1) {
      grid-template-columns: 1fr 1.75fr;

      .platform_capabilities-section-img {
        grid-column: 2 / span 1;
        justify-self: flex-end;
      }
      .platform_capabilities-section-list {
        grid-column: 1 / span 1;
        justify-self: flex-start;
      }
    }

    @media @tablet-landscape {
      text-align: center;
      grid-template-columns: 1fr !important;
      .platform_capabilities-section-img {
        grid-column: unset !important;
        height: fit-content !important;
        justify-self: center !important;
      }
      .platform_capabilities-section-list {
        grid-column: unset !important;
        text-align: left;
      }
    }
    &-img {
      max-width: 100%;
      @media @tablet-landscape {
        padding: 0 1.5rem;
        width: 100%;
        height: auto;
        margin: 0 auto 3rem;
      }
    }

    .button-component {
      margin-top: 1rem;

      @media @tablet {
        width: 100%;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      h3 {
        .text-heading-3();
        color: @color-violet-900;
        margin-top: 0;
      }
      p {
        .text-paragraph();
        color: #514779;
        margin: 0;
      }
      ul {
        background: @color-white;
        border-radius: @button-border-radius;
        padding: @card-small-textarea-padding;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        li {
          display: flex;
          align-items: center;
          .text-paragraph();
          margin: 0;
          p {
            color: @color-violet-900;
          }
          img {
            margin-right: 1rem;
          }
          @media @mobile {
            flex-direction: column;
            text-align: center;
            gap: 0.5rem;
          }
        }
      }
    }
  }
}
