@import (reference) "../../styles/main";

.DataCatalogPage {
  background-color: @color-platformBackground;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__contentWrapper {
    overflow: auto;
    padding: 20px 40px;
    flex: 1;
    .bp-mobile({
      padding: 20px;
    });
  }

  &__tabs {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #e5e7eb;
    margin-top: 25px;

    & > div {
      & > div {
        overflow: hidden;
        & > a {
          border-bottom: none;
          margin-bottom: -1px;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @text-size-16;
      line-height: 1.5;
      font-weight: 400;
      border-bottom: 2px solid transparent;
      color: #4b5563;
      padding: 16px;

      &__label {
        font-size: @text-size-14;
        line-height: 1.5;
        font-weight: 400;

        .Loader {
          width: 20px;
          height: 20px;
        }
      }

      &--home {
        min-width: 65px;
      }

      &--connectors {
        min-width: 85px;
      }

      &--recipes {
        width: 55px;
      }

      &.active {
        color: #17009a;
        font-weight: 600;
        border-bottom: 2px solid #594ab6;

        .DataCatalogPage__tabs__item__label {
          // background-color: #6f62be;
          color: #e8e7f1;

          .Loader svg {
            stroke: #e8e7f1;
          }
        }
      }
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 24px;
    z-index: @z-index-productpage;
    position: relative;

    &__results {
      color: #111827;
      font-weight: 600;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    .bp-phone({
      flex-direction: column;
    });

    &__title {
      font-size: @text-size-30;
      line-height: 40px;
      font-weight: 600;
    }

    &__action {
      display: flex;
      align-items: flex-end;
      gap: 10px;
      .bp-phone({
        margin-top: 20px;
      });
      svg {
        stroke: #9b92d2;
      }
    }
  }
}
