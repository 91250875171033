@import (reference) "../../../../styles/main";

.UseCaseGeneralSettings {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #E5E7EB;
  &__header {
    font-size: @text-size-22;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    &__row {
      margin-bottom: 20px;
      width: 100%;
      .FormComponent__input.Select__input {
        margin-top: 5px;
        height: 40px;
      }
      .Button {
        font-size: @text-size-16;
        font-weight: 400;
        > svg {
          width: 16px;
          height: 16px;
          stroke-width: 2.5;
        }
      }
    }
    &__name {
      width: 100%;
    }
    &__description {
      width: 100%;
      &__textArea {
        min-height: 140px;
        resize: none;
      }
    }
    &__row, &__name, &__description {
      .FormComponent__label {
        color: #4B5563;
      }
    }
  }
}
