@import (reference) "../../../styles/main";

.overcome_challenges {
  .main-Layout();
  background-color: #f2f2f5;
  h2 {
    .text-heading-3();
    line-height: 72px;
    background-color: @color-white;
    color: @color-violet-900;
    border-radius: @card-border-radius;
    padding: 0 @layout-padding-left-right;
    max-width: 790px;
    margin: 0 auto 4rem auto;
    @media @tablet-landscape {
      line-height: 28px;
      text-align: center;
      padding: 8px 12px;
    }
  }
  &-card_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    margin: 0 auto;
    align-items: center;
    grid-auto-flow: dense;

    @media @tablet-landscape {
      grid-template-columns: 1fr;
    }
  }

  &-card {
    background-color: @color-white;
    border-radius: @card-border-radius;
    padding: @card-medium-padding;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;

    @media @tablet-landscape {
      text-align: center;
      align-items: center;
    }

    > img {
      width: 48px;
      height: 48px;
    }

    > h3 {
      .text-heading-3();
      margin: 0;
      color: @color-violet-900;
    }

    > p {
      .text-paragraph();
      margin: 0;
      color: @color-violet-900;
    }
  }
}
