@import (reference) "../../../../styles/main";

.industry_recipe_article {
    position: relative;
    background-color: #F2F2F5;
    cursor: pointer;
    padding: 2rem 2rem 3rem 2rem;
    border-radius: 1.25rem;
    border: 0.5rem solid @color-white;
    transition: @transition-all;
    text-align: left;

    &:hover,
    &:focus {
      box-shadow: @box-shadow-active;
      border-color: @color-indigo-100;
      box-shadow: @box-shadow-default;
  
      h3,
      p {
        color: @color-primary-900;
      }
    }

    h3 {
        .text-heading-3();
        margin: 0.5rem 0;
        color: @color-violet-900;
      }

    > p {
        .text-paragraph();
        color: #514779;
        margin-bottom: 1.25rem;
      }

    a {
    position: absolute;
    bottom: 2rem;
    left: 1.875rem;
    box-shadow: none;
    color: @color-primary-900;

    padding: 0;
    width: fit-content;
    min-width: 0;
    margin: 0;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    .button-text();
    img {
        margin-left: 0.625rem;
        width: 0.875rem;
        height: 0.875rem;
    }
    }

    &-pill {
        width: fit-content;
        height: 1.25rem;
        border-radius: 0.25rem;
        line-height: 1.25rem;
        background-color: @color-teal-400;
        font-size: 0.875rem;
        font-weight: 600;
        padding: 0 0.375rem;
        color: @color-blue-900;
      }
}