@import (reference) "../../../styles/main";

.home_solutions {
  display: flex;
  justify-content: center;
  padding-top: @main-padding-top;

  @media @mobile {
    padding-top: @main-padding-top-mobile;
  }

  &-background {
    .main-Layout();
  }

  h2 {
    .text-heading-2();
    @media @mobile {
      text-align: left;
    }
  }

  &-text {
    .text-subheading-2();
    max-width: @layout-text-max-width;
  }

  &-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;

    @media @tablet-landscape {
      grid-template-columns: 1fr 1fr;
    }

    @media @mobile {
      grid-template-columns: 1fr;
    }
  }

  &-card {
    .card-small-Layout();
    .card-hoverable-Layout();
    height: 100%;
    border-color: @color-blue-50;
    background-color: @color-blue-50;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &_text {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &:hover {
      background-color: @color-blue-100;
      .home_solutions {
        &-card {
          &-btndummy {
            color: @color-white;
            background: @color-primary-1000;
          }
        }
      }
    }

    img {
      width: 3rem;
      height: 3rem;
    }

    h3 {
      .text-heading-3();
      color: @color-primary-900;
      margin-top: 1.25rem;
      margin-bottom: 0.75rem;
    }

    p {
      .text-paragraph();
      color: @color-primary-800;
      margin: 0;
      flex: 1;
    }

    &-btndummy {
      .button-text();
      position: relative;
      overflow: hidden;
      display: flex;
      flex: 0 1 auto;
      justify-content: center;
      align-items: center;
      height: 2.25rem;
      padding: 0 0.75rem;
      font-family: @font-family-headings;
      border-radius: 0.5rem;
      background: @color-white;
      color: @color-primary-900;
      cursor: pointer;
      transition: @transition-all;
      white-space: nowrap;
      width: fit-content;
      margin: 0.5rem auto 0;
    }
  }
}
