@import (reference) "../../../../styles/main";

.OverviewRemovePopup {
  overflow: hidden;
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__paper {
    width: 100%;
    height: 100%;
    max-width: 960px;
    max-height: calc(100% - 120px);
    padding: 0;
    display: flex;
    flex-direction: column;
    .bp-mobile({
      max-width: initial;
      max-height: initial;
      border-radius: 0;
    });
    &__header {
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__content {
      padding: 0 100px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      display: flex;
      flex-direction: column;
      .bp-mobile({
        padding: 0 40px;
      });
      &__header {
        font-weight: 700;
        font-size: @text-size-32;
        margin-bottom: 20px;
      }
      &__message {
        margin-bottom: 40px;
      }
      &__deleteButton {
        margin-bottom: 20px;
        background-color: #D91C5C;
        border: none;
        &:hover {
          background-color: #B7184E;
          border-color: transparent;
        }
        &:focus {
          background-color: #B7184E;
          border-color: transparent;
        }
        &:active {
          background-color: #B7184E;
          border-color: transparent;
        }
      }
    }
  }
}
