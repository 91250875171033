@import (reference) "../../styles/main";

.webinar_registration {
  &-summary {
    width: 50%;
    @media @tablet-landscape {
      width: 100%;
    }
  }

  &-form {
    width: 50%;
    @media @tablet-landscape {
      width: 100%;
    }
  }

  &-main {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    min-height: 100vh;
    align-items: stretch;

    @media @tablet-landscape {
      flex-direction: column;
      min-height: unset;
    }
  }
}

.webinar_form {
  &-main {
    flex: 1;
    margin-top: 6.5rem;
    padding: 0 1.5rem 10rem 5%;
    max-width: (@layout-max-width / 2);

    .button-component {
      width: 100%;
      z-index: @layer-top-1;
    }

    @media @tablet-landscape {
      padding: @hero-padding-bottom 1.5rem 10rem;
      margin: 0 auto;
    }
  }

  &-header {
    .text-heading-3();
    margin-bottom: 3rem;
    white-space: pre-line;
  }
}
