@import (reference) "../../../styles/main";

.DemystFilesTable {
  thead {
    tr {
      th {
        padding: 10px 15px;
        font-size: @text-size-14;
        color: @color-coolGray-600;
        text-align: left;

        > div {
          display: flex;
          white-space: nowrap;
        }

        &:last-of-type {
          min-width: 100px;
        }
      }
    }
  }

  &__label--active {
    font-weight: bold;
    color: @color-primary-900;
  }

  &__buttonLabel {
    display: flex;
    white-space: nowrap;
    align-items: center;
    outline: none;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 100%;
    justify-content: space-between;
    &--secure {
      justify-content: flex-start;
      gap: 10px;
    }
    &__secure {
      display: flex;
      align-items: center;
      position: relative;
      text-align: center;
      cursor: pointer;

      div {
        position: absolute;
        background-color: @color-black;
        color: @color-white;
        font-size: 0.9rem;
        padding: 6px;
        border-radius: 10px;
        visibility: hidden;
        opacity: 0;
        width: 150%;
        white-space: normal;
        z-index: 99;
        top: 30px;
        left: 50%; 
        transform: translateX(-50%);
        min-width: 150px;
        transition: opacity 0.5 ease;

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 100%;
          transform: translateX(-50%);
          border: 10px solid;
          border-color: #0000 #0000 #000 #0000;
        }
      }
      
      &:hover div {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__chevrons {
    padding: 0 0 0 20px;
  }

  &__chevron {
    transform: rotate(45deg);
    width: 5px;
    height: 5px;

    &__up {
      border-top: 2px solid #bab9c1;
      border-left: 2px solid #bab9c1;

      &--active {
        border-top: 2px solid @color-primary-900;
        border-left: 2px solid @color-primary-900;
      }

      &--inactive {
        display: none;
      }
    }

    &__down {
      border-bottom: 2px solid #bab9c1;
      border-right: 2px solid #bab9c1;

      &--active {
        border-bottom: 2px solid @color-primary-900;
        border-right: 2px solid @color-primary-900;
      }

      &--inactive {
        display: none;
      }
    }
  }

  tbody {
    tr {
      height: 40px;
      > td {
        background-color: #ffffff;
      }
      &:nth-child(even) {
        > td {
          background-color: #f6f6f6;
        }
      }
      &:hover {
        > td {
          background-color: @color-secondary-50;
        }
      }
      transition: @transition-all;
      & td {
        vertical-align: middle;
        padding-left: 15px;
        padding-right: 15px;
        font-size: @text-size-16;
        line-height: 20px;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}
