@import (reference) "../../../styles/main";

.LandingCtas {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 4rem;

  @media @tablet {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .button-component {
      width: 100%;
    }
  }
}
