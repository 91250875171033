@import (reference) "../../styles/main";

.ToSAcceptancePage {
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-color: #f7f7fa;
  display: grid;
  grid-template-rows: 160px minmax(450px, 1fr) 1fr;
  grid-template-columns: 1fr calc(100% - 3rem) 1fr;
  grid-template-areas:
    ". header ."
    ". paper ."
    ". footer .";
  .bp-mobile({
      grid-template-rows: 80px minmax(min-content, 1fr) 60px;
      position: relative;
      background-color: @color-white;
      grid-template-columns: 1fr;
      grid-template-areas:
    "header"
    "paper"
    "footer"
    });
  &__header {
    grid-area: header;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__paper {
    grid-area: paper;
    width: 100%;
    max-width: 800px;
    margin: auto;

    .bp-tablet-landscape({
      max-width: unset;
    });
    & > section {
      height: 100%;
      display: flex;
      justify-content: flex-start;
    }
    &__header {
      width: 100%;
      text-align: left;
      & > h1 {
        font-size: @text-size-28;
        line-height: 1.3;
        font-weight: 700;
        margin-bottom: 20px;
      }
      & > p {
        line-height: 24px;
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
    &__tosScrollWrapper {
      border: 1px solid #dadada;
      background-color: rgba(0, 0, 0, 0.02);
      margin: 40px 0 30px;
      width: 100%;
      flex: 1 1 auto;
      overflow-y: auto;
      padding: 20px;
      border-radius: 4px;
    }
    &__error {
      width: 100%;
      margin-bottom: 20px;
    }
    &__accept {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &__footer {
    grid-area: footer;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
