.BadgeFilter {
    background: white;
    
    &__container {

        &__selected {
            background-color: var(--coolGray-300);
            padding: 8px;

            & > div {
                margin: 0px 4px;
            }
        }
        &__unordered {
            position: absolute;
            z-index: 10000000;
            width: 225px;
            
            & > li {
                padding: 8px;
            }
        }
    }

}
