@import (reference) "../../../../styles/main";

.DataPageDetails__svgSize {
  min-height: 16px;
  height: 16px;
  width: 16px;
  min-width: 16px;
}

.DataPageDetails {
  &__badge {
    & > :nth-child(1n) {
      & > img {
        width: 18px;
        height: 18px;
        max-width: 18px;
        border-radius: 18px;
      }

      & > svg {
        stroke: #B2ABDB;
        stroke-width: 3px;
        width: 16px;
      }
    }
    & > :nth-child(2n) {
      color: #594AB6;
    }
  }

&__diligenceBadge {
  & > :nth-child(1n){
    & > svg {
      stroke: #4F9E90;
      stroke-width: 3px;
    }
    color: #594AB6;
  }

  & > :nth-child(2n) {
    color: #594AB6;
  }
}

  &__separator {
    width: 100%;
    border-bottom: 1px solid #E5E7EB;
    margin: 16px 0;
  }
  &__table {
    width: 100%;
    display: grid;
    grid-row-gap: 16px;

    &__row {
      width: 100%;
      display: grid;
      grid-column-gap: 60px;
      grid-template-columns: 116px 1fr;
      .bp-mobile({
        grid-template-columns: 1fr;
      });
      &__name {
        display: flex;
        align-items: flex-start;
        &.diligence {
          align-items: center;
        }
        img {
          height: 24px;
        }
      }
      &__tooltip {
        background: #6F62BE;
        border-radius: 4px;
        padding: 6px 8px 8px;
        font-size: @text-size-14;
        line-height: 20px;
        font-weight: 400;
        color: @color-white;
        text-align: center;
        &--container {
          &.diligenceLabel {
            display: none;
            width: 150px;
            .bp-mobile({
              left: 45px;
              &:before {
                left: 35px;
              }
            });
          }
          &.diligenceValue {
            display: none;
            width: 300px;
            .bp-mobile({
              width: 200px;
            });
          }
          position: absolute;
          transform: translateX(-50%);
          left: calc(50% + 30px);
          top: 25px;
          padding-top: 10px;
          z-index: 100;
          &:before {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            top: 5px;
            transform: translateX(-50%) rotate(45deg);
            left: calc(50% - 30px);
            background: #6F62BE;
          }
        }
      }
      &__logo {
        margin-right: 8px;
        position: relative;
        &:hover {
          .DataPageDetails__table__row__tooltip--container.diligenceLabel {
            display: block;
          }
        }
      }
      &__head {
        color: #4B5563;
        font-weight: 600;
        line-height: 30px;
        min-width: 85px;
        white-space: nowrap;
      }
      &__body {
        font-size: 16px;
        line-height: 30px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        align-items: center;
      }
      &__id {
        width: 100%;
        display: inline-flex;
        line-break: anywhere;
        align-items: center;
        color: #111827;
      }
      &__notes {
        align-items: center;
        color: #111827;
        white-space: break-spaces;
        ul, ol {
          list-style: revert;
          padding: revert;
        }
      }
      &__label {
        position: relative;
        display: inline-flex;
        align-items: center;
        border-radius: 30px;
        color: #594AB6;
        background-color: #E8E7F1;
        min-height: 32px;
        padding: 0 10px;
        gap: 10px;

        &.diligence {
          svg {
            .DataPageDetails__svgSize();
            stroke: #4F9E90;
          }
          &:hover {
            .DataPageDetails__table__row__tooltip--container.diligenceValue {
              display: block;
            }
          }
        }
        
        svg {
          .DataPageDetails__svgSize();
          font-size: @text-size-16;
          display: flex;
          align-items: center;
          stroke: #B2ABDB;
          stroke-width: 3px;
        }

        img {
          width: 18px;
          height: 18px;
          max-width: 18px;
          border-radius: 18px;
        }
      }
    }
  }
}
