.InternalProductOnboarding {
    width: 40%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 20%);
    border-radius: 12px;
    height: -webkit-fill-available;
    @media @desktop-small{
        width: 100%;
       }


    &__header {
        height: 80px;
        padding: 24px;
        border-bottom: 1px solid #E5E7EB;

        & > h1 {
            font-size: 22px;
            font-weight: 600;
            color: var(--coolGray-900);
        }
    }

    &__section {
     
        &__header {
            padding: 14px 24px;
            font-size: 14px;
            color: var(--coolGray-900);
            font-weight: 600;
            min-width: 85px;
            white-space: nowrap;
            border-bottom: 1px solid #E5E7EB;

            &__flex {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                &__badgeWrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 14px;
                    cursor: pointer;
                }
            }

            &__edit {
                cursor: pointer;
            }

        }

        &__body {
            padding: 24px;
            background-color: var(--coolGray-50);

            & > div {
                & > :nth-child(2) {
                    & > p {
                        color: var(--coolGray-700);
                        font-size: 14px;
                    }
                }
            }


        & > .ProjectCard__table__row {
            padding: 14px 0px;
            @media @mobile {
                grid-template-columns: 1fr;
            }
        }
        }

        &__button {
            padding: 24px;
        }
    }
}
