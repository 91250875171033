@import (reference) "../../../../styles/main";

.OverviewQuickStart {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 32px;
  width: 560px;
  min-width: 560px;
  max-width: 560px;
  background: @color-white;
  .bp-desktop-big({
    width: 100%;
    min-width: unset;
    max-width: unset;
  });
  &__title {
    font-size: @text-size-22;
    font-weight: 600;
    margin-bottom: 16px;
  }
  &__paragraph {
    margin-bottom: 20px;
    span {
      color: #8479C7;
      font-size: @text-size-13;
      font-family: @font-family-mono;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  &__curl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    flex-wrap: wrap;
    gap: 8px;
    &__link {
      color: #4B5563;
      font-size: @text-size-12;
      span {
        color: #594AB6;
        background-color: #E8E7F1;
        border-radius: 12px;
        padding: 2px 8px;
      }
    }
    &__button {
      border: none;
      outline: none;
      background-color: #E8E7F1;
      border-radius: 12px;
      padding: 2px 8px;
      display: flex;
      align-items: center;
      font-size: @text-size-12;
      line-height: @text-size-16;
      color: #594AB6;
      &__chevrons {
        position: relative;
        svg {
          stroke: #B2ABDB;
          stroke-width: 4;
          &:first-child {
            margin-top: 2px;
            margin-bottom: -2px;
          }
          &:last-child {
            margin-top: 2px;
            margin-bottom: -2px;
            margin-left: -5px;
          }
        }
      }
    }
  }
  &__codeblock {
    width: 100%;
    height: 330px;
    background: #111827;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-bottom: 16px;
    overflow-y: auto;
    &__text {
      color: #B2ABDB;
      padding: 4px 8px;
      font-family: @font-family-mono;
      font-size: @text-size-13;
      line-height: 20px;
      .red {
        color: #E99DB9;
      }
      .gray {
        color: #6B7280;
      }
      .white {
        color: #F3F4F6;
      }
    }
  }
  &__actions {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    &__errorWrapper {
      width: 100%;
    }
  }
}