@import (reference) "../styles/main";

.layout-main {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background-color: @color-white;
  align-items: center;
  padding-top: @header-height;
  overflow: hidden;
  max-width: 300rem;
  margin: 0 auto;

  &.banner-open {
    padding-top: @header-height + @banner-height;
  }

  &.uplifted {
    padding-top: 0;
  }
}

.section-layout {
  .section-Layout();
}

.bottom-content {
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  pointer-events: none;
  .slackButton {
    margin: 1rem;
    pointer-events: initial !important;
  }
}
