@import (reference) "../../../styles/main";

.PublicSignupBox {
  width: 420px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #10006c;
  overflow: hidden;
  margin-bottom: 30px;

  .bp-mobile({
      width: 320px;
    });
  .bp-phone({
      width: 280px;
    });

  &__belt {
    width: 100%;
    height: 20px;
    display: flex;

    & .box0 {
      background-color: @color-tertiary-200;
    }
    & .box1 {
      background-color: @color-tertiary-400;
    }
    & .box2 {
      background-color: @color-tertiary-600;
    }
    & .box3 {
      background-color: @color-tertiary-900;
    }
    & .box4 {
      background-color: @color-secondary-50;
    }
    & .box5 {
      background-color: @color-secondary-200;
    }
    & .box6 {
      background-color: @color-secondary-300;
    }
    & .box7 {
      background-color: @color-secondary-400;
    }
    & .box8 {
      background-color: @color-primary-200;
    }
    & .box9 {
      background-color: @color-primary-400;
    }
    & .box10 {
      background-color: @color-primary-600;
    }
    & .box11 {
      background-color: @color-primary-900;
    }
  }

  &__main {
    color: @color-white;
    padding: 24px 24px 36px;

    &__title {
      font-size: @text-size-30;
      line-height: @body-text-lineheight;
      font-weight: 600;
    }

    &__paragraph {
      margin-top: 16px;
      font-size: @text-size-16;
      line-height: 1.5;
      font-weight: 400;
    }

    &__button {
      cursor: pointer;
      border: none;
      outline: none;
      width: 100%;
      height: 48px;
      margin-top: 16px;
      background-color: @color-tertiary-900;
      color: @color-white;
      font-size: @text-size-16;
      line-height: 1.5;
      font-weight: 600;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
  }
}
