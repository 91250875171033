@import (reference) "../../../styles/main";

.usecases_recipes_section {
  background-color: @color-blue-25;
  .main-Layout();
  &-container {
    .section-Layout();
    align-items: flex-start;
  }
  h2 {
    .text-heading-2();
    text-align: left;
    width: 100%;
  }
  > div > p {
    .text-subheading-2();
    text-align: left;
    width: 100%;
    max-width: 60rem;
  }
}
