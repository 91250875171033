@import (reference) "../../../../styles/main";

.industry_hero {
  .hero-Layout();

  &-background {
    .hero-backgroundLayout();
    .gradient-violet-900();

    &.platform {
      .gradient-violet-800();
    }
  }

  .button-component {
    height: 3.75rem;
    padding: 0 1.75rem;
    margin-bottom: 2.5rem;

    @media @tablet {
      width: 100%;
    }
  }

  h1 {
    .text-heading-1();
    color: @color-white;
    margin: 0;
  }

  &-small_text {
    .text-paragraph();
    color: @color-violet-200;
    .margin {
      margin-top: 6.25rem;
    }
  }

  &-text {
    .text-subheading-2();
    color: @color-violet-200;
  }

  &-main_image {
    margin: 4rem 0;
    max-width: 100%;
    @media @tablet {
      margin: 2rem auto;
    }
  }

  &-images_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4.625rem;

    @media @tablet-landscape {
      flex-direction: row;
      gap: 1.75rem;
    }
  }

  &-providers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
    margin-top: 1.5rem;

    @media @mobile {
      justify-content: flex-start;
      column-gap: 4rem;
    }
  }
}
