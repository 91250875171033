@desktop-large: ~"screen and (max-width: 1680px)";
@desktop-big: ~"screen and (max-width: 1440px)";
@desktop-small: ~"screen and (max-width: 1180px)";
@desktop: ~"screen and (min-width: 1025px)";
@tablet-landscape: ~"screen and (max-width: 1024px)";
@tablet: ~"screen and (max-width: 768px)";
@phone: ~"screen and (max-width: 480px)";

// Whole platform is designed in "desktop first" way. Main desktop breakpoint is at 1025px.
// Currently there are no breakpoints for widescreens, it might change in the future.

// General breakpoint that covers all mobile devices (doesn't matter whether it's a smartphone
// or a tablet etc.)
.bp-mobile(@rules, @bp: @tablet-landscape) {
  @media @bp {
    @rules();
  }
}

// General breakpoint that covers all desktop sizes. It shouldn't really be used, because styles by
// default are meant for desktop, but if it's really necessary, this mixin can be used.
.bp-desktop(@rules, @bp: @desktop) {
  @media @bp {
    @rules();
  }
}

.bp-phone(@rules, @bp: @phone) {
  @media @bp {
    @rules();
  }
}

.bp-desktop-small(@rules, @bp: @desktop-small) {
  @media @bp {
    @rules();
  }
}

.bp-desktop-big(@rules, @bp: @desktop-big) {
  @media @bp {
    @rules();
  }
}

.bp-desktop-large(@rules, @bp: @desktop-large) {
  @media @bp {
    @rules();
  }
}

.bp-tablet(@rules, @bp: @tablet) {
  @media @bp {
    @rules();
  }
}

.bp-tablet-landscape(@rules, @bp: @tablet-landscape) {
  @media @bp {
    @rules();
  }
}
