@import (reference) "../../../landingPage/styles/main";

.cookies-message {
  background-color: @color-violet-100;
  width: 100%;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: @box-shadow-reverse;
  pointer-events: initial !important;
  height: 100%;
  > div {
    color: @color-coolGray-900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    max-width: @layout-max-width;
    padding: @layout-padding;
    flex: 1;
    > div {
      white-space: nowrap;
      flex: 0;
      @media @mobile {
        white-space: normal;
      }
    }
    button {
      margin-left: 8px;
      border: none;
      padding: 0 12px;
      font-weight: 700;
      min-height: unset;
      height: 30px;
      .button-text();
      font-size: 14px;
      &:nth-child(1) {
        color: @color-primary-900;
      }
      &:nth-child(2),
      &:nth-child(3) {
        background-color: @color-violet-500;
        color: @color-white;
        &:hover {
          background-color: @color-violet-600;
        }
      }
      @media @mobile {
        margin-bottom: 10px;
      }
    }
    @media @tablet-landscape {
      flex-direction: column;
      text-align: center;
      gap: 16px;
    }
  }
}
