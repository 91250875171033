@import (reference) "../../../../styles/main";

.FilterStyles {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  position: relative;
  &__rightContent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__item {
    align-items: center;
    background: #fff;
    background-color: #fff;
    border: 3px solid transparent !important;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    line-height: 1.5;
    outline: none;
    padding: 1px 5px;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: #d2cee9;
      transition: 0.25s all ease-in-out;
    }

    &--active {
      background-color: #f3f4f6 !important;
      border: 3px solid #a5b3fa !important;

      &:hover {
        background-color: #f3f4f6;
      }
    }

    > p {
      color: #9ca3af;
      margin-right: 8px;
    }

    &__label {
      color: #17009a;
    }

    &__counter {
      align-self: end;
      background-color: #6f62be;
      border-radius: 10px;
      color: #e8e7f1;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 1px;
      padding-bottom: 1px;
      width: 20px;
    }

    &__container {
      position: relative;
    }

    &__error {
      color: @color-tertiary-200;
      background-color: @color-tertiary-600;
      padding: 0 8px;
      border-radius: 20px;
    }

    .Loader {
      max-width: 20px;
      max-height: 20px;
      svg {
        max-width: 20px;
        max-height: 20px;
      }
    }
  }

  &__menu {
    position: absolute;
    background-color: @color-white;
    top: 40px;
    right: 0;
    left: 0;
    width: max-content;
    z-index: 1;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1);

    &__right {
      right: 0px;
      left: unset;
    }

    &__search {
      padding: 16px;
      border-bottom: 1px solid #e5e7eb;

      .TextField {
        min-width: 300px;
      }
    }

    &__select-section {
      padding: 16px;
      p {
        margin-top: 20px;
        margin-bottom: 10px;
        &:first-of-type {
          margin-top: 0;
        }
      }
      select {
        width: 300px;
      }
    }

    &__list {
      padding: 8px 0 16px;
      max-height: 350px;
      overflow: auto;
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }

      &__item {
        display: flex;
        align-items: center;
        color: #374151;
        font-size: @text-size-16;
        line-height: 1.5;
        padding: 0 16px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #dddced;
        }
      }

      .RadioButton {
        width: 100%;
        height: 100%;

        &__label {
          width: 100%;
          height: 100%;
          min-height: 40px;
        }

        &__label::after {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .Checkbox {
        width: 100%;
        height: 100%;

        &__label {
          width: 100%;
          height: 100%;
          min-height: 40px;
        }
      }
    }
  }

  &__arrows {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > svg {
      stroke: #9b92d2;
      stroke-width: 3px;

      &:first-of-type {
        margin-bottom: -7px;
      }
    }
    &--noMargin {
      > svg {  
        &:first-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.ButtonText {
  color: #9ca3af;
}
