@import (reference) "../../styles/main";

.button-component {
  .button-text();
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.75rem;
  padding: 0 1.25rem;
  border: none;
  outline: none;
  color: @color-white;
  box-shadow: @box-shadow-default;
  border-radius: 0.5rem;
  background: @color-primary-900;
  cursor: pointer;
  transition: @transition-all;
  white-space: nowrap;
  &:hover {
    background: @color-primary-1000;
    box-shadow: @box-shadow-active;
    transition-duration: @transition-duration / 2;
  }

  &:active {
    background: @color-primary-1000;
  }

  &:focus {
    outline: 0.1975rem solid @color-indigo-300;
    outline: 0.1975rem solid @color-indigo-300;
  }

  img {
    margin-left: 0.75rem;
    filter: brightness(1) saturate(0) invert(1);
  }

  &-size-md {
    height: 3rem;
    padding: 0 1rem;
  }

  &-size-sm {
    height: 2.25rem;
    padding: 0 0.75rem;
  }
  &-inverted {
    background: @color-white;
    color: @color-primary-900;

    img {
      filter: none;
    }

    &:hover,
    &:active {
      color: @color-primary-1000;
      background: @color-coolGray-300;
    }
  }

  &-secondary {
    background: @color-teal-400;
    color: @color-primary-900;
    img {
      filter: none;
    }
    &:hover,
    &:active {
      color: @color-primary-1000;
      background: @color-teal-700;
    }
  }

  &-secondary-alt {
    background: @color-teal-100;
    color: @color-primary-900;
    box-shadow: none !important;
    img {
      filter: none;
    }
    &:hover,
    &:active {
      color: @color-primary-1000;
      background: @color-teal-400;
    }
  }

  &-spinner {
    position: absolute;
    right: 1.25rem;
    height: 1.875rem;
    width: 1.875rem;
    border-left: 0.25rem solid @color-teal-400;
    border-right: 0.25rem solid @color-teal-400;
    border-top: 0.25rem solid transparent;
    border-bottom: 0.25rem solid transparent;
    border-radius: 0.9375rem;
    animation: @animation-spin;
  }

  &-autoWidth {
    width: fit-content;
  }

  &:disabled,
  &-disabled {
    background-color: @color-coolGray-200!important;
    color: @color-coolGray-400!important;
    box-shadow: none !important;
    cursor: not-allowed;
    img {
      filter: grayscale(100%);
      opacity: 0.25;
    }
  }

  &-disabled {
    pointer-events: none;
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media @mobile {
    width: 100% !important;
  }
}
