@import (reference) "../../../../styles/main";

.EstimatedTransactions {
  background-color: #f0eff8;
  color: @color-primary-600;
  padding: 20px;
  border-radius: @border-radius-md;
  overflow: hidden;
  position: relative;
  &__loadingOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &__header {
    font-weight: 600;
    margin-bottom: 20px;
  }
  &__message {
    font-size: @text-size-14;
    margin-bottom: 20px;
  }
  &__descriptionInput {
    border: none;
    font-size: @text-size-14;
    &::placeholder {
      font-size: @text-size-14;
    }
    &__label {
      font-size: @text-size-14;
      color: @color-primary-600;
      font-weight: 400;
    }
  }
  &__transactionsSlider {
    padding: 10px 0;
    position: relative;
    &__value {
      position: absolute;
      bottom: -4px;
      left: -16px;
      width: 55px;
      display: flex;
      justify-content: center;
      color: @color-coolGray-900;
      & span {
        font-size: @text-size-14;
      }
      &--100 {
        left: calc(20% - 21px);
      }
      &--1000 {
        left: calc(40% - 24px);
      }
      &--10000 {
        left: calc(60% - 28px);
      }
      &--100000 {
        left: calc(80% - 31px);
      }
      &--1000000 {
        left: initial;
        right: -18px;
      }
    }
    &__label {
      font-size: @text-size-14;
      color: @color-primary-600;
      font-weight: 400;
    }
    &__input {
      background: transparent;
      margin-top: 20px;
      -webkit-appearance: none;
      width: 100%;
      &:focus {
        outline: none;
      }
      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        box-shadow: 0 0 0 #d4d3df;
        background: #d4d3df;
        border: 0 solid #d4d3df;
      }
      &::-webkit-slider-thumb {
        box-shadow: 0 0 1px #5140b3;
        border: 0 solid #5140b3;
        height: 20px;
        width: 20px;
        border-radius: 14px;
        background: #5140b3;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -9px;
        &::after {
          position: absolute;
          top: calc(50% - 0px);
          left: 4px;
          content: "asdfasdf";
        }
      }
      &:focus::-webkit-slider-runnable-track {
        background: #d4d3df;
      }
      &::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        box-shadow: 0 0 0 #d4d3df;
        background: #d4d3df;
        border-radius: 0;
        border: 0 solid #d4d3df;
      }
      &::-moz-range-thumb {
        box-shadow: 0 0 1px #5140b3;
        border: 0 solid #5140b3;
        height: 20px;
        width: 20px;
        border-radius: 14px;
        background: #5140b3;
        cursor: pointer;
      }
      &::-ms-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
      }
      &::-ms-fill-lower {
        background: #d4d3df;
        border: 0 solid #d4d3df;
        border-radius: 0;
        box-shadow: 0 0 0 #d4d3df;
      }
      &::-ms-fill-upper {
        background: #d4d3df;
        border: 0 solid #d4d3df;
        border-radius: 0;
        box-shadow: 0 0 0 #d4d3df;
      }
      &::-ms-thumb {
        box-shadow: 0 0 1px #5140b3;
        border: 0 solid #5140b3;
        height: 20px;
        width: 20px;
        border-radius: 14px;
        background: #5140b3;
        cursor: pointer;
      }
      &:focus::-ms-fill-lower {
        background: #d4d3df;
      }
      &:focus::-ms-fill-upper {
        background: #d4d3df;
      }
    }
  }
  &__errorWrapper {
    margin-top: 20px;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &__submitSuccess {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: @text-size-14;
      &__mainText {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        & > svg {
          width: 20px;
          height: 20px;
          color: green;
          margin-right: 2px;
        }
      }
    }
    & > button {
      padding: 0 15px;
      height: 30px;
      font-weight: 400;
      &:first-of-type {
        & > div {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          & > svg {
            width: 20px;
            height: 20px;
            color: #5b5b5b;
          }
        }
      }
    }
  }
}
