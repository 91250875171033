@import (reference) "../../styles/main";

.InviteUsersModal {
  &__modal {
    position: absolute;
    top: 110px;
    left: calc(50% - 270px);
    width: 540px;
    background-color: @color-white;
    padding: 40px;
    border: 1px solid #e5e5e5;
    box-shadow: @box-shadow-darker;
    border-radius: 8px;
    overflow: auto;
    .bp-tablet({
      top: 0;
      left: 0;
      min-height: 100vh;
      width: 100%;
      padding: 30px 20px;
    });
    &__header {
      font-weight: 700;
      font-size: @text-size-32;
      line-height: 40px;
      margin-bottom: 30px;
    }
    &__heading {
      font-weight: 600;
      font-size: @text-size-16;
      line-height: 1.4;
      margin-bottom: 10px;
    }
  }
  &__copy-field {
    position: relative;
    input[disabled] {
      cursor: text;
      color: #5b5b5b;
      background: rgba(#bab9c1, 0.25);
      border-color: #bab9c1;
    }
    button {
      position: absolute;
      bottom: 5px;
      right: 5px;
      height: 30px;
      width: 65px !important;
    }
  }
  &__or-divider {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    hr {
      border: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      width: 100%;
      margin: 0;
    }
    span {
      background-color: @color-white;
      padding: 0 20px;
      margin-top: -10px;
      z-index: 2;
      color: #5b5b5b;
    }
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 5;
  }
  &__form {
    flex: 1;
    input {
      margin-bottom: 20px;
    }
    textarea {
      margin-bottom: 20px;
      height: 110px;
      resize: none;
    }
    button {
      margin-top: 20px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding: 10px 15px;
      width: 100%;
      font-weight: 600;
      font-size: @text-size-16;
      line-height: 130%;
    }
    &__error {
      margin: 20px 0;
      width: 100%;
    }
    &__actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &__skip {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: auto;
      margin-top: 10px;
      background-color: transparent;
      color: #5b5b5b;
      font-weight: 400;
      border: none;
      box-shadow: none;
      &:hover {
        background-color: transparent;
      }
      &:active {
        background-color: transparent;
      }
      &:focus {
        background-color: transparent;
      }
    }
  }
}
