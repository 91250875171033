@import (reference) "../../../styles/main";

.webinar_player {
  position: relative;
  z-index: @layer-top-2;
  outline: none;
  object-position: 0 50%;
  margin-bottom: 4rem;
  width: 100%;

  @media @tablet {
    margin-bottom: 1.5rem;
  }

  &-title {
    width: 100%;
    .text-heading-2();
    max-width: @layout-text-max-width;

    @media @tablet {
      text-align: left;
      padding: 0 1.5rem;
    }
  }
  &-date {
    width: 100%;
    .text-subheading-2();

    @media @tablet {
      text-align: left;
      padding: 0 1.5rem;
    }
  }
}
