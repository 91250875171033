@import (reference) "../../../styles/main";

.DataUtilizationContainer {
  margin: 0 0 40px;
  &__header {
    padding: 15px;
    display: flex;
    border-bottom: 1px solid #E5E5E5;
    .bp-mobile({
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: auto 1fr;
    });
    &__title {
      display: flex;
      flex-wrap: wrap;
      font-size: @text-size-22;
      font-weight: 600;
      align-items: center;
      > p {
        margin-right: 10px;
      }
      > span {
        white-space: nowrap;
        font-size: @text-size-18;
        font-weight: 400;
        line-height: 1.2;
        color: #5B5B5B;
      }
    }
    &__info {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;

      div {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background-color: @color-black;
        color: @color-white;
        white-space: nowrap;
        font-size: 0.9rem;
        padding: 6px;
        border-radius: 10px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.5 ease;

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 100%;
          transform: translateX(-50%);
          border: 10px solid;
          border-color: #000 #0000 #0000 #0000;
        }
      }
      
      &:hover div {
        top: -110%;
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__subHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #E5E5E5;
    font-weight: 600;
    font-size: @text-size-14;
    height: 40px;
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 30px;
      height: 30px;
      margin-left: 15px;
      background-color: transparent;
      box-shadow: none;
      border-color: transparent;
      cursor: pointer;
      svg {
        transition: 0.25s all ease-in-out;
        width: 20px;
        height: 20px;
        margin: 0;
        stroke: #5b5b5b;
        transform: rotate(0deg);
      }
      &.collapsed {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__chartWrapper {
    padding: 0 15px;
  }
  &__tableWrapper {
    overflow: hidden;
    &__actions {
      padding: 15px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      button {
        padding: 0 15px;
        height: 30px;
        font-size: @text-size-14;
        font-weight: 400;
        svg {
          width: 16px;
          height: 16px;
          stroke-width: 2.5px;
        }
      }
    }
  }
  &__dummy {
    width: 100%;
    height: 300px;
    background-color: green;
  }
  .Pagination__container {
    padding: 10px 0 0 15px;
    &__navButton {
      width: 30px;
      height: 30px;
    }
  }
}
