@import (reference) "../../styles/main";

.partners_hero {
  .gradient-primary-900();
  padding: @hero-padding;
  margin-top: -@header-height;
  width: 100%;
  .section-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 6rem;
    margin: auto;

    h1 {
      .text-heading-1();
      color: @color-white;
      min-width: 28rem;
    }
    p {
      .text-subheading-1();
      color: @color-blue-200;
      margin-bottom: 4rem;
    }

    > img {
      width: 100%;
      padding-left: 4rem;
    }

    a:hover {
      z-index: @layer-top-1;
    }
  }
}
