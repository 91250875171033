@import (reference) "../../../styles/main";

.home_highlights {
  &-background {
    width: 100%;
    background-color: @color-blue-25;
    padding: @main-padding;
  }

  .section-layout {
    margin: auto;
  }

  h2 {
    .text-heading-2();
    margin-bottom: @text-subheading-2-margin-bottom;
  }

  &-text {
    line-height: 120%;
    font-size: 1.4rem;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    color: @color-violet-600;
    max-width: 56.25rem;
    text-align: center;
  }

  &-grid {
    .img-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 13.75rem;
      border-radius: @card-border-radius * 0.6667;
    }
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3rem;
    row-gap: 3rem;

    @media @tablet-landscape {
      grid-template-columns: 1fr 1fr;
    }

    @media @tablet {
      grid-template-columns: 1fr;
    }

    & > a > article {
      position: relative;
      padding-bottom: 6.25rem;
      height: 100%;
      border-radius: @card-border-radius;
      background-color: @color-white;
      border: 0.5rem solid @color-white;
      transition: @transition-all;

      &:hover {
        cursor: pointer;
        border-color: @color-indigo-100;
        box-shadow: @box-shadow-default;

        h3,
        p,
        span {
          color: @color-primary-900;
        }
      }


      .avatar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .open-icon {
        display: none;
      }

      h3 {
        .text-heading-3();
        margin-top: 0;
        margin-bottom: 0.75rem;
        padding: 1.75rem 1.75rem 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      p {
        .text-paragraph();
        margin-bottom: 0;
        color: @color-violet-600;
        padding: 0 1.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .author {
        position: absolute;
        bottom: 1.5rem;
        left: 1.75rem;
        display: flex;
        flex-direction: column;
        img {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 1rem;
        }
        div {
          margin-left: 3.125rem;
        }
        p {
          margin: 0 0 -0.5rem 0;
          padding: 0;
          color: @color-coolGray-900;
        }
        span {
          color: @color-violet-600;
        }
      }
    }
  }
}
