@import (reference) "../../../../styles/main";

.dsar {
  &-header {
    .text-heading-3();
    margin-top: 0;
  }

  &-label {
    .text-heading-4();
  }

  &-form_paragraph {
    .text-paragraph();
    color: @color-coolGray-600;
    font-weight: 600;
  }

  &-form_indent {
    border-left: 1rem solid @color-teal-100;
    padding: 1rem;
    background: @color-teal-50;
    margin-bottom: 1.5rem;
    .dsar-form_paragraph {
      font-weight: 400;
    }
  }
}
