@import (reference) "../../../styles/main";

.DataCatalogConnectors {
  background-color: @color-platformBackground;
  flex: 1;
  display: flex;
  flex-direction: column;

  &__block {
    margin-bottom: 40px;
  }

  &__source {
    margin: 32px 48px 0;
    display: flex;
    border-radius: 12px;
    padding: 24px 32px;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(241, 241, 247, 0.5) 0%, rgba(241, 241, 247, 0) 100%);
    gap: 16px;
    p {
      font-size: 0.875rem;
      line-height: 1.4;
      color: #4b5563;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
      }
      h2 {
        font-size: 16px;
        line-height: 24px;
        color: #6b7280;
      }
    }
  }

  &__table {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    margin: 24px 0;
    .bp-desktop-large({
      grid-template-columns: 1fr 1fr 1fr;
    });
    .bp-desktop-big({
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    });
    .bp-phone({
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    });
  }

  &__loading {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__error {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > a {
      margin-top: 20px;
    }
  }

  &__noData {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px 40px 40px;
    &__content {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      padding: 80px 20px;
      color: @color-primary-900;
      background-color: #f0eff9;
      border-radius: 8px;
      text-align: center;
      & > svg {
        width: 40px;
        height: 40px;
        margin-bottom: 20px;
        stroke-width: 1px;
      }
      h3 {
        font-weight: 600;
        font-size: @text-size-22;
        line-height: 30px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 15px;
        max-width: 760px;
      }
    }
    &__actions {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      gap: 15px;
    }
  }

  .Pagination__container {
    justify-content: center;
  }
}
