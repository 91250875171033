html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1.3;
}

strong {
  font-weight: bold;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-variant-numeric: tabular-nums;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
}

html {
  font-size: 16px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  color: #111827;
  background-color: #ffffff;
  box-sizing: border-box;
  position: relative;
}

#__next {
  width: 100%;
  height: 100%;
}

code {
  font-family: Monaco, Menlo, Consolas, Liberation Mono, Lucida Console, monospace;
}

textarea,
input[type="text"] {
  -webkit-appearance: none;
}

::-moz-focus-inner {
  border: 0 !important;
}

* {
  box-sizing: border-box !important;
}

a {
  font-weight: inherit;
  outline: 0;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.customFont {
  font-family: "Open Sans", Arial, sans-serif;
}

.osFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.ql-container {
  white-space: pre;
}

.bullet-list {
  list-style-type: initial;
  b {
    font-weight: bold;
    color: #0000bb;
  }
}

@import url(./404.less);
@import url(./fallback.less);

@import url("../landingPage/layouts/LayoutComponents/Banner.less");
@import url("../landingPage/layouts/LayoutComponents/Footer.less");
@import url("../landingPage/layouts/LayoutComponents/Header.less");
@import url("../landingPage/layouts/LayoutComponents/NavDesktop.less");
@import url("../landingPage/layouts/LayoutComponents/NavMobile.less");
@import url("../landingPage/views/components/Button.less");

@import url("../landingPage/views/components/ui/BottomCTA.less");
@import url("../landingPage/views/components/ui/Access.less");
@import url("../landingPage/layouts/LandingPageLayout.less");
@import url("../landingPage/views/Careers/Careers.less");
@import url("../landingPage/views/Careers/components/CareersOffers.less");

@import url("../landingPage/views/Forms/templates/DataFormTemplate.less");
@import url("../landingPage/views/Forms/templates/DiscoveryFormTemplate.less");
@import url("../landingPage/views/Forms/templates/components/CCPAForm.less");
@import url("../landingPage/views/Forms/templates/components/ContactForm.less");
@import url("../landingPage/views/Forms/templates/components/DSARForm.less");

@import url("../landingPage/views/Forms/templates/components/RetractableField.less");
@import url("../landingPage/views/Forms/templates/components/StandardField.less");
@import url("../landingPage/views/HomePage/components/GetStarted.less");
@import url("../landingPage/views/ADXPage/ADXDemystExchange.less");

@import url("../landingPage/views/IndustryPages/IndustryPages.less");

@import url("../landingPage/views/components/HighlightCard.less");
@import url("../landingPage/views/components/Slider.less");
@import url("../landingPage/views/components/form/Box.less");

@import url("../landingPage/views/components/form/Datepicker.less");
@import url("../landingPage/views/components/form/InfoBox.less");
@import url("../landingPage/views/components/form/Input.less");
@import url("../landingPage/views/components/form/MultipleChoice.less");
@import url("../landingPage/views/components/form/Radio.less");
@import url("../landingPage/views/components/form/Select.less");
@import url("../landingPage/views/components/form/SingleChoice.less");
@import url("../landingPage/views/components/form/TermsCheckbox.less");
@import url("../landingPage/views/components/form/Textarea.less");
@import url("../landingPage/views/components/modal/Modal.less");
@import url("../landingPage/views/components/sections/DataProviders.less");
@import url("../landingPage/views/components/sections/GetStartedNew.less");
@import url("../landingPage/views/components/sections/Highlights.less");
@import url("../landingPage/views/components/sections/Solutions.less");
@import url("../landingPage/views/components/sections/HomeHero.less");
@import url("../landingPage/views/components/ui/Benefit.less");
@import url("../landingPage/views/components/ui/NewsletterSection.less");
@import url("../landingPage/views/components/ui/Recipe.less");
@import url("../landingPage/views/ADXPage/components/ExchangePerks.less");
@import url("../landingPage/views/ADXPage/components/ExchangeQuote.less");
@import url("../landingPage/views/ADXPage/components/ADXExchangeForm.less");
@import url("../landingPage/views/ADXPage/components/ExchangeFAQ.less");

@import url("../landingPage/views/ESGReport/ESGReport.less");
@import url("../landingPage/views/Report2022/Report2022.less");
@import url("../landingPage/views/WebinarRegistration/WebinarRegistration.less");
@import url("../landingPage/views/WebinarRegistration/components/WebinarCard.less");

@import url("../landingPage/views/LandingTemplate/components/LandingMainContent.less");
@import url("../landingPage/views/LandingTemplate/components/LandingButtonGrid.less");
@import url("../landingPage/views/LandingTemplate/components/LandingConnectorCards.less");
@import url("../landingPage/views/LandingTemplate/components/LandingCtaBar.less");
@import url("../landingPage/views/LandingTemplate/components/LandingCtas.less");
@import url("../landingPage/views/LandingTemplate/components/LandingCustomers.less");
@import url("../landingPage/views/LandingTemplate/components/LandingGetStarted.less");
@import url("../landingPage/views/LandingTemplate/components/LandingHero.less");
@import url("../landingPage/views/LandingTemplate/components/LandingImageBar.less");
@import url("../landingPage/views/LandingTemplate/components/LandingSourceCarousel.less");
@import url("../landingPage/views/LandingTemplate/components/LandingTestimonials.less");
@import url("../landingPage/views/LandingTemplate/components/LandingTextImageContent.less");
@import url("../landingPage/views/Campaigns/Campaigns.less");

@import url("../landingPage/views/LegalPages/template/LegalTemplate.less");
@import url("../landingPage/views/Newsletter/Newsletter.less");
@import url("../landingPage/views/Newsletter/components/NewsletterForm.less");
@import url("../landingPage/views/Newsletter/components/NewsletterHero.less");
@import url("../landingPage/views/Newsletter/components/NewsletterHighlights.less");
@import url("../landingPage/views/Partners/Partners.less");
@import url("../landingPage/views/Partners/PartnersBenefits.less");
@import url("../landingPage/views/Partners/PartnersHero.less");
@import url("../landingPage/views/Platform/Platform.less");
@import url("../landingPage/views/Platform/components/OvercomeChallenges.less");
@import url("../landingPage/views/Platform/components/PlatformHelp.less");
@import url("../landingPage/views/Platform/components/PlatformCapabilities.less");
@import url("../landingPage/views/Platform/components/PlatformSolutions.less");
@import url("../landingPage/views/Platform/components/PlatformStrategy.less");
@import url("../landingPage/views/Platform/components/Security.less");
@import url("../landingPage/views/Usecases/ApplicationPreFill/ApplicationPreFill.less");
@import url("../landingPage/views/Usecases/BNPL/BNPL.less");
@import url("../landingPage/views/Usecases/BusinessDecisioning/BusinessDecisioning.less");
@import url("../landingPage/views/Usecases/components/DataPartners.less");
@import url("../landingPage/views/Usecases/components/Recipes.less");
@import url("../landingPage/views/Usecases/components/Resources.less");
@import url("../landingPage/views/Usecases/components/TitleSection.less");
@import url("../landingPage/views/Usecases/components/TitleSectionBenefits.less");
@import url("../landingPage/views/Usecases/CreditBureau/CreditBureau.less");
@import url("../landingPage/views/Usecases/DataEnrichment/DataEnrichment.less");
@import url("../landingPage/views/Usecases/KYB/KYB.less");
@import url("../landingPage/views/Usecases/KYC/KYC.less");
@import url("../landingPage/views/Usecases/LeadTargeting/LeadTargeting.less");
@import url("../landingPage/views/Usecases/PortfolioTracking/PortfolioMonitoring.less");
@import url("../landingPage/views/Webinars/Webinars.less");
@import url("../landingPage/views/Webinars/WebinarsVideo.less");
@import url("../landingPage/views/Webinars/components/TitleSection.less");
@import url("../landingPage/views/Webinars/components/WebinarsList.less");
@import url("../landingPage/views/Webinars/components/WebinarsPlayer.less");
@import url("../landingPage/views/Webinars/components/WebinarVideoForm.less");

/* Blog styles */
@import url("../landingPage/views/Blog/components/ShareMenu.less");
@import url("../landingPage/views/Blog/components/PostBody.less");
@import url("../landingPage/views/Blog/components/PostsList.less");
@import url("../landingPage/views/Blog/BlogMain.less");
@import url("../landingPage/views/Blog/components/FirstPost.less");

/* Platform */

@import url("../platform/styles/platformClasses.less");
@import url("../platform/components/buttons/SlackButton.less");
@import url("../platform/components/cookies/CookiesMessage.less");
@import url("../platform/components/cookies/CookiesMessage.less");
@import url("../platform/components/CodeBlock/CodeBlock.less");
@import url("../platform/components/AppErrorBoundary/AppErrorBoundary.less");
@import url("../platform/components/buttons/Button.less");
@import url("../platform/components/Loader/Loader.less");
@import url("../platform/components/MessageBox/MessageBox.less");
@import url("../platform/components/Paper/Paper.less");
@import url("../platform/components/formComponents/Checkbox.less");
@import url("../platform/components/formComponents/FormComponents.less");
@import url("../platform/components/formComponents/Switch.less");
@import url("../platform/components/formComponents/RadioButton.less");
@import url("../platform/components/formComponents/Select.less");
@import url("../platform/components/formComponents/TextField.less");
@import url("../platform/components/formComponents/TextArea.less");
@import url("../platform/layout/DevTools.less");
@import url("../platform/components/DataTable/DataTable.less");
@import url("../platform/components/DatePickers/DatePickers.less");
@import url("../platform/components/Modal/InviteUsersModal.less");
@import url("../platform/components/Modal/Modal.less");
@import url("../platform/components/Modal/PricingRequestModal.less");
@import url("../platform/components/Modal/StandardModalTemplate.less");
@import url("../platform/components/Modal/NewProjectModal.less");
@import url("../platform/components/Modal/NewConnectorModal.less");
@import url("../platform/components/Pagination/Pagination.less");
@import url("../platform/components/pricing/PricingTable.less");
@import url("../platform/components/Tooltip/Tooltip.less");
@import url("../platform/components/WorkInProgress/WorkInProgress.less");
@import url("../platform/layout/AppHeader.less");
@import url("../platform/layout/ContactModal.less");
@import url("../platform/layout/FreeUserBar.less");
@import url("../platform/layout/PlatformLayout.less");
@import url("../platform/layout/ReactToastifyClasses.less");
@import url("../platform/layout/SearchBar.less");
@import url("../platform/layout/UnverifiedButton.less");
@import url("../platform/layout/UnverifiedDrawer.less");
@import url("../platform/layout/UserMenu.less");
@import url("../platform/layout/Navigation/MainNav.less");
@import url("../platform/layout/Navigation/Navigation.less");
@import url("../platform/layout/Navigation/subNavs/SubNav.less");
@import url("../platform/views/Auth/Login.less");
@import url("../platform/views/Auth/ForgotPassword.less");
@import url("../platform/views/Auth/ResetPassword.less");
@import url("../platform/views/Auth/SignUp.less");
@import url("../platform/views/Auth/ToSAcceptancePage.less");
@import url("../platform/views/Auth/components/AuthPageFooter.less");
@import url("../platform/views/Auth/components/AuthPageHeader.less");
@import url("../platform/views/Auth/components/AuthPagePaper.less");
@import url("../platform/views/Auth/components/PasswordValidator.less");
@import url("../platform/views/Auth/components/ThirdPartyBox.less");
@import url("../platform/views/Auth/components/UserUnlockedBox.less");
@import url("../platform/views/Billing/BillingPage.less");
@import url("../platform/views/Billing/components/BillingHeader.less");
@import url("../platform/views/Billing/components/BillingMetricContainer.less");
@import url("../platform/views/Billing/components/ReportSwitcher.less");
@import url("../platform/views/Billing/components/Reports/BillingReportsDashboard.less");
@import url("../platform/views/Billing/components/Reports/charts/UsageChart.less");
@import url("../platform/views/Billing/components/Reports/tables/CreditHistoryTable.less");
@import url("../platform/views/Billing/components/Reports/tables/InvoiceTable.less");
@import url("../platform/views/DataUtilization/DataUtilization.less");
@import url("../platform/views/ReportsMonitor/ReportsMonitor.less");
@import url("../platform/views/DataUtilization/components/charts/UtilizationChart.less");
@import url("../platform/views/DataUtilization/components/tables/DataUtilizationTable.less");
@import url("../platform/views/DataUtilization/components/DataUtilizationContainer.less");
@import url("../platform/views/DataUtilization/components/DataUtilizationHeader.less");
@import url("../platform/views/DataCatalog/DataCatalogPage.less");
@import url("../platform/views/DataShare/DataShare.less");
@import url("../platform/views/Documentation/DemystAi.less");
@import url("../platform/views/DataCatalog/CatalogPages/DataCatalogConnectors.less");
@import url("../platform/views/DataCatalog/CatalogPages/DataCatalogHome.less");
@import url("../platform/views/DataCatalog/CatalogPages/DataCatalogRecipes.less");
@import url("../platform/views/DataCatalog/components/DataCatalogItem.less");
@import url("../platform/views/DataCatalog/components/BadgeFilter.less");
@import url("../platform/views/DataCatalog/components/LockTag.less");
@import url("../platform/views/DataCatalog/components/dataPages/ApplicableRegulationsTags.less");
@import url("../platform/views/DataCatalog/components/dataPages/CloneAPIModal.less");
@import url("../platform/views/DataCatalog/components/dataPages/DataPageAbout.less");
@import url("../platform/views/DataCatalog/components/dataPages/DataPageDetails.less");
@import url("../platform/views/DataCatalog/components/dataPages/DataPageHeader.less");
@import url("../platform/views/DataCatalog/components/dataPages/DataPageNav.less");
@import url("../platform/views/DataCatalog/components/filters/CatalogFilters.less");
@import url("../platform/views/DataCatalog/components/filters/FilterStyles.less");
@import url("../platform/views/DataCatalog/components/pagination/PaginationOverride.less");
@import url("../platform/views/DataCatalog/components/tables/DataPageTables.less");
@import url("../platform/views/DataCatalog/components/tables/DataPageOutputs.less");
@import url("../platform/views/DataCatalog/ConnectorPage/ProductPage.less");
@import url("../platform/views/DataCatalog/components/dataPages/DataPageLayout.less");
@import url("../platform/views/DataCatalog/PublicComponents/PublicConfiguration.less");
@import url("../platform/views/DataCatalog/PublicComponents/PublicMask.less");
@import url("../platform/views/DataCatalog/PublicComponents/PublicSignupBox.less");
@import url("../platform/views/DataCatalog/PublicComponents/PublicTable.less");
@import url("../platform/views/DataCatalog/RecipePage/ApiTemplatePage.less");
@import url("../platform/views/FileTransfers/FileTransfers.less");
@import url("../platform/views/FileTransfers/components/DemystFilesTable.less");
@import url("../platform/views/FileTransfers/components/FilesTable.less");
@import url("../platform/views/FileTransfers/components/FileUpload.less");
@import url("../platform/views/FileTransfers/components/FileUploadGPG.less");
@import url("../platform/views/FileTransfers/components/FileUploadInput.less");
@import url("../platform/views/FileTransfers/components/FileUploadProcess.less");
@import url("../platform/views/FileTransfers/components/OrganizationSelect.less");
@import url("../platform/views/Settings/ApiKeys.less");
@import url("../platform/views/Home/Home.less");
@import url("../platform/views/Projects/ProjectsPage.less");
@import url("../platform/views/Projects/ProjectsAll.less");
@import url("../platform/views/Projects/ProjectPageTemplate.less");
@import url("../platform/views/Projects/components/ProjectComment.less");
@import url("../platform/views/Projects/components/ProjectHeaderSection.less");
@import url("../platform/views/Projects/components/ProjectCommentsSection.less");
@import url("../platform/views/Projects/components/ProjectDeleteSection.less");
@import url("../platform/views/InternalData/ConnectorsPage.less");
@import url("../platform/views/InternalData/ConnectorsAll.less");
@import url("../platform/views/InternalData/InternalProductPage.less");
@import url("../platform/views/InternalData/InternalProductHeader.less");
@import url("../platform/views/InternalData/InternalProductAbout.less");
@import url("../platform/views/InternalData/InternalProductOnboarding.less");
@import url("../platform/views/InternalData/InternalNotes.less");
@import url("../platform/views/Settings/IpWhitelist.less");
@import url("../platform/views/Settings/ConfigurationsSettings.less");
@import url("../platform/views/Settings/CookiesPreferences.less");
@import url("../platform/views/Settings/BringYourOwnKeyCredentialSettings.less");
@import url("../platform/views/Settings/CredentialSettings.less");
@import url("../platform/views/Settings/GeneralPageSettings.less");
@import url("../platform/views/Settings/GeneralSettings.less");
@import url("../platform/views/Settings/ProfileSettings.less");
@import url("../platform/views/Settings/SettingsPage.less");
@import url("../platform/views/Settings/SingleSignOnSettings.less");
@import url("../platform/views/Settings/TwoFactorAuthSettings.less");
@import url("../platform/views/Settings/UsersSettings.less");
@import url("../platform/views/Settings/components/BringYourOwnKeyCredentialList.less");
@import url("../platform/views/Settings/components/CredentialList.less");
@import url("../platform/views/Settings/components/DeactivateUserModal.less");
@import url("../platform/views/Settings/components/GenerateApiKeyPopup.less");
@import url("../platform/views/Settings/components/GenerateCredentialPopup.less");
@import url("../platform/views/Settings/components/GeneratedApiKeyModal.less");
@import url("../platform/views/Settings/components/InviteUserMenu.less");
@import url("../platform/views/Settings/components/RemoveApiKeyModal.less");
@import url("../platform/views/Settings/components/RemoveCredentialModal.less");
@import url("../platform/views/Settings/components/SettingsFormRow.less");
@import url("../platform/views/Settings/components/SettingsList.less");
@import url("../platform/views/Settings/components/SettingsPageHeader.less");
@import url("../platform/views/Settings/components/UsersTable.less");
@import url("../platform/views/Settings/components/WebhooksTable.less");
@import url("../platform/views/UseCases/CreateDataAPI.less");
@import url("../landingPage/views/Enterprise/Enterprise.less");
@import url("../platform/views/UseCases/CreateDataCatalog/CreateAddConnectorModal.less");
@import url("../platform/views/UseCases/CreateDataCatalog/ConnectorCard.less");
@import url("../platform/views/UseCases/CreateDataCatalog/AddConnectorCard.less");
@import url("../platform/views/UseCases/UseCaseErrorsPage.less");
@import url("../platform/views/UseCases/UseCaseOverviewPage.less");
@import url("../platform/views/UseCases/UseCasePage.less");
@import url("../platform/views/UseCases/UseCasesDashboard.less");
@import url("../platform/views/UseCases/UseCaseTestsPage.less");
@import url("../platform/views/UseCases/UseCaseBatchesPage.less");
@import url("../platform/views/UseCases/components/Batches/UseCasesRunBatch.less");
@import url("../platform/views/UseCases/components/Batches/UseCasesRunBatchFileInput.less");
@import url("../platform/views/UseCases/components/Batches/UseCasesBatchInputsTable.less");
@import url("../platform/views/UseCases/components/Batches/UseCasesBatchInputsGuide.less");
@import url("../platform/views/UseCases/components/Batches/UseCasesBatchHistoryTable.less");
@import url("../platform/views/UseCases/components/Batches/UseCasesBatchApprovalTable.less");
@import url("../platform/views/UseCases/components/Batches/UseCasesBatchDraftTable.less");
@import url("../platform/views/UseCases/components/Batches/UseCasesBatchActiveTable.less");
@import url("../platform/views/UseCases/components/Batches/UseCasesBatchDataTable.less");
@import url("../platform/views/UseCases/components/Batches/HomeActivityTable.less");
@import url("../platform/views/UseCases/components/Batches/UseCasesBatchSelect.less");
@import url("../platform/views/UseCases/components/Batches/Modals/UseCasesBatchModal.less");
@import url("../platform/views/UseCases/components/ActiveResponseTimeTypeSwitch.less");
@import url("../platform/views/UseCases/components/MetricContainer.less");
@import url("../platform/views/UseCases/components/UseCasePageHeader.less");
@import url("../platform/views/UseCases/components/Dashboard/FilterStylesDashboard.less");
@import url("../platform/views/UseCases/components/Dashboard/UseCasesInfoItem.less");
@import url("../platform/views/UseCases/components/Dashboard/UseCasesListItem.less");
@import url("../platform/views/UseCases/components/ErrorsPage/ContactForm.less");
@import url("../platform/views/UseCases/components/ErrorsPage/ErrorDetails.less");
@import url("../platform/views/UseCases/components/ErrorsPage/ErrorsByCategory.less");
@import url("../platform/views/UseCases/components/ErrorsPage/ErrorsByDate.less");
@import url("../platform/views/UseCases/components/ErrorsPage/ErrorsDisplayController.less");
@import url("../platform/views/UseCases/components/ErrorsPage/ErrorsGetStarted.less");
@import url("../platform/views/UseCases/components/ErrorsPage/ErrorsPageDisplayTypeSwitcher.less");
@import url("../platform/views/UseCases/components/Overview/OverviewChart.less");
@import url("../platform/views/UseCases/components/Overview/OverviewSparkline.less");
@import url("../platform/views/UseCases/components/Overview/OverviewQuickStart.less");
@import url("../platform/views/UseCases/components/Overview/OverviewRemovePopup.less");
@import url("../platform/views/UseCases/components/Overview/OverviewAddConnector.less");
@import url("../platform/views/UseCases/components/Overview/tables/UseCaseTables.less");
@import url("../platform/views/UseCases/components/Reports/ErrorsSection.less");
@import url("../platform/views/UseCases/components/Reports/MatchRatesSection.less");
@import url("../platform/views/UseCases/components/Reports/ReportsDashboard.less");
@import url("../platform/views/UseCases/components/Reports/ReportsDashboardHeader.less");
@import url("../platform/views/UseCases/components/Reports/ResponseTimeSection.less");
@import url("../platform/views/UseCases/components/Reports/TransactionsSection.less");
@import url("../platform/views/UseCases/components/MetricChart.less");
@import url("../platform/views/UseCases/components/Reports/charts/Charts.less");
@import url("../platform/views/UseCases/components/Reports/tables/Tables.less");
@import url("../platform/views/UseCases/components/Settings/CloneUseCase.less");
@import url("../platform/views/UseCases/components/Settings/DeleteUseCase.less");
@import url("../platform/views/UseCases/components/Settings/EstimatedTransactions.less");
@import url("../platform/views/UseCases/components/Settings/RemoveCredentialModal.less");
@import url("../platform/views/UseCases/components/Settings/UseCaseConfiguration.less");
@import url("../platform/views/UseCases/components/Settings/UseCaseCredentials.less");
@import url("../platform/views/UseCases/components/Settings/UseCaseCredentialsList.less");
@import url("../platform/views/UseCases/components/Settings/UseCaseCredentialsPopup.less");
@import url("../platform/views/UseCases/components/Settings/UseCaseGeneralSettings.less");
@import url("../platform/views/UseCases/components/Settings/UseCasePricing.less");
@import url("../platform/views/UseCases/components/TestsPage/ConfigurationEditor.less");
@import url("../platform/views/UseCases/components/TestsPage/InputsForm.less");
@import url("../platform/views/UseCases/components/TestsPage/InputsGuide.less");
@import url("../platform/views/UseCases/components/TestsPage/ResultsJSONView.less");
@import url("../platform/views/UseCases/components/TestsPage/ResultsTableView.less");
@import url("../platform/views/UseCases/components/TestsPage/TransactionPanel.less");
@import url("../platform/views/UseCases/UseCasesCatalogs/UseCasesStyles.less");
@import url("../platform/views/Settings/VerificationChecklist.less");
@import url("../platform/views/Settings/components/Agreements.less");
@import url("../platform/views/Settings/BillingInfo.less");
@import url("../platform/views/Settings/components/ServiceAgreement.less");
@import url("../platform/views/Settings/components/WeWillBeInTouch.less");
@import url("../platform/views/Settings/components/InternalApproval.less");
@import url("../platform/views/CreateSchema/CreateSchema.less");
@import url("../platform/views/SearchResults/Results.less");
@import url("../platform/views/DataCatalog/SourcePage/Sources.less");
@import url("../platform/components/ProjectCard/ProjectCard.less");
@import url("../platform/components/InternalConnectorCard/InternalConnectorCard.less");
@import url("../../pages/powering/details/details.less");
