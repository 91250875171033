@import (reference) "../../../styles/main";

.section-layout {
  .LandingTextImageContent {
    &-col {
      max-width: calc(50% - 3rem);

      @media @tablet-landscape {
        max-width: unset;
      }
      &-text {
        width: 100%;
        &-position {
          &-right {
            order: 2;

            @media @tablet-landscape {
              order: -1;
            }
          }
          &-left {
            order: -1;
          }
          &-center {
            order: 2;
            @media @tablet-landscape {
              order: -1;
            }
          }
        }
      }

      img {
        border-radius: @card-border-radius;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      .LandingCtas {
        margin-left: auto;
        margin-right: auto;
        max-width: @layout-text-max-width;
      }
    }

    &-customUl {
      background: @color-blue-25;
      border-radius: @card-border-radius;
      padding: @card-medium-padding;
      ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        li {
          display: flex;
          align-items: center;
          padding-left: 2.5rem;
          position: relative;
          &:before {
            position: absolute;
            left: 0;
            top: 0.25rem;
            height: 1rem;
            width: 1rem;
            content: "";
            border-radius: 50%;
            background-color: @color-blue-200;
            color: @color-primary-900;
            text-align: center;
            font-size: @text-paragraph-font-size;
            font-weight: 600;
            line-height: 1.5rem;
          }
          &:after {
            content: "";
            border-radius: 50%;
            position: absolute;
            height: 0.5rem;
            width: 0.5rem;
            left: 0.25rem;
            top: 0.5rem;
            background-color: @color-blue-300;
          }

          p,
          h3.as-paragraph {
            .text-paragraph();
            margin: 0 !important;
          }
        }
      }

      .LandingMainContent-background-purple & {
        background: @color-violet-900;

        ul {
          li {
            &:before {
              background-color: @color-violet-600;
            }
            &:after {
              background-color: @color-violet-100;
            }
          }
        }
      }
    }

    &-customUl2 {
      margin-top: 3rem;
      li {
        display: flex;
        margin-bottom: 3rem;
        align-items: flex-start;
        img {
          width: 3rem;
          height: 3rem;
          margin-right: 1rem;
        }
        div > h3 {
          .text-heading-3();
          text-align: left;
          margin-top: 0.5rem;

          + p {
            .text-paragraph();
            text-align: left;
            margin-bottom: 0;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-dangerous-content {
      text-align: inherit;
      max-width: @layout-text-max-width;
      margin: auto;
      p {
        line-height: 1.875rem;
        font-size: 1.2rem;
      }
      ol,
      ul {
        line-height: 1.875rem;
        font-size: 1.2rem;
        margin: 0 3rem 1.5rem;
        color: @color-violet-200;
        font-family: @font-family-body;
        @media @tablet-landscape {
          margin-left: 1.5rem;
          margin-right: 1.5rem;
        }
        @media @tablet {
          margin-left: 0.75rem;
          margin-right: 0.75rem;
        }
        @media @mobile {
          margin-left: 0;
          margin-right: 0;
        }
        li {
          padding-left: 3rem;
          position: relative;
          counter-increment: section;
          text-align: left;
          &:before {
            position: absolute;
            left: 0;
            top: 0.25rem;
            height: 1.5rem;
            width: 1.5rem;
            content: counter(section);
            border-radius: 50%;
            background-color: rgba(@color-teal-100, 0.75);
            color: @color-primary-900;
            text-align: center;
            font-size: @text-paragraph-font-size;
            font-weight: 600;
            line-height: 1.5rem;
          }

          p {
            text-align: left !important;
            line-height: 1.875rem;
            font-size: 1.2rem;
          }
        }
      }
      ul {
        li {
          &:before {
            content: "";
          }
          &:after {
            content: "";
            border-radius: 50%;
            position: absolute;
            height: 0.75rem;
            width: 0.75rem;
            left: 0.375rem;
            top: 0.625rem;
            background-color: rgba(@color-teal-300, 0.5);
          }
        }
      }
    }
  }
}

.LandingMainContent-background-gray-2 .LandingTextImageContent-customUl {
  background-color: @color-white;
}
