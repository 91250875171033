@import (reference) "../../../../styles/main";

.DeleteUseCase {
  &__header {
    font-size: @text-size-22;
    font-weight: 600;
    margin-bottom: 24px;
  }
  &__message {
    font-size: @text-size-14;
    color: #6B7280;
    margin-bottom: 24px;
  }
  &__openDeleteUseCaseModalButton {
    background-color: #D91C5C;
    border: none;
    font-size: @text-size-16;
    font-weight: 400;
    &:hover {
      background-color: #B7184E;
      border-color: transparent;
    }
    &:focus {
      background-color: #B7184E;
      border-color: transparent;
    }
    &:active {
      background-color: #B7184E;
      border-color: transparent;
    }
    svg {
      height: 16px;
      width: 16px;
      stroke-width: 2.5;
    }
  }
  &__errorMessageWrapper {
    margin-bottom: 20px;
    width: 100%;
  }
}

.DeleteUseCaseModal {
  overflow: hidden;
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__paper {
    width: 100%;
    height: 100%;
    max-width: 960px;
    max-height: calc(100% - 120px);
    padding: 0;
    display: flex;
    flex-direction: column;
    .bp-mobile({
      max-width: initial;
      max-height: initial;
      border-radius: 0;
    });
    &__header {
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__content {
      padding: 0 100px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      display: flex;
      flex-direction: column;
      .bp-mobile({
        padding: 0 40px;
      });
      &__header {
        font-weight: 700;
        font-size: @text-size-32;
        margin-bottom: 20px;
      }
      &__message {
        margin-bottom: 40px;
      }
      &__deleteButton {
        margin-bottom: 20px;
        background-color: #D91C5C;
        border: none;
        &:hover {
          background-color: #B7184E;
          border-color: transparent;
        }
        &:focus {
          background-color: #B7184E;
          border-color: transparent;
        }
        &:active {
          background-color: #B7184E;
          border-color: transparent;
        }
      }
    }
  }
}
