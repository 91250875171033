@import (reference) "../../../styles/main";

.LandingCtaBar {
  .card-large-Layout();
  background-color: @color-violet-900;
  border-color: @color-violet-900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5rem;

  > h3 {
    margin: 0 !important;
    color: @color-white !important;
    text-align: left !important;
  }
  > .LandingCtas {
    margin: 0 !important;
    flex-direction: column;
    align-items: stretch;
    .LandingCtas-button {
      margin: 0 !important;
      width: 100%;
    }
  }

  &-align {
    &-left {
      flex-direction: row-reverse;
    }

    &-center {
      flex-direction: column;
      gap: 2.5rem;

      > h3 {
        text-align: center !important;
      }
    }
  }

  @media @tablet-landscape {
    padding: 2.5rem;
  }
  @media @tablet {
    flex-direction: column;
  }
}
