.Billing {

    &__error {
        margin-bottom: 24px;
        max-width: 540px;
    }

    &__header {
        & > h3 {
            font-size: 18px;
            color: var(--gray-900);
            font-weight: 600;
            margin-bottom: 24px;
            margin-top: 24px;
        }
    }

    &__subheader {
        & > div {
            font-size: 14px;
            color: #4B5563;
            font-weight: 600;
            margin-bottom: 12px;
        }
    }

    &__inputFields {
        margin-top: 24px;
        margin-bottom: 24px;
        max-width: 600px;

        @media @mobile {
            width: 100%;
          }

        &__wrapper {
        & > div {
            max-width: 100%;
        }
        }

        & > div {
            margin: 16px 0px;
            max-width: 100%;
        }

        &__label {
            margin: 0;
            padding: 0;
            color: var(--coolGray-600);
            font-weight: 600;
            margin-bottom: 8px;
        }

        &__stateProvince {
            display: flex;
            flex-direction: row;
            gap: 8px;

            @media @mobile {
                flex-direction: column;
              }

            & > :first-child {
                width: 50%;
                @media @mobile {
                    width: 100%;
                  }
            }

            & > :nth-child(2) {
                max-width: 100%;
                width: 50%;
                @media @mobile {
                    width: 100%;
                  }
            }

            &__label {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }
    }
}
