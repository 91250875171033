@import (reference) "../../styles/main";

.UseCaseBatchesPage {
  &__infobox {
    position: fixed;
    transform: translateX(calc(130px - 50%));
    top: 100px;
    left: 50%;
    color: #F2E8EE;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
    padding: 16px 20px;
    background-color: #D91C5C;
    border-radius: 12px;
    z-index: 1;
    &--error {
      background-color: #D91C5C;
    }
    &--success {
      background-color: #4F9E90;
    }
    .bp-mobile({
      transform: translateX(-50%);
    });
  }
  &__header {
    margin-bottom: 15px;
    &__main {
      margin-bottom: 24px;
    }
    &__title {
      font-weight: 600;
      font-size: @text-size-22;
      line-height: 28px;
    }
    &__subtitle {
      color: #6B7280;
      font-weight: 400;
      font-size: @text-size-14;
      line-height: 20px;
      margin-bottom: 15px;
    }
  }
  &__blackfinJWTFetching {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__blackfinJWTFetchingError {
    width: 100%;
  }
}
