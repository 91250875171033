@import (reference) "../../../styles/main";

.usecases_section_benefits {
  .hero-backgroundLayout();
  background-color: @color-blue-900;

  &-container {
    margin: auto;
  }

  &-text_container {
    max-width: 64rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-top: 3.125rem;
    }
    a {
      margin: 3.125rem 0;
      width: fit-content;
    }
  }
  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3rem;
    row-gap: 3rem;
    margin-top: 2.5rem;
  }
  h1 {
    z-index: @layer-top-1;
    .text-heading-1();
    color: @color-white;
    text-align: center;
    @media @tablet-landscape {
      text-align: left;
    }
    .secondary {
      color: @color-teal-100;
    }
  }
  &-description {
    z-index: @layer-top-1;
    .text-subheading-2();
    text-align: center;
    color: @color-primary-100;
    @media @tablet-landscape {
      text-align: left;
    }
  }
}
