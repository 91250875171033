@import (reference) "../../../styles/main";

.PublicMask {
  position: absolute;
  max-width: 710px;
  width: 80%;
  min-height: 36px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 12px;
  background-color: #10006C;
  font-size: @text-size-16;
  font-weight: 400;
  color: @color-white;
  padding: 20px 0;

  &__buttons {
    display: flex;
    margin-top: 20px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 8px;
      border-radius: 4px;
      height: 32px;
      font-size: @text-size-16;
      border: none;
      outline: none;

      &:first-child {
        background-color: #D91C5C;
        margin-right: 8px;
        color: @color-white;
      }

      &:last-child {
        background-color: @color-white;
        color: #17009A;
      }
    }
  }
}
