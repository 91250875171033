@import (reference) "../../styles/main";

.UsersSettings {
  &__header {
    flex: 1;
    display: inline-flex;
    justify-content: flex-end;
    position: relative;
    .bp-mobile({
      position: initial;
    });
  }
  &__table {
    border-radius: 8px;
    border: 1px solid #eaeaf0;
    background-color: @color-white;
    padding: 30px 40px;
    overflow-x: auto;
    padding: 0;
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF99;
    padding-top: 100px;
  }
}
