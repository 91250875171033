@import (reference) "../../../../styles/main";

.ErrorsByCategory {
  padding: 30px 40px;
  .bp-mobile({
    padding: 20px 20px;
  });
  &__header {
    &__description {
      margin-top: 30px;
      & > span {
        color: #5b5b5b;
      }
      & > p {
        margin-top: 5px;
        & strong {
          font-weight: 600;
        }
        & a {
          color: @color-primary-900;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &__errorsCount {
      margin-top: 30px;
      color: #5b5b5b;
    }
    &__selectWrapper {
      display: inline-flex;
      position: relative;
    }
    &__colorSquare {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 10px;
      top: 13px;
    }
    &__select {
      &__input {
        padding-left: 40px;
      }
      //appearance: none;
      //padding: 6px 24px 6px 15px;
      //background-color: transparent;
      //background-position: right 10px top 50%, 0 0;
      //color: #17009a;
      //cursor: pointer;
      //margin-right: 12px;
      //font-weight: 600;
      //font-size: 1.125rem;
      //transition: 0.25s background-color;
      //border: 2px solid #B9B2E5;

      //&:hover {
      //  background-color: #b9b2e580;
      //}
      //
      //&.FormComponent__input:focus, &.FormComponent__input:active {
      //  border-color: #B9B2E5;
      //  background-color: #b9b2e580;
      //}
    }
  }
  &__errorsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
}
