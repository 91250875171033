@import (reference) "../../../../styles/main";

.TransactionPanel {
  //display: grid;
  //grid-template-rows: min-content min-content min-content;
  display: flex;
  flex-direction: column;
  font-size: @text-size-14;
  position: relative;
  &__header {
    position: sticky;
    top: 0;
    background-color: @color-white;
    padding: 15px 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top-left-radius: @border-radius-md;
    border-top-right-radius: @border-radius-md;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    z-index: 9;
    @media @mobile {
      flex-direction: column;
    }
    &__requestInfo {
      display: flex;
      align-items: center;
      overflow: hidden;
      &__method {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding: 4px 12px;
        background-color: #e8e7f1;
        border-radius: 32px;
        margin-right: 10px;
        color: #594ab6;
      }
      &__url {
        color: #4b5563;
        font-size: @text-size-14;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      @media @mobile {
        width: 100%;
        justify-content: space-between;

        & > span {
          font-size: 12px;
        }
      }
    }
    &__actions {
      display: flex;
      align-items: center;
      white-space: nowrap;
      @media @mobile {
        width: 100%;
        justify-content: space-between;
      }
      &__message {
        line-height: 20px;
        margin-right: 20px;
        color: #5b5b5b;
        text-align: right;
      }
      &__sample {
        margin-right: 28px;
        color: #4b5563;
        font-weight: 600;
      }
      .Button svg {
        margin-left: 8px;
        margin-right: 0;
        width: 16px;
        height: 16px;
        stroke-width: 3px;
      }
    }
  }
  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    display: flex;
    padding-top: 100px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 10;
    &__message {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: @text-size-18;
    }
  }
}

.PricingMessage {
  margin-right: 20px;
  display: flex;
  padding: 4px 12px;
  height: 32px;
  border-radius: 20px;
  background-color: #4f9e90;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  p {
    color: #bbfaee;
    font-size: @text-size-16;
    font-weight: 600;
  }
}

.ConfigurationSection {
  width: 100%;
  background-color: @color-white;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  &__warning {
    background-color: #fff7ed;
    padding: 8px 16px;
    p,
    a {
      color: #ea580c;
      font-size: @text-size-14;
    }

    a {
      text-decoration: underline;
    }
  }
  &__header {
    height: 40px;
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-position 0.5s ease, box-shadow 1s ease;
    &:hover {
      cursor: pointer;
    }
    &__title {
      font-size: @text-size-16;
      font-weight: 600;
    }
    &__actions {
      &__toggleSection {
        padding: 0;
        width: 30px;
        height: 30px;
        margin-left: 15px;
        background-color: transparent;
        box-shadow: none;
        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
        }
        &--closed {
          & > svg {
            transform: rotateZ(-180deg);
          }
        }
        & > svg {
          transition: @transition-all;
          width: 20px;
          height: 20px;
          margin: 0;
          stroke: #bab9c1;
        }
        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px;
        }
      }
    }
  }
  &__configurationFetchErrorWrapper {
    padding: 10px 15px;
  }
  &__contentWrapper {
    overflow: hidden;
    position: relative;
  }
  &__actions {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
    display: flex;
    align-items: center;
    @media @mobile {
      position: unset;
      padding: 8px 16px;
    }
    &__cancelEditingConfiguration {
      font-size: @text-size-16;
      margin-right: 10px;
      height: 30px;
    }
    &__saveConfigurationChanges {
      font-size: @text-size-16;
      height: 30px;
      svg {
        width: 16px;
        height: 16px;
        stroke-width: 2.5;
      }
    }
    &__editConfiguration {
      font-size: @text-size-16;
      height: 30px;
      svg {
        width: 16px;
        height: 16px;
        stroke: #9b92d2;
        stroke-width: 3px;
      }
    }
    p {
      font-size: @text-size-14;
      color: @color-coolGray-600;
    }
  }
}

.InputsSection {
  width: 100%;
  background-color: @color-white;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  &__header {
    height: 40px;
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: @transition-all;

    &:hover {
      cursor: pointer;
    }

    &__title {
      font-size: @text-size-16;
      font-weight: 600;
    }
    &__actions {
      display: flex;
      align-items: center;
      &__toggleSection {
        padding: 0;
        width: 30px;
        height: 30px;
        margin-left: 15px;
        &--closed {
          & > svg {
            transform: rotateZ(180deg);
          }
        }
        & > svg {
          width: 20px;
          height: 20px;
          margin: 0;
        }
        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px;
        }
      }
    }
  }
  &__contentWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 500px;
    transition-duration: 0.4s;
    @media @phone {
      grid-template-columns: 1fr;
      height: fit-content;
    }
    overflow: hidden;
    &.closed {
      height: 0;
    }
    &__inputsFormWrapper,
    &__inputsGuideWrapper {
      flex: 1;
      overflow-y: auto;
    }
    &__inputsFormWrapper {
      border-right: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
  &__noInputsMessageWrapper {
    padding: 10px 15px;
  }
}

.ResultsSection {
  width: 100%;
  background-color: @color-white;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  &__header {
    height: 40px;
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--empty {
      border-bottom-color: transparent;
    }
    &:hover {
      cursor: pointer;
      .Button svg {
        stroke: @color-primary-600;
      }
    }
    &__title {
      font-size: @text-size-16;
      font-weight: 600;
      margin-right: 20px;
    }
    &__toggleSection {
      padding: 0;
      width: 30px;
      height: 30px;
      margin-left: 15px;
      &--closed {
        & > svg {
          transform: rotateZ(180deg);
        }
      }
      & > svg {
        stroke: #bab9c1;
        width: 20px;
        height: 20px;
        margin: 0;
      }
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
  }
  &__subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @media @mobile {
      height: auto;
    }

    &__viewSwitch {
      display: flex;
      background: #f3f4f6;
      border-radius: 4px;
      position: relative;
      &:before {
        content: "";
        width: 65px;
        height: 30px;
        border-radius: 4px;
        position: absolute;
        background-color: @color-white;
        z-index: 2;
        box-shadow: 0 2px 4px 0 #00000033;
        transition-duration: @transition-duration;
        transition-timing-function: @transition-timing-function;
        transition-property: transform;
        transform: translateX(65px);
      }
      &--json {
        &:before {
          transform: translateX(0);
        }
        & .ResultsSection__subheader__viewSwitch__jsonButton {
          color: @color-primary-900;
        }
      }
      &--table {
        & .ResultsSection__subheader__viewSwitch__tableButton {
          color: @color-primary-900;
        }
      }
      & > button {
        cursor: pointer;
        z-index: 3;
        background: transparent;
        color: #9ca3af;
        font-size: @text-size-16;
        border: none;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65px;
        transition-duration: @transition-duration;
        transition-timing-function: @transition-timing-function;
        transition-property: color;
      }
    }
    &__transactionId {
      flex: 1;
      display: flex;
      &__title {
        font-size: @text-size-14;
        font-weight: 600;
        padding-right: 10px;
        color: #4b5563;
      }
      &__value {
        font-size: @text-size-14;
        color: #111827;
      }
    }
  }
  &__contentWrapper {
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }
  &__noResponse {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    align-items: center;
    padding-top: 85px;
    opacity: 50%;
    color: #5b5b5b;
    & > svg {
      width: 40px;
      height: 40px;
      margin-bottom: 20px;
      stroke-width: 1px;
    }
  }
  &__errorWrapper {
    width: 100%;
    padding: 10px 15px;
  }
}

.ResizeHandle {
  width: 100%;
  height: 2px;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: row-resize;
  transition-property: background-color;
  transition-timing-function: @transition-timing-function;
  transition-duration: @transition-duration;

  &:hover,
  &--active {
    background: @color-coolGray-200;

    > div {
      border-color: @color-coolGray-200;
      background-color: lighten(@color-coolGray-200, 20%);
      & > div {
        background-color: @color-coolGray-200;
      }
    }
  }
  & > div {
    position: absolute;
    width: 30px;
    height: 12px;
    bottom: -6px;
    background-color: @color-white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    right: 50%;
    transform: translateX(15px);
    z-index: 2;
    border-radius: @border-radius-md;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    transition: @transition-all;
    & > div {
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
