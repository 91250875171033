@import (reference) "../../styles/main";

.UseCaseErrorsPage {
  &__contentWrapper {
    display: grid;
    grid-template-columns: 1fr 340px;
    grid-column-gap: 30px;
    margin-top: 15px;
  }
  &__errorsDetailsPaper {
    padding: 0;
    position: initial;
  }
  &__contactForm {
    padding: 40px;
    height: auto;
    &__header {
      font-size: @text-size-22;
      font-weight: 600;
    }
    &__description {
      margin: 20px 0;
    }
    &__textArea {
      height: 150px;
    }
    &__submitButton {
      margin-top: 40px;
      width: 100%;
    }
  }
}
