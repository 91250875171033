.ProjectCard {
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  margin: 14px 0px;
  border: 1px solid transparent;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    border: 1px solid #78f5de;
    background-color: #ebfdfa;
    transition: all 0.3s;
  }

  &__header {
    font-size: 22px;
    color: var(--blue-900);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  &__description {
    padding: 14px 0px;
    color: var(--coolGray-700);
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__connectors {
    padding: 14px 0px;

    & > :first-child {
      font-size: 14px;
      color: var(--coolGray-900);
      font-weight: 600;
    }

    &__none {
      margin: 8px 0px;
      background-color: var(--coolGray-50);
      height: 40px;
      border-radius: 6px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--coolGray-700);
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
  }

  &__table {
    width: 100%;
    display: grid;
    grid-row-gap: 8px;
    padding-top: 14px;

    &__row {
      width: 100%;
      display: grid;
      grid-column-gap: 60px;
      grid-template-columns: 116px 1fr;

      &__name {
        display: flex;
        align-items: center;
      }

      &__head {
        font-size: 14px;
        color: var(--coolGray-900);
        font-weight: 600;
        min-width: 85px;
        white-space: nowrap;
      }

      &__body {
        font-size: 16px;
        line-height: 30px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        &__created {
          color: var(--blue-900) !important;
          font-size: 14px;
        }

        &__updated {
          color: var(--coolGray-700);
          font-size: 14px;
        }
      }
    }
  }

  &__badge {
    div {
      color: var(--coolGray-700) !important;
    }
  }
}
