@import (reference) "../../../styles/main";

.ActiveResponseTimeTypeSwitch {
  display: flex;
  padding-right: 20px;
  .bp-phone({
    flex-direction: column;
  });
  .bp-mobile({
    grid-column: 1;
    grid-row: 2;
  });
  &__checkboxLabel {
    padding-left: 26px;
    &__content {
      display: flex;
      align-items: center;
      & > svg {
        min-width: 10px;
        width: 20px;
        height: 10px;
        margin-right: 10px;
      }
    }
  }
  & > .Checkbox {
    margin-right: 20px;
    .bp-phone({
      margin-top: 18px;
    });
  }
}
