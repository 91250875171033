@import (reference) "../../../../styles/main";

.ConfigurationEditor {
  height: 100%;
  position: relative;
  &--isEdited {
    border: 1px solid @color-secondary-400;
    box-shadow: @box-shadow-darker;
    padding-bottom: 45px;
  }
  &__validationErrorsWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    flex: 0 0 80px;
    max-height: 80px;
    width: 100%;
    padding: 0 20px 1px;
    &__messageBox {
      width: 100%;
    }
  }
}
