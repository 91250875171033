@import (reference) "../../styles/main";

.slider_section {
  &-slider {
    width: 100vw;
    padding: 2rem 0 0;
    margin-left: max(calc(-1 * (100vw - @layout-max-width) / 2), 0);
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    position: relative;
    z-index: @layer-top-1;
  }
  &-container {
    padding-left: max(calc(-1 * (100vw - @layout-max-width) / 2), 0);
    padding-right: 32rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: fit-content;
    padding-bottom: 2rem;
    overflow: visible;
    article,
    .highlight-card {
      margin-right: 2rem;
      width: calc((min(@layout-max-width, 100vw) - 7.75rem) / 3);
    }
  }
  &-button_container {
    display: flex;
    button:first-child {
      margin-right: 1.5rem;
    }
  }
  &-slider_button {
    position: relative;
    z-index: @layer-top-1;
    border: none;
    transition-duration: @transition-duration;
    background: @color-white;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.75rem;
    cursor: pointer;
    transition: @transition-all;
    &:hover {
      box-shadow: @box-shadow-active;
      background: @color-coolGray-100;
    }
  }
  .rotate-180 {
    transform: rotate(180deg);
  }
  @media @tablet-landscape {
    article,
    .highlight-card {
      width: calc((min(@layout-max-width, 100vw) - 5.75rem) / 2);
    }
  }
  @media @mobile {
    article,
    .highlight-card {
      width: calc((min(@layout-max-width, 100vw) - 3.75rem));
    }
  }
}
