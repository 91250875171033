@import (reference) "../../../styles/main";

.share_menu {
  &-background {
    position: absolute;
    left: -0.75rem;
    top: 3.5125rem;
    display: none;

    &.hover {
      display: flex;
    }

    @media @tablet-landscape {
      left: initial;
      right: -0.5rem;
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.25rem 0;
    background-color: @color-white;
    box-shadow: @box-shadow-default;
    border-radius: 0.5rem;
  }

  &-label {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    filter: grayscale(100%);
    cursor: pointer;
    overflow: hidden;
    outline: none;
    background: none;
    border: none;
    padding: 0;

    p {
      color: @color-coolGray-900;
      margin-left: 1rem;
      font-size: @text-paragraph-font-size;
      line-height: 1;
    }

    img {
      border-radius: 100%;
    }

    &:hover {
      p {
        color: @color-blue-900;
      }
    }
  }

  &-circle {
    width: 2.1875rem;
    height: 2.1875rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &-copied {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(150%);
    opacity: 0;
    transition: @transition-all;

    &.visible {
      transform: translateY(0);
      opacity: 1;
    }

    &-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: @color-blue-25;
      top: 0;
      border-radius: 0.9375rem;
    }

    p {
      position: relative;
      line-height: 2.1875rem;
      z-index: 40;
      margin-left: 0;
    }
  }
}
