@import (reference) "../../../styles/main";

.partnership {
  .main-Layout();
  padding-bottom: 0;
  background-color: #F2F2F5;

  @media @tablet-landscape {
    text-align: center;
  }

  > .section-layout {
    h2 {
      max-width: 72rem;
    }
  }  

  h2 {
    .text-heading-2();
    color: @color-violet-900;
    text-align: center;
    max-width: 72rem;
  }

  p {
    .text-subheading-2();
  }

  &__section {
    width: 100%;
    text-align: left;
    scroll-margin-top: 7rem;
    padding-bottom: @main-padding-bottom;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    margin: 0 auto;
    column-gap: 6rem;
    align-items: center;
    grid-auto-flow: dense;

    &:nth-child(2n) {
      .partnership__section-img {
        grid-column: 2 / span 1;
        justify-self: flex-end;
      }
      .partnership__section-list {
        grid-column: 1 / span 1;
        justify-self: flex-start;
      }
    }

    @media @tablet-landscape {
      text-align: center;
      grid-template-columns: 1fr !important;
      .partnership__section-img {
        grid-column: unset !important;
        height: fit-content !important;
        justify-self: center !important;
      }
      .partnership__section-list {
        grid-column: unset !important;
        text-align: left;
      }
    }

    &-img {
      max-width: 100%;
      @media @tablet-landscape {
        padding: 0 1.5rem;
        width: 100%;
        height: auto;
        margin: 0 auto 3rem;
      }
    }

    &-list {
      background-color: @color-white;
      padding: @card-small-textarea-padding;
      border-radius: @card-border-radius;

      h3 {
        .text-heading-3();
          margin-bottom: 0.5rem;
          color: @color-violet-900;
        
        & ~ p {
          margin-bottom: 1rem;
        }
      }
      p {
        .text-paragraph();
        margin: 0;
        color: @color-violet-900;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        li {
          display: flex;
          align-items: center;
          .text-paragraph();
          margin: 0;
          img {
            margin-right: 1rem;
          }
          @media @mobile {
            flex-direction: column;
            text-align: center;
            gap: 0.5rem;
          }      
        }
      }
    }
  }
}

