@import (reference) "../../../styles/main";

.FileUploadInput {
  width: 100%;
  &__inputEl {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  &__clickableEl {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 300px;
    background-color: #eefcfa;
    border: 1px solid @color-secondary-400;
    border-radius: 4px;
    cursor: pointer;
    color: @color-primary-900;
    & > svg {
      width: 40px;
      height: 40px;
      margin-bottom: 20px;
      stroke-width: 1px;
    }
    & > h4 {
      font-weight: 500;
      margin-bottom: 10px;
      font-size: @text-size-22;
    }
    & > h6 {
      font-size: @text-size-18;
    }
  }
}
