@import (reference) "../../../../styles/main";

.ErrorsDisplayController {
  width: 100%;
  &__loading {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    margin-top: 30px;
    justify-content: space-around;
    align-items: center;
  }
  &__error {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;
    padding: 0 40px;
    .bp-mobile({
      padding: 0 20px;
    })
  }
  &__noData {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding: 0 40px;
    flex-direction: column;
    .bp-mobile({
      padding: 0 20px;
    });
    & > h4 {
      font-weight: 600;
      font-size: @text-size-22;
    }
    & > h6 {
      font-size: @text-size-18;
      margin-top: 10px;
    }
  }
}
