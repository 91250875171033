@import (reference) "../../styles/main";

.adx_exchange {
  .hero-Layout();

  &-background {
    .hero-backgroundLayout();
    background-color: @color-teal-900;
  }

  &-bottom_section {
    .main-Layout();
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 100%;
      top: 0;
      left: 0;
      height: 25rem;
      background-color: @color-coolGray-50;
    }
  }

  &-main {
    display: flex;
    justify-content: center;
    gap: 6rem;

    @media @tablet {
      gap: 3rem;
      flex-direction: column;
    }
  }

  &-logos {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    p {
      font-size: 4.5rem;
      font-weight: 100;
      margin: 0 1.875rem;
    }
  }

  &-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.25rem;
    height: 6.25rem;

    img {
      max-height: 6.25rem;
      margin-left: -1rem;
      margin-top: -1rem;
    }
  }

  &-text {
    width: 66.666%;
    @media @tablet {
      width: 100%;
    }
  }

  &-head {
    .text-heading-1();
    color: @color-white;
  }

  &-paragraph {
    .text-subheading-2();
    color: @color-teal-100;
  }
}
