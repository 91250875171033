@import (reference) "../../../../styles/main";

.ErrorDetails {
  width: 100%;
  border-bottom: 1px solid #eaeaf0;
  &:last-of-type {
    border-bottom: 0;
  }
  padding: 30px 0;
  &__table {
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns:
      220px
      minmax(200px, 1fr);
    grid-row-gap: 10px;

    thead,
    tbody,
    tr {
      display: contents;
    }

    th,
    td {
      padding: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th {
      text-align: left;
      font-weight: normal;
      padding: 0;
      color: #5b5b5b;
      background-color: @color-white;
    }

    th:last-child {
      border: 0;
    }

    td {
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 400;
      padding: 0;
      & code {
        font-size: @text-size-14;
        line-height: 1.3;
      }
      & a {
        color: @color-primary-900;
        &:hover {
          text-decoration: underline;
        }
      }
      & strong {
        font-weight: 600;
      }
    }
  }
  &__errorType {
    &__colorSquare {
      width: 16px;
      height: 16px;
      background: black;
      margin-right: 10px;
    }
    &__label {
      font-weight: 600;
    }
  }
}
