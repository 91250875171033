.injected-html {
  h2 {
    color: black;
    font-size: 2em;
    padding-bottom: 2em;
    padding-top: 2em;
    // Add more styles for h2 tags as needed
  }

  h3 {
    color: black;
    font-size: 1.5em;
    text-align: center;
    font-weight: bolder;
    padding-bottom: 1em;
    padding-top: 1em;
    // Add more styles for h3 tags as needed
  }

  p {
    strong {
      color: black;
      font-size: 1.25em;
      text-align: center;
    }
    color: black;
    font-size: 1em;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    text-align: left;

    // Add more styles for p tags as needed
  }
}
