@import (reference) "../../../styles/main";

.newsletter_hero {
  &-background {
    .hero-backgroundLayout();
  }

  &-container {
    display: flex;
    justify-content: center;
    gap: 6rem;
  }

  &-text {
    flex: 1;
    color: @color-white;
  }

  &-head {
    .text-heading-1();
    color: @color-white;
    text-align: left;
  }

  &-paragraph {
    .text-subheading-1();
    color: @color-violet-200;
    margin-top: 3rem;
    text-align: left;

    span {
      font-weight: 700;
    }
  }
  @media @tablet-landscape {
    &-container {
      flex-direction: column;
      padding: 0;
    }

    &-text {
      width: 100%;
    }
  }
}
