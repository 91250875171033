@import (reference) "../../styles/main";

.Checkbox {
  display: flex;
  justify-content: flex-start;
  &__input {
    width: 1px;
    height: 22px;
    z-index: -1;
    margin: 0;
    opacity: 0;
    & + .Checkbox__label::after {
      content: none;
      opacity: 0;
    }

    &:indeterminate + .Checkbox__label {
      &::before {
        background-color: @color-primary-900;
        border-color: transparent;
      }
      &::after {
        position: absolute;
        top: calc(50% - 0px);
        left: 4px;
        transform: translateY(-50%) rotate(0deg);
        content: "";
        display: inline-block;
        color: @color-white;
        width: 8px;
        height: 2px;
        border: none;
        background-color: @color-white;
        transition-property: opacity;
        transition-timing-function: @transition-timing-function;
        transition-duration: @transition-duration;
        opacity: 1;
      }
    }

    &:checked + .Checkbox__label {
      &::before {
        background-color: @color-primary-900;
        border-color: transparent;
      }
      &::after {
        content: "";
        opacity: 1;
      }
    }

    &:focus + .Checkbox__label::before {
      outline: #5140b3 solid 1px;
      border: 1px solid transparent;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }
    &:disabled + .Checkbox__label {
      cursor: not-allowed;
      &::before {
        background-color: #dcdce0;
        border-color: #bab9c0;
      }
      &::after {
        cursor: not-allowed;
        background-color: #dcdce0;
        border-color: #bab9c0;
      }
    }
    cursor: pointer;
  }

  &__label {
    position: relative;
    display: flex;
    padding-left: 16px;
    align-items: center;
    cursor: pointer;
    &--withLabelText {
      padding-left: 34px;
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: "";
      display: inline-block;
      height: 14px;
      width: 14px;
      border: 1px solid #bab9c1;
      border-radius: @border-radius-xs;
      background-color: @color-white;
      transition-property: opacity;
      transition-timing-function: @transition-timing-function;
      transition-duration: @transition-duration;
    }
    &::after {
      position: absolute;
      top: calc(50% - 1px);
      left: 3px;
      transform: translateY(-50%) rotate(-45deg);
      content: "";
      display: inline-block;
      color: @color-white;
      height: 4px;
      width: 8px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transition-property: opacity;
      transition-timing-function: @transition-timing-function;
      transition-duration: @transition-duration;
    }
  }
}
