@import (reference) "../../../../styles/main";

.contact_form {
  &-background {
    position: relative;
    min-width: 100%;
  }

  &-main {
    position: relative;
    width: 100%;
    max-width: 52.5rem;
    padding: @card-medium-padding;
    background-color: @color-white;
    border-radius: @card-border-radius;
    z-index: @layer-top-2;
    margin: 0 auto @hero-padding-bottom;

    .button-component {
      width: 100%;
      z-index: @layer-top-1;
    }
  }

  &-header {
    .text-heading-3();
    margin-top: 0;
  }

  &-label {
    .text-paragraph();
    margin-bottom: 2rem;
    max-width: 85%;
  }

  &-getStarted {
    .text-paragraph();
    text-align: center !important;
    width: 100%;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid @color-coolGray-200;
    a {
      color: @color-primary-900;
      text-decoration: underline;
      &:hover {
        color: @color-primary-1000;
        text-decoration: underline;
      }
    }
  }
}
