@import (reference) "../../../styles/main";

.platform_security {
  scroll-margin-top: 7.5rem;
  background-color: @color-blue-25;
  width: 100%;
  .main-Layout();
  text-align: center;

  &--governance {
    padding-top: 0;
  }

  h2 {
    .text-heading-2();
  }
  p {
    .text-subheading-2();
  }
  &-adhere {
    display: grid;
    grid-template-columns: 1fr 15.875rem;
    column-gap: 2rem;
    border-radius: @card-border-radius;
    padding: 4rem;
    margin: 1rem 0;
    background-color: @color-white;
    grid-auto-flow: dense;
    text-align: left;
    @media @tablet {
      grid-template-columns: 1fr;
      padding: 1.5rem;
      text-align: center;
      img {
        margin: auto;
        margin-bottom: 2.625rem;
        grid-row: 1 / span 1;
      }
    }
    .list {
      .text-subheading-2();
      margin-top: 0;
      @media @tablet {
        grid-row: 2 / span 1;
      }
      ul {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;
        margin: 1rem 0;
        li {
          border-radius: 0.5rem;
          padding: 1rem;
          background-color: @color-teal-200;
          .text-heading-4();
          margin: 0;
        }
      }
    }
  }
}
