@import (reference) "../../../../styles/main";

.industry_recipes_section {
    background-color: @color-white;
    .main-Layout();

    &-container {
        .section-Layout();
        }

    h2 {
        .text-heading-2();
        color: @color-violet-900;
    }

    > div > p {
        .text-subheading-2();
        max-width: 57rem;
    }

    &-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1.5rem;
        row-gap: 2rem;

        @media @tablet-landscape {
            grid-template-columns: 1fr 1fr;
        }
      
        @media @mobile {
            grid-template-columns: 1fr;
        }
    }
}