@import (reference) "../../styles/main";

.DataUtilizationPage {
  background-color: @color-platformBackground;
  flex: 1;
  overflow: auto;
  padding: 0 40px;
  .bp-mobile({
    padding: 0 20px 20px;
  });
  &__header {
    margin-bottom: 30px;
    &__content {
      display: flex;
      justify-content: flex-end;
      .bp-phone({
        align-self: flex-end;
      });
      & > div:not(:last-of-type) {
        margin-right: 20px;
      }
      & > div {
        .bp-phone({
          margin-top: 10px;
        });
      }
    }
  }
  &__container {
    background-color: @color-platformBackground;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__error {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
    }
    &__noData {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0 20px;
      text-align: center;
      & > h3 {
        font-size: @text-size-24;
      }
      & > a {
        margin-top: 20px;
      }
    }
  }
  &__currentBill {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    .bp-mobile({
      grid-template-columns: repeat(1, minmax(0, 1fr));
    });
    .bp-phone({
      grid-template-columns: repeat(1, minmax(0, 1fr));
    });

    .Bill {
      background-color: @color-white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: @border-radius-md;
      padding: 20px;
      display: flex;
      flex-direction: column;
      color: @color-coolGray-900;
      text-decoration: none;
      transition: @transition-all;
      text-align: left;
      position: relative;
      overflow: hidden;
    }
  }
}
