@import (reference) "../../../styles/main";

.ProjectCommentsSection {
  background-color: #FFFFFF;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 20%);
  border-radius: 12px;

  em {
    font-style: italic;
  }

  &__quill__container {
    padding-top: 15px;
    border-top: 1px solid #ccc;
  }

  .ql-link, .ql-clean {
    display: none !important;
  }

  .ql-toolbar.ql-snow {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .ql-container.ql-snow {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &__sectionTitle {
    font-size: 22px;
    line-height: 1.4;
    font-weight: 600;
    padding: 24px;
    border-bottom: 1px solid @color-coolGray-200;
    color: @color-coolGray-900;
  }

  &__comments {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 24px;
  }

  &__loader {
    margin: auto;
  }
}
