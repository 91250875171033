@import (reference) "../../../styles/main";

.terms_checkbox {
  &-main {
    position: relative;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  &-request-demo {
    position: relative;
    width: 100%;
    margin-bottom: 7px;

    @media (max-height: 744px){
      margin-top: -6px;
    }
  }

  &-label {
    display: flex;
    align-items: center;

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
  }

  &-text {
    align-items: center;
    .text-paragraph();
    margin: 0;
    margin-left: 0.75rem;

    a {
      color: @color-primary-900;
      text-decoration: underline;
      &:hover {
        color: @color-primary-1000;
        text-decoration: underline;
      }
    }
  }

  input:checked ~ &-box:before {
    transform: scale(1);
    transition: @transition-all;
  }

  input:checked ~ &-box {
    border: 1px solid @color-primary-900;
    transition: @transition-all;
  }

  input:focus ~ &-box {
    border: 1px solid @color-primary-900;
    outline: 0.1975rem solid @color-indigo-300;
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.25rem;
  }

  &-request-demo-container {
    display: flex;
    align-items: center;
    width: 2.0rem;
    min-width: 2.0rem;
    height: 2.5rem;
    border-radius: 0.25rem;
  }

  &-box {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.125rem;
    background: @color-white;
    border: 1px solid @color-violet-100;

    &:before {
      content: "";
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      background-color: @color-primary-900;
      border-radius: 0.1975rem;
      top: calc(50% - 0.375rem);
      right: calc(50% - 0.375rem);
      transform: scale(0);
      transition: @transition-all;
    }
  }

  &-error {
    position: absolute;
    bottom: -1.125rem;
    font-size: 0.875rem;
    color: @color-pink-900;
  }

  &-request-demo-error {
    bottom: -1.125rem;
    font-size: 0.875rem;
    color: @color-pink-900;
  }
}
