@import (reference) "../../styles/main";

.layout-footer {
  background-color: @color-white;
  width: 100%;

  &-main {
    .main-Layout();
    text-align: left;
    flex-direction: row;
    padding-bottom: 7rem;
    gap: 3rem;
    @media @tablet {
      gap: 6rem;
      flex-direction: column;
    }
  }

  &-left {
    flex: 0 1 auto;
    min-width: 16rem;

    @media @tablet {
      width: 100%;
      padding-top: 2rem;
      order: 2;
    }
  }

  &-social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    margin-bottom: 1.5rem;
    font-size: @text-subheading-2-font-size;
    &-link {
      display: inline-flex;
      color: @color-violet-500;
      &:hover {
        color: @color-violet-800;
      }
    }
  }

  &-toTop {
    display: none !important;
    @media @tablet {
      display: flex !important;
      margin-top: 3rem;
    }
  }

  &-landing {
    //background-color: darken(@color-violet-1000, 2%);
    .layout-footer {
      &-main {
        align-items: center;
        justify-content: center;
      }

      &-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @media @tablet {
          padding-top: 0;
          padding-bottom: 2rem;
        }
      }
    }
  }
  &-nav {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3rem;

    @media @tablet-landscape {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 3rem;
    }

    @media @tablet {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1.5rem;
    }

    @media @mobile {
      grid-template-columns: 1fr;
    }
  }

  &-column {
    padding: 0;
  }

  &-landingNav {
    flex: 1;
    display: flex;
    gap: 1rem;
    margin: 1rem 0 2.5rem;
    flex-wrap: wrap;
    justify-content: center;

    &-divider {
      color: @color-violet-500;
    }

    &-link {
      font-size: 0.875rem;
      text-decoration: none;
      color: @color-violet-700;
      position: relative;
      width: fit-content;
      font-weight: 500;

      &:hover,
      &:focus {
        text-decoration: underline;
        color: @color-violet-800;
      }
    }

    @media @tablet {
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;

      &-divider {
        display: none;
      }
    }
  }

  &-header {
    .text-heading-4();
    margin-top: 0;
    margin-bottom: 2rem;
  }

  &-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;

    img {
      margin-right: 1.25rem;
      width: 1.25rem;
    }
  }

  &-link {
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: none;
    color: @color-violet-700;
    position: relative;
    width: fit-content;

    &:hover,
    &:focus {
      text-decoration: underline;
      color: @color-violet-800;
    }
  }

  &-logo {
    height: 2.5rem;
    max-width: 100%;
    margin-bottom: 1.875rem;
  }

  &-copy {
    color: @color-violet-600;
    font-size: 0.875rem;
    font-weight: 500;

    span {
      display: block;
    }
  }
}
