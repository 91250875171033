@import (reference) "../../../styles/main";

.UseCasesStyles {
  background-color: @color-platformBackground;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    &__actions {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 12px;
      flex-wrap: wrap;
      .Button {
        max-height: 32px;
      }
      .bp-phone({
        margin-top: 20px;
      });
    }
  }
  &__filters {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    &__counter {
      color: #111827;
      font-weight: 600;
    }
  }
  &__useCases {
    display: flex;
    flex-direction: column;
    width: 100%;
    .bp-mobile({
      margin-top: 20px;
    });
    &__loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
    }
    &__errorWrapper {
      width: 100%;
    }
    &__noData {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      & > h3 {
        font-size: @text-size-24;
        margin-bottom: 20px;
      }
    }
    &__listContainer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 40px;
    }
    &__list {
      margin-top: 32px;
      display: grid;
      grid-template-columns:
        minmax(300px, 1fr)
        minmax(300px, 1fr)
        minmax(300px, 1fr);
      grid-template-rows: auto;
      column-gap: 15px;
      row-gap: 15px;
      width: 100%;
      .bp-desktop-big({
        grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
      });
      .bp-tablet({
        grid-template-columns: minmax(300px, 1fr);
      });
      .bp-phone({
        grid-template-columns: minmax(280px, 1fr);
      });
    }
  }

  .Pagination__container {
    padding: 0;
  }
}
