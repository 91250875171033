@import (reference) "../../../landingPage/styles/main";

.SignUp {
  width: 100%;
  overflow: hidden;
  .gradient-violet-1000();
  font-family: @font-family-body;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;

  &__wrapper {
    min-height: calc(100vh - 100px);
    width: 100%;
    max-width: @layout-max-width;
    padding: 0 1.5rem;

    &.sent {
      min-height: 900px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media @tablet-landscape {
        align-items: flex-start;
        max-width: 540px;
      }
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 540px;
    column-gap: 6rem;
    width: 100%;
    @media @tablet-landscape {
      gap: 0px;
      grid-template-columns: 1fr;
      > *:last-child {
        order: -1;
      }
    }

    &.sent {
      grid-template-columns: 1fr;
      column-gap: 0;
    }
  }

  &__logo {
    height: 40px;
    width: 100%;
    margin: 0 0 calc(25px + (@hero-padding-bottom / 2));

    > svg {
      height: 100%;
      max-height: 40px;
    }
  }
  &__h1 {
    .text-heading-2();
    color: @color-white;
  }
  &__h2,
  &__h3 {
    .text-subheading-2();
    font-size: 1.2rem;
    color: @color-violet-100;
    margin-bottom: 32px;
  }

  &__h3 {
    margin-top: 64px;
    > a {
      color: @color-teal-400;
      &:hover {
        color: @color-teal-500;
        text-decoration: underline;
      }
    }
  }
  &__paragraph {
    .text-subheading-2();
    font-size: 1.2rem;
    color: @color-violet-100;
  }
  hr {
    border-color: @color-white;
    opacity: 0.2;
    margin: 80px 0;
  }
  &__feature-list {
    li {
      display: flex;
      gap: 24px;
      align-items: center;
      margin: 0 0 24px;
      h2 {
        .text-subheading-2();
        font-size: 1.2rem;
        color: @color-violet-100;
        margin: 0;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        min-width: 16px;
        height: 16px;
        border-radius: 20px;
        background-color: rgba(@color-violet-200, 0.5);
        > div {
          width: 10px;
          min-width: 10px;
          height: 10px;
          border-radius: 10px;
          background-color: @color-violet-100;
        }
      }
    }
  }
  &__hero-logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    img {
      padding: 24px 48px 24px 0;
      width: auto;
      height: auto;
    }
    @media @tablet-landscape {
      justify-content: space-around;
      img {
        padding: 24px;
      }
    }
  }

  &__green-text {
    color: #78f5de;
    font-weight: 600;
  }
  &__header {
    display: flex;
    justify-content: center;
    color: red;
    z-index: 999;
  }
  &__paper {
    border-radius: 24px;
    box-shadow: @box-shadow-active;
    width: 100%;
    @media @tablet-landscape {
      grid-row: 1;
      margin-bottom: 60px;
      justify-self: center;
    }

    &__login-link {
      text-align: center;
      margin-top: 20px;
      > p {
        .text-paragraph();
        color: @color-violet-200;

        > a {
          color: @color-teal-400;
          &:hover {
            color: @color-teal-500;
            text-decoration: underline;
          }
        }
      }
    }
    .AuthPagePaper {
      overflow: initial;
      border-radius: 24px !important;
      padding: @card-medium-padding;
      @media @mobile {
        padding: @card-medium-padding-mobile;
      }
    }

    &__header {
      @media @tablet-landscape {
        margin: 20px 0;
      }
      margin-bottom: 40px;
      &__title {
        .text-heading-3();
        margin: 0;
        span {
          span {
            @media @tablet-landscape {
              display: none;
            }
          }
        }
      }
      &__message {
        > p {
          margin-bottom: 30px;
          line-height: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__backLinks {
      width: 100%;
      display: flex;
      justify-content: center;
      & > a {
        // .standardLink();
      }
    }
    &__loadingOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
  &__footer {
    display: flex;
    .AuthPageFooter {
      margin-top: 60px;

      &__content {
        color: @color-coolGray-400;
        .bp-tablet-landscape({
        justify-content: center;
      });
        &__spacer {
          background: @color-coolGray-400;
        }
        & a {
          color: @color-coolGray-400;
          &:hover {
            color: @color-coolGray-500;
          }
        }
      }
    }
  }
}

.SignUpForm {
  width: 100%;

  button[type="submit"] {
    .button-text();
    min-height: 60px;
    max-height: 60px;
    margin-top: 24px;
    background-color: @color-teal-400;
    box-shadow: @box-shadow-default;
    display: flex;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
    &:hover,
    &:active {
      box-shadow: @box-shadow-active;
      background-color: @color-teal-600;
    }
  }

  &__textfieldWrapper {
    & > div:first-child {
      max-width: unset;
    }
    margin-bottom: 30px;
    & > :nth-child(2n) {
      max-width: 100%;
    }
  }

  & > :nth-child(5n) {
    text-align: left !important;
    max-width: 100%;
    margin: 1rem 0px;
    & > div {
      & > div {
        & > span {
          display: none;
        }
      }
    }
  }
  .accept-terms-checkbox {
    text-align: left !important;
    & > div {
      & > input:enabled:checked {
        background-image: url("/checkbox-image/checkbox-checked.7e7bd052.svg");
        background-color: @color-blue-700;
      }
      > div > span {
        display: none;
      }
    }
  }
  .tos-message {
    color: @color-coolGray-600;
    padding-left: 1.75rem;
    margin: 1rem 0 2rem 0;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  & .FormComponent {
    margin-bottom: 30px;

    &__input {
      outline-color: transparent;
    }

    &__input:focus,
    &__input:active {
      background-color: @color-white;
      border-color: transparent;
      outline: 1px solid #5140b3;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }

  &__formValidationErrors {
    list-style: none;
  }

  &__errorMessageWrapper {
    margin-bottom: 30px;
    > p {
      line-height: 24px;
      margin-bottom: 15px;
    }
  }
  &__textfieldWrapper {
    position: relative;

    &__validationIcon {
      position: absolute;
      top: 0;
      right: 0;
      color: #b9b2e5;
      & > svg {
        width: 20px;
        height: 20px;
      }
      &--valid {
        color: #26937f;
      }
    }

    &__passwordValidator {
      width: 100%;
      position: absolute;
      top: 86px;
      z-index: 9999;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    }
  }
}
