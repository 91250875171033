@import (reference) "../../../styles/main";

.single_choice {
  &-main {
    position: relative;
    margin-bottom: 1.5rem;

    .radio-main:last-child {
      margin-bottom: 0;
    }
  }

  &-label {
    display: flex;
    justify-content: space-between;
    .text-paragraph();
    color: @color-coolGray-600;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.25rem;

    span {
      font-weight: 400;
      color: @color-violet-500;
    }
  }

  &-error {
    font-weight: 400;
    font-family: @font-family-body;
    font-size: 0.875rem;
    position: absolute;
    bottom: 0.375rem;
    color: @color-pink-900;
  }
}
