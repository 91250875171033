@import (reference) "../../../styles/main";

.AlignSvgRequestDemo {
  display: flex;
  justify-content: flex-end;
  align-items: start;
  padding: 20px;
}

.left-side {
  background: linear-gradient(180deg, #f3f2ff 0%, #ffffff 100%);
  min-width: 804px;
  @media @mobile {
    min-width: auto;
  }
}

.right-side {
  height: 100%; /* Take up full height */
  background: linear-gradient(180deg, #130b4d 0%, #0a066c 100%);
  min-width: 588px;
  @media @mobile {
    display: none;
  }
}
.align-text-and-logos {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.demyst-blue {
  color: #0f006c !important;
}

.x-button {
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  text-decoration: underline;
}
.text-center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
}

.text-right {
  max-width: 450px;
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 27.24px;
  letter-spacing: 0;
  text-align: center;
  margin: auto;
  font-family: "Open Sans", Arial, sans-serif;
  white-space: pre-wrap;
}
/* To control the space between paragraphs, adjust the HTML to use p tags */
.text-right p {
  margin-top: 0;
  margin-bottom: 1.5rem; /* Adjust this value to increase/decrease the space between paragraphs */
}

.text-right-side {
  margin-top: 85px;

  @media (max-height: 744px) {
    margin-top: 5px;
  }
}

.logo-group {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
}

.align-two-first-logos {
  display: flex;
  gap: 50px;
  align-items: center;
}

.logo {
  text-align: center;
  margin: auto 2rem;
  padding: 1rem;
}

.logo-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}

.brightLogo {
  filter: brightness(100);
}

.align-demyst-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 50px;
}

.align-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 50px;
}

.contact-form-header {
  font-family: "Montserrat", Verdana, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 600px;

  @media (max-height: 744px) {
    gap: 0;
    margin-top: 5px;
    font-size: 15px;
  }
}

.contact-form-label {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  color: rgba(83, 74, 119, 1);

  @media (max-height: 744px) {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.width-100 {
  width: 100%;
}

.input-label-style {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(83, 74, 119, 1);
}

.terms-agreement {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(88, 80, 146, 1);
}

.align-text-schedule-demo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mt-7px {
  margin-top: 7px;

  @media (max-height: 744px) {
    margin-top: 0;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 700px;
  gap: 25px;
  margin-top: 60px;

  @media (max-height: 780px) {
    gap: 0;
    margin-top: 0;
  }
}

.form-header {
  @media @mobile {
    margin-top: 10px;
  }
}

.LandingHero {
  .hero-Layout();
  align-items: flex-start;
  justify-content: center;
  gap: 4rem;

  @media @tablet-landscape {
    flex-direction: column;
    gap: @main-padding-bottom;
    align-items: center;
  }

  @media @tablet {
    gap: @main-padding-bottom-mobile;
  }

  .section-layout {
    margin-top: 0;
  }

  &-type-use-case-data-source,
  &-type-platform-sign-up,
  &-type-contact-form {
    max-width: @layout-max-width;
    padding: @layout-padding;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(100vh - @hero-padding-top - @hero-padding-bottom);

    .section-layout {
      padding: 0;
      & + .section-layout {
        flex: 0;
        align-items: flex-start !important;
        text-align: left !important;

        @media @tablet-landscape {
          align-items: center !important;
        }
      }
    }
  }

  &-logos {
    p {
      .text-paragraph();
      color: rgba(@color-white, 90%);
    }

    &-images {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      gap: 3rem;
      margin-top: 1.5rem;
      @media @mobile {
        justify-content: flex-start;
        column-gap: 4rem;
      }

      .LandingHero-align-right & {
        justify-content: flex-end;
        @media @tablet-landscape {
          justify-content: flex-start;
        }
      }
      .LandingHero-align-center & {
        justify-content: center;
        @media @tablet-landscape {
          justify-content: flex-start;
        }
      }

      &--connectors {
        img {
          border-radius: 1rem !important;
        }
      }
    }
  }

  &-dataSource {
    .card-medium-Layout();
    width: 32rem;
    gap: 2rem;
    display: flex;
    flex-direction: column;

    @media @tablet-landscape {
      width: 100%;
    }

    &-preheader {
      text-transform: uppercase;
      font-size: 0.875rem;
      letter-spacing: 0.125rem;
      line-height: 1;
    }

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }

    &-logo {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 1rem;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      border: 1px solid lighten(@color-violet-200, 10%);
      box-shadow: none !important;
      flex: 0 0 auto;
    }

    &-title {
      .text-heading-3();
    }

    &-description {
      .text-paragraph();
      color: @color-violet-600 !important;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-link {
      font-size: @text-paragraph-font-size;
      margin-top: 1rem;
      width: fit-content;
      box-shadow: none !important;
      padding: 0;
      height: initial !important;
      background: transparent !important;
      border-radius: 0 !important;
      outline: 0 !important;
    }
  }

  &-form {
    width: 32rem;
    margin: 0;

    @media @mobile {
      width: 100vw;
    }

    &.SignUp__paper {
      width: 32rem;
      margin: 0;
      box-shadow: none;
      @media @tablet {
        width: 100%;
        max-width: 32rem;
      }
      @media @mobile {
        width: 100vw;
        max-width: unset;
        border-radius: 0;
        .AuthPagePaper {
          border-radius: 0 !important;
          padding-bottom: 3rem;
        }
      }
    }
  }

  &-regionIcon {
    border-radius: 0.5rem;
    width: 4rem;
    height: 3rem;
    margin-bottom: 2rem;
  }

  &-title {
    .text-heading-1();
    color: @color-white;
  }

  &-subtitle {
    .text-subheading-2();
    color: rgba(@color-white, 90%);
  }

  img {
    max-width: 100%;
  }

  &-image {
    margin: 4rem 0;
    max-width: 100%;
    border-radius: @card-border-radius;

    &.noRadius {
      border-radius: 0;
    }

    &.centralizeManagement {
      width: 40rem;
      margin-left: -6.7991rem;
    }

    @media @tablet {
      margin: 2rem 0;
    }
  }

  &-align-left {
    .section-layout {
      align-items: flex-start;
      h1,
      h2,
      p {
        text-align: left;
      }
    }

    .LandingCtas {
      align-items: flex-start;
    }
  }

  &-align-right {
    .section-layout {
      align-items: flex-end;
      h1,
      h2,
      p {
        text-align: right;
      }
      @media @tablet-landscape {
        align-items: flex-start;
        h1,
        h2,
        p {
          text-align: left;
        }
      }
    }
  }

  &-background-request-demo {
    .hero-backgroundLayoutRequestDemo();

    &-blue {
      background-color: @color-blue-900;
      .LandingHero {
        &-dataSource {
          &-preheader {
            color: @color-blue-600 !important;
          }
        }
      }
    }
    &-purple {
      .gradient-violet-800();
      .LandingHero {
        &-dataSource {
          &-preheader {
            color: @color-violet-600 !important;
          }
        }
      }
    }
    &-teal {
      background-color: @color-teal-900;
      .LandingHero {
        &-dataSource {
          &-preheader {
            color: @color-teal-1000 !important;
          }
        }
      }
    }
    &-dark {
      .gradient-violet-1000();
      min-height: calc(100vh + @header-height);
      .LandingHero {
        &-dataSource {
          &-preheader {
            color: @color-violet-600 !important;
          }
        }
      }
    }
    &-magenta {
      .gradient-pink-1000();
      .LandingHero {
        &-dataSource {
          &-preheader {
            color: @color-pink-600 !important;
          }
        }
      }
    }
  }

  &-background {
    .hero-backgroundLayout();

    &-blue {
      background-color: @color-blue-900;
      .LandingHero {
        &-dataSource {
          &-preheader {
            color: @color-blue-600 !important;
          }
        }
      }
    }
    &-purple {
      .gradient-violet-800();
      .LandingHero {
        &-dataSource {
          &-preheader {
            color: @color-violet-600 !important;
          }
        }
      }
    }
    &-teal {
      background-color: @color-teal-900;
      .LandingHero {
        &-dataSource {
          &-preheader {
            color: @color-teal-1000 !important;
          }
        }
      }
    }
    &-dark {
      .gradient-violet-1000();
      min-height: calc(100vh + @header-height);
      .LandingHero {
        &-dataSource {
          &-preheader {
            color: @color-violet-600 !important;
          }
        }
      }
    }
    &-magenta {
      .gradient-pink-1000();
      .LandingHero {
        &-dataSource {
          &-preheader {
            color: @color-pink-600 !important;
          }
        }
      }
    }
  }

  .LandingCtas {
    margin-top: 0;
    margin-bottom: 2.5rem;
    &-button {
      height: 3.75rem;
      padding: 0 1.75rem;
    }
  }
}
