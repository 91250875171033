@import (reference) "../../styles/main";

.FormComponent {
  &--fullWidth {
    width: 100%;
  }
  &__label {
    transition: @transition-all;
    color: @color-coolGray-900;
    font-size: @text-size-16;
    outline: none;
    font-weight: 600;
    &__requiredAsterisk {
      font-size: 1rem;
      line-height: 0;
      vertical-align: middle;
      color: @color-primary-900;
    }
  }
  &__input {
    color: @color-coolGray-900;
    font-family: inherit;
    font-size: @text-size-16;
    height: 40px;
    background: none;
    width: 100%;
    display: block;
    background: @color-white;
    border: 1px solid #bab9c0;
    border-radius: 4px;
    padding: 8px 10px;
    transition: @transition-all;
    .FormComponent__label + & {
      margin-top: 5px;
    }
    &::placeholder {
      color: #c8c8c8;
    }

    &:focus,
    &:active {
      outline: none;
      border-color: @color-secondary-400;
      background-color: @color-secondary-50;
    }

    &:disabled {
      background-color: #dcdce0;
      border-color: #bab9c0;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }
  &--sm {
    & .FormComponent__input {
      height: 30px;
      font-size: @text-size-16;
      padding: 0 10px;
    }
    & .FormComponent__label {
      font-size: @text-size-14;
    }
  }
  &--lg {
    & .FormComponent__input {
      height: 46px;
      padding: 0 12px;
    }
    & .FormComponent__label + .FormComponent__input {
      margin-top: 10px;
    }
  }
}
