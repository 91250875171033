@import (reference) "../../styles/main";

.PricingTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: @color-blue-25;

  &__table {
    &__header {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #d1d5db;
      color: #4b5563;
      font-weight: 600;
      font-size: @text-size-12;
      &--forSingleProvider {
        justify-content: flex-end;
      }
      > span {
        padding: 10px 15px;
        &:first-child {
          width: 60%;
        }
        &:last-child {
          width: 40%;
          text-align: end;
        }
      }
    }

    &__totalBadge {
      display: flex;
      justify-content: space-between;
      color: #4b5563;
      > span:first-child,
      > div:first-child {
        width: 60%;
      }
      > span:last-child,
      > div:last-child {
        width: 40%;
        text-align: end;
      }
      > span,
      > div {
        padding: 10px 15px;
      }
      > :last-child {
        padding: 10px 8px 4px;

        > div {
          > :first-child {
            color: @color-teal-200;
          }
        }
      }
      &&__total {
        border-top: 1px solid #e5e5e5;
        background-color: transparent;
        & > span:nth-of-type(1) {
          font-weight: 600;
        }
        &--priceOverride {
          color: #26937f;
        }
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      color: #4b5563;
      > span:first-child,
      > div:first-child {
        width: 60%;
      }
      > span:last-child,
      > div:last-child {
        width: 40%;
        text-align: end;
      }
      > span,
      > div {
        padding: 10px 15px;
      }
      &:nth-child(odd) {
        background-color: #f9fafb;
      }

      &&__total {
        border-top: 1px solid #e5e5e5;
        background-color: transparent;
        & > span:nth-of-type(1) {
          font-weight: 600;
        }
        &--priceOverride {
          color: #26937f;
        }
      }
      &__provider {
        display: flex;
        overflow: hidden;
        align-items: center;
        width: 100%;
        &__logo {
          min-width: 20px;
          width: 20px;
          height: 20px;
          background-color: @color-white;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          margin-right: 5px;
          overflow: hidden;
          & > img {
            margin-left: -1px;
            width: 20px;
            max-height: 100%;
          }
        }
        &__info {
          display: flex;
          flex-direction: column;
          width: calc(100% - 20px);
          &__name {
            font-size: @text-size-16;
            font-weight: 600;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &__providerName {
            font-size: @text-size-14;
            font-weight: 400;
            color: #6b7280;
          }
        }
      }
      &__price {
        min-width: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    &__circleResult {
      display: flex;
      justify-content: flex-end;
      > p {
        background-color: #4f9e90;
        color: #bbfaee;
        border-radius: 30px;
        padding: 2px 8px 4px;
        font-weight: 600;
      }
    }
  }
  &__cta {
    background-color: #f0eff8;
    color: @color-primary-600;
    padding: 20px;
    border-radius: @border-radius-md;
    margin-top: 20px;

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      & > svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        stroke: #c5bfe6;
      }
      & > span {
        font-weight: 600;
        font-size: @text-size-16;
      }
    }
    &__message {
      line-height: 24px;
      font-size: @text-size-14;
      &__startChatButton {
        background: transparent;
        text-decoration: underline;
        border: none;
        display: inline-block;
        align-items: center;
        cursor: pointer;
        padding: 0;
        outline: 0;
        color: @color-primary-600;
        border-radius: @border-radius-sm;
        transition-property: color;
        transition-duration: @transition-duration;
        transition-timing-function: @transition-timing-function;
        &:hover {
          color: @color-primary-400;
        }
      }
    }
  }
}
