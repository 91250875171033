@import (reference) "../../../styles/main";

.ApiTemplatePage {
  &__paper {
    &__inputsTable {
      padding: 0 30px 40px;
      .bp-mobile({
        padding: 0 20px 30px;
      });
      &__title {
        font-size: @text-size-22;
        font-weight: 600;
        margin-bottom: 20px;
      }
      &__wrapper ul {
        display: grid;
        row-gap: 15px;
        column-gap: 15px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 0.3333fr));

        .bp-mobile({
          display: flex;
          flex-direction: column;
        });
      }
      &__item {
        display: flex;
        flex-direction: column;
        padding: 15px;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        color: @color-coolGray-900;
        height: 100%;
        transition: all 0.3s;
        &:hover,
        &:focus {
          cursor: pointer;
          border: 1px solid #78f5de;
          background-color: #ebfdfa;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }
        img {
          width: 35px;
          height: 35px;
          border-radius: 17.5px;
          margin-right: 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
        div {
          overflow: hidden;
        }
        div:first-of-type {
          display: flex;
          align-items: center;
          p {
            font-size: @text-size-14;
            font-weight: 400;
          }
        }
        div:last-of-type {
          p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            &:first-of-type {
              font-size: @text-size-18;
              font-weight: 600;
              margin: 10px 0;
            }
            &:last-of-type {
              font-size: @text-size-14;
              font-weight: 400;
            }
          }
        }
        &__tag {
          width: fit-content;
          margin-top: 10px;
          padding: 5px;
          background: @color-platformBackground;
          border-radius: 2px;
          font-size: @text-size-14;
          font-weight: 400;
        }
      }
    }
  }
}
