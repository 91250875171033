@import (reference) "../../../../styles/main";

.standard-multielement {
  display: flex;
  justify-content: stretch;
  section {
    width: 100%;
    &:first-of-type {
      margin-right: 1.25rem;
    }
  }
}
