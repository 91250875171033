@import (reference) "../../../styles/main";

.box {
  &-main {
    position: relative;
    width: 100%;
    background-color: @color-blue-25;
    margin-bottom: 0.5rem;
    padding: 1rem;
  }

  &-label {
    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
  }

  &-text {
    align-items: center;
    .text-paragraph();
    color: @color-coolGray-900;
    margin: 0;
    margin-left: 2.5rem;
    a {
      color: @color-primary-900;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  input:checked ~ &-box:before {
    transform: scale(1);
    transition: @transition-all;
  }

  input:checked ~ &-box {
    border: 1px solid @color-primary-900;
    transition: @transition-all;
  }

  input:focus ~ &-box {
    border: 1px solid @color-primary-900;
    outline: 0.1975rem solid @color-indigo-300;
  }

  &-box {
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    background: @color-white;
    border: 1px solid @color-violet-100;
    top: 50%;
    left: 1.25rem;
    transform: translateY(-50%);

    &:before {
      content: "";
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      background-color: @color-primary-900;
      border-radius: 0.1975rem;
      top: calc(50% - 0.375rem);
      right: calc(50% - 0.375rem);
      transform: scale(0);
      transition: @transition-all;
    }
  }
}
