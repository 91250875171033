@import (reference) "../styles/main";

.AppHeader {
  width: 100%;
  height: 80px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  padding: 0 40px;
  border-bottom: 1px solid #e5e7eb;
  z-index: @z-index-underpopups;
  .bp-mobile({
    height: 80px;
    padding: 0 20px;
  });
  &__demystLogo {
    & > svg {
      height: 40px;
      & path {
        fill: @color-primary-900;
      }
    }
  }
  &__fullUserInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    font-size: @text-size-14;
    font-weight: 500;
    &__logo {
      max-width: 30px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: @color-coolGray-100;
      overflow: hidden;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  &__creditsInfoWrapper {
    display: flex;
    height: 40px;
    cursor: default;
    position: relative;

    .bp-mobile({
      display: none;
    });
  }

  &__publicHeaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__publicHeader {
    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 28px;
      color: #4b5563;
      .bp-mobile({
        display: none;
      });
      svg {
        margin-left: 12px;
        stroke: #9b92d2;
        stroke-width: 3px;
      }
    }
    &__button {
      margin-left: 12px;
    }
  }

  &__creditsInfo {
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-right: 12px;
    &--evaluation {
      background-color: #c2f4ed;
      color: #4f9e90;
    }
    &--production {
      background-color: #fef9c3;
      color: #a16207;
    }
    &__value {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      line-height: 24px;
      font-size: @text-size-14;
      font-weight: 600;
      padding: 8px;
      border-radius: 12px;
      &--evaluation {
        background-color: #4f9e90;
        color: #c2f4ed;
      }
      &--production {
        background-color: #ca8a04;
        color: #fef9c3;
      }
    }
    &__label {
      font-size: @text-size-14;
      font-weight: 400;
      margin-left: 8px;

      &--long {
        display: block;
        .bp-desktop-small({
          display: none;
        });
      }

      &--short {
        display: none;
        .bp-desktop-small({
          display: block;
        });
      }
    }
    &--empty {
      background-color: #ebebed;
      color: #5b5b5b;
      font-size: @text-size-16;
    }
  }

  &__creditsInfoTooltip {
    background-color: @color-white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 48px;
    left: calc(50% - 120px);
    min-width: 240px;
    border-radius: 8px;
    box-shadow: @box-shadow-default;
  }
  &__dynamicContent {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 16px;

    &--desktop {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 16px;
      justify-content: flex-start;

      .bp-mobile ({
        display: none;
      });;
    }

    &--mobile {
      display: none;

      .bp-mobile ({
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > button {
          outline: none;
          border: none;
          background-color: transparent;
          margin-left: 16px;
          cursor: pointer;

          > svg {
            stroke: #9B92D2;
            width: 24px;
            height: 24px;
          }
        }
      });
    }

    &__searchBarWrapper {
      max-width: 0;
      width: 100%;
      transition: all 0.8s;
      display: flex;
      justify-content: flex-end;
      overflow: hidden;

      &.open {
        transition: all 0.4s;
        max-width: 100%;
        overflow: visible;
      }
    }
  }
}
