@import (reference) "../../../styles/main";

.MetricChart {
  &__tooltip {
    min-width: 140px;
    max-width: 400px;
    white-space: nowrap;
    padding: 20px 0 10px;
    z-index: @z-index-popups;
    border: 0;
    &__date {
      font-size: @text-size-12;
      color: @color-coolGray-600;
      padding: 0 20px;
    }
    &__foot {
      border-top: 1px solid #0000001a;
      color: @color-primary-600;
      display: flex;
      align-items: center;
      font-size: @text-size-16;
      font-weight: 600;
      padding: 10px 20px 0;
      margin-top: 10px;
      > svg {
        margin-right: 5px;
      }
    }
    &__list {
      display: grid;
      border-collapse: collapse;
      min-width: 100%;
      grid-template-columns:
        minmax(50px, 1fr)
        minmax(20px, 100px);
      margin-top: 10px;
      padding: 0 20px;
      &__key {
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        &__indicator {
          min-width: 10px;
          width: 10px;
          height: 10px;
          margin-right: 10px;
        }
        &__label {
          font-size: @text-size-14;
          white-space: break-spaces;
          word-break: break-word;
        }
      }
      &__value {
        font-size: @text-size-14;
        text-align: right;
      }
    }
  }
}
