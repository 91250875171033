@import (reference) "../../../styles/main";

.PublicTable {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 24px 0;

    &__table {
        position: relative;

        &__header {
            padding: 0 32px;
            display: flex;
            justify-content: space-between;

            .bp-tablet({
                padding: 0 15px;
                flex-direction: column;
            });

            &__searchbox {
                position: relative;
                background-color: #E5E7EB;
                border-radius: 4px;
                height: 32px;
                width: 100%;
                max-width: 272px;

                > svg {
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    stroke: #9CA3AF;
                    stroke-width: 3px;
                }
            }

            &__actions {
                display: flex;

                .bp-tablet({
                    margin-top: 12px;
                    align-self: flex-end;
                    text-align: end;
                });

                &__action {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #D1D5DB;
                    font-size: @text-size-16;
                    font-weight: 400;

                    svg {
                        stroke-width: 3px;
                        margin: 0 8px;
                    }

                    &.export {
                        margin-right: 20px;
                    }
                }

                &__chevronContainer {
                    display: flex;
                    flex-direction: column;
                    > svg {
                        &:first-of-type {
                            margin-bottom: -3px;
                        }
                        &:last-of-type {
                            margin-top: -3px;
                        }
                    }
                }
            }
        }

        &__row {
            display: grid;
            grid-template-columns: 60px 30% calc(70% - 70px);
            height: 40px;
            padding: 0 20px;

            .bp-phone({
                padding: 0;
            });

            &.header {
                margin-top: 24px;
                border-top: 1px solid #F3F4F6;
                border-bottom: 2px solid #D1D5DB;
            }

            &__item {
                background: #6B7280;
                mix-blend-mode: multiply;
                opacity: 0.2;
                filter: blur(5px);
                border-radius: 200px;
                height: 60%;
                width: calc(100% - 5px);
                margin: auto;

                &.first {
                    width: 32px;
                }

                .bp-phone({
                    width: calc(100% - 8px);
                });
            }
        }

        &__paragraph {
            display: flex;
            align-items: center;
            font-size: @text-size-12;
            font-weight: 600;
            color: #D1D5DB;
            padding: 0 16px;
            white-space: nowrap;

            .bp-phone({
                padding: 0 4px;
                white-space: unset;
            });
        }
    }
}
