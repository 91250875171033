@import (reference) "../../../../styles/main";

.retractable-checkbox {
  label {
    position: relative;
    display: flex;
    padding: 0.75rem 1rem;
    margin-bottom: 1.5rem;
    align-items: center;
    cursor: pointer;
  }

  &-box {
    position: absolute;
    top: 0;
    left: 0;
    background: @color-coolGray-100;
    z-index: 0;
    border-radius: 0.5rem;
    height: 100%;
    width: 100%;
    transition: @transition-all;

    &:hover {
      background-color: @color-coolGray-200;
    }
  }

  &-label {
    display: flex;
    align-items: center;
    gap: 1rem;
    span,
    p {
      z-index: 6;
      margin: 0;
      font-size: @text-paragraph-font-size;
      line-height: 1;
      color: @color-violet-400;
    }
  }

  p {
    .button-text();
    display: flex;
    align-items: center;
    color: @color-primary-900;
  }

  input {
    opacity: 0;
    z-index: -1;
  }

  input:focus + &-box {
    background-color: @color-coolGray-200;
    outline: 0.1975rem solid @color-indigo-300;
  }
}
