@import (reference) "../../../styles/main";

.LandingButtonGrid {
  max-width: 100%;
  width: 100%;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: 1fr;
  @media @tablet-landscape {
    grid-template-columns: repeat(6, 1fr);
    gap: 1.25rem;
  }
  @media @tablet {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media @mobile {
    grid-template-columns: 1fr 1fr;
    gap: 1.125rem;
  }
  > a {
    .card-small-Layout();
    .card-hoverable-Layout();
    width: auto;
    height: 100%;
    text-align: center;
    outline: 0;
    grid-column: span 2;

    &:last-child:nth-child(4n - 1) {
      background: black !important;
      grid-column-end: -2;
      @media @tablet-landscape {
        grid-column-end: initial;
      }
    }
    &:nth-last-child(3):nth-child(4n + 1) {
      background: green !important;
      grid-column-end: 4;
      @media @tablet-landscape {
        grid-column-end: initial;
      }
    }

    &:nth-last-child(2):nth-child(4n + 1) {
      grid-column-end: 5;
      @media @tablet-landscape {
        grid-column-end: initial;
      }
    }

    &:last-child:nth-child(4n - 2) {
      grid-column-end: -3;
      @media @tablet-landscape {
        grid-column-end: initial;
      }
    }

    &:last-child:nth-child(4n - 3) {
      grid-column-end: 6;
      @media @tablet-landscape {
        grid-column-end: initial;
      }
    }

    @media @tablet-landscape {
      &:last-child:nth-child(3n - 1) {
        grid-column-end: -2;
      }
      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }
      &:last-child:nth-child(3n - 2) {
        grid-column-end: 5;
      }
    }

    @media @tablet {
      &:last-child:nth-child(3n - 1) {
        grid-column-end: inherit;
      }
      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: inherit;
      }
      &:last-child:nth-child(3n - 2) {
        grid-column-end: inherit;
      }
      &:last-child:nth-child(2n - 1) {
        grid-column-end: 4;
      }
    }

    @media @mobile {
      grid-column-end: initial !important;
    }

    img {
      max-width: 3rem;
      height: auto;
    }
  }

  &-title {
    .text-heading-3();
    margin-top: 1.5rem;
  }
  &-subtitle {
    .text-paragraph();
    margin: 0;
  }

  &-title,
  &-subtitle {
    &:hover {
      color: @color-primary-900;
    }
  }

  &-teal {
    > a {
      color: @color-primary-900!important;
      border-color: @color-teal-50;
      background-color: @color-teal-50;
      &:hover,
      &:active {
        border-color: @color-indigo-100;
      }
    }
  }
}
