@import (reference) "../../../../styles/main";

.OverviewChart {
  position: relative;
  &-error_message {
    align-self: center;
    color: @color-coolGray-900;
    height: 100%;
  }
  > a {
    align-items: center;
  }
  > a,
  > div {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 24px 20px;
    background-color: @color-white;
    transition: @transition-all;
    border-radius: 12px;
    height: 100%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    .bp-mobile({
      align-items: flex-start;
    });
  }
  &__sparkline {
    overflow: hidden;
    flex: 0 0 100px;
    min-width: 100%;
    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__loading {
      & > div {
        width: 100%;
        height: 20px;
        animation: skeletonBackgroundAnimation 1.5s ease-in-out;
        border-radius: @border-radius-sm;
      }
    }
    &__noData {
      color: @color-coolGray-100;
      font-size: @text-size-12;
    }
    & .recharts-surface {
      width: 100%;
    }
  }
  &__defaultChannelQueriesData {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin-bottom: 24px;
    .bp-phone({
      margin-bottom: 0;
    });
    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .bp-phone({
        flex-direction: column;
      });
    }
    &__loading {
      & > div {
        width: 100%;
        height: 20px;
        animation: skeletonBackgroundAnimation 1.5s ease-in-out;
        border-radius: @border-radius-sm;
      }
    }
    &__noData {
      color: @color-coolGray-100;
      font-size: @text-size-12;
    }
    &__list {
      display: flex;
      gap: 12px;
      &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: @color-coolGray-900;
        padding: 12px 24px;
        border-radius: 4px;
        border: none;
        outline: none;
        cursor: pointer;
        &--home {
          background-color: transparent;
          border: 1px solid var(--primary-900);
          padding: 12px;
          align-items: center;
          min-width: 150px;
          &:hover {
            background-color: var(--primary-100);
          }
        }
        .bp-phone({
          width: 100%;
          margin-bottom: 20px;
          flex-direction: row-reverse;
          justify-content: space-between;
        });
        > p {
          &:first-child {
            font-weight: 600;
            font-size: @text-size-30;
          }
          &:last-child {
            font-weight: 400;
            font-size: @text-size-14;
            color: #6b7280;
          }
        }
        &.active {
          color: #17009a;
          background: #e8e7f1;
          > p:last-child {
            color: #17009a;
          }
        }
        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px;
        }
      }
    }
  }
}
