@import (reference) "../../../styles/main";

.newsletter_highlights {
  &-background {
    .main-Layout();
    text-align: left;
    background-color: @color-coolGray-50;
  }

  &-posts {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3rem;
    row-gap: 3rem;
  }

  &-post {
    position: relative;
    height: 100%;
    border-radius: @card-border-radius;
    background-color: @color-white;
    border: 0.5rem solid @color-white;
    transition: @transition-all;

    &:hover {
      cursor: pointer;
      border-color: @color-indigo-100;
      box-shadow: @box-shadow-default;

      h3,
      p,
      span {
        color: @color-primary-900;
      }
    }
  }

  &-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 13.75rem;
    border-radius: @card-border-radius;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &-textarea {
    position: relative;
    width: 100%;
    padding: 1.75rem;
    background-color: @color-white;
    z-index: 40;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-title {
    .text-heading-3();
    margin-top: 0;
    margin-bottom: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    flex: 1;
  }

  &-date {
    font-family: @font-family-body;
    font-weight: 400;
    color: @color-violet-600;
    margin-top: 2rem;
  }

  &-description {
    .text-paragraph();
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &-more {
    .button-text();
    margin-top: 2rem;
    flex: 0;
    color: @color-primary-900;
  }
}

@media @tablet-landscape {
  .newsletter_highlights {
    &-posts {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media @tablet {
  .newsletter_highlights {
    &-posts {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem;
    }
  }
}
