@import (reference) "../../../styles/main";

.webinar_video_form {
  &-background {
    position: relative;
    z-index: @layer-top-2;
    outline: none;
    margin: 0 0 4rem;
    width: 100%;
    background-color: @color-coolGray-700;
    padding-top: 56.25%;
    @media @tablet-landscape {
      padding: 2rem 0;
    }

    @media @tablet {
      padding: 0;
      margin-bottom: 1.5rem;
    }

    @media @mobile {
      padding: 0;
      background-color: transparent;
    }
  }

  &-icon {
    color: @color-coolGray-500;
    top: 2rem;
    left: 2rem;
    position: absolute;
    font-size: 4rem;
    @media @tablet {
      display: none;
    }
  }

  &-header {
    .text-heading-3();
    margin: 0 0 2rem;
    text-align: left;
  }

  &-container {
    width: 100%;
    max-width: 32rem;
    padding: @card-medium-padding;
    background-color: @color-white;
    box-shadow: @box-shadow-default;
    border-radius: @card-border-radius;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .button-component {
      margin-top: 2rem;
      width: 100%;
    }

    @media @tablet-landscape {
      position: relative;
      transform: translate(-50%, 0);
      top: 0;
    }

    @media @tablet {
      box-shadow: none;
      border-radius: 0;
      top: 0;
      left: 0;
      transform: none;
      background-color: @color-blue-25;
      max-width: unset;
      padding: @card-medium-padding-mobile;
    }
  }
}
