@import (reference) "../../../styles/main";

.webinars_title_section {
  .hero-backgroundLayout();
  .gradient-violet-1000();
  width: 100%;
  height: 100%;
  padding-bottom: (@hero-padding-bottom + 12rem);

  @media @mobile {
    padding-bottom: (@hero-padding-bottom-mobile + 12rem);
  }

  &-container {
    max-width: @layout-max-width;
    margin: auto;
  }

  &-text_container {
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  h1 {
    z-index: @layer-top-1;
    .text-heading-1();
    color: @color-white;
  }
}
