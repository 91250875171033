@import (reference) "../../../styles/main";

.datepicker {
  &-main {
    position: relative;
  }

  &-label {
    display: flex;
    justify-content: space-between;
    .text-paragraph();
    color: @color-coolGray-600;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.25rem;

    span {
      font-weight: 400;
      color: @color-violet-500;
    }
  }

  &-proper {
    width: 100%;
    height: 3rem;
    outline: none;
    border: 1px solid @color-violet-100;
    border-radius: 0.25rem;
    .text-paragraph();
    color: @color-coolGray-900;
    margin-top: 0;
    font-weight: 300;
    padding: 0.25rem 0.75rem;
    margin-bottom: 1.5rem;

    &.error {
      border: 1px solid @color-pink-900;
    }

    &:focus {
      background: @color-white;
      border: 1px solid @color-primary-900;
      outline: 0.1975rem solid @color-indigo-300;
      box-shadow: @box-shadow-active;
    }
  }

  &-error {
    font-weight: 400;
    font-family: @font-family-body;
    font-size: 0.875rem;
    position: absolute;
    bottom: 0.375rem;
    color: @color-pink-900;
  }
}
