@import (reference) "../../../styles/main";

.ProjectComment {
  &__child {
    margin-left: 20px;
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 54px;
    gap: 5px;
    border: 1px solid @color-coolGray-300;
    border-radius: 6px;
    padding: 12px;
    padding-right: 56px;

    ul {
      list-style: initial;
      padding: revert;
    }

    ol {
      list-style: decimal;
      padding: revert;
    }

    &__text {
      font-size: 14px;
    }

    &__author {
      display: flex;
      font-size: 12px;
      color: @color-coolGray-600;

      i {
        font-style: italic;
      }
    }

    & > button {
      position: absolute;
      top: 10px;
      right: 10px;

      &.ProjectComment__body__edit {
        position: initial;
        margin-top: 10px;
        width: 100%;
      }
    }

    &__reply {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 10px 0 10px 20px;
    }
  }

  // &__controls {
  //   display: flex;
  //   gap: 10px;
  // }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
