@import (reference) "../../../styles/main";

.adx_exchange_form {
  &-background {
    width: 50%;
  }

  &-main {
    position: relative;
    .card-medium-Layout();
    background-color: @color-white;

    z-index: @layer-top-2;

    .button-component {
      width: 100%;
      min-width: unset;
      z-index: @layer-top-1;
    }

    .terms_checkbox-main {
      margin-bottom: 1.875rem;
    }

    .terms_checkbox-text {
      font-weight: 400;
    }
  }

  &-header {
    font-family: @font-family-headings;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 600;
    margin-bottom: 3.125rem;
  }

  &-label {
    .text-heading-3();
    margin-top: 0;
    display: block;
    margin-bottom: 2.5rem;
  }

  &-footer {
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.75rem;

    a {
      text-decoration: underline;
      color: @color-primary-900;
    }
  }
}

@media (max-width: 77.5rem) {
  .adx_exchange_form {
    &-background {
      width: 100%;
    }
  }
}

@media @mobile {
  .adx_exchange_form {
    &-main {
      padding: 1.875rem;
    }
  }
}
