@import (reference) "../../styles/main";

.Campaign {
  &.Campaign-WithCarousel {
    &.LandingHero {
      &-background {
        @media @mobile {
          padding-bottom: 0;
        }
      }
    }
  }
  &.LandingMainContent {
    .section-layout .LandingTextImageContent-col {
      max-width: unset;
    }
  }
  &.LandingHero {
    @media @tablet-landscape {
      gap: 3rem;
    }
    .SignUpForm__textfieldWrapper {
      @media @mobile {
        margin-bottom: 20px;
      }
    }
    .LandingHero {
      &-form {
        min-height: 48rem;
      }
      &-title {
        @media @desktop-small {
          font-size: 3rem;
        }
        @media @mobile {
          font-size: 1.75rem;
        }
      }

      &-subtitle {
        @media @mobile {
          font-size: @text-paragraph-font-size;
          margin: 1rem 0 0;
        }
      }
    }
    &.Campaign-WithCarousel {
      .LandingHero {
        &-title,
        &-subtitle {
          padding-right: 4rem;
          @media @desktop-small {
            padding-right: 0;
          }
        }
        &-title {
          margin-top: 6rem;
          @media @tablet-landscape {
            margin-top: 1.5rem;
          }
        }
      }
    }
  }
}
