@import (reference) "../../styles/main";

.platform_diagram {
  display: grid;
  grid-template-columns: repeat(5, fit-content(3.125rem));
  @media @tablet-landscape {
    grid-template-columns: 1fr 1fr;
    column-gap: 2.5rem;
  }
  margin: auto;
  color: @color-violet-900;
  align-items: center;
  &-main {
    border: 1px dashed @color-teal-400;
    border-radius: @card-border-radius;
    padding: 0.75rem;
    @media @tablet-landscape {
      grid-column: 1 / span 2;
    }
  }
  &-aside {
    border: 1px dashed @color-teal-400;
    border-radius: @card-border-radius;
    padding: 0.75rem;
    @media @tablet-landscape {
      &.left {
        grid-row: 3 / span 1;
        height: 100%;
        grid-column: 1 / span 1;
      }
      &.right {
        grid-row: 3 / span 1;
        height: 100%;
        grid-column: 2 / span 1;
      }
    }
    .content {
      padding: 3.75rem 1.25rem;
      background: @color-violet-600;
      max-width: 7.5rem;
      border-radius: 0.5rem;
      text-align: center;
      color: @color-white;
      height: auto;
      margin: 0;
      overflow: hidden;

      @media @tablet-landscape {
        max-width: unset;
        height: 100%;
      }
    }
  }

  &-line {
    width: 6.25rem;
    border-top: 1px dashed @color-teal-400;
    @media @tablet-landscape {
      &.left {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
      }
      &.right {
        grid-row: 2 / span 1;
        grid-column: 2 / span 1;
      }
      width: 0;
      height: 6.25rem;
      border-right: 1px dashed @color-teal-400;
      margin: auto;
    }
  }
  .white-card {
    background-color: @color-white;
    padding: 1.25rem;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .column-title {
      font-size: 0.8rem;
      text-align: center;
    }
    .button-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1.5rem;
      padding: 1.4375rem;
      border: 1px dashed #0b009a;
      border-radius: 0.5rem;
      margin-bottom: 1.25rem;
      position: relative;
      text-align: center;
      width: 100%;
      @media @mobile {
        grid-template-columns: 1fr;
        border: none;
        padding: 0;
        align-items: center;
      }

      .aqua-tag {
        color: @color-primary-900;
        background-color: @color-teal-400;
        border-radius: 0.25rem;
        height: 1.25rem;
        padding: 0 0.625rem;
        font-weight: bold;
      }
      & > .button-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .aqua-tag {
          position: absolute;
          top: -2rem;
        }
        @media @mobile {
          border: 1px dashed #0b009a;
          padding: 1.4375rem;
          &:first-child {
            border-bottom: none;
            border-radius: 0.75rem 0.75rem 0 0;
          }
          &:nth-child(2) {
            border-radius: 0 0 0.75rem 0.75rem;
          }
          .aqua-tag {
            position: absolute;
            top: -0.625rem;
          }
          .bottom-tag {
            grid-column: 1 / span 2;
            position: absolute;
            bottom: -1.375rem;
            left: 50%;
          }
        }
        a {
          width: 100%;
          min-width: 12.5rem;
          margin: 0.3125rem;
          .button-text();
          button {
            height: 2.625rem;
            width: 100%;
            @media @tablet {
              min-width: 0;
            }
          }
        }
      }
      .bottom-tag {
        grid-column: 1 / span 2;
        position: absolute;
        bottom: -0.625rem;
        width: 100%;
        @media @mobile {
          grid-column: 1 / span 1;
        }
      }
    }
    .demyst-logo {
      margin: 2.375rem 0;
    }
  }
  .list-container {
    margin-top: 0.75rem;
    background: @color-violet-600;
    border-radius: 0.75rem;
    color: @color-white;
    padding: 1px;
    text-align: center;
    &-header {
      margin: 0.5rem 0;
      text-transform: uppercase;
    }
    ul {
      li {
        background: @color-violet-900;
        padding: 0.5rem;
        margin: 1px;
        a {
          color: @color-white;
        }
        &:first-child {
          border-radius: 0.625rem 0.625rem 0 0;
        }
        &:last-child {
          border-radius: 0 0 0.625rem 0.625rem;
        }
      }
    }
  }
}
