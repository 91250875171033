@import (reference) "../../../../styles/main";

.DataPageNav {
  display: flex;
  align-items: flex-end;
  height: 50px;
  margin-left: -40px;
  padding-left: 40px;

  &__buttons {
    display: none;
    gap: 12px;
    align-items: center;
    margin-left: auto;
    margin-right: 32px;
    height: 50px;

    > button {
      height: 32px;
    }
  }

  .bp-mobile({
    margin-left: -20px;
    padding-left: 20px;
  });

  &--detached {
    position: fixed;
    top: 80px;
    background-color: white;
    border-bottom: 1px solid #E5E7EB;
    z-index: @z-index-productpage;

    .DataPageNav__buttons {
      display: flex;
      .bp-desktop-big({
        display: none;
      });
    }
  }

  &__navItems {
    outline: none;
    border: none;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    padding: 12px 16px;
    font-size: @text-size-16;
    font-weight: 400;
    color: #4B5563;
    margin-bottom: -1px;
    cursor: pointer;
  }
}
