@import (reference) "../../../../styles/main";

.CloneAPIModal {
  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 960px;
    max-width: 95%;
    max-height: 95%;
    background-color: @color-white;
    padding: 80px 100px 100px;
    border: 1px solid #E5E5E5;
    box-shadow: @box-shadow-darker;
    border-radius: 8px;
    overflow: auto;
    .bp-desktop-big({
      padding: 80px 50px 50px;
    });
    .bp-tablet({
      padding: 60px 30px 30px;
    });
    &__closeButton {
      position: absolute;
      top: 10px;
      left: 20px;
    }
    &__header {
      font-weight: 700;
      font-size: @text-size-32;
      line-height: 40px;
      margin-bottom: 30px;
    }
    &__paragraph {
      font-weight: 400;
      font-size: @text-size-16;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 5;
  }
  &__form {
    flex: 1;
    &__checkboxWrapper {
      padding: 20px;
      background: #F0EFF9;
      border-radius: @border-radius-sm;
      margin-bottom: 40px;
    }
    button {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding: 10px 15px;
      width: 100%;
      font-weight: 600;
      font-size: @text-size-16;
      line-height: 130%;
    }
    &__error {
      margin: 20px 0;
      width: 100%;
    }
    &__actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
