@import (reference) "../../../../../styles/main";

.UseCasesBatchModal {
  position: relative;
  background-color: @color-white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 48px;
  width: 760px;
  max-width: 95%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__close {
    position: absolute;
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 0;
    right: 20px;
    top: 20px;
    background-color: transparent;
    border: none;
    outline: none;

    > svg {
      stroke: #9B92D2;
      stroke-width: 2.5;
    }

    &:hover {
      cursor: pointer;

      > svg {
        stroke: #17009a;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: @text-size-30;
    line-height: 1.3;
    margin-bottom: 24px;
  }

  &__description {
    line-height: 1.4;
    margin-bottom: 40px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  &__priceTag {
    padding: 4px 12px 6px;
    font-weight: 600;
    border-radius: 16px;
    line-height: 1.5;
    color: #BBFAEE;
    background-color: #4F9E90;
  }
}
