@import (reference) "../../styles/main";

.AppErrorBoundary {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #F7F7FA;
  color: #170A4D;

  &__contentWrapper {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  &__message {
    font-size: @text-size-22;
    font-weight: 600;
    margin-bottom: 30px;
  }

  &__reloadPageButton {
    margin-bottom: 20px !important;
  }

  &__returnHome {
    width: 100%;
    &__button {
      width: 100%;
    }
  }
}
